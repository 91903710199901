import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import {
  Formik,
  Field,
  FieldArray,
  Form as FormikForm,
  ErrorMessage,
} from "formik";
import LoginLayout from "../components/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import toast from "react-hot-toast";
import { editAgentAction, getAgentDataById } from "../Redux/Action/agentAction";
import Select from "react-select";
import { getDirectorListAction } from "../Redux/Action/directorAction";
import ProgressBar from "../CommonModals/ProgressBar";

const AddAssociateAgentSeven = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const agentId = id && Decryptedid(atob(id));

  const getAgentDetailById = useSelector((e) => e.agentAuthData.agentDetail);

  const directorListData = useSelector(
    (e) => e.directorAuthData.getListOfDirectors
  );

  const directorList = directorListData.map((state) => {
    return {
      value: state._id,
      label: state.full_name,
    };
  });

  console.log(getAgentDetailById, "getAgentDetailById");

  const validate = (values) => {
    const errors = {};

    if (values.have_any_director_from_this_agent === null) {
      errors.have_any_director_from_this_agent = "Required";
    }

    if (values.have_any_director_from_this_agent === 1) {
      values.agent1.forEach((agent, index) => {
        if (!agent.agent_directors_name) {
          errors[`agent1[${index}].agent_directors_name`] = "Required";
        }
      });
    }

    // second

    if (values.have_any_my_director_in_team === null) {
      errors.have_any_my_director_in_team = "Required";
    }

    if (values.have_any_my_director_in_team === 1) {
      values.agent2.forEach((agent, index) => {
        if (!agent.my_directors_name) {
          errors[`agent2[${index}].my_directors_name`] = "Required";
        }
      });
    }

    // third

    if (values.have_any_director_proposed_to_this_agent === null) {
      errors.have_any_director_proposed_to_this_agent = "Required";
    }

    if (values.have_any_director_proposed_to_this_agent === 1) {
      values.agent3.forEach((agent, index) => {
        if (!agent.my_proposed_directors_name) {
          errors[`agent3[${index}].my_proposed_directors_name`] = "Required";
        }
      });
    }

    // fourth

    if (values.have_any_director_proposed_from_this_agent === null) {
      errors.have_any_director_proposed_from_this_agent = "Required";
    }

    if (values.have_any_director_proposed_from_this_agent === 1) {
      values.agent4.forEach((agent, index) => {
        if (!agent.proposed_directors_name) {
          errors[`agent4[${index}].proposed_directors_name`] = "Required";
        }
      });
    }

    // five

    if (values.have_any_director_request_this_agent === null) {
      errors.have_any_director_request_this_agent = "Required";
    }

    if (values.have_any_director_request_this_agent === 1) {
      values.agent5.forEach((agent, index) => {
        if (!agent.my_request_directors_name) {
          errors[`agent5[${index}].my_request_directors_name`] = "Required";
        }
      });
    }

    return errors;
  };

  const handleSaveAndClose = (values, validateForm, setErrors) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length > 0) {
        toast.error("Please fill in all required fields.");
        setErrors(errors);
        return;
      }

      handleSubmit(values, "save");
    });
  };

  const handleSubmit = (values, type) => {
    let formData = new FormData();

    formData.append(
      "have_any_director_from_this_agent",
      values.have_any_director_from_this_agent
    );

    if (values.have_any_director_from_this_agent === 1) {
      values.agent1.forEach((agent, index) => {
        formData.append(
          `agent_directors_name[${index}]`,
          agent.agent_directors_name
        );
      });
    }

    // second
    formData.append(
      "have_any_my_director_in_team",
      values.have_any_my_director_in_team
    );

    if (values.have_any_my_director_in_team === 1) {
      values.agent2.forEach((agent, index) => {
        formData.append(`my_directors_name[${index}]`, agent.my_directors_name);
      });
    }

    // third
    formData.append(
      "have_any_director_proposed_to_this_agent",
      values.have_any_director_proposed_to_this_agent
    );

    if (values.have_any_director_proposed_to_this_agent === 1) {
      values.agent3.forEach((agent, index) => {
        formData.append(
          `my_proposed_directors_name[${index}]`,
          agent.my_proposed_directors_name
        );
      });
    }

    // fourth
    formData.append(
      "have_any_director_proposed_from_this_agent",
      values.have_any_director_proposed_from_this_agent
    );

    if (values.have_any_director_proposed_from_this_agent === 1) {
      values.agent4.forEach((agent, index) => {
        formData.append(
          `proposed_directors_name[${index}]`,
          agent.proposed_directors_name
        );
      });
    }

    // five
    formData.append(
      "have_any_director_request_this_agent",
      values.have_any_director_request_this_agent
    );

    if (values.have_any_director_request_this_agent === 1) {
      values.agent5.forEach((agent, index) => {
        formData.append(
          `my_request_directors_name[${index}]`,
          agent.my_request_directors_name
        );
      });
    }

    formData.append("agentId", agentId);
    formData.append("is_profile_completed", 3);

    dispatch(editAgentAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        if (type == "save") {
          navigate(`/my-profile`);
        } else {
          navigate(
            `/add-associate-eleven/${Encryptedid(res?.payload?.data?._id)}`
          );
        }
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const initialValues = {
    have_any_director_from_this_agent:
      getAgentDetailById?.have_any_director_from_this_agent ?? 0,
    agent1:
      getAgentDetailById?.agent_directors_name?.length > 0
        ? getAgentDetailById.agent_directors_name.map((elem) => ({
            agent_directors_name: elem?._id || "",
          }))
        : [
            {
              agent_directors_name: "",
            },
          ],

    have_any_my_director_in_team:
      getAgentDetailById?.have_any_my_director_in_team ?? 0,
    agent2:
      getAgentDetailById?.my_directors_name?.length > 0
        ? getAgentDetailById.my_directors_name.map((elem) => ({
            my_directors_name: elem?._id || "",
          }))
        : [
            {
              my_directors_name: "",
            },
          ],

    have_any_director_proposed_to_this_agent:
      getAgentDetailById?.have_any_director_proposed_to_this_agent ?? 0,
    agent3:
      getAgentDetailById?.my_proposed_directors_name?.length > 0
        ? getAgentDetailById.my_proposed_directors_name?.map((elem) => ({
            my_proposed_directors_name: elem?._id || "",
          }))
        : [
            {
              my_proposed_directors_name: "",
            },
          ],

    have_any_director_proposed_from_this_agent:
      getAgentDetailById?.have_any_director_proposed_from_this_agent ?? 0,
    agent4:
      getAgentDetailById?.proposed_directors_name?.length > 0
        ? getAgentDetailById.proposed_directors_name?.map((elem) => ({
            proposed_directors_name: elem?._id || "",
          }))
        : [
            {
              proposed_directors_name: "",
            },
          ],

    have_any_director_request_this_agent:
      getAgentDetailById?.have_any_director_request_this_agent ?? 0,
    agent5:
      getAgentDetailById?.my_request_directors_name?.length > 0
        ? getAgentDetailById.my_request_directors_name?.map((elem) => ({
            my_request_directors_name: elem?._id || "",
          }))
        : [
            {
              my_request_directors_name: "",
            },
          ],
  };

  useEffect(() => {
    if (agentId) {
      const fetchData = async () => {
        try {
          await Promise.all([
            dispatch(getAgentDataById({ id: agentId })),
            dispatch(getDirectorListAction()),
          ]);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [dispatch, agentId]);

  return (
    <LoginLayout>
      <section>
        <Container>
          <ProgressBar currentStep={3} totalSteps={5} />
          <div className="steps-heading">
            <p>
              {" "}
              <span>Step 3:</span> Transactions with Technical/Spot Directors{" "}
            </p>
          </div>
          <div className="">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center personal-heading ">
              <h3>Director's Information</h3>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validate={validate}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, validateForm, setErrors }) => (
                <FormikForm>
                  {console.log(
                    values.have_any_director_from_this_agent,
                    "34ertretertert"
                  )}
                  <div>
                    <div className="player-agent-fist">
                      {/* first */}
                      <div className=" w-100">
                        <div className=" mt-4">
                          <div className="d-flex justify-content-between this-team">
                            <p className="h-20">
                              {/* Do I have any Director in team from this Agent? */}
                              Technical/Sport Directors I have in teams from
                              this Agent
                            </p>
                          </div>
                          <div className="cmn-form-fields mt-2">
                            <Row>
                              <Col lg={6}>
                                <div className="licensed-sec">
                                  <h3>Yes</h3>
                                  <div className="form-check form-switch">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      name="have_any_director_from_this_agent"
                                      checked={
                                        values.have_any_director_from_this_agent ===
                                        1
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "have_any_director_from_this_agent",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="licensed-sec">
                                  <h3>No</h3>
                                  <div className="form-check form-switch">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      name="have_any_director_from_this_agent"
                                      checked={
                                        values.have_any_director_from_this_agent ===
                                        0
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "have_any_director_from_this_agent",
                                          0
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </Col>
                              <ErrorMessage
                                name="have_any_director_from_this_agent"
                                component="div"
                                className="text-danger"
                              />
                            </Row>
                          </div>
                        </div>
                        {values.have_any_director_from_this_agent === 1 && (
                          <div className=" ">
                            <div className="d-flex justify-content-between">
                              {/* <h3 className="h-20">agent</h3> */}
                              <button
                                type="button"
                                style={{ background: "none", border: "none" }}
                              >
                                <svg
                                  width="28"
                                  height="28"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  {/* SVG content */}
                                </svg>
                              </button>
                            </div>
                            <div className="cmn-form-fields mt-2">
                              <FieldArray name="agent1">
                                {({ push, remove }) => (
                                  <>
                                    <div className="d-flex justify-content-between">
                                      {" "}
                                      <h3 className="h-20">Director 1 </h3>
                                      <button
                                        type="button"
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                        onClick={() =>
                                          push({
                                            agent_directors_name: "",
                                          })
                                        }
                                      >
                                        <svg
                                          width="28"
                                          coaching_licenses="28"
                                          viewBox="0 0 28 28"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                            fill="#4C8BB4"
                                          />
                                        </svg>
                                      </button>
                                    </div>

                                    {values.agent1.map((player, index) => (
                                      <div key={index}>
                                        <div
                                          className={`more-coache ${
                                            index > 0 ? "mt-3" : ""
                                          }`}
                                        >
                                          {index !== 0 && (
                                            <h3 className="h-20">
                                              Director {index + 1}
                                            </h3>
                                          )}
                                          {index !== 0 && (
                                            <button
                                              type="button"
                                              style={{
                                                border: "none",
                                                background: "none",
                                              }}
                                              onClick={() => remove(index)}
                                            >
                                              <svg
                                                width="28"
                                                height="28"
                                                viewBox="0 0 28 28"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                  fill="#4C8BB4"
                                                />
                                              </svg>
                                            </button>
                                          )}
                                        </div>
                                        <Row>
                                          {/* <Col lg={4}>
                                      <Form.Group className="mb-3">
                                        <Field
                                          as={Form.Control}
                                          type="text"
                                          name={`agent[${index}].agent_directors_name`}
                                          placeholder="Enter name"
                                        />
                                      </Form.Group>
                                      <ErrorMessage
                                        name={`agent[${index}].agent_directors_name`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Col> */}

                                          <Col lg={12}>
                                            <div className="profile-card mb-3 label-text-up">
                                              <label className="mb-2">
                                                Full Name
                                              </label>
                                              <Select
                                                name={`agent1[${index}].agent_directors_name`}
                                                placeholder="Select"
                                                classNamePrefix="custom-select"
                                                options={directorList}
                                                value={directorList.find(
                                                  (option) =>
                                                    option.value ===
                                                    values.agent1[index]
                                                      .agent_directors_name
                                                )}
                                                onChange={(option) =>
                                                  setFieldValue(
                                                    `agent1[${index}].agent_directors_name`,
                                                    option.value
                                                  )
                                                }
                                              />
                                              <ErrorMessage
                                                name={`agent1[${index}].agent_directors_name`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </FieldArray>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* second */}
                      <div className=" w-100">
                        <div className=" mt-4">
                          <div className="d-flex justify-content-between this-team">
                            <p className="h-20">
                              {/* Does this agent have any of my directors in team? */}
                              My Technical/Sport Directors this Agent has in
                              teams
                            </p>
                          </div>
                          <div className="cmn-form-fields mt-2">
                            <Row>
                              <Col lg={6}>
                                <div className="licensed-sec">
                                  <h3>Yes</h3>
                                  <div className="form-check form-switch">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      name="have_any_my_director_in_team"
                                      checked={
                                        values.have_any_my_director_in_team ===
                                        1
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "have_any_my_director_in_team",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="licensed-sec">
                                  <h3>No</h3>
                                  <div className="form-check form-switch">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      name="have_any_my_director_in_team"
                                      checked={
                                        values.have_any_my_director_in_team ===
                                        0
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "have_any_my_director_in_team",
                                          0
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </Col>
                              <ErrorMessage
                                name="have_any_my_director_in_team"
                                component="div"
                                className="text-danger"
                              />
                            </Row>
                          </div>
                        </div>
                        {values.have_any_my_director_in_team === 1 && (
                          <div className="">
                            <div className="d-flex justify-content-between">
                              {/* <h3 className="h-20">agent</h3> */}
                              <button
                                type="button"
                                style={{ background: "none", border: "none" }}
                              >
                                <svg
                                  width="28"
                                  height="28"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  {/* SVG content */}
                                </svg>
                              </button>
                            </div>
                            <div className="cmn-form-fields mt-2">
                              <FieldArray name="agent2">
                                {({ push, remove }) => (
                                  <>
                                    <div className="d-flex justify-content-between">
                                      {" "}
                                      <h3 className="h-20">Director 1</h3>
                                      <button
                                        type="button"
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                        onClick={() =>
                                          push({
                                            my_directors_name: "",
                                          })
                                        }
                                      >
                                        <svg
                                          width="28"
                                          coaching_licenses="28"
                                          viewBox="0 0 28 28"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                            fill="#4C8BB4"
                                          />
                                        </svg>
                                      </button>
                                    </div>

                                    {values.agent2.map((player, index) => (
                                      <div key={index}>
                                        <div
                                          className={`more-coache ${
                                            index > 0 ? "mt-3" : ""
                                          }`}
                                        >
                                          {index !== 0 && (
                                            <h3 className="h-20">
                                              Director {index + 1}
                                            </h3>
                                          )}
                                          {index !== 0 && (
                                            <button
                                              type="button"
                                              style={{
                                                border: "none",
                                                background: "none",
                                              }}
                                              onClick={() => remove(index)}
                                            >
                                              <svg
                                                width="28"
                                                height="28"
                                                viewBox="0 0 28 28"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                  fill="#4C8BB4"
                                                />
                                              </svg>
                                            </button>
                                          )}
                                        </div>
                                        <Row>
                                          {/* <Col lg={4}>
                                      <Form.Group className="mb-3">
                                        <Field
                                          as={Form.Control}
                                          type="text"
                                          name={`agent[${index}].my_directors_name`}
                                          placeholder="Enter name"
                                        />
                                      </Form.Group>
                                      <ErrorMessage
                                        name={`agent[${index}].my_directors_name`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Col> */}

                                          <Col lg={12}>
                                            <div className="profile-card mb-3 label-text-up">
                                              <label className="mb-2">
                                                Full Name
                                              </label>
                                              <Select
                                                name={`agent2[${index}].my_directors_name`}
                                                placeholder="Select"
                                                classNamePrefix="custom-select"
                                                options={directorList}
                                                value={directorList.find(
                                                  (option) =>
                                                    option.value ===
                                                    values.agent2[index]
                                                      .my_directors_name
                                                )}
                                                onChange={(option) =>
                                                  setFieldValue(
                                                    `agent2[${index}].my_directors_name`,
                                                    option.value
                                                  )
                                                }
                                              />
                                              <ErrorMessage
                                                name={`agent2[${index}].my_directors_name`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </FieldArray>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* third */}
                    <div className="player-agent-fist">
                      <div className=" w-100">
                        <div className=" mt-4">
                          <div className="d-flex justify-content-between this-team">
                            <p className="h-20">
                              {/* Do I have proposed any of my directors to this
                            agent? */}
                              Technical/Sport Directors I have Proposed to this
                              Agent
                            </p>
                          </div>
                          <div className="cmn-form-fields mt-2">
                            <Row>
                              <Col lg={6}>
                                <div className="licensed-sec">
                                  <h3>Yes</h3>
                                  <div className="form-check form-switch">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      name="have_any_director_proposed_to_this_agent"
                                      checked={
                                        values.have_any_director_proposed_to_this_agent ===
                                        1
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "have_any_director_proposed_to_this_agent",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="licensed-sec">
                                  <h3>No</h3>
                                  <div className="form-check form-switch">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      name="have_any_director_proposed_to_this_agent"
                                      checked={
                                        values.have_any_director_proposed_to_this_agent ===
                                        0
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "have_any_director_proposed_to_this_agent",
                                          0
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </Col>
                              <ErrorMessage
                                name="have_any_director_proposed_to_this_agent"
                                component="div"
                                className="text-danger"
                              />
                            </Row>
                          </div>
                        </div>
                        {values.have_any_director_proposed_to_this_agent ===
                          1 && (
                          <div className="">
                            <div className="d-flex justify-content-between">
                              {/* <h3 className="h-20">agent</h3> */}
                              <button
                                type="button"
                                style={{ background: "none", border: "none" }}
                              >
                                <svg
                                  width="28"
                                  height="28"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  {/* SVG content */}
                                </svg>
                              </button>
                            </div>
                            <div className="cmn-form-fields mt-2">
                              <FieldArray name="agent3">
                                {({ push, remove }) => (
                                  <>
                                    <div className="d-flex justify-content-between">
                                      {" "}
                                      <h3 className="h-20">Director 1</h3>
                                      <button
                                        type="button"
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                        onClick={() =>
                                          push({
                                            my_proposed_directors_name: "",
                                          })
                                        }
                                      >
                                        <svg
                                          width="28"
                                          coaching_licenses="28"
                                          viewBox="0 0 28 28"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                            fill="#4C8BB4"
                                          />
                                        </svg>
                                      </button>
                                    </div>

                                    {values.agent3.map((player, index) => (
                                      <div key={index}>
                                        <div
                                          className={`more-coache ${
                                            index > 0 ? "mt-3" : ""
                                          }`}
                                        >
                                          {index !== 0 && (
                                            <h3 className="h-20">
                                              Director {index + 1}
                                            </h3>
                                          )}
                                          {index !== 0 && (
                                            <button
                                              type="button"
                                              style={{
                                                border: "none",
                                                background: "none",
                                              }}
                                              onClick={() => remove(index)}
                                            >
                                              <svg
                                                width="28"
                                                height="28"
                                                viewBox="0 0 28 28"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                  fill="#4C8BB4"
                                                />
                                              </svg>
                                            </button>
                                          )}
                                        </div>
                                        <Row>
                                          {/* <Col lg={4}>
                                      <Form.Group className="mb-3">
                                        <Field
                                          as={Form.Control}
                                          type="text"
                                          name={`agent[${index}].my_proposed_directors_name`}
                                          placeholder="Enter name"
                                        />
                                      </Form.Group>
                                      <ErrorMessage
                                        name={`agent[${index}].my_proposed_directors_name`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Col> */}

                                          <Col lg={12}>
                                            <div className="profile-card mb-3 label-text-up">
                                              <label className="mb-2">
                                                Full Name
                                              </label>
                                              <Select
                                                name={`agent3[${index}].my_proposed_directors_name`}
                                                placeholder="Select"
                                                classNamePrefix="custom-select"
                                                options={directorList}
                                                value={directorList.find(
                                                  (option) =>
                                                    option.value ===
                                                    values.agent3[index]
                                                      .my_proposed_directors_name
                                                )}
                                                onChange={(option) =>
                                                  setFieldValue(
                                                    `agent3[${index}].my_proposed_directors_name`,
                                                    option.value
                                                  )
                                                }
                                              />
                                              <ErrorMessage
                                                name={`agent3[${index}].my_proposed_directors_name`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </FieldArray>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* fourth */}
                      <div className=" w-100">
                        <div className=" mt-4">
                          <div className="d-flex justify-content-between this-team">
                            <p className="h-20">
                              {/* Does this agent proposed to me any directors? */}
                              Technical/Sport Directors Have been proposed to me
                              from this Agent
                            </p>
                          </div>
                          <div className="cmn-form-fields mt-2">
                            <Row>
                              <Col lg={6}>
                                <div className="licensed-sec">
                                  <h3>Yes</h3>
                                  <div className="form-check form-switch">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      name="have_any_director_proposed_from_this_agent"
                                      checked={
                                        values.have_any_director_proposed_from_this_agent ===
                                        1
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "have_any_director_proposed_from_this_agent",
                                          1
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="licensed-sec">
                                  <h3>No</h3>
                                  <div className="form-check form-switch">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      name="have_any_director_proposed_from_this_agent"
                                      checked={
                                        values.have_any_director_proposed_from_this_agent ===
                                        0
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "have_any_director_proposed_from_this_agent",
                                          0
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </Col>
                              <ErrorMessage
                                name="have_any_director_proposed_from_this_agent"
                                component="div"
                                className="text-danger"
                              />
                            </Row>
                          </div>
                        </div>
                        {values.have_any_director_proposed_from_this_agent ===
                          1 && (
                          <div className="">
                            <div className="d-flex justify-content-between">
                              {/* <h3 className="h-20">agent</h3> */}
                              <button
                                type="button"
                                style={{ background: "none", border: "none" }}
                              >
                                <svg
                                  width="28"
                                  height="28"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  {/* SVG content */}
                                </svg>
                              </button>
                            </div>
                            <div className="cmn-form-fields mt-2">
                              <FieldArray name="agent4">
                                {({ push, remove }) => (
                                  <>
                                    <div className="d-flex justify-content-between">
                                      {" "}
                                      <h3 className="h-20">Director 1</h3>
                                      <button
                                        type="button"
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                        onClick={() =>
                                          push({
                                            proposed_directors_name: "",
                                          })
                                        }
                                      >
                                        <svg
                                          width="28"
                                          coaching_licenses="28"
                                          viewBox="0 0 28 28"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                            fill="#4C8BB4"
                                          />
                                        </svg>
                                      </button>
                                    </div>

                                    {values.agent4.map((player, index) => (
                                      <div key={index}>
                                        <div
                                          className={`more-coache ${
                                            index > 0 ? "mt-3" : ""
                                          }`}
                                        >
                                          {index !== 0 && (
                                            <h3 className="h-20">
                                              Director {index + 1}
                                            </h3>
                                          )}
                                          {index !== 0 && (
                                            <button
                                              type="button"
                                              style={{
                                                border: "none",
                                                background: "none",
                                              }}
                                              onClick={() => remove(index)}
                                            >
                                              <svg
                                                width="28"
                                                height="28"
                                                viewBox="0 0 28 28"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                  fill="#4C8BB4"
                                                />
                                              </svg>
                                            </button>
                                          )}
                                        </div>
                                        <Row>
                                          {/* <Col lg={4}>
                                      <Form.Group className="mb-3">
                                        <Field
                                          as={Form.Control}
                                          type="text"
                                          name={`agent[${index}].proposed_directors_name`}
                                          placeholder="Enter name"
                                        />
                                      </Form.Group>
                                      <ErrorMessage
                                        name={`agent[${index}].proposed_directors_name`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Col> */}

                                          <Col lg={12}>
                                            <div className="profile-card mb-3 label-text-up">
                                              <label className="mb-2">
                                                Full Name
                                              </label>
                                              <Select
                                                name={`agent4[${index}].proposed_directors_name`}
                                                placeholder="Select"
                                                classNamePrefix="custom-select"
                                                options={directorList}
                                                value={directorList.find(
                                                  (option) =>
                                                    option.value ===
                                                    values.agent4[index]
                                                      .proposed_directors_name
                                                )}
                                                onChange={(option) =>
                                                  setFieldValue(
                                                    `agent4[${index}].proposed_directors_name`,
                                                    option.value
                                                  )
                                                }
                                              />
                                              <ErrorMessage
                                                name={`agent4[${index}].proposed_directors_name`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </FieldArray>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* five */}
                    {/* <div>
                      <div className=" mt-4">
                        <div className="d-flex justify-content-between this-team">
                          <p className="h-20">
                            Profile of the Technical/Sport Directors Request
                            from this Agent
                          </p>
                        </div>
                        <div className="cmn-form-fields mt-2">
                          <Row>
                            <Col lg={3}>
                              <div className="licensed-sec">
                                <h3>Yes</h3>
                                <div className="form-check form-switch">
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    name="have_any_director_request_this_agent"
                                    checked={
                                      values.have_any_director_request_this_agent ===
                                      1
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "have_any_director_request_this_agent",
                                        1
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="licensed-sec">
                                <h3>No</h3>
                                <div className="form-check form-switch">
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    name="have_any_director_request_this_agent"
                                    checked={
                                      values.have_any_director_request_this_agent ===
                                      0
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "have_any_director_request_this_agent",
                                        0
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                            <ErrorMessage
                              name="have_any_director_request_this_agent"
                              component="div"
                              className="text-danger"
                            />
                          </Row>
                        </div>
                      </div>
                      {values.have_any_director_request_this_agent === 1 && (
                        <div className="w-50">
                          <div className="d-flex justify-content-between">
                            <button
                              type="button"
                              style={{ background: "none", border: "none" }}
                            >
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                              </svg>
                            </button>
                          </div>
                          <div className="cmn-form-fields mt-2">
                            <FieldArray name="agent5">
                              {({ push, remove }) => (
                                <>
                                  <div className="d-flex justify-content-between">
                                    {" "}
                                    <h3 className="h-20">Director 1</h3>
                                    <button
                                      type="button"
                                      style={{
                                        border: "none",
                                        background: "none",
                                      }}
                                      onClick={() =>
                                        push({
                                          my_request_directors_name: "",
                                        })
                                      }
                                    >
                                      <svg
                                        width="28"
                                        coaching_licenses="28"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                          fill="#4C8BB4"
                                        />
                                      </svg>
                                    </button>
                                  </div>

                                  {values.agent5.map((player, index) => (
                                    <div key={index}>
                                      <div
                                        className={`more-coache ${
                                          index > 0 ? "mt-3" : ""
                                        }`}
                                      >
                                        {index !== 0 && (
                                          <h3 className="h-20">
                                            Director {index + 1}
                                          </h3>
                                        )}
                                        {index !== 0 && (
                                          <button
                                            type="button"
                                            style={{
                                              border: "none",
                                              background: "none",
                                            }}
                                            onClick={() => remove(index)}
                                          >
                                            <svg
                                              width="28"
                                              height="28"
                                              viewBox="0 0 28 28"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                fill="#4C8BB4"
                                              />
                                            </svg>
                                          </button>
                                        )}
                                      </div>
                                      <Row>

                                        <Col lg={12}>
                                          <div className="profile-card mb-3 label-text-up">
                                            <label className="mb-2">
                                              Full Name
                                            </label>
                                            <Select
                                              name={`agent5[${index}].my_request_directors_name`}
                                              placeholder="Select"
                                              classNamePrefix="custom-select"
                                              options={directorList}
                                              value={directorList.find(
                                                (option) =>
                                                  option.value ===
                                                  values.agent5[index]
                                                    .my_request_directors_name
                                              )}
                                              onChange={(option) =>
                                                setFieldValue(
                                                  `agent5[${index}].my_request_directors_name`,
                                                  option.value
                                                )
                                              }
                                            />
                                            <ErrorMessage
                                              name={`agent5[${index}].my_request_directors_name`}
                                              component="div"
                                              className="text-danger"
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          </div>
                        </div>
                      )}
                    </div> */}
                  </div>

                  <div className="d-flex justify-content-between mb-5">
                    <div className="d-flex">
                      <div className="mt-4 cmn-light-btn ">
                        <button
                          className="button"
                          type="button"
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mt-4 me-2 cmn-light-btn save-and-close-btn">
                        <button
                          type="button"
                          onClick={() =>
                            handleSaveAndClose(values, validateForm, setErrors)
                          }
                          className="next-btn-ps"
                        >
                          Save & Close
                        </button>
                      </div>
                      <div className="mt-4 cmn-light-btn">
                        <button type="submit" className="next-btn-ps">
                          {" "}
                          Save & Next
                        </button>
                      </div>
                    </div>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default AddAssociateAgentSeven;
