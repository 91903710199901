import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import LoginLayout from "../components/LoginLayout";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMeeting,
  getMeetingIdAction,
  getMeetinglist,
} from "../Redux/Action/calenderAction";
import { capitalize, dateOfBirth, formatTime } from "../utils/helper";
import { format } from "date-fns";
import toast from "react-hot-toast";
import DeleteModal from "../CommonModals/DeleteModal";
import { Encryptedid } from "../utils/Becrypt";
import Loader from "../components/Loader";

export default function Calendor() {
  const [startDate, setStartDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [Id, setIds] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const meetingList = useSelector((e) => e.calenderAuthData.getMeetingData);
  const loader = useSelector((e) => e.calenderAuthData.loading);

  useEffect(() => {
    dispatch(getMeetinglist({}));
  }, []);

  // Toggle calendar visibility
  const toggleCalendar = () => setShowCalendar(!showCalendar);

  const handleDelete = (id) => {
    setIds(id);
    setModalShow(true);
  };

  const meetingDates = meetingList?.meetings?.map(
    (elem) => new Date(elem?.date?.split("T")[0])
  );

  const handleConfirm = () => {
    dispatch(deleteMeeting({ id: Id })).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.message);
        dispatch(getMeetinglist({}));
      }
    });
  };

  const handleEdit = (id) => {
    navigate(`/edit-meeting/${Encryptedid(id)}`);
  };

  console.log("meetingList", meetingList)

  return (
    <>
      <LoginLayout>
        <section>
        {loader && <Loader />}
          <Container>
            <div className="cmn-card">
              <div className="card-heading mb-2 d-flex justify-content-between align-items-center new-heading-color">
                <h2>Upcoming Meetings</h2>
                {/* <Link to="/create-meeting" className="light-blue-btn">
                Create Meeting
              </Link> */}
                <div className="calendar-icons">
                  <img
                    onClick={toggleCalendar}
                    src={require("../Assets/images/calander-icon.svg").default}
                  />

                  <div className="calendar-show">
                    {showCalendar && (
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          setShowCalendar(false);
                          const formattedDate = format(date, "yyyy-MM-dd");
                          navigate(`/create-meeting?date=${formattedDate}`);
                        }}
                        inline
                        minDate={new Date()}
                        highlightDates={meetingDates}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Row className="mt-4">
                <div class="table-calander">
                  <Table size="sm" className="table-cmn">
                    <thead>
                      <tr>
                        <th>Date/Time</th>
                        <th>Title </th>
                        <th>Participants</th>
                        <th>Notes</th>
                        {/* <th>Location</th> */}
                        <th>Meeting Link</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {meetingList?.length > 0 &&
                        meetingList?.map((elem, i) => (
                          <tr key={i}>
                            <td>
                              {dateOfBirth(elem?.date?.split("T")[0])}{" "}
                              <p>{formatTime(elem?.time)}</p>
                            </td>
                            <td>{capitalize(elem?.title)}</td>
                            <td>{elem?.participants?.full_name}</td>
                            <td>
                              {elem?.notes?.length > 60
                                ? `${elem.notes.substring(0, 100)}...`
                                : elem?.notes}
                            </td>
                            {/* <td>{elem?.location}</td> */}
                            <td>
                              <Link to="/#"> {elem?.meeting_link} </Link>
                            </td>
                            <td>
                              {/* <img
                              src={
                                require("../Assets/images/view-icon.svg")
                                  .default
                              }
                            /> */}
                              <div className="d-flex justify-content-center">
                                <div
                                  onClick={() => handleEdit(elem?._id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={
                                      require("../Assets/images/edit-icon.svg")
                                        .default
                                    }
                                  />
                                </div>
                                <div
                                  onClick={() => handleDelete(elem?._id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={
                                      require("../Assets/images/delete-icon-table.svg")
                                        .default
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {meetingList?.length === 0 && (
                    <div className="meeting-sheduled">
                      <h4 className="nodata">No Meeting Scheduled.</h4>
                    </div>
                  )}
                </div>
              </Row>
            </div>
          </Container>
        </section>
      </LoginLayout>
      <DeleteModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
}
