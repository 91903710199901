import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { getProfileDetail } from "../Redux/Action/auth";
import { useDispatch, useSelector } from "react-redux";
import backgroundImage from "../Assets/images/playerbanner.png";
import { getPlayerListAction } from "../Redux/Action/playerAction";
import { Encryptedid } from "../utils/Becrypt";
import {
  coachingLicense,
  gender,
  playerNationality,
  positions,
  role,
} from "../utils/rawjson";
import { homeListAction } from "../Redux/Action/homeAction";
import { calculateAge, capitalize } from "../utils/helper";
import Loader from "../components/Loader";
export default function MyProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileDetail = useSelector(
    (state) => state.userAuthData.getProfileData
  );

  const profileData = useSelector((e) => e.homeAuthData.homeProfileDetail);
  const loadingState = useSelector((e) => e.homeAuthData.loading);

  useEffect(() => {
    dispatch(getProfileDetail());
    dispatch(homeListAction());
  }, []);

  const backgroundImageStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  };

  console.log("profileDetail", profileDetail);
  return (
    <LoginLayout>
      {loadingState && <Loader />}
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-4 d-flex justify-content-between align-items-center">
              <h2>My Profile</h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    {profileDetail?.gender == 0 ? (
                      <img
                        className="play-img"
                        src={
                          profileDetail?.profile_image
                            ? `${process.env.REACT_APP_IMAGE_PATH}${profileDetail.profile_image}`
                            : require("../Assets/images/player_Ai.png")
                        }
                        alt="Profile"
                      />
                    ) : (
                      <img
                        className="play-img"
                        src={
                          profileDetail?.profile_image
                            ? `${process.env.REACT_APP_IMAGE_PATH}${profileDetail.profile_image}`
                            : require("../Assets/images/player-female.png")
                        }
                        alt="Profile"
                      />
                    )}

                    <div>
                      <h2>
                        {capitalize(profileDetail?.full_name)},{" "}
                        {calculateAge(profileDetail?.dob)}
                      </h2>
                      <div className="d-flex align-items-center mb-4 mt-3">
                        <p className="mb-0">
                          ID: {profileDetail?.unique_id}
                        </p>
                        {/* <button onClick={copyToClipboard}> */}
                        <button>
                          <img
                            src={
                              require("../Assets/images/copywhite.svg").default
                            }
                          />
                        </button>
                      </div>

                      <div className="socio-icons">
                        {/* Instagram */}
                        {profileDetail?.instagram && (
                          <Link
                            to={profileDetail.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/instablue.svg")
                                  .default
                              }
                              alt="Instagram"
                            />
                          </Link>
                        )}

                        {/* Snapchat */}
                        {profileDetail?.snapchat && (
                          <Link
                            to={profileDetail.snapchat}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/snapblue.svg").default
                              }
                              alt="Snapchat"
                            />
                          </Link>
                        )}

                        {/* X (formerly Twitter) */}
                        {profileDetail?.twitter && (
                          <Link
                            to={profileDetail.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/xblue.svg").default
                              }
                              alt="X"
                            />
                          </Link>
                        )}

                        {/* Facebook */}
                        {profileDetail?.facebook && (
                          <Link
                            to={profileDetail.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/fbblue.svg").default
                              }
                              alt="Facebook"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="player-right-info">
                    <div className="play-edit">
                      {/* <Link to="/settings">
                        <img
                          src={
                            require("../Assets/images/settingwhite.svg").default
                          }
                        />
                      </Link> */}
                      <Link to={`/edit-profile`}>
                        <img
                          src={
                            require("../Assets/images/editwhite.svg").default
                          }
                        />
                        Edit Profile
                      </Link>
                    </div>
                  
                  </div>
                </Col>
              </Row>
            </div>
            ;
            <section className="mt-4 team-players">
              <Container>
                <Row>
                  <Col lg={4} className="ps-0">
                    <div className="play-card-sec">
                      <div className="play-scroll-box position-relative">
                        <div className="d-flex justify-content-between">
                          <h4>My Players</h4>
                          {profileData?.player?.length > 0 && (
                            <Link to="/my-players" className="linkclass">
                              View all
                            </Link>
                          )}
                        </div>

                        {profileData?.player?.length > 0 ? (
                          profileData?.player?.map((elem, i) => (
                            <div
                              className="play-inner-box"
                              key={i}
                              onClick={() =>
                                navigate(
                                  `/player-profile/${Encryptedid(elem?._id)}`
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <div className="d-flex justify-content-between">
                                {/* <img
                                  src={`${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`}
                                /> */}

                                {elem?.gender == 0 ? (
                                  <img
                                    src={
                                      elem?.profile_image
                                        ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                        : require("../Assets/images/player_Ai.png")
                                    }
                                    alt="Profile"
                                  />
                                ) : (
                                  <img
                                    src={
                                      elem?.profile_image
                                        ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                        : require("../Assets/images/player-female.png")
                                    }
                                    alt="Profile"
                                  />
                                )}
                                <div className="ms-3">
                                  <h4>{capitalize(elem?.full_name) || 'N/A'}</h4>
                                  {/* <h5>{elem?.national_team || 'N/A'}</h5> */}
                                  <p>
                                    {elem?.positions
                                      ?.slice(0, 5)
                                      ?.map((position, i) => (
                                        <React.Fragment key={i}>
                                          <span>
                                            {positions[position]}
                                            {i <
                                              Math.min(
                                                elem.positions.length,
                                                7
                                              ) -
                                                1 && ", "}
                                          </span>
                                        </React.Fragment>
                                      ))}
                                    {elem?.positions?.length > 7 && " ..."}
                                  </p>
                                </div>
                              </div>

                              <p>
                                <span>{elem?.unique_id.toUpperCase()}</span>
                              </p>
                            </div>
                          ))
                        ) : (
                          <p className="nodatafound">No Data</p>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} className="ps-0">
                    <div className="play-card-sec">
                      <div className="play-scroll-box position-relative">
                        <div className="d-flex justify-content-between">
                          <h4>My Directors</h4>
                          {profileData?.director?.length > 0 && (
                            <Link to="/my-directors" className="linkclass">
                              View all
                            </Link>
                          )}
                        </div>

                        {profileData?.director?.length > 0 ? (
                          profileData?.director?.map((elem, i) => (
                            <div
                              className="play-inner-box"
                              key={i}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  `/my-directors/${Encryptedid(elem?._id)}`
                                )
                              }
                            >
                              <div className="d-flex justify-content-between">
                                {/* <img
                                  src={`${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`}
                                /> */}

                                {elem?.gender == 0 ? (
                                  <img
                                    src={
                                      elem?.profile_image
                                        ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                        : require("../Assets/images/My Directors male.png")
                                    }
                                    alt="Profile"
                                  />
                                ) : (
                                  <img
                                    src={
                                      elem?.profile_image
                                        ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                        : require("../Assets/images/director-female-new.png")
                                    }
                                    alt="Profile"
                                  />
                                )}
                                <div className="ms-3">
                                  <h4>{capitalize(elem?.full_name)}</h4>
                                  {/* <h5>{capitalize(elem?.current_team_name)}</h5> */}
                                  <h5>
                                    {elem?.coaching_licenses
                                      ?.map((res) => coachingLicense[res])
                                      .join(", ")}
                                  </h5>
                                </div>
                              </div>

                              <p>
                                {/* Director ID:{" "} */}
                                <span>{elem?.unique_id.toUpperCase()}</span>
                              </p>
                            </div>
                          ))
                        ) : (
                          <p className="nodatafound">No Data</p>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} className="pe-0 ps-0">
                    <div className="play-card-sec">
                      <div className="play-scroll-box position-relative">
                        <div className="d-flex justify-content-between">
                          <h4>My Coaches</h4>

                          {profileData?.coach?.length > 0 && (
                            <Link to="/my-coach" className="linkclass">
                              View all
                            </Link>
                          )}
                        </div>

                        {profileData?.coach?.length > 0 ? (
                          profileData?.coach?.map((elem, i) => (
                            <div
                              className="play-inner-box"
                              key={i}
                              onClick={() =>
                                navigate(`/my-coach/${Encryptedid(elem?._id)}`)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <div className="d-flex">
                                {elem?.gender == 0 ? (
                                  <img
                                    src={
                                      elem?.profile_image
                                        ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                        : require("../Assets/images/coach-male.png")
                                    }
                                    alt="Profile"
                                  />
                                ) : (
                                  <img
                                    src={
                                      elem?.profile_image
                                        ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                        : require("../Assets/images/coach-female.png")
                                    }
                                    alt="Profile"
                                  />
                                )}
                                <div className="ms-3">
                                  <h4>{capitalize(elem?.full_name) || 'N/A'}</h4>
                                  {/* <h5>{capitalize(elem?.current_team_name)}</h5> */}
                                  <h5>
                                    {elem?.coaching_licenses
                                      ?.map((res) => coachingLicense[res])
                                      .join(", ") || 'N/A'}
                                  </h5>
                                </div>
                              </div>

                              <p>
                                {/* Coach ID:{" "} */}
                                <span>{elem?.unique_id.toUpperCase()}</span>
                              </p>
                            </div>
                          ))
                        ) : (
                          <p className="nodatafound">No Data</p>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} className="ps-0 mt-3">
                    <div className="play-card-sec">
                      <div className="play-scroll-box position-relative">
                        <div className="d-flex justify-content-between">
                          <h4>My Teams</h4>
                          {profileData?.team?.length > 0 && (
                            <Link to="/my-team" className="linkclass">
                              View all
                            </Link>
                          )}
                        </div>
                        {profileData?.team?.length > 0 ? (
                          profileData?.team?.map((elem, i) => (
                            <div
                              className="play-inner-box"
                              key={i}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/my-team/${Encryptedid(elem?._id)}`)
                              }
                            >
                              <div className="d-flex">
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`
                                      : require("../Assets/images/team-new.png")
                                  }
                                />
                                <div className="ms-3">
                                  <h4>{capitalize(elem?.team_name)}</h4>
                                  <h5>{capitalize(elem?.team_country)}</h5>
                                </div>
                              </div>

                              <p>
                                {/* Team ID:{" "} */}
                                <span>{elem?.unique_id.toUpperCase()}</span>
                              </p>
                            </div>
                          ))
                        ) : (
                          <p className="nodatafound">No Data</p>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} className="ps-0 mt-3">
                    <div className="play-card-sec">
                      <div className="play-scroll-box position-relative">
                        <div className="d-flex justify-content-between">
                          <h4>My Associate Agents</h4>
                          {profileData?.agent?.length > 0 && (
                            <Link
                              to="/manage-associate-agent"
                              className="linkclass"
                            >
                              View all
                            </Link>
                          )}
                        </div>
                        {profileData?.agent?.length > 0 ? (
                          profileData?.agent?.map((elem, i) => (
                            <div
                              className="play-inner-box"
                              key={i}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  `/associate-agent/${Encryptedid(elem?._id)}`
                                )
                              }
                            >
                              <div className="d-flex">
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`
                                      : require("../Assets/images/My Associate Agent male.png")
                                  }
                                />
                                <div className="ms-3">
                                  <h4>{capitalize(elem?.agent_name)}</h4>
                                  <h5>{`${capitalize(
                                    elem?.country
                                  )}, ${capitalize(elem?.city)}`}</h5>
                                </div>
                              </div>

                              <p>
                                {/* Agent ID:{" "} */}
                                <span>{elem?.unique_id.toUpperCase()}</span>
                              </p>
                            </div>
                          ))
                        ) : (
                          <p className="nodatafound">No Data</p>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} className="pe-0 ps-0 mt-3">
                    <div className="play-card-sec">
                      <div className="play-scroll-box position-relative">
                        <div className="d-flex justify-content-between">
                          <h4>My Other Associates</h4>
                          {profileData?.otherAgent?.length > 0 && (
                            <Link
                              to="/other-associate-agent"
                              className="linkclass"
                            >
                              View all
                            </Link>
                          )}
                        </div>
                        {profileData?.otherAgent?.length > 0 ? (
                          profileData?.otherAgent?.map((elem, i) => (
                            <div
                              className="play-inner-box"
                              key={i}
                              onClick={() =>
                                navigate(
                                  `/other-associate-agent/${Encryptedid(
                                    elem?._id
                                  )}`
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <div className="d-flex">
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`
                                      : require("../Assets/images/My Other Associates male.png")
                                  }
                                />
                                <div className="ms-3">
                                  <h4>{capitalize(elem?.associate_name)}</h4>
                                  <h5>{role[elem?.role]}</h5>
                                </div>
                              </div>

                              <p>
                                {/* Agent ID */}
                                <span>{elem?.unique_id.toUpperCase()}</span>
                              </p>
                            </div>
                          ))
                        ) : (
                          <p className="nodatafound">No Data</p>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
