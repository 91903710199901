import React from "react";
import { Button, Modal } from "react-bootstrap";

const CautionModalComp = ({ showSelected, setShowSelected }) => {
  return (
    <>
      <Modal
        className="cmn-modal"
        size="xs"
        show={showSelected}
        onHide={() => setShowSelected(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          className="pb-0"
          closeButton
          style={{ borderBottom: "none" }}
        ></Modal.Header>
        <Modal.Body className="pt-0">
          <div className="choose-agent">
            <h3>Caution!</h3>
            <p>
              All of your Player Data will be transferred to the Agent,
              <b>
                {" "}
                except for your Notes, Coaches, Directors, Associate Agents, and
                Other Associate Agents Data
              </b>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: "none" }}
          className="d-flex justify-content-center"
        >
          <Button variant="primary" onClick={()=>setShowSelected(false)}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CautionModalComp;
