import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getDirectorDataById,
  getDirectorListAction,
} from "../Redux/Action/directorAction";
import { Encryptedid } from "../utils/Becrypt";
import { capitalize } from "../utils/helper";
import Loader from "../components/Loader";

export default function MyDirectors() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allListOfDirectors = useSelector(
    (e) => e.directorAuthData.getListOfDirectors
  );

  const loader = useSelector((e) => e.directorAuthData.loading);

  useEffect(() => {
    dispatch(getDirectorListAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDirectorDataById({ directorId: "" }));
  }, [dispatch]);

  return (
    <LoginLayout>
      {loader && <Loader />}
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-3 d-flex justify-content-between align-items-center">
              <h2>My Directors</h2>
            </div>
            <div className="">
              <Row>
                {allListOfDirectors?.length > 0 ? (
                  allListOfDirectors?.map((item, index) => (
                    <Col lg={4} key={index}>
                      <div
                        className="player-box"
                        onClick={() =>
                          navigate(`/my-directors/${Encryptedid(item?._id)}`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {/* <img src={require("../Assets/images/user.png")} /> */}
                        {/* <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}${item?.profile_image}`}
                        /> */}

                        {/* require("../Assets/images/directorimage.png") */}

                        {item?.gender == 0 ? (
                          <img
                            src={
                              item?.profile_image
                                ? `${process.env.REACT_APP_IMAGE_PATH}${item.profile_image}`
                                : require("../Assets/images/My Directors male.png")
                            }
                            alt="Profile"
                          />
                        ) : (
                          <img
                            src={
                              item?.profile_image
                                ? `${process.env.REACT_APP_IMAGE_PATH}${item.profile_image}`
                                : require("../Assets/images/director-female-new.png")
                            }
                            alt="Profile"
                          />
                        )}

                        <div className="mt-2">
                          <h3>{capitalize(item?.full_name)}</h3>
                          {/* <h5>{capitalize(item?.current_team_name)}</h5> */}
                          <p>
                            <span>Director ID:</span>{" "}
                            {item?.unique_id.toUpperCase()}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <h4 className="nodata">No Director Data</h4>
                )}
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
