import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import {
  getCompareDataAction,
  getExternalPlayers,
  getGlobalCompareDataAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid } from "../utils/Becrypt";
import { calculateAge, capitalize, dateOfBirth } from "../utils/helper";
import {
  AvailabilityStatus,
  gender,
  NationalTeamPlayer,
  playerFoot,
  playerNationality,
  playerTrophy,
  positions,
  teamLeague,
} from "../utils/rawjson";
import {
  setGlobalPlayer1Image,
  setGlobalPlayer2Image,
} from "../Redux/Reducers/playerSlice";
import toast from "react-hot-toast";
import backgroundImage from "../Assets/images/playerbanner.png";
import { createChatRoomAction } from "../Redux/Action/message";

export default function GlobalSearchPlayerProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [activePlayer, setActivePlayer] = useState(null);
  const [selectedPlayerIds, setSelectedPlayerIds] = useState([]);

  const { id } = useParams();
  const paylerId = id && Decryptedid(atob(id));

  const globalPlayerData = useSelector((e) => e.playerAuthData.playerDetail);
  const externalplayerList = useSelector(
    (e) => e.playerAuthData.externalPlayerData
  );

  const globalPlayer1Image = useSelector(
    (state) => state.playerAuthData.globalPlayer1Image
  );
  const globalPlayer2Image = useSelector(
    (state) => state.playerAuthData.globalPlayer2Image
  );

  const globalPlayer2Gender = useSelector(
    (state) => state.playerAuthData.genderGlobal
  );

  const player1Id = useSelector(
    (state) => state.playerAuthData.globalPlayer1Id
  );
  const player2Id = useSelector(
    (state) => state.playerAuthData.globalPlayer2Id
  );

  const backgroundImageStyle = {
    // backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}${globalPlayerData?.banner_image})`,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  };

  useEffect(() => {
    if (paylerId) {
      dispatch(getPlayerDataById({ id: paylerId }));
    }
    dispatch(getExternalPlayers());
  }, [dispatch]);

  const copyToClipboard = () => {
    if (globalPlayerData?.unique_id) {
      navigator.clipboard
        .writeText(globalPlayerData.unique_id)
        .then(() => {
          console.log("Copied");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleChoosePlayer = (playerNumber) => {
    setActivePlayer(playerNumber);
    setShowModal(true);
  };

  const handlePlayerSelect = (player) => {
    const imagePath = `${process.env.REACT_APP_IMAGE_PATH}${player.profile_image}`;

    console.log(imagePath, "55rrrrrr");

    if (player1Id === player._id || player2Id === player._id) {
      toast.error("This player is already selected.");
      return;
    }
    console.log(activePlayer, "ttttttttttttttttt", player);
    if (activePlayer === 1) {
      dispatch(setGlobalPlayer1Image({ image: imagePath, id: player._id }));
    } else if (activePlayer === 2) {
      dispatch(
        setGlobalPlayer2Image({
          image: imagePath,
          id: player._id,
          gender: player.gender,
        })
      );
    }

    if (player1Id && player2Id) {
      dispatch(getCompareDataAction({ id1: player1Id, id2: player2Id }));
    }

    setShowModal(false);
  };

  useEffect(() => {
    if (globalPlayerData) {
      const imagePath = `${process.env.REACT_APP_IMAGE_PATH}${globalPlayerData?.profile_image}`;
      dispatch(
        setGlobalPlayer1Image({ image: imagePath, id: globalPlayerData?._id })
      );
    }
  }, [globalPlayerData, dispatch]);

  const handleLinkClick = async () => {
    if (globalPlayer1Image && globalPlayer2Image) {
      dispatch(
        getGlobalCompareDataAction({ id1: player1Id, id2: player2Id })
      ).then((res) => {
        if (res?.payload?.status === 200) {
          navigate("/global-players");
        }
      });
    }
  };

  console.log(globalPlayer2Image, "globalPlayer2Image");
  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Player </h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    <img
                      className="play-img"
                      src={
                        // globalPlayerData?.profile_image
                        //   ? `${process.env.REACT_APP_IMAGE_PATH}${globalPlayerData?.profile_image}`
                        //   :
                        globalPlayerData?.gender === 0
                          ? require("../Assets/images/player_Ai.png")
                          : require("../Assets/images/compare-female.png")
                      }
                    />
                    {/* <img
                      className="play-img"
                      src={`${process.env.REACT_APP_IMAGE_PATH}${globalPlayerData?.profile_image}`}
                    /> */}
                    <div>
                      <h2>
                        {" "}
                        {globalPlayerData?.full_name},{" "}
                        {calculateAge(globalPlayerData?.dob)}
                      </h2>
                      <div className="d-flex align-items-center mb-4 mt-3">
                        <p className="mb-0" ref={inputRef}>
                          Player ID: {globalPlayerData?.unique_id}
                        </p>
                        <button onClick={copyToClipboard}>
                          <img
                            src={
                              require("../Assets/images/copywhite.svg").default
                            }
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="player-right-info">
                    <div></div>
                    <div className="play-info-box">
                      <div>
                        <h3>{gender[globalPlayerData?.gender] || "N/A"}</h3>
                        <p>Gender</p>
                      </div>
                      <div>
                        <h3>
                          {globalPlayerData?.dob
                            ? calculateAge(globalPlayerData?.dob)
                            : "N/A"}
                        </h3>
                        <p>Age</p>
                      </div>
                      <div>
                        <h3>
                          <h3>
                            {/* {playerData?.nationality?.length > 1
                                           ? `${playerData.nationality[0]} +${
                                               playerData.nationality.length - 1
                                             }`
                                           : playerData?.nationality?.[0]} */}
                            {playerNationality[
                              globalPlayerData?.nationality_id
                            ] || "N/A"}
                          </h3>
                        </h3>
                        <p>National ID</p>
                      </div>
                      {/* <div>
                                     <h3>
                                       {playerData?.languages?.length > 1
                                         ? `${playerData.languages[0]} +${playerData.languages.length - 1
                                         }`
                                         : playerData?.languages?.[0]}
                                     </h3>
             
                                     <p>Language</p>
                                   </div> */}
                      <div>
                        <h3>{globalPlayerData?.height || "N/A"}</h3>

                        <p>Height</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Row className="mt-4">
              <Col lg={4}>
                <div className="search-inner-box">
                  {/* <div className="play-search-box">
                    <div>
                      <h3>{gender[globalPlayerData?.gender] || "N/A"}</h3>
                      <p>Gender</p>
                    </div>
                    <div>
                      <h3>{calculateAge(globalPlayerData?.dob) || "N/A"}</h3>
                      <p>Age</p>
                    </div>
                    <div>
                      <h3>
                        {" "}
                        {AvailabilityStatus[
                          globalPlayerData?.availability_status
                        ] || "N/A"}
                      </h3>
                      <p>Availability</p>
                    </div>
                    <div>
                      {globalPlayerData?.languages?.map((elem, i) => (
                        <h3 key={i}>{elem}</h3>
                      ))}
                      <p>Language</p>
                    </div>
                  </div> */}
                </div>
                <div className="search-inner-box mt-2">
                  <ul>
                    <li>
                      <h4>Nationality ID:</h4>
                      <p>
                        {playerNationality[globalPlayerData?.nationality_id] ||
                          "N/A"}
                      </p>
                    </li>
                    <li>
                      <h4>Teams League:</h4>
                      <p>
                        {" "}
                        {teamLeague[globalPlayerData?.team_league] || "N/A"}
                      </p>
                    </li>
                    <li>
                      <h4>National Team Player:</h4>
                      <p>
                        {" "}
                        {NationalTeamPlayer[
                          globalPlayerData?.national_team_player
                        ] || "N/A"}
                      </p>
                    </li>
                    {/* <li>
                      <h4>Positions:</h4>
                      {globalPlayerData?.positions?.map((elem, i) => (
                        <p key={i}>{positions[elem]}</p>
                      ))}
                    </li> */}
                    <li>
                      <h4>Position(s)</h4>
                      <p className="position-right-side">
                        {globalPlayerData?.positions
                          ?.map((elem) => positions[elem])
                          ?.join(", ") || "N/A"}
                      </p>
                    </li>
                    <li>
                      <h4>Foot:</h4>
                      {globalPlayerData?.foot?.map((elem, i) => (
                        <p key={i}>{playerFoot[elem]}</p>
                      ))}
                    </li>
                    <li>
                      <h4>Height:</h4>
                      <p>{globalPlayerData?.height || "N/A"}</p>
                    </li>
                    <li>
                      <h4>Languages:</h4>
                      {globalPlayerData?.languages?.map((elem, i) => (
                        <p key={i}>{elem}</p>
                      ))}
                    </li>
                    <li>
                      <h4>Annual Asking Salary:</h4>
                      <p>{globalPlayerData?.current_contract || "N/A"}</p>
                    </li>
                    <li>
                      <h4>Amount for Transfer:</h4>
                      <p>
                        {globalPlayerData?.new_contract_team_asking_amount
                          ? globalPlayerData?.new_contract_team_asking_amount
                          : "N/A"}
                      </p>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={4}>
                <div className="play-inner-box">
                  <div className="d-flex align-items-center">
                    {
                      <img
                        src={
                          globalPlayerData?.creator_id?.profile_image
                            ? `${process.env.REACT_APP_IMAGE_PATH}${globalPlayerData?.creator_id?.profile_image}`
                            : require("../Assets/images/My Associate Agent male.png")
                        }
                      />
                    }
                    <div className="ms-3 d-flex align-items-center">
                      <h4>
                        {capitalize(globalPlayerData?.creator_id?.full_name)}
                      </h4>
                      <p
                        className="ms-2"
                        style={{ color: "#1D161780", fontSize: "13px" }}
                      >
                        Agent
                      </p>
                    </div>
                  </div>
                  {/* <div className="badge-img">
                    <img src={require("../Assets/images/tiger.png")} />
                  </div> */}
                </div>
                <div className="search-cmn-box">
                  <h2>Carrier Trophies</h2>
                  <div className="search-inner-box">
                    <ul>
                      {globalPlayerData?.carrier_trophies?.map((elem, i) => (
                        <li>
                          <div className="d-flex">
                            <img
                              src={require("../Assets/images/smtrophy.png")}
                            />
                            <h4 className="ms-2">{playerTrophy[elem]}</h4>
                          </div>
                          {/* <p>
                            {dateOfBirth(elem?.date_of_winning?.split("T")[0])}
                          </p> */}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* <div className="search-cmn-box mt-3">
                  <h2>Compare Players</h2>
                  <div className="search-inner-box">
                    <div
                      className="players-top-sec"
                      style={{ boxShadow: "none" }}
                    >
                      <div className="play-box-wrap">
                        <div className="play-inner ad-delt-btn">
                          <button
                            id="choosePlayer1"
                            style={{ display: "none" }}
                            onClick={() => handleChoosePlayer(1)}
                          ></button>
                          <label
                            htmlFor="choosePlayer1"
                            style={{
                              cursor: "pointer",
                              display: "inline-block",
                            }}
                          >
                            <img
                              className="up-icon"
                              src={
                                require("../Assets/images/userimg.svg").default
                              }
                            />
                          </label>

                          {globalPlayer1Image && (
                            <img
                              className="play-img"
                              src={
                                globalPlayerData?.profile_image
                                  ? `${process.env.REACT_APP_IMAGE_PATH}${globalPlayerData?.profile_image}`
                                  : globalPlayerData?.gender === 0
                                  ? require("../Assets/images/player_Ai.png")
                                  : require("../Assets/images/player-female.png")
                              }
                            />
                          )}
                        </div>
                        <h4>Add Player 1</h4>
                      </div>

                      <div className="play-box-wrap">
                        <div className="play-inner ad-delt-btn">
                          <button
                            id="choosePlayer2"
                            style={{ display: "none" }}
                            onClick={() => handleChoosePlayer(2)}
                          ></button>
                          <label
                            htmlFor="choosePlayer2"
                            style={{
                              cursor: "pointer",
                              display: "inline-block",
                            }}
                          >
                            <img
                              className="up-icon"
                              src={
                                require("../Assets/images/userimg.svg").default
                              }
                            />
                          </label>
                          {console.log(globalPlayer2Image, "globalPlayer2Image", globalPlayer2Gender)}
                          {globalPlayer2Image && (
                            <img
                            className="prev-img"
                            src={
                              /\.(jpeg|jpg|png|gif)$/i.test(globalPlayer2Image)
                                ? globalPlayer2Image
                                : require("../Assets/images/compare-male.png")
                            }
                            alt="Player 1"
                            onError={(e) => {
                              e.target.src = require("../Assets/images/compare-male.png");
                            }}
                          />
                          )}
                        </div>
                        <h4>Add Player 2</h4>
                      </div>
                    </div>
                    <div className="comp-btn">
                      <Link onClick={handleLinkClick} className="mt-0">
                        See Comparison
                      </Link>
                    </div>
                  </div>
                </div> */}
              </Col>
              <Col lg={4}>
                {" "}
                <div className="search-inner-box p-0">
                  <div className="play-images">
                    <img src={require("../Assets/images/smllbanner.png")} />
                    <div className="players-value inner-tab">
                      <h3>CHECK THE MOST VALUABLE PLAYERS IN THE WORLD</h3>
                    </div>
                  </div>{" "}
                </div>
              </Col>
            </Row>
          </div>

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Select Player</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="ad-pop-height-player">
                {externalplayerList?.map((player) => (
                  <div
                    style={{ cursor: "pointer" }}
                    className="play-inner-box"
                    key={player.id}
                    onClick={() => handlePlayerSelect(player)}
                  >
                    <div className="d-flex">
                      <img
                        src={
                          player?.profile_image
                            ? `${process.env.REACT_APP_IMAGE_PATH}${player?.profile_image}`
                            : player?.gender === 0
                            ? require("../Assets/images/player_Ai.png")
                            : require("../Assets/images/player-female.png")
                        }
                      />
                      <div className="ms-3">
                        <h4>{player?.full_name}</h4>
                        <p>
                          Player ID:{" "}
                          <span>{player?.unique_id.toUpperCase()}</span>
                        </p>
                      </div>
                    </div>

                    <div className="badge-img">
                      <img src={require("../Assets/images/tiger.png")} />
                    </div>
                  </div>
                ))}
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </section>
    </LoginLayout>
  );
}
