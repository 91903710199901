import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { getMessage } from "../Action/message";

const MessageSlice = createSlice({
  name: "message",
  initialState: {
    isLoading: false,
    isError: false,
    messageList: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    // Messafe Listing
    builder.addCase(getMessage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMessage.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.success) {
        state.messageList = action.payload.data;
      } else {
        toast.error(action.payload.message);
      }
    });
    builder.addCase(getMessage.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      toast.error(action.error.message || "Something went wrong");
    });
  },
});

export default MessageSlice.reducer;
