import React, { useEffect } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Row, Form } from "react-bootstrap";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editTeamAction, getTeamDataById } from "../Redux/Action/teamAction";
import ProgressBar from "../CommonModals/ProgressBar";

const validationSchema = Yup.object({
  general_other_notes: Yup.string().required("required"),
});

const AddTeamEight = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const teamId = id && Decryptedid(atob(id));

  const getTeamDetails = useSelector((e) => e.teamAuthData.getTeamDetailById);

  const handleSubmit = (values) => {
    console.log("dfgdfgdf", values);

    let formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("teamId", teamId);
    formData.append("is_profile_completed", 0);

    dispatch(editTeamAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.message);
        // localStorage.removeItem("teamId");
        navigate(`/my-team`);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    if (teamId) {
      dispatch(getTeamDataById({ id: teamId }));
    }
  }, [dispatch]);

  const initialValues = {
    general_other_notes: getTeamDetails?.general_other_notes || "",
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <ProgressBar currentStep={5} totalSteps={5} />
          <div className="steps-heading">
            <p>
              {" "}
              <span>Step 5:</span> My General Notes{" "}
            </p>
          </div>

          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <FormikForm>
                <div className="d-flex justify-content-between">
                  <h3 className="h-20">Other Notes</h3>
                </div>
                <Form.Group className="mb-3 form-inner">
                  <Field
                    as="textarea"
                    name="general_other_notes"
                    rows={3}
                    placeholder="Write notes here"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="general_other_notes"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <div className="d-flex justify-content-between mb-5">
                  <div className="d-flex">
                    <div className="mt-4 cmn-light-btn ">
                      <button
                        className="button"
                        type="button"
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="mt-4 cmn-light-btn">
                      <button type="submit" className="next-btn-ps">
                        {" "}
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default AddTeamEight;
