import React from "react";
import { Tab, Tabs, Accordion, Row, Col } from "react-bootstrap";
import {
  coachCurrentContractDetails,
  coachIsProposed,
  coachMainInformation,
  coachNewContractDetails,
  coachProfessionalRelations,
  CoachsTeam,
  teamsInterestedInCoach,
} from "../utils/rawjson";

const CoachFilterTab = ({
  renderSelectCoach,
  renderSelectCoach1,
  renderSelectCoach2,
  renderSelectCoach3,
  renderSelectCoach4,
  renderSelectCoach5,
  renderSelectCoach6,
  handleSearchForCoach,
}) => {
  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Coach Main Information</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(coachMainInformation).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectCoach(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>Coach's Team</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(CoachsTeam).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectCoach6(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Professional Relations</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(coachProfessionalRelations).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectCoach1(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Current Contract Details</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(coachCurrentContractDetails).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectCoach2(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>New Contract Details</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(coachNewContractDetails).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectCoach3(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>Coach Proposal Details</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(coachIsProposed).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectCoach4(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>Teams Interested in Coach</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(teamsInterestedInCoach).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectCoach5(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Row>
        <Col lg={12} className="mt-4 d-flex justify-content-end">
          <div className="cmn-light-btn">
            <button type="button" onClick={handleSearchForCoach}>
              Search
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CoachFilterTab;
