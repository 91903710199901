import React, { useEffect, useState } from "react";
import LoginLayout from "../components/LoginLayout";
import { Container, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  FieldArray,
} from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { staticDataAction } from "../Redux/Action/staticAction";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import {
  addNewTeam,
  createPlayerAction,
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import { getYesterday } from "../utils/helper";
import { getTeamListAction } from "../Redux/Action/teamAction";
import PreviewModal from "../CommonModals/PreviewModal";
import ProgressBar from "../CommonModals/ProgressBar";
import Loader from "../components/Loader";
import { clearPlayerDetail } from "../Redux/Reducers/playerSlice";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";

Modal.setAppElement("#root");

const ProfileInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const location = useLocation();
  const [playerId, setPlayerId] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ids = queryParams.get("playerId");
    const id = ids && Decryptedid(atob(ids));

    if (id) {
      setPlayerId(id);
      dispatch(getPlayerDataById({ id, type: 1 }));
    }
  }, [dispatch, location.search]);

  const [age, setAge] = useState("");
  const [isFree, setIsFree] = useState(false);
  const [inputType, setInputType] = useState("text");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTeamName, setNewTeamName] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [dragging, setDragging] = useState(false);

  const staticListForPlayer = useSelector(
    (e) => e.staticAuthData.userStaticDetail
  );
  const playerData = useSelector((e) => e.playerAuthData.playerDetail);
  const playerDataLoadingState = useSelector((e) => e.playerAuthData.loading);
  const teanListingData = useSelector((e) => e.teamAuthData.getTeamListing);

  const [teamOptions, setTeamOptions] = useState(
    teanListingData?.map((elem) => ({
      label: elem?.team_name,
      value: elem?._id,
      data: elem,
    })) || []
  );

  useEffect(() => {
    const teamOptions = teanListingData?.map((elem) => ({
      label: elem?.team_name,
      value: elem?._id,
      data: elem,
    }));
    setTeamOptions(teamOptions);
  }, [teanListingData]);

  const optionsGender = staticListForPlayer?.gender?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPlaceOfBirth = staticListForPlayer?.place_of_birth?.map(
    (item) => ({
      label: item,
      value: item,
    })
  );

  const optionsNationaity = staticListForPlayer?.nationality?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsNationaityId = staticListForPlayer?.nationality_id?.map(
    (item, i) => ({
      label: item?.val,
      value: i,
    })
  );

  const optionsLanguage = staticListForPlayer?.languages?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsHeight = staticListForPlayer?.hight?.map((item) => ({
    label: item?.val,
    value: item?.val,
  }));

  const optionsFoot = staticListForPlayer?.foot?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPosition = staticListForPlayer?.positions?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsTeamLeague = staticListForPlayer?.team_league?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsNationalTeam = staticListForPlayer?.national_team_player?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsTrophy = staticListForPlayer?.trophy?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPlayerStatus = staticListForPlayer?.availability_status?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );


  const optionsAskingAmount = staticListForPlayer?.asking_amount?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  const optionsannualAskingSalary = staticListForPlayer?.current_contract?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  useEffect(() => {
    dispatch(staticDataAction());
  }, [dispatch]);

  const validateFile = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxSize = 2 * 1024 * 1024; // 2 MB for profile image
    const bannerMaxSize = 5 * 1024 * 1024; // 5 MB for banner image

    if (!file) return "required";

    if (file.size > (file ? bannerMaxSize : maxSize)) {
      return "File Size is too large";
    }
  };

  const validateForm = (values) => {
    const errors = {};

    const profileImageError = validateFile(values.profile_image);

    if (!values.gender || values.gender.length === 0) {
      errors.gender = "required";
    }

    const nameRegex = /^[a-zA-Z\s]*$/;

    if (!values.full_name || values.full_name.trim().length === 0) {
      errors.full_name = "required";
    }

    if (!values.dob || values.dob.length === 0) {
      errors.dob = "required";
    }

    if (!values.nationality || values.nationality.length === 0) {
      errors.nationality = "required";
    }

    if (!values.nationality_id || values.nationality_id.length === 0) {
      errors.nationality_id = "required";
    }

    if (!values.height || values.height.length === 0) {
      errors.height = "required";
    }

    if (!values.positions || values.positions.length === 0) {
      errors.positions = "required";
    }

    if (!values.foot || values.foot.length === 0) {
      errors.foot = "required";
    }

    if (!values.languages || values.languages.length === 0) {
      errors.languages = "required";
    }

    if (
      !values.availability_status ||
      values.availability_status.length === 0
    ) {
      errors.availability_status = "required";
    }

    if (values.is_player_free == 0) {
      if (!values.team_id) {
        errors.team_id = "required";
      }
    }

    // if (!values.team_country) {
    //   errors.team_country = "Required";
    // }

    // if (!values.team_league) {
    //   errors.team_league = "Required";
    // }

    // if (!values.national_team_player) {
    //   errors.national_team_player = "Required";
    // }

    // if (values.national_team_player?.label === 'Yes' && !values.national_team) {
    //   errors.national_team = "Required when National Team Player is Yes";
    // }
    // }

    if (
      !values.availability_status ||
      values.availability_status.length === 0
    ) {
      errors.availability_status = "required";
    }

    // ====================================================================================
    if (!values.new_contract_annual_salary) {
      errors.new_contract_annual_salary = "required";
    }

    if (!values.new_contract_team_asking_amount) {
      errors.new_contract_team_asking_amount = "required";
    }

    // ===================================================================================

    if (!values.carrier_trophies || values.carrier_trophies.length === 0) {
      errors.carrier_trophies = "required";
    }
    return errors;
  };

  useEffect(() => {
    if (playerData?.dob) {
      const birthDate = moment(playerData.dob).toDate();
      calculateAge(birthDate);
    }
  }, [playerData?.dob]);

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDateObj = new Date(dob); // Ensure dob is a valid Date object
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }
    setAge(age); // This will update the state
  };

  const handleFileChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (file && allowedTypes.includes(file.type)) {
      setFieldValue("player_cv", file);
    } else {
      toast.error("Please select a PDF, DOC, or DOCX file.");
    }
  };

  const handleFileChange1 = (e, setFieldValue, fieldName) => {
    const file = e.target.files[0];
    setFieldValue(fieldName, file ? file : null);
  };

  useEffect(() => {
    dispatch(clearPlayerDetail());
    if (playerId) {
      dispatch(getPlayerDataById({ id: playerId, type: 1 }));
    }
    dispatch(getTeamListAction());
  }, [dispatch, playerId]);

  const initialValues = {
    gender:
      optionsGender?.find((elem) => elem.value === playerData?.gender) || [],
    full_name: playerData?.full_name || "",
    // dob: playerData?.dob?.split("T")[0] || null,
    dob: playerData?.dob ? moment(playerData.dob).format("DD/MM/YYYY") : "",
    profile_other_notes: playerData?.profile_other_notes || "",
    place_of_birth:
      optionsPlaceOfBirth?.find(
        (elem) => elem.value === playerData?.place_of_birth
      ) || [],
    nationality:
      playerData?.nationality?.map((item) => ({
        label: item,
        value: item,
      })) || [],

    nationality_id:
      optionsNationaityId?.find(
        (elem) => elem.value === playerData?.nationality_id
      ) || [],
    languages:
      playerData?.languages?.map((item) => ({ label: item, value: item })) ||
      [],
    height:
      optionsHeight?.find((elem) => elem.value === playerData?.height) || "",
    foot:
      playerData?.foot
        ?.map((footValue) => {
          const matchedOption = optionsFoot?.find(
            (option) => option.value === footValue
          );
          return matchedOption || null;
        })
        .filter((option) => option !== null) || [],

    positions:
      playerData?.positions
        ?.map((footValue) => {
          const matchedOption = optionsPosition?.find(
            (option) => option.value === footValue
          );
          return matchedOption || null;
        })
        .filter((option) => option !== null) || [],

    is_player_free: playerData?.is_player_free || 0,

    team_id:
      teamOptions?.find((elem) => elem.value === playerData?.team_id?._id) ||
      "",
    team_country:
      optionsPlaceOfBirth?.find(
        (elem) => elem.value === playerData?.team_id?.team_country
      ) ?? null,
    team_league:
      optionsTeamLeague?.find(
        (elem) => elem.value == playerData?.team_id?.team_league
      ) ?? null,

    national_team_player:
      optionsNationalTeam?.find(
        (elem) => elem.value === playerData?.national_team_player
      ) ?? null,
    national_team:
      optionsPlaceOfBirth?.find(
        (elem) => elem.value === playerData?.national_team
      ) || "",
    availability_status:
      playerData?.availability_status
        ?.map((footValue) => {
          const matchedOption = optionsPlayerStatus?.find(
            (option) => option.value === footValue
          );
          return matchedOption || null;
        })
        .filter((option) => option !== null) || [],

    // carrier_trophies:
    //   playerData?.carrier_trophies?.length > 0
    //     ? playerData?.carrier_trophies?.map((elem) => ({
    //         trophy: optionsTrophy?.find(
    //           (option) => option.value === elem.trophy
    //         ),
    //       }))
    //     : [{ trophy: null }],
    carrier_trophies:
      playerData?.carrier_trophies
        ?.map((trophyObj) => {
          return (
            optionsTrophy?.find((option) => option.value === trophyObj) || null
          );
        })
        .filter((option) => option !== null) || [],

    profile_image: playerData?.profile_image || "",
    video_link: playerData?.video_link || "",
    new_contract_team_asking_amount:
      optionsAskingAmount?.find(
        (elem) => elem.value === playerData?.new_contract_team_asking_amount
      ) || "",
    new_contract_annual_salary:
      optionsannualAskingSalary?.find(
        (elem) => elem.value === playerData?.new_contract_annual_salary
      ) || "",

    carrier_other_notes: playerData?.carrier_other_notes || "",
    previous_player_other_notes: playerData?.previous_player_other_notes || "",
    personal_details_other_notes:
      playerData?.personal_details_other_notes || "",
  };

  const handleShowPreview = (imageSrc) => {
    setPreviewImage(imageSrc);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setPreviewImage("");
  };

  const handleDrop = (e, setFieldValue, type, values) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files);

    if (type === "photos") {
      const imageFiles = files.filter((file) => file.type.startsWith("image/"));

      if (imageFiles.length === 0) {
        return;
      }

      const existingPhotos = Array.isArray(values.photos)
        ? values.photos
        : Array(5).fill(null);
      const spaceLeft =
        5 - existingPhotos.filter((photo) => photo !== null).length;
      const newPhotos = imageFiles.slice(0, spaceLeft);

      const updatedPhotos = [
        ...existingPhotos.filter((photo) => photo !== null),
        ...newPhotos,
      ].slice(0, 5);

      setFieldValue("photos", updatedPhotos);
    } else if (type === "player_cv") {
      const allowedFiles = files.filter(
        (file) =>
          file.type === "application/pdf" ||
          file.type === "application/msword" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );

      if (allowedFiles.length === 0) {
        console.error("Only .pdf, .doc, or .docx files are allowed!");
        return;
      }

      if (allowedFiles.length > 0) {
        setFieldValue("player_cv", allowedFiles[0]);
      }
    }
    // else if (type === "profile_image") {
    //   const imageFiles = files.filter((file) => file.type.startsWith("image/"));
    //   if (imageFiles.length > 0) {
    //     setFieldValue("profile_image", imageFiles[0]);
    //   }
    // }
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      justifyContent: state.data.isAddTeam ? "center" : "flex-start",
      alignItems: "center",
      color: state.data.isAddTeam ? "#103D56" : provided.color,
      fontWeight: state.data.isAddTeam ? "bold" : provided.fontWeight,
    }),
  };

  const handleAddTeam = (values, setFieldValue) => {
    if (!newTeamName.trim()) {
      toast.error("Team name cannot be empty.");
      return;
    }

    let teamCountry = values?.team_country?.value;
    let teamLeague = values?.team_league?.value;

    if (!teamCountry) {
      toast.error("Please select a team country.");
      return;
    }

    if (teamLeague == null) {
      toast.error("Please select a team league.");
      return;
    }
    dispatch(
      addNewTeam({
        name: newTeamName,
        type: 1,
        team_country: teamCountry,
        team_league: teamLeague,
      })
    ).then((res) => {
      if (res?.payload?.status === 200) {
        const newTeamId = res?.payload?.data?._id;
        const newTeam = { label: newTeamName, value: newTeamId };

        setTeamOptions([...teamOptions, newTeam]);
        setFieldValue("team_id", newTeam);
        // setFieldValue("team_country", values.team_country.value);
        // setFieldValue("team_league", values.team_league.value);
        // setFieldValue("national_team_player", null);

        setTimeout(() => {
          setIsModalOpen(false);
          setNewTeamName("");
        }, 0);
        // setIsModalOpen(false);
        // setNewTeamName("");
      } else {
        toast.error("Failed to add the team. Please try again.");
      }
    });
  };

  // const handleInputChange = (input) => {
  //   const isTeamExists = teamOptions?.some((team) =>
  //     team?.label?.toLowerCase()?.includes(input.toLowerCase())
  //   );
  //   setIsAddingTeam(!isTeamExists);
  // };

  const handleInputChange = (input) => {
    const filteredInput = input.replace(/[^a-zA-Z\s]/g, "");
    const isTeamExists = teamOptions?.some((team) =>
      team?.label?.toLowerCase()?.includes(filteredInput.toLowerCase())
    );
    setIsAddingTeam(!isTeamExists);
    return filteredInput;
  };

  // const customOptions = isAddingTeam
  //   ? [{ label: "Add Team", value: "add-team", isAddTeam: true }]
  //   : teamOptions;

  const customOptions = [
    { label: "+ Add new Team", value: "add-team", isAddTeam: true },
    ...teamOptions,
  ];

  const handleSelectChange = (option, setFieldValue) => {
    if (option?.isAddTeam) {
      setIsModalOpen(true);
    } else {
      setFieldValue(
        "team_country",
        optionsPlaceOfBirth?.find(
          (elem) => elem.value === option?.data?.team_country
        )
      );
      setFieldValue(
        "team_league",
        optionsTeamLeague?.find(
          (elem) => elem.value == option?.data?.team_league
        )
      );
      setFieldValue(
        "national_team_player",
        optionsNationalTeam?.find(
          (elem) => elem.value === option?.data?.national_team_player
        )
      );
      setFieldValue("team_id", option);
    }
  };

  const handleSaveAndClose = (values, validateForm, setErrors) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length > 0) {
        toast.error("Please fill in all required fields.");
        setErrors(errors);
        return;
      }

      if (playerId) {
        if (values.dob) {
          values.dob = moment(values.dob, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        let formData = new FormData();

        Object.keys(values).forEach((key) => {
          if (key === "team_id" && values.is_player_free === 1) {
            return;
          }

          if (Array.isArray(values[key])) {
            values[key].forEach((value, index) => {
              if (key !== "photos") {
                if (typeof value === "object" && value !== null) {
                  formData.append(`${key}[${index}]`, value?.value);
                } else if (value !== null && value !== undefined) {
                  formData.append(`${key}[${index}]`, value);
                }
              }
            });
          } else {
            if (key === "height" && values[key]?.value !== undefined) {
              formData.append(key, values[key]?.value);
            } else if (
              typeof values[key] === "object" &&
              values[key] !== null &&
              values[key]?.value !== undefined
            ) {
              formData.append(key, values[key]?.value);
            } else if (values[key] !== null && values[key] !== undefined) {
              formData.append(key, values[key]);
            }
          }
        });

        // formData.append(
        //   "carrier_trophies",
        //   JSON.stringify(transformedData)
        // );
        formData.append("playerId", playerId);
        formData.append("is_profile_completed", 1);

        dispatch(editPlayerAction(formData)).then((res) => {
          if (res?.payload?.status === 200) {
            navigate("/my-players");
          } else {
            toast.error(res?.payload?.message);
          }
        });
      } else {
        if (values.dob) {
          values.dob = moment(values.dob, "DD/MM/YYYY").format("YYYY-MM-DD");
        }

        let formData = new FormData();

        Object.keys(values).forEach((key) => {
          if (Array.isArray(values[key])) {
            values[key].forEach((value, index) => {
              if (key !== "photos") {
                if (typeof value === "object" && value !== null) {
                  formData.append(
                    `${key}[${index}]`,
                    key === "team_id" ? value?.value : value?.value
                  );
                } else if (value !== null && value !== undefined) {
                  formData.append(`${key}[${index}]`, value);
                }
              }
            });
          } else {
            if (key === "height" && values[key]?.value !== undefined) {
              formData.append(key, values[key]?.value);
            } else if (key === "gender" || key === "team_id") {
              if (
                values[key]?.value !== null &&
                values[key]?.value !== undefined
              ) {
                formData.append(key, values[key]?.value);
              }
            } else if (
              typeof values[key] === "object" &&
              values[key] !== null &&
              values[key]?.value !== undefined
            ) {
              formData.append(key, values[key]?.value);
            } else if (values[key] !== null && values[key] !== undefined) {
              formData.append(key, values[key]);
            }
          }
        });

        // formData.append("carrier_trophies", JSON.stringify(transformedData));
        formData.append("is_profile_completed", 1);

        dispatch(createPlayerAction(formData)).then((res) => {
          if (res?.payload?.status === 200) {
            navigate("/my-players");
          } else {
            toast.error(res?.payload?.message);
          }
        });
      }
    });
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <ProgressBar currentStep={1} totalSteps={8} />
          <div className="steps-heading">
            <p>
              {" "}
              <span>Step 1:</span> Player's Main Information{" "}
            </p>
          </div>
          <div className="over-ride-cmn">
            <Formik
              initialValues={initialValues}
              validate={validateForm}
              enableReinitialize={true}
              onSubmit={(values) => {
                if (playerId) {
                  if (values.dob) {
                    values.dob = moment(values.dob, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    );
                  }

                  let formData = new FormData();

                  Object.keys(values).forEach((key) => {
                    if (key === "team_id" && values.is_player_free === 1) {
                      return;
                    }

                    if (Array.isArray(values[key])) {
                      values[key].forEach((value, index) => {
                        if (key !== "photos") {
                          if (typeof value === "object" && value !== null) {
                            formData.append(`${key}[${index}]`, value?.value);
                          } else if (value !== null && value !== undefined) {
                            formData.append(`${key}[${index}]`, value);
                          }
                        }
                      });
                    } else {
                      if (
                        key === "height" &&
                        values[key]?.value !== undefined
                      ) {
                        formData.append(key, values[key]?.value);
                      } else if (
                        typeof values[key] === "object" &&
                        values[key] !== null &&
                        values[key]?.value !== undefined
                      ) {
                        formData.append(key, values[key]?.value);
                      } else if (
                        values[key] !== null &&
                        values[key] !== undefined
                      ) {
                        formData.append(key, values[key]);
                      }
                    }
                  });

                  formData.append("playerId", playerId);
                  formData.append("is_profile_completed", 1);

                  dispatch(editPlayerAction(formData)).then((res) => {
                    if (res?.payload?.status === 200) {
                      navigate(
                        `/agent-relationship/${Encryptedid(
                          res?.payload?.data?._id
                        )}`
                      );
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                } else {
                  if (values.dob) {
                    values.dob = moment(values.dob, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    );
                  }

                  let formData = new FormData();

                  Object.keys(values).forEach((key) => {
                    if (Array.isArray(values[key])) {
                      values[key].forEach((value, index) => {
                        if (key !== "photos") {
                          if (typeof value === "object" && value !== null) {
                            formData.append(
                              `${key}[${index}]`,
                              key === "team_id" ? value?.value : value?.value
                            );
                          } else if (value !== null && value !== undefined) {
                            formData.append(`${key}[${index}]`, value);
                          }
                        }
                      });
                    } else {
                      if (
                        key === "height" &&
                        values[key]?.value !== undefined
                      ) {
                        formData.append(key, values[key]?.value);
                      } else if (key === "gender" || key === "team_id") {
                        if (
                          values[key]?.value !== null &&
                          values[key]?.value !== undefined
                        ) {
                          formData.append(key, values[key]?.value);
                        }
                      } else if (
                        typeof values[key] === "object" &&
                        values[key] !== null &&
                        values[key]?.value !== undefined
                      ) {
                        formData.append(key, values[key]?.value);
                      } else if (
                        values[key] !== null &&
                        values[key] !== undefined
                      ) {
                        formData.append(key, values[key]);
                      }
                    }
                  });

                  formData.append("is_profile_completed", 1);

                  dispatch(createPlayerAction(formData)).then((res) => {
                    if (res?.payload?.status === 200) {
                      navigate(
                        `/agent-relationship/${Encryptedid(
                          res?.payload?.data?._id
                        )}`
                      );
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                }
              }}
            >
              {({ values, setFieldValue, validateForm, setErrors }) => (
                <FormikForm>
                  {playerDataLoadingState && <Loader />}
                  <div className="personal-heading mt-2">
                    <h3>Personal Details</h3>

                    <div className="grid-section">
                      <Form.Group className="mb-3 form-inner label-text-up">
                        <label className="mb-2">Full Name</label>
                        <Field
                          name="full_name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Name here"
                          onKeyPress={(event) => {
                            const regex = /^[a-zA-Z\s]*$/;
                            if (!regex.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onPaste={(event) => {
                            const pastedData =
                              event.clipboardData.getData("Text");
                            const regex = /^[a-zA-Z\s]*$/;
                            if (!regex.test(pastedData)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <ErrorMessage
                          name="full_name"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3 form-inner  label-text-up">
                        <label className="mb-2">Date of Birth</label>
                        <div className="age-box">
                          {/* <Field
                            name="dob"
                            placeholder="Date of Birth"
                            type={inputType}
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("dob", e.target.value);
                              calculateAge(e.target.value);
                            }}
                            onFocus={() => setInputType("date")}
                            onBlur={() => setInputType("text")}
                            max={getYesterday()}
                          /> */}

                          <DatePicker
                            id="dob"
                            name="dob"
                            selected={
                              values.dob
                                ? moment(values.dob, "DD/MM/YYYY").toDate()
                                : null
                            }
                            // onChange={(date) => {
                            //   const formattedDate = moment(date).format('DD/MM/YYYY');
                            //   setFieldValue("dob", formattedDate);
                            //   calculateAge(date);
                            // }}
                            onChange={(date) => {
                              if (date) {
                                const formattedDate =
                                  moment(date).format("DD/MM/YYYY");
                                setFieldValue("dob", formattedDate);
                                calculateAge(date);
                              } else {
                                setFieldValue("dob", "");
                              }
                            }}
                            placeholderText="Date of birth"
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            className="custom-datepicker__input form-control"
                            calendarClassName="custom-datepicker"
                          />

                          {age ? (
                            <Form.Group className="form-inner player-age">
                              <div className="form-control">{`${age} yrs`}</div>
                            </Form.Group>
                          ) : (
                            ""
                          )}
                        </div>
                        <ErrorMessage
                          name="dob"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <div className="profile-card label-text-up">
                        <label className="mb-2">Gender</label>
                        <Select
                          name="gender"
                          placeholder="Select"
                          options={optionsGender}
                          onChange={(option) => setFieldValue("gender", option)}
                          value={values.gender}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Nationality(s)</label>
                        <Select
                          name="nationality"
                          placeholder="Select"
                          isMulti
                          options={optionsNationaity}
                          onChange={(option) =>
                            setFieldValue("nationality", option)
                          }
                          value={values.nationality}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="nationality"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Nationality ID</label>
                        <Select
                          name="nationality_id"
                          placeholder="Select"
                          options={optionsNationaityId}
                          onChange={(option) =>
                            setFieldValue("nationality_id", option)
                          }
                          value={values.nationality_id || ""}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="nationality_id"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="grid-section bottom-lines">
                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Language(s)</label>
                        <Select
                          name="Languages"
                          options={optionsLanguage}
                          placeholder="Select"
                          isMulti
                          onChange={(option) =>
                            setFieldValue("languages", option)
                          }
                          value={values.languages}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="languages"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Height</label>
                        <Select
                          name="height"
                          placeholder="Select"
                          options={optionsHeight}
                          onChange={(option) => setFieldValue("height", option)}
                          value={values.height}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="height"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Foot</label>
                        <Select
                          name="foot"
                          placeholder="Select"
                          options={optionsFoot}
                          isMulti
                          onChange={(option) => setFieldValue("foot", option)}
                          value={values.foot}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="foot"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Position(s)</label>
                        <Select
                          name="positions"
                          placeholder="Select"
                          options={optionsPosition}
                          isMulti
                          onChange={(option) =>
                            setFieldValue("positions", option)
                          }
                          value={values.positions}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="positions"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">
                          Annual Asking Salary for New Contract
                        </label>

                        <Select
                          options={optionsannualAskingSalary}
                          name="new_contract_annual_salary"
                          placeholder="Select"
                          onChange={(option) =>
                            setFieldValue("new_contract_annual_salary", option)
                          }
                          value={values.new_contract_annual_salary}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="new_contract_annual_salary"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">
                          Team’s Asking Amount for Transfer
                        </label>
                        <Select
                          placeholder="Select"
                          name="new_contract_team_asking_amount"
                          options={optionsAskingAmount}
                          onChange={(option) =>
                            setFieldValue(
                              "new_contract_team_asking_amount",
                              option
                            )
                          }
                          value={values.new_contract_team_asking_amount}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="new_contract_team_asking_amount"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="availability label-text-up">
                        <label className="">Availability Status</label>

                        <div className="cmn-form-fields mt-2">
                          <div className="profile-card mb-3">
                            <Select
                              name="availability_status"
                              placeholder="Select"
                              options={optionsPlayerStatus}
                              isMulti
                              onChange={(option) =>
                                setFieldValue("availability_status", option)
                              }
                              value={values.availability_status}
                              classNamePrefix="custom-select"
                            />
                            <ErrorMessage
                              name="availability_status"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>

                      <Form.Group className="mb-3 form-inner label-text-up">
                        <label className="mb-2">Other notes</label>
                        <Field
                          name="personal_details_other_notes"
                          type="text"
                          className="form-control"
                          placeholder="Write notes here"
                        />
                      </Form.Group>
                    </div>

                    <div className="mt-4">
                      <div className="d-flex justify-content-between">
                        {values?.availability_status?.some(
                          (option) => option.label == "Currently Free"
                        ) ? (
                          <h3 className="h-20">Previous Player's Team</h3>
                        ) : (
                          <h3 className="h-20">Player's Team</h3>
                        )}
                        {/* <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="is_player_free"
                            onChange={(e) => {
                              const isChecked = e.target.checked;

                              setFieldValue(
                                "is_player_free",
                                isChecked ? 1 : 0
                              );
                              setIsFree(isChecked ? 1 : 0);

                              if (isChecked) {
                                setFieldValue("team_id", []);
                                setFieldValue("team_country", []);
                                setFieldValue("team_league", []);
                                setFieldValue("national_team_player", []);
                                setFieldValue("national_team", []);
                              }
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="is_player_free"
                          >
                            {" "}
                            Free{" "}
                          </label>
                        </div> */}
                      </div>

                      {/* {!isFree && values.is_player_free == 0 ? (
                        <> */}
                      <div className="cmn-form-fields grid-players bottom-lines mt-2">
                        <Form.Group className="mb-3 form-inner">
                          <div className="label-text-up">
                            <label className="mb-2">Team's Name</label>
                            <Select
                              name="team_id"
                              placeholder="Select"
                              classNamePrefix="custom-select"
                              options={customOptions}
                              isDisabled={
                                isFree &&
                                (!values.team_id || values.team_id.length === 0)
                              }
                              components={{ DropdownIndicator: null }}
                              onChange={(e) =>
                                handleSelectChange(e, setFieldValue)
                              }
                              onInputChange={handleInputChange}
                              value={values.team_id || null}
                              isClearable
                              // styles={customStyles}
                              formatOptionLabel={(data) =>
                                data.isAddTeam ? (
                                  <div
                                    style={{
                                      color: "#103D56",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => setIsModalOpen(true)}
                                  >
                                    {" "}
                                    + Add new Team
                                  </div>
                                ) : (
                                  data.label
                                )
                              }
                              noOptionsMessage={() =>
                                isAddingTeam ? (
                                  <div
                                    className="modal-add-teams"
                                    style={{
                                      color: "#103D56",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => setIsModalOpen(true)}
                                  >
                                    {" "}
                                    + Add new Team
                                  </div>
                                ) : (
                                  "No options found"
                                )
                              }
                            />

                            <Modal
                              isOpen={isModalOpen}
                              onRequestClose={() => setIsModalOpen(false)}
                              contentLabel="Add New Team"
                              style={{
                                content: {
                                  top: "50%",
                                  left: "50%",
                                  right: "auto",
                                  bottom: "auto",
                                  marginRight: "-50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "100%",
                                  maxWidth: "600px",
                                  boxShadow:
                                    "0px 2px 20px 0px rgba(0, 35, 149, 0.15)",
                                  background: "#fff",
                                  borderRadius: "10px",
                                  border: "none",
                                  padding: "30px",
                                },
                              }}
                            >
                              <div className="heding-add-new ">
                                <h3>Add New Team</h3>
                              </div>
                              <div className="add-new-input label-text-up">
                                <label className="mb-2">Team’s Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Team Name"
                                  value={newTeamName}
                                  onChange={(e) =>
                                    setNewTeamName(e.target.value)
                                  }
                                  onKeyPress={(event) => {
                                    const regex = /^[a-zA-Z\s]*$/;
                                    if (!regex.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onPaste={(event) => {
                                    const pastedData =
                                      event.clipboardData.getData("Text");
                                    const regex = /^[a-zA-Z\s]*$/;
                                    if (!regex.test(pastedData)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />

                                {/* {!newTeamName && touched.teamName && (
                                  <div className="text-danger">required.</div>
                                )} */}
                              </div>

                              <div className="add-new-input label-text-up">
                                <label className="mb-2">Team’s Country</label>
                                <Select
                                  name="team_country"
                                  placeholder="Select"
                                  options={optionsPlaceOfBirth}
                                  classNamePrefix="custom-select"
                                  onChange={(option) =>
                                    setFieldValue("team_country", option)
                                  }
                                  value={values.team_country}
                                />
                                {/* {!values.team_country && touched.teamCountry && (
                                  <div className="text-danger">required.</div>
                                )} */}
                              </div>

                              <div className="add-new-input label-text-up">
                                <label className="mb-2">Team’s League</label>
                                <Select
                                  name="team_league"
                                  placeholder="Select"
                                  classNamePrefix="custom-select"
                                  options={optionsTeamLeague}
                                  onChange={(option) =>
                                    setFieldValue("team_league", option)
                                  }
                                  value={values.team_league}
                                  // isDisabled={values.team_league == null}
                                />
                                {/* {!values.team_league && touched.teamLeague && (
                                  <div className="text-danger">required.</div>
                                )} */}
                              </div>

                              <div className="add-team-btn ">
                                <button
                                  className="add-modal-btn-ps"
                                  onClick={() =>
                                    handleAddTeam(values, setFieldValue)
                                  }
                                >
                                  Add
                                </button>
                                {/* <button
                                  className="add-modal-btn-ps"
                                  onClick={() => {
                                    // Validate before submitting
                                    if (!newTeamName || !values.team_country || !values.team_league) {
                                      setTouched({
                                        teamName: true,
                                        teamCountry: true,
                                        teamLeague: true,
                                      });
                                      return;
                                    }
                                    handleAddTeam(values, setFieldValue);
                                  }}
                                >
                                  Add
                                </button> */}
                                <button onClick={() => setIsModalOpen(false)}>
                                  Cancel
                                </button>
                              </div>
                            </Modal>
                          </div>

                          <ErrorMessage
                            name="team_id"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>

                        <div className="profile-card mb-3 label-text-up">
                          <label className="mb-2">Team's Country</label>

                          <Select
                            name="team_country"
                            placeholder="Select"
                            options={optionsPlaceOfBirth}
                            classNamePrefix="custom-select"
                            onChange={(option) =>
                              setFieldValue("team_country", option)
                            }
                            value={values.team_country}
                            isDisabled={
                              !!values.team_country ||
                              values.team_country == null
                            }
                          />
                          <ErrorMessage
                            name="team_country"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="profile-card mb-3 label-text-up">
                          <label className="mb-2">Team's League</label>

                          <Select
                            name="team_league"
                            placeholder="Select"
                            classNamePrefix="custom-select"
                            options={optionsTeamLeague}
                            onChange={(option) =>
                              setFieldValue("team_league", option)
                            }
                            value={values.team_league}
                            isDisabled={
                              !!values.team_league || values.team_league == null
                            }
                          />
                          <ErrorMessage
                            name="team_league"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="profile-card label-text-up">
                          <label className="mb-2">National Team Player</label>

                          <Select
                            name="national_team_player"
                            placeholder="Select"
                            classNamePrefix="custom-select"
                            options={optionsNationalTeam}
                            onChange={(option) =>
                              setFieldValue("national_team_player", option)
                            }
                            value={values.national_team_player}
                            // isDisabled={values.national_team_player == null}
                          />
                          <ErrorMessage
                            name="national_team_player"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <Form.Group className="mb-3 form-inner label-text-up one-more-grid">
                          <label className="mb-2">Other notes</label>

                          <Field
                            name="previous_player_other_notes"
                            type="text"
                            className="form-control"
                            placeholder="Write notes here"
                          />
                        </Form.Group>

                        {values?.national_team_player?.label == "Yes" && (
                          <div className="profile-card mb-3 label-text-up ">
                            <label className="mb-2">National Team</label>

                            <Select
                              name="national_team"
                              placeholder="Select"
                              options={optionsPlaceOfBirth}
                              onChange={(option) =>
                                setFieldValue("national_team", option)
                              }
                              value={values.national_team}
                              classNamePrefix="custom-select"
                            />
                            <ErrorMessage
                              name="national_team"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        )}
                      </div>
                      {/* </>
                      ) : (
                        <>
                          <div className="cmn-form-fields grid-section   mt-2">
                            <Form.Group className="mb-3 form-inner label-text-up">
                              <label className="mb-2">Team's Name</label>
                              <Select
                                name="team_id"
                                placeholder="Select"
                                options={teamOptions}
                                onChange={(option) => {
                                  setFieldValue("team_id", option);

                                  if (teanListingData) {
                                    const data = teanListingData?.find(
                                      (ele) => ele?._id === option?.value
                                    );
                                    setFieldValue(
                                      "team_country",
                                      optionsPlaceOfBirth?.find(
                                        (elem) =>
                                          elem.value == data?.team_country
                                      )
                                    );
                                    setFieldValue(
                                      "team_league",
                                      optionsTeamLeague?.find(
                                        (elem) =>
                                          elem.value == data?.team_league
                                      )
                                    );
                                    setIsDisabled(true);
                                  }
                                }}
                                value={values.team_id || null}
                                classNamePrefix="custom-select"
                              />
                            </Form.Group>

                            <div className="profile-card mb-3 label-text-up">
                              <label className="mb-2">Team's Country</label>
                              <Select
                                name="team_country"
                                placeholder="Select"
                                options={optionsPlaceOfBirth}
                                onChange={(option) =>
                                  setFieldValue("team_country", option)
                                }
                                value={values.team_country}
                                isDisabled={isDisabled}
                                classNamePrefix="custom-select"
                              />
                              <ErrorMessage
                                name="team_country"
                                component="div"
                                className="text-danger"
                              />
                            </div>

                            <div className="profile-card mb-3 label-text-up">
                              <label className="mb-2">Team's League</label>
                              <Select
                                name="team_league"
                                placeholder="Select"
                                options={optionsTeamLeague}
                                onChange={(option) =>
                                  setFieldValue("team_league", option)
                                }
                                value={values.team_league}
                                isDisabled={isDisabled}
                                classNamePrefix="custom-select"
                              />
                              <ErrorMessage
                                name="team_league"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </>
                      )} */}
                      {/* ===================================================================================== */}

                      <div className="vidoe-link-box">
                        <div
                          className="bottom-lines mt-4 mb-3"
                          style={{ display: "flex", gap: "10px" }}
                        >
                          <div className="trophies ">
                            {/* <FieldArray name="carrier_trophies">
                            {({ push, remove }) => (
                              <>
                                <div className="d-flex justify-content-between">
                                  <h3 className="h-20">Carrier Trophies</h3>
                                </div>
                                {values?.carrier_trophies?.map(
                                  (trophy, index) => (
                                    <div
                                      key={index}
                                      className="cmn-form-fields new-bottom-flex"
                                    >
                                      <div className="profile-card mb-3 profile-width">
                                        <Select
                                          name={`carrier_trophies[${index}].trophy`}
                                          placeholder="Select"
                                          classNamePrefix="custom-select"
                                          options={optionsTrophy}
                                          isMulti
                                          // onChange={(option) =>
                                          //   setFieldValue( `carrier_trophies[${index}].trophy`, option )
                                          // }
                                          onChange={(selectedOptions) => {
                                            const isNotYetSelected =
                                              selectedOptions.some(
                                                (option) =>
                                                  option.label === "Not Yet"
                                              );
                                            if (isNotYetSelected) {
                                              setFieldValue(
                                                `carrier_trophies[${index}].trophy`,
                                                [
                                                  {
                                                    label: "Not Yet",
                                                    value: "Not Yet",
                                                  },
                                                ]
                                              );
                                            } else {
                                              setFieldValue(
                                                `carrier_trophies[${index}].trophy`,
                                                selectedOptions
                                              );
                                            }
                                          }}
                                          value={
                                            values.carrier_trophies[index]
                                              .trophy
                                          }
                                        />
                                        <ErrorMessage
                                          name={`carrier_trophies[${index}].trophy`}
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                      {index !== 0 && (
                                        <button
                                          type="button"
                                          onClick={() => remove(index)}
                                          style={{
                                            border: "none",
                                            background: "none",
                                          }}
                                        >
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51808 4.3459 0.761209 6.17316C0.0043329 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34879 18.9423 4.80707 17.0676 2.93239C15.1929 1.0577 12.6512 0.0031277 10 0ZM14.5455 10.9091H10.9091H7.05411C7.05411 11.1502 11.0796 14.1381 10.9091 14.3086C8.95963 12.4289 8.69336 13.8086 10 15.4545C9.7589 15.4545 11.6021 13.1035 11.4316 12.933C11.2612 12.7625 10.6934 14.0477 10.6934 13.8066L9.09091 10.9091H5.45455C5.21344 10.9091 4.98221 10.8133 4.81172 10.6428C4.64124 10.4723 4.54546 10.2411 4.54546 10C4.54546 9.75889 4.64124 9.52766 4.81172 9.35717C4.98221 9.18669 5.21344 9.09091 5.45455 9.09091H9.09091L10.4316 6.80859C10.4316 6.56749 11.2612 6.97908 11.4316 6.80859C11.6021 6.63811 11.6905 6.30859 11.9316 6.30859C12.1727 6.30859 10.4723 4.64123 10.6428 4.81172C10.8133 4.98221 7.16322 8.8498 7.16322 9.09091H10.9091H14.5455C14.7866 9.09091 15.0178 9.18669 15.1883 9.35717C15.3588 9.52766 15.4545 9.75889 15.4545 10C15.4545 10.2411 15.3588 10.4723 15.1883 10.6428C15.0178 10.8133 14.7866 10.9091 14.5455 10.9091Z"
                                              fill="#103D56"
                                            />
                                          </svg>
                                        </button>
                                      )}
                                    </div>
                                  )
                                )}
                              </>
                            )}
                          </FieldArray> */}
                            <div className="d-flex justify-content-between">
                              <h3 className="h-20">Carrier Trophies</h3>
                            </div>
                            <div className="profile-card mb-3 profile-width">
                              <Select
                                name="carrier_trophies"
                                placeholder="Select"
                                classNamePrefix="custom-select"
                                options={optionsTrophy}
                                onChange={(selectedOptions) => {
                                  const isNotYetSelected = selectedOptions.some(
                                    (option) => option.value == 6
                                  );
                                  if (isNotYetSelected) {
                                    setFieldValue(`carrier_trophies`, [
                                      {
                                        label: "Not Yet",
                                        value: 6,
                                      },
                                    ]);
                                  } else {
                                    setFieldValue(
                                      `carrier_trophies`,
                                      selectedOptions
                                    );
                                  }
                                }}
                                isMulti
                                // onChange={(selectedOptions) => setFieldValue('carrier_trophies', selectedOptions)}
                                value={values.carrier_trophies}
                              />
                            </div>
                            <ErrorMessage
                              name="carrier_trophies"
                              component="div"
                              className="text-danger"
                            />
                          </div>

                          <div className="trophies ">
                            <Form.Group className="mb-3 form-inner label-text-up">
                              <label className="mb-2">Other notes</label>
                              <Field
                                name="carrier_other_notes"
                                type="text"
                                className="form-control"
                                placeholder="Write notes here"
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div className="box-flex-up link-videos bottom-lines pb-4 mb-3">
                          <div className="players-cv">
                            <div className="player-up">
                              <h3>Player's Image</h3>
                              <div
                                className={`uplode-the-img ${
                                  dragging ? "dragging" : ""
                                }`}
                                onDrop={(e) =>
                                  handleDrop(e, setFieldValue, "profile_image")
                                }
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                              >
                                <div className="upload-and-drop">
                                  <div className="click-to-up">
                                    <label htmlFor="file-upload">
                                      <button type="button">
                                        Click to Upload
                                        <Field name="profile_image">
                                          {({ field }) => (
                                            <input
                                              type="file"
                                              id="file-upload"
                                              onChange={(e) =>
                                                handleFileChange1(
                                                  e,
                                                  setFieldValue,
                                                  "profile_image"
                                                )
                                              }
                                              accept="image/*"
                                            />
                                          )}
                                        </Field>
                                      </button>
                                    </label>
                                  </div>
                                  <div className="drag-drop">
                                    <p>
                                      <span>
                                        <img
                                          src={
                                            require("../Assets/images/drag-and-drop.svg")
                                              .default
                                          }
                                          alt="Upload Placeholder"
                                        />
                                      </span>
                                      Or drag and drop files.
                                    </p>
                                  </div>
                                </div>
                                <div className="preview">
                                  <p>Preview</p>
                                  <div className="preview-content">
                                    {values?.profile_image &&
                                      (typeof values.profile_image ===
                                      "string" ? (
                                        <img
                                          className="inner-view"
                                          src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                          alt="Profile Preview"
                                          onClick={() =>
                                            handleShowPreview(
                                              `${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`
                                            )
                                          }
                                        />
                                      ) : values.profile_image instanceof
                                        File ? (
                                        <img
                                          src={URL.createObjectURL(
                                            values?.profile_image
                                          )}
                                          className="prev-img"
                                          alt="Profile Preview"
                                          style={{ width: "50px" }}
                                          onClick={() =>
                                            handleShowPreview(
                                              URL.createObjectURL(
                                                values?.profile_image
                                              )
                                            )
                                          }
                                        />
                                      ) : null)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="carrier-notes">
                      <div className="link-videos mt-4">
                        <div className="video-link new-video-link  pb-4 ">
                          <h3>Player's Video Links</h3>
                          <Form.Group controlId="formBasicEmail">
                            <Field name="video_link">
                              {({ field }) => (
                                <Form.Control
                                  {...field}
                                  type="url"
                                  placeholder="Add Video Link (YouTube / Instagram Video link)."
                                  name="video_link"
                                  value={values.video_link}
                                />
                              )}
                            </Field>
                            <Form.Text className="text-muted">
                              {" "}
                              *You can paste here any YouTube / Instagram video
                              link{" "}
                            </Form.Text>
                          </Form.Group>
                        </div>
                        <ErrorMessage
                          name="video_link"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      {/* <div className="box-flex-up link-videos">
                        <div className="players-cv">
                          <div className="player-up">
                            <h3>Profile Image</h3>
                            <div
                              className={`uplode-the-img ${
                                dragging ? "dragging" : ""
                              }`}
                              onDrop={(e) =>
                                handleDrop(e, setFieldValue, "profile_image")
                              }
                              onDragOver={handleDragOver}
                              onDragLeave={handleDragLeave}
                            >
                              <div className="upload-and-drop">
                                <div className="click-to-up">
                                  <label htmlFor="file-upload">
                                    <button type="button">
                                      Click to Upload
                                      <Field name="profile_image">
                                        {({ field }) => (
                                          <input
                                            type="file"
                                            id="file-upload"
                                            onChange={(e) =>
                                              handleFileChange1(
                                                e,
                                                setFieldValue,
                                                "profile_image"
                                              )
                                            }
                                            accept="image/*"
                                          />
                                        )}
                                      </Field>
                                    </button>
                                  </label>
                                </div>
                                <div className="drag-drop">
                                  <p>
                                    <span>
                                      <img
                                        src={
                                          require("../Assets/images/drag-and-drop.svg")
                                            .default
                                        }
                                        alt="Upload Placeholder"
                                      />
                                    </span>
                                    Or drag and drop file.
                                  </p>
                                </div>
                              </div>
                              <div className="preview">
                                <p>Preview</p>
                                <div className="preview-content">
                                  {values?.profile_image &&
                                    (typeof values.profile_image ===
                                    "string" ? (
                                      <img
                                        className="inner-view"
                                        src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                        alt="Profile Preview"
                                        onClick={() =>
                                          handleShowPreview(
                                            `${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`
                                          )
                                        }
                                      />
                                    ) : values.profile_image instanceof File ? (
                                      <img
                                        src={URL.createObjectURL(
                                          values?.profile_image
                                        )}
                                        className="prev-img"
                                        alt="Profile Preview"
                                        style={{ width: "50px" }}
                                        onClick={() =>
                                          handleShowPreview(
                                            URL.createObjectURL(
                                              values?.profile_image
                                            )
                                          )
                                        }
                                      />
                                    ) : null)}
                                </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="cmn-form-fields uplode-click-box-main">
                        <div className="all-notes ">
                          <h3 className="h-20">Other Notes</h3>
                          <div className="cmn-form-fields">
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                as="textarea"
                                name="profile_other_notes"
                                rows={3}
                                className="form-control"
                                placeholder="Write notes here"
                              />
                              <ErrorMessage
                                name="profile_other_notes"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="d-flex justify-content-end mb-5">
                    <div className="mt-4 ">
                      <div className="cmn-light-btn save-and-close-btn">
                        <button
                          type="button"
                          onClick={() =>
                            handleSaveAndClose(values, validateForm, setErrors)
                          }
                          className="next-btn-ps"
                        >
                          {" "}
                          Save & Close{" "}
                        </button>
                      </div>
                    </div>

                    <div className="mt-4 ">
                      <div className="cmn-light-btn">
                        <button type="submit" className="next-btn-ps">
                          {" "}
                          Save & Next{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
      <PreviewModal
        show={showPreview}
        onHide={handleClosePreview}
        previewImage={previewImage}
      />
    </LoginLayout>
  );
};

export default ProfileInfo;
