import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import axios from "axios"; // Make sure to install axios
import api from "../Service/api";
import AgentModalComp from "./AgentModalComp";
import { capitalize } from "../utils/helper";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const TransferModalComp = ({
  showTransfer,
  setShowTransfer,
  setShowSelected,
}) => {
  const profileDetail = useSelector(
    (state) => state.userAuthData.getProfileData
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [showAgent, setShowAgent] = useState(false);
  const [formData, setFormData] = useState({
    transfer_to: "",
    type: "",
    player_id: "",
    director_id: "",
    coach_id: "",
    joined_on: "",
    transfer_on: "",
  });

  console.log("selectedUser", selectedUser);
  const handleSearch = async (query) => {
    setSearchQuery(query);
    // if (query.length > 2) {
    try {
      const response = await api.get(
        `/user/internalSearchForTransfer?word=${query}`
      );
      setSearchResults(response?.data?.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
    // }
  };

  console.log(searchResults, "searchResults");

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setSearchResults([]);
    setSearchQuery("");

    if (user.type === "player") {
      setFormData((prev) => ({ ...prev, player_id: user._id, type: "1" }));
    } else if (user.type === "director") {
      setFormData((prev) => ({ ...prev, director_id: user._id, type: "2" }));
    } else if (user.type === "coach") {
      setFormData((prev) => ({ ...prev, coach_id: user._id, type: "3" }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  console.log("formData", formData);
  const handleSubmit = async () => {
    try {
      if (formData?.transfer_to == "") {
        return toast.error("Please add Agent to transfer");
      }

      let payload = {
        joined_on: formData?.joined_on,
        transfer_on: formData?.transfer_on,
        transfer_to: formData?.transfer_to,
        type: formData?.type,
      };
      if (formData?.player_id) {
        payload = { ...payload, player_id: formData?.player_id };
      } else if (formData?.coach_id) {
        payload = { ...payload, coach_id: formData?.coach_id };
      } else if (formData?.director_id) {
        payload = { ...payload, director_id: formData?.director_id };
      }
      const response = await api.post("/user/transferUser", payload);
      if (response.status === 200) {
        // toast.success(response?.data?.message);
        setShowSelected(true);
        setShowTransfer(false);
        setSelectedUser(null)
        setSelectedAgent(null)
        setFormData({
          transfer_to: "",
          type: "",
          player_id: "",
          director_id: "",
          coach_id: "",
          joined_on: "",
          transfer_on: "",
        });
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error submitting transfer:", error);
    }
  };

  const handleAgentSelect = (agent) => {
    setFormData((prev) => ({ ...prev, transfer_to: agent._id }));
    setSelectedAgent(agent);
  };

  console.log(searchResults, "searchResults");

  const handleClose = () => {
    setShowTransfer(false);
    setSearchResults([])
    setSelectedUser(null)
    setSelectedAgent(null)
    setFormData({
      transfer_to: "",
      type: "",
      player_id: "",
      director_id: "",
      coach_id: "",
      joined_on: "",
      transfer_on: "",
    });
  };

  return (
    <Modal
      className="cmn-modal"
      rr
      size="xl"
      show={showTransfer}
      onHide={() => handleClose()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title>Schedule Transfer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="create-met">
          <div className="cmn-form-fields">
            <Row>
              <Col lg={4}>
                <Form.Group className="mb-3  add-shadow">
                  <label className="sub-16 mb-2">
                    Choose Player/Director/Coach
                  </label>

                  <Form.Control
                    type="text"
                    placeholder="Search by name or ID"
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                  />

                  {searchResults?.length > 0 && (
                    <div className="search-results">
                      {searchResults?.map((user) => {
                        return (
                          <div
                            key={user._id}
                            className="play-inner-box add-new-bg-shadow"
                            onClick={() => handleUserSelect(user)}
                          >
                            <div className="d-flex align-items-center">
                              {user.type === "player" ? (
                                <img
                                  src={
                                    user?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${user?.profile_image}`
                                      : require("../Assets/images/user.png")
                                  }
                                />
                              ) : user.type === "director" ? (
                                <img
                                  src={
                                    user?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${user?.profile_image}`
                                      : require("../Assets/images/My Directors male.png")
                                  }
                                />
                              ) : (
                                <img
                                  src={
                                    user?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${user?.profile_image}`
                                      : require("../Assets/images/coach-male.png")
                                  }
                                />
                              )}
                              <div className="ms-3">
                                <h4>{capitalize(user?.full_name)}</h4>
                                <h6>
                                  {user.type === "player"
                                    ? "Player"
                                    : user.type === "director"
                                    ? "Director"
                                    : "Coach"}{" "}
                                  ID: <span>{user?.unique_id}</span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Form.Group>

                {selectedUser && (
                  <div className="play-inner-box add-new-bg-shadow">
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          selectedUser?.profile_image
                            ? `${process.env.REACT_APP_IMAGE_PATH}${selectedUser?.profile_image}`
                            : require("../Assets/images/user.png")
                        }
                      />
                      <div className="ms-3">
                        <h4>{selectedUser?.full_name}</h4>
                        <h4>
                          {selectedUser.type === "player"
                            ? "Player"
                            : selectedUser.type === "director"
                            ? "Director"
                            : "Coach"}{" "}
                          ID: <span>{selectedUser?.unique_id}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3">
                  <label className="sub-16 mb-2">Transferring from:</label>
                  <div className="small-cmn-shadow">
                    {profileDetail?.profile_image ? (
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PATH}${profileDetail?.profile_image}`}
                        alt="User Profile"
                      />
                    ) : (
                      <img
                        src={require("../Assets/images/vector-image.png")}
                        alt="Default User"
                      />
                    )}
                    <h3>{profileDetail?.full_name || "N/a"}</h3>
                  </div>
                  <div className="small-cmn-shadow justify-content-between mt-2 border-hide">
                    <h4>Joined On</h4>
                    <input
                      type="date"
                      name="joined_on"
                      className="form-control"
                      value={formData.joined_on}
                      onChange={handleInputChange}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group className="mb-3">
                  <label className="sub-16">Transferring To:</label>
                  <div className="small-cmn-shadow justify-content-between mt-2">
                    <h4>Add Agent</h4>
                    <button type="button" onClick={() => setShowAgent(true)}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_12863_11784)">
                          <path
                            d="M10.0007 0.832031C8.18766 0.832031 6.41538 1.36965 4.90793 2.37689C3.40048 3.38414 2.22556 4.81578 1.53176 6.49077C0.837956 8.16576 0.656426 10.0089 1.01012 11.787C1.36382 13.5652 2.23686 15.1985 3.51884 16.4805C4.80082 17.7625 6.43417 18.6355 8.21233 18.9892C9.99049 19.3429 11.8336 19.1614 13.5086 18.4676C15.1836 17.7738 16.6152 16.5989 17.6225 15.0914C18.6297 13.584 19.1673 11.8117 19.1673 9.9987C19.1645 7.56843 18.1978 5.23851 16.4793 3.52005C14.7608 1.80159 12.4309 0.834898 10.0007 0.832031ZM14.1673 10.832H10.834V14.1654C10.834 14.3864 10.7462 14.5983 10.5899 14.7546C10.4336 14.9109 10.2217 14.9987 10.0007 14.9987C9.77964 14.9987 9.56768 14.9109 9.4114 14.7546C9.25512 14.5983 9.16732 14.3864 9.16732 14.1654V10.832H5.83399C5.61297 10.832 5.40101 10.7442 5.24473 10.588C5.08845 10.4317 5.00065 10.2197 5.00065 9.9987C5.00065 9.77768 5.08845 9.56572 5.24473 9.40944C5.40101 9.25316 5.61297 9.16536 5.83399 9.16536H9.16732V5.83203C9.16732 5.61102 9.25512 5.39906 9.4114 5.24278C9.56768 5.0865 9.77964 4.9987 10.0007 4.9987C10.2217 4.9987 10.4336 5.0865 10.5899 5.24278C10.7462 5.39906 10.834 5.61102 10.834 5.83203V9.16536H14.1673C14.3883 9.16536 14.6003 9.25316 14.7566 9.40944C14.9129 9.56572 15.0007 9.77768 15.0007 9.9987C15.0007 10.2197 14.9129 10.4317 14.7566 10.588C14.6003 10.7442 14.3883 10.832 14.1673 10.832Z"
                            fill="#103D56"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_12863_11784">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>

                  {selectedAgent && (
                    <div className="play-inner-box add-new-bg-shadow">
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            selectedAgent?.profile_image
                              ? `${process.env.REACT_APP_IMAGE_PATH}${selectedAgent?.profile_image}`
                              : require("../Assets/images/user.png")
                          }
                        />
                        <div className="ms-3">
                          <h5>{selectedAgent?.full_name}</h5>
                          <h4>
                            Agent ID: <span>{selectedAgent?.unique_id}</span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="small-cmn-shadow justify-content-between mt-2 border-hide">
                    <h4>Transfer On</h4>
                    <input
                      type="date"
                      name="transfer_on"
                      className="form-control"
                      value={formData.transfer_on}
                      onChange={handleInputChange}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: "none" }}>
        <Button
          onClick={handleSubmit}
          className="incress-width"
          variant="primary"
          disabled={
            !selectedUser || !formData.joined_on || !formData.transfer_on
          }
        >
          Done
        </Button>
      </Modal.Footer>

      <AgentModalComp
        showAgent={showAgent}
        setShowAgent={setShowAgent}
        onAgentSelect={handleAgentSelect}
      />
    </Modal>
  );
};

export default TransferModalComp;
