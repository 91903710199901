import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";
import { constructQueryString } from "../../utils/helper";

export const getMessage = createAsyncThunk("getMessage", async (reqBody) => {
  const queryString = constructQueryString(reqBody);
  const response = await api.get(
    `/user/getMessage${queryString ? `?${queryString}` : ""}`
  );
  return response.data;
});

export const getRoom = createAsyncThunk("getRoom", async (reqBody) => {
  const queryString = constructQueryString(reqBody);
  const response = await api.get(
    `/user/getRoom${queryString ? `?${queryString}` : ""}`
  );
  return response.data;
});

export const createChatRoomAction = createAsyncThunk(
  "createChatRoom",
  async (reqBody) => {
    const response = await api.post(`/user/createChatRoom`, reqBody);
    return response.data;
  }
);

export const blockUnblockUser = createAsyncThunk(
  "blockUnblockUser",
  async (reqBody) => {
    const response = await api.post(`/user/blockUnblockUser`, reqBody);
    return response.data;
  }
);
