import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import LoginScreen from "./pages/LoginScreen";
import ForgotPassword from "./pages/ForgotPassword";
import OtpVerification from "./pages/OtpVerification";
import ResetPassword from "./pages/ResetPassword";
import CreateAccount from "./pages/CreateAccount";
import CreateProfile from "./pages/CreateProfile";
import CountrySelect from "./pages/CountrySelect";
import SocialLinks from "./pages/SocialLinks";
import YourCompany from "./pages/YourCompany";
import AgentId from "./pages/AgentId";
import HomeLogin from "./pages/HomeLogin";
import ComparePlayers from "./pages/ComparePlayers";
import GlobalPlayers from "./pages/GlobalPlayers";
import PlayerFilter from "./pages/PlayerFilter";
import Message from "./pages/Message";
import Calendor from "./pages/Calendor";
import CreateMeeting from "./pages/CreateMeeting";
import MyProfile from "./pages/MyProfile";
import MyPlayers from "./pages/MyPlayers";
import MyDirectors from "./pages/MyDirectors";
import MyCoach from "./pages/MyCoach";
import MyTeam from "./pages/MyTeam";
import ManageAssociateAgent from "./pages/ManageAssociateAgent";
import OtherAssociateAgent from "./pages/OtherAssociateAgent";
import EditProfile from "./pages/EditProfile";
import Settings from "./pages/Settings";
import Transfer from "./pages/Transfer";
import Support from "./pages/Support";
import AddPlayer from "./pages/AddPlayer";
import AddDirector from "./pages/AddDirector";
import AddCoaches from "./pages/AddCoaches";
import AddTeam from "./pages/AddTeam";
import AddAssociateAgent from "./pages/AddAssociateAgent";
import AddOtherAgent from "./pages/AddOtherAgent";
import PlayerSearch from "./pages/PlayerSearch";
import ProfileInfo from "./pages/ProfileInfo";
import AgentRelationship from "./pages/AgentRelationship";
import Contract from "./pages/Contract";
import Proposals from "./pages/Proposals";
import Health from "./pages/Health";
import OtherInformation from "./pages/OtherInformation";
import ContactInformation from "./pages/ContactInformation";
import Statistics from "./pages/Statistics";
import DirectorMainInformation from "./pages/DirectorMainInformation";
import DirectAgentRelationship from "./pages/DirectorAgentRelationship";
import DirectorContract from "./pages/DirectorContract";
import DirectorProposals from "./pages/DirectorProposals";
import DirectorContactInformation from "./pages/DirectorContactInformation";
import CoachesMainInformation from "./pages/CoachesMainInformation";
import CoachAgentRelationship from "./pages/CoachAgentRelationship";
import CoachesContract from "./pages/CoachesContract";
import CoachProposals from "./pages/CoachProposals";
import CoachContactInformation from "./pages/CoachContactInformation";
import AddTeamTwo from "./pages/AddTeamTwo";
import TeamAgentRelationship from "./pages/TeamAgentRelationship";
import AddTeamThree from "./pages/AddTeamThree";
import AddTeamFour from "./pages/AddTeamFour";
import AddTeamFive from "./pages/AddTeamFive";
import AddTeamSix from "./pages/AddTeamSix";
import AddTeamSeven from "./pages/AddTeamSeven";
import AddTeamEight from "./pages/AddTeamEight";
import AssociateAgentTwo from "./pages/AssociateAgentTwo";
import InternalSearchDirectorProfile from "./pages/InternalSearchDirectorProfile";
import InternalSearchCoachProfile from "./pages/InternalSearchCoachProfile";
import InternalSearchAssociateProfile from "./pages/InternalSearchAssociateProfile";
import GlobalSearch from "./pages/GlobalSearch";

import GlobalSearchPlayerProfile from "./pages/GlobalSearchPlayerProfile";
import PrivateRoute from "./Routes/PrivateRoutes";
import ProfileDetail from "./pages/ProfileDetail";
import GlobalSearchDirectorProfile from "./pages/GlobalSearchDirectorProfile";
import GlobalSearchCoachProfile from "./pages/GlobalSearchCoachProfile";
import GlobalSearchAgentProfile from "./pages/GlobalSearchAgentProfile";
import StaticPage from "./pages/StaticPage";
import Banner from "./pages/Banner";
import CoachMainInfo from "./pages/CoachMainInfo";
import AddAssociateAgentThree from "./pages/AddAssociateAgentThree";
import AddAssociateAgentFour from "./pages/AddAssociateAgentFour";
import AddAssociateAgentFive from "./pages/AddAssociateAgentFive";
import AddAssociateAgentSix from "./pages/AddAssociateAgentSix";
import AddAssociateAgentSeven from "./pages/AddAssociateAgentSeven";
import AddAssociateAgentEight from "./pages/AddAssociateAgentEight";
import AddAssociateAgentNine from "./pages/AddAssociateAgentNine";
import AddAssociateAgentTen from "./pages/AddAssociateAgentTen";
import AddAssociateAgentEleven from "./pages/AddAssociateAgentEleven";
import AddAssociateAgentTwelve from "./pages/AddAssociateAgentTwelve";
import AddAssociateAgentThirteen from "./pages/AddAssociateAgentThirteen";
import AddAssociateAgentFourteen from "./pages/AddAssociateAgentFouteen";
import QuickLinks from "./pages/QuickLinks";
import BeInvolved from "./pages/BeInvolved";
import AboutUs from "./pages/AboutUs";
import GlobalSearchResult from "./pages/GlobalSearchResult";
import PrivatePublicRoute from "./Routes/PrivatePublicRoute";
import NotFound from "./pages/PageNotFound";
import InternalSearchDirectorProfileDetail from "./pages/InternalSearchDirectorProfileDetail";
import MycoachDetail from "./pages/MycoachDetail";
import MyteamDetail from "./pages/MyteamDetail";
import InternalSearchResult from "./pages/InternalSearchResult";
import PlayerProfile from "./pages/PlayerProfile";
import InternalSearchPlayerProfile from "./pages/InternalSearchPlayerProfile";
// import InternalSearchTeamProfile from "./pages/InternalSearchTeamProfile";
import InternalSearchProfileTeam from "./pages/InternalSearchProfileTeam";
import AssociateAgentDetail from "./pages/AssociateAgentDetail";
import OtherAssociateAgentDetail from "./pages/OtherAssociateAgentDetail";
import { useDispatch, useSelector } from "react-redux";
import { internalSearchAction } from "./Redux/Action/internalSearch";
import SearchedComponent from "./pages/SearchedComponent";
import PrivateRouteForProfile from "./Routes/PrivateRouteForProfile";
import EditMeeting from "./pages/EditMeeting";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import AddAssociateAgentFifteen from "./pages/AddAssociateAgentFifteen";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<PrivatePublicRoute />}>
            {/* <Route path="/static" element={<StaticPage />}></Route> */}
            <Route path="/" element={<LoginScreen />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route
              path="/otp-verification/:id"
              element={<OtpVerification />}
            ></Route>
            <Route
              path="/reset-password/:id"
              element={<ResetPassword />}
            ></Route>
            <Route path="/create-account" element={<CreateAccount />}></Route>
            <Route path="/create-profile" element={<CreateProfile />}></Route>
            <Route path="/country-select" element={<CountrySelect />}></Route>
            <Route path="/your-company" element={<YourCompany />}></Route>
            <Route path="/social-links" element={<SocialLinks />}></Route>
            <Route path="/agent-id" element={<AgentId />}></Route>
            {/* <Route path="/banner" element={<Banner />}></Route> */}
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/home" element={<HomeLogin />}></Route>
            <Route path="/compare-players" element={<ComparePlayers />}></Route>
            <Route path="/global-players" element={<GlobalPlayers />}></Route>
            <Route path="/player-filter" element={<PlayerFilter />}></Route>
            <Route path="/message" element={<Message />}></Route>
            <Route path="/calendor" element={<Calendor />}></Route>
            <Route path="/create-meeting" element={<CreateMeeting />}></Route>
            <Route path="/edit-meeting/:id" element={<EditMeeting />}></Route>
            <Route path="/my-profile" element={<MyProfile />}></Route>
            <Route path="/my-players" element={<MyPlayers />}></Route>
            <Route path="/my-directors" element={<MyDirectors />}></Route>
            <Route path="/my-coach" element={<MyCoach />}></Route>
            <Route path="/my-team" element={<MyTeam />}></Route>
            <Route path="/my-team/:id" element={<MyteamDetail />}></Route>
            <Route path="/settings" element={<Settings />}></Route>
            <Route
              path="/manage-associate-agent"
              element={<ManageAssociateAgent />}
            ></Route>
            <Route
              path="/associate-agent/:id"
              element={<AssociateAgentDetail />}
            ></Route>
            <Route
              path="/other-associate-agent/:id"
              element={<OtherAssociateAgentDetail />}
            ></Route>
            <Route
              path="/other-associate-agent"
              element={<OtherAssociateAgent />}
            ></Route>
            <Route path="/edit-profile" element={<EditProfile />}></Route>
            <Route path="/transfer" element={<Transfer />}></Route>
            <Route path="/support" element={<Support />}></Route>
            <Route path="/add-player" element={<AddPlayer />}></Route>
            {/* <Route path="/add-player-stepper" element={<AddPlayerStepper />}></Route> */}
            <Route path="/add-director" element={<AddDirector />}></Route>
            <Route path="/add-coaches" element={<AddCoaches />}></Route>
            <Route path="/add-team" element={<AddTeam />}></Route>
            <Route
              path="/add-associate-agent"
              element={<AddAssociateAgent />}
            ></Route>
            <Route path="/add-other-agent" element={<AddOtherAgent />}></Route>
            <Route path="/player-search" element={<PlayerSearch />}></Route>
            {/* <Route path="/profile-info/:id" element={<ProfileInfo />}></Route> */}
            <Route path="/profile-info" element={<ProfileInfo />}></Route>
            <Route
              path="/agent-relationship/:id"
              element={<AgentRelationship />}
            ></Route>
            <Route path="/contract/:id" element={<Contract />}></Route>
            <Route path="/proposals/:id" element={<Proposals />}></Route>
            <Route path="/health/:id" element={<Health />}></Route>
            <Route
              path="/other-information/:id"
              element={<OtherInformation />}
            ></Route>
            <Route
              path="/contact-information/:id"
              element={<ContactInformation />}
            ></Route>
            <Route path="/statistics/:id" element={<Statistics />}></Route>
            <Route
              path="/director-info"
              element={<DirectorMainInformation />}
            ></Route>

            <Route path="/coach-info" element={<CoachMainInfo />}></Route>

            <Route
              path="/director-agent-relationship/:id"
              element={<DirectAgentRelationship />}
            ></Route>
            <Route
              path="/director-contract/:id"
              element={<DirectorContract />}
            ></Route>
            <Route
              path="/director-proposals/:id"
              element={<DirectorProposals />}
            ></Route>
            <Route
              path="/director-contact-information/:id"
              element={<DirectorContactInformation />}
            ></Route>
            {/* <Route
              path="/coaches-main-information"
              element={<CoachesMainInformation />}
            ></Route> */}
            <Route
              path="/coaches-agent-relationship/:id"
              element={<CoachAgentRelationship />}
            ></Route>
            <Route
              path="/coaches-contract/:id"
              element={<CoachesContract />}
            ></Route>
            <Route
              path="/coaches-proposals/:id"
              element={<CoachProposals />}
            ></Route>
            <Route
              path="/coaches-contact-information/:id"
              element={<CoachContactInformation />}
            ></Route>
            <Route path="/add-team-two/:id" element={<AddTeamTwo />}></Route>
            <Route
              path="/team-agent-relationship/:id"
              element={<TeamAgentRelationship />}
            ></Route>
            <Route
              path="/add-team-three/:id"
              element={<AddTeamThree />}
            ></Route>
            <Route path="/add-team-four/:id" element={<AddTeamFour />}></Route>
            <Route path="/add-team-five/:id" element={<AddTeamFive />}></Route>
            <Route path="/add-team-six/:id" element={<AddTeamSix />}></Route>
            <Route
              path="/add-team-seven/:id"
              element={<AddTeamSeven />}
            ></Route>
            <Route
              path="/add-team-eight/:id"
              element={<AddTeamEight />}
            ></Route>
            <Route
              path="/add-associate-two/:id"
              element={<AssociateAgentTwo />}
            ></Route>
            <Route
              path="/add-associate-three/:id"
              element={<AddAssociateAgentThree />}
            ></Route>
            <Route
              path="/add-associate-four/:id"
              element={<AddAssociateAgentFour />}
            ></Route>

            <Route
              path="/add-associate-five/:id"
              element={<AddAssociateAgentFive />}
            ></Route>

            <Route
              path="/add-associate-six/:id"
              element={<AddAssociateAgentSix />}
            ></Route>

            <Route
              path="/add-associate-seven/:id"
              element={<AddAssociateAgentSeven />}
            ></Route>

            <Route
              path="/add-associate-eight/:id"
              element={<AddAssociateAgentEight />}
            ></Route>

            <Route
              path="/add-associate-nine/:id"
              element={<AddAssociateAgentNine />}
            ></Route>

            <Route
              path="/add-associate-ten/:id"
              element={<AddAssociateAgentTen />}
            ></Route>

            <Route
              path="/add-associate-eleven/:id"
              element={<AddAssociateAgentEleven />}
            ></Route>

            <Route
              path="/add-associate-twelve/:id"
              element={<AddAssociateAgentTwelve />}
            ></Route>

            <Route
              path="/add-associate-thirtheen/:id"
              element={<AddAssociateAgentThirteen />}
            ></Route>

            <Route
              path="/add-associate-fourteen/:id"
              element={<AddAssociateAgentFourteen />}
            ></Route>

            <Route
              path="/add-team-fifteen/:id"
              element={<AddAssociateAgentFifteen />}
            ></Route>

            <Route
              path="/player-profile/:id"
              element={<PlayerProfile />}
            ></Route>

            <Route
              path="/my-directors/:id"
              element={<InternalSearchDirectorProfileDetail />}
            ></Route>
            <Route
              path="/internal-director-search/:id"
              element={<InternalSearchDirectorProfile />}
            ></Route>

            <Route
              path="/internal-search-result"
              element={<InternalSearchResult />}
            ></Route>

            <Route
              path="/search-list/:query"
              element={<SearchedComponent />}
            ></Route>

            <Route
              path="/internalplayer-search-result/:id"
              element={<InternalSearchPlayerProfile />}
            ></Route>

            <Route path="/my-coach/:id" element={<MycoachDetail />}></Route>
            <Route
              path="/internal-coach-search/:id"
              element={<InternalSearchCoachProfile />}
            ></Route>
            {/* <Route path="/internal-team-search/:id" element={<InternalSearchTeamProfile />}></Route> */}
            <Route
              path="/internal-team-searched/:id"
              element={<InternalSearchProfileTeam />}
            ></Route>
            <Route
              path="/internal-associate-search/:id"
              element={<InternalSearchAssociateProfile />}
            ></Route>
            <Route path="/global-search" element={<GlobalSearch />}></Route>
            <Route
              path="/global-search-result"
              element={<GlobalSearchResult />}
            ></Route>
            <Route
              path="/global-player-profile/:id"
              element={<GlobalSearchPlayerProfile />}
            ></Route>

            <Route
              path="/global-director-profile/:id"
              element={<GlobalSearchDirectorProfile />}
            ></Route>
            <Route
              path="/global-coach-profile/:id"
              element={<GlobalSearchCoachProfile />}
            ></Route>
            <Route
              path="/global-agent-profile/:id"
              element={<GlobalSearchAgentProfile />}
            ></Route>
            <Route path="/quick-links" element={<QuickLinks />}></Route>
            <Route path="/be-involved" element={<BeInvolved />}></Route>
            <Route path="/about-us" element={<AboutUs />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            ></Route>
          </Route>

          <Route element={<PrivateRouteForProfile />}>
            <Route path="/profile-detail" element={<ProfileDetail />}></Route>
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
