import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Row } from "react-bootstrap";
import {
  internalAgentSearchAction,
  internalCoachSearchAction,
  internalDirectorSearchAction,
  internalPlayerSearchAction,
  internalSearchAction,
  internalTeamSearchAction,
} from "../Redux/Action/internalSearch";
import { Encryptedid } from "../utils/Becrypt";
import {
  clearSearchInternalAgentData,
  clearSearchInternalCoachData,
  clearSearchInternalDirectorData,
  clearSearchInternalPlayerData,
  clearSearchInternalTeamData,
} from "../Redux/Reducers/internalSearchSlice";
import { capitalize } from "../utils/helper";

const InternalSearchResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const internalSearchNameAndIdData = useSelector(
    (e) => e.internalSeachAuthData.internalSearchlist
  );

  const internalSearchedData = useSelector(
    (e) => e.internalSeachAuthData.internalPayerSearchlist
  );

  const internalSearchedDataforDirector = useSelector(
    (e) => e.internalSeachAuthData.internalDirectorSearchlist
  );

  const internalSearchedDataforCoach = useSelector(
    (e) => e.internalSeachAuthData.internalCoachSearchlist
  );

  const internalSearchedDataforTeam = useSelector(
    (e) => e.internalSeachAuthData.internalTeamSearchlist
  );

  const internalSearchedDataforAgent = useSelector(
    (e) => e.internalSeachAuthData.internalAgentSearchlist
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const queryParams = {};
    let type = null;

    for (let [key, value] of searchParams.entries()) {
      if (key === "type") {
        type = value;
        continue;
      }

      if (key.endsWith("[]")) {
        const trimmedKey = key.slice(0, -2);
        if (!queryParams[trimmedKey]) {
          queryParams[trimmedKey] = [];
        }
        queryParams[trimmedKey].push(value);
      } else if (queryParams[key]) {
        queryParams[key] = Array.isArray(queryParams[key])
          ? [...queryParams[key], value]
          : [queryParams[key], value];
      } else {
        queryParams[key] = value;
      }
    }

    if (type === "player") {
      dispatch(clearSearchInternalDirectorData());
      dispatch(clearSearchInternalCoachData());
      dispatch(clearSearchInternalTeamData());
      dispatch(clearSearchInternalAgentData());

      dispatch(internalPlayerSearchAction(queryParams));
    } else if (type === "director") {
      dispatch(clearSearchInternalPlayerData());
      dispatch(clearSearchInternalCoachData());
      dispatch(clearSearchInternalTeamData());
      dispatch(clearSearchInternalAgentData());

      dispatch(internalDirectorSearchAction(queryParams));
    } else if (type === "coach") {
      dispatch(clearSearchInternalPlayerData());
      dispatch(clearSearchInternalDirectorData());
      dispatch(clearSearchInternalTeamData());
      dispatch(clearSearchInternalAgentData());

      dispatch(internalCoachSearchAction(queryParams));
    } else if (type === "team") {
      dispatch(clearSearchInternalPlayerData());
      dispatch(clearSearchInternalDirectorData());
      dispatch(clearSearchInternalCoachData());

      dispatch(internalTeamSearchAction(queryParams));
    } else {
      dispatch(clearSearchInternalPlayerData());
      dispatch(clearSearchInternalDirectorData());
      dispatch(clearSearchInternalCoachData());
      dispatch(clearSearchInternalTeamData());

      dispatch(internalAgentSearchAction(queryParams));
    }
  }, [dispatch, location.search]);

  console.log("internalSearchedDataforAgent", internalSearchedDataforAgent);

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2">
              <div className="play-card-sec">
                <h2>Internal Search Result</h2>

                {internalSearchedData?.length > 0 &&
                !internalSearchedDataforDirector?.length &&
                !internalSearchedDataforCoach?.length &&
                !internalSearchedDataforTeam?.length &&
                !internalSearchedDataforAgent?.length ? (
                  <div className="play-scroll-box mt-3">
                    <Row>
                      {internalSearchedData?.map((elem, i) => (
                        <Col lg={4} key={i}>
                          <div
                            className="play-inner-box global-search-tabs"
                            // onClick={() =>
                            //   navigate(
                            //     `/internalplayer-search-result/${Encryptedid(
                            //       elem?._id
                            //     )}`
                            //   )
                            // }
                            onClick={() =>
                              navigate(
                                `/player-profile/${Encryptedid(elem?._id)}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex">
                              <img
                                src={
                                  elem?.profile_image
                                    ? `${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`
                                    : elem?.gender === 0
                                    ? require("../Assets/images/player_Ai.png")
                                    : require("../Assets/images/player-female.png")
                                }
                                alt={`${elem?.full_name} profile`}
                              />
                              <div className="ms-3">
                                <h4>{elem?.full_name}</h4>
                                <p>
                                  Player ID:{" "}
                                  <span>{elem?.unique_id.toUpperCase()}</span>
                                </p>
                              </div>
                            </div>

                            {/* <div className="badge-img">
                              <img
                                src={require("../Assets/images/tiger.png")}
                                alt="badge"
                              />
                            </div> */}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : internalSearchedDataforDirector?.length > 0 ? (
                  <div className="play-scroll-box mt-3">
                    <Row>
                      {internalSearchedDataforDirector?.map((elem, i) => (
                        <Col lg={4} key={i}>
                          <div
                            className="play-inner-box global-search-tabs"
                            // onClick={() =>
                            //   navigate(
                            //     `/internal-director-search/${Encryptedid(
                            //       elem?._id
                            //     )}`
                            //   )
                            // }
                            onClick={() =>
                              navigate(
                                `/my-directors/${Encryptedid(elem?._id)}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex">
                              {elem?.gender == 0 ? (
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                      : require("../Assets/images/My Directors male.png")
                                  }
                                  alt="Profile"
                                />
                              ) : (
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                      : require("../Assets/images/director-female-new.png")
                                  }
                                  alt="Profile"
                                />
                              )}

                              <div className="ms-3">
                                <h4>{elem?.full_name}</h4>
                                <p>
                                  Director ID:{" "}
                                  <span>{elem?.unique_id.toUpperCase()}</span>
                                </p>
                              </div>
                            </div>

                            {/* <div className="badge-img">
                              <img
                                src={require("../Assets/images/tiger.png")}
                                alt="badge"
                              />
                            </div> */}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : internalSearchedDataforCoach?.length > 0 ? (
                  <div className="play-scroll-box mt-3">
                    <Row>
                      {internalSearchedDataforCoach?.map((elem, i) => (
                        <Col lg={4} key={i}>
                          <div
                            className="play-inner-box global-search-tabs"
                            // onClick={() =>
                            //   navigate(
                            //     `/internal-coach-search/${Encryptedid(
                            //       elem?._id
                            //     )}`
                            //   )
                            // }
                            onClick={() =>
                              navigate(`/my-coach/${Encryptedid(elem?._id)}`)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex">
                              {elem?.gender == 0 ? (
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                      : require("../Assets/images/coach-male.png")
                                  }
                                  alt="Profile"
                                />
                              ) : (
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                      : require("../Assets/images/coach-female.png")
                                  }
                                  alt="Profile"
                                />
                              )}
                              <div className="ms-3">
                                <h4>{capitalize(elem?.full_name)}</h4>
                                <p>
                                  Coach ID:{" "}
                                  <span>{elem?.unique_id?.toUpperCase()}</span>
                                </p>
                              </div>
                            </div>

                            {/* <div className="badge-img">
                              <img
                                src={require("../Assets/images/tiger.png")}
                                alt="badge"
                              />
                            </div> */}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : internalSearchedDataforTeam?.length > 0 ? (
                  <div className="play-scroll-box mt-3">
                    <Row>
                      {internalSearchedDataforTeam?.map((elem, i) => (
                        <Col lg={4} key={i}>
                          <div
                            className="play-inner-box global-search-tabs"
                            // onClick={() =>
                            //   navigate(
                            //     `/internal-team-searched/${Encryptedid(
                            //       elem?._id
                            //     )}`
                            //   )
                            // }
                            onClick={() =>
                              navigate(`/my-team/${Encryptedid(elem?._id)}`)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex">
                              <img
                                src={
                                  elem?.profile_image
                                    ? `${process.env.REACT_APP_IMAGE_PATH}${elem?.profile_image}`
                                    : require("../Assets/images/biglogo.png")
                                }
                                alt={`${elem?.team_name} profile`}
                              />
                              <div className="ms-3">
                                <h4>{capitalize(elem?.team_name)}</h4>
                                <p>
                                  Team ID:{" "}
                                  <span>{elem?.unique_id.toUpperCase()}</span>
                                </p>
                              </div>
                            </div>

                            {/* <div className="badge-img">
                              <img
                                src={require("../Assets/images/tiger.png")}
                                alt="badge"
                              />
                            </div> */}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : internalSearchedDataforAgent?.length > 0 ? (
                  <div className="play-scroll-box mt-3">
                    <Row>
                      {internalSearchedDataforAgent?.map((elem, i) => (
                        <Col lg={4} key={i}>
                          <div
                            className="play-inner-box global-search-tabs"
                            // onClick={() =>
                            //   navigate(
                            //     `/internal-associate-search/${Encryptedid(
                            //       elem?._id
                            //     )}`
                            //   )
                            // }
                            onClick={() =>
                              navigate(
                                `/associate-agent/${Encryptedid(elem?._id)}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex">
                              <img
                                src={
                                  elem?.profile_image
                                    ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                    : elem?.gender == 1
                                    ? require("../Assets/images/My Associate Agent female.png")
                                    : require("../Assets/images/My Associate Agent male.png")
                                }
                                alt="Profile"
                              />
                              <div className="ms-3">
                                <h4>{capitalize(elem?.agent_name)}</h4>
                                <p>
                                  Agent ID:{" "}
                                  <span>{elem?.unique_id.toUpperCase()}</span>
                                </p>
                              </div>
                            </div>

                            {/* <div className="badge-img">
                              <img
                                src={require("../Assets/images/tiger.png")}
                                alt="badge"
                              />
                            </div> */}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : (
                  <div className="no-data-found">No Data</div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default InternalSearchResult;
