export const positions = {
  0: "GK",

  1: "Right Back",

  2: "Left Back",
  3: "Left Central Defender",

  4: "Right Central Defender",

  5: "Midfield Defender",

  6: "Box to Box",

  7: "No.10",

  8: "Right Winger",

  9: "Left Winger",
  10: "Striker",

  11: "2nd Striker",
};

export const gender = {
  0: "Male",

  1: "Female",
};

export const teamLeague = {
  0: "Premier/Top League",

  1: "1st Division",

  2: "2nd Division",

  3: "3rd Division",

  4: "4th Division",
};

export const playerFoot = {
  0: "Right",

  1: "Left",

  2: "Both",
};

export const playerNationality = {
  0: "EU",

  1: "Non EU",
  2: "Any",
};

export const NationalTeamPlayer = {
  0: "No",

  1: "Yes",

  2: "Former National Player",

  3: "Youth National Player",

  4: "Former Youth National Player",
};

export const role = {
  0: "Players",

  1: "Technical/ Sport Directors",
  2: "Coaches/ Managers",
  3: "Assistant Coaches",
  4: "Trainers",

  5: "Football Analysts/ Scouters",

  6: "Nutritionist",

  7: "Doctors",

  8: "Lawyers",

  9: "Accountants",

  10: "Others",
};

export const educationLevel = {
  0: "Primary School",

  1: "High School",

  2: "Collage",

  3: "University",
};

export const releaseClouse = {
  0: "No",

  1: "Yes",
};

export const martialStatus = {
  0: "Single",

  1: "Engaged",

  2: "Married",
};

export const Children = {
  0: "none",
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5+",
};

export const AvailabilityStatus = {
  0: "Free",

  1: "Not free",

  2: "On loan",

  3: "Free in the coming transfer period",

  4: "Open for discussions",
};

export const playerTrophy = {
  0: "Youth Trophies",

  1: "Local Championships",

  2: "Local Cups",

  3: "European Champions League",

  4: "European Cups",

  5: "National Team Cups",
  6: "Not Yet",
};

export const previousStatus = {
  0: "Technical/Sport Director",
  1: "Coach",
  2: "Scouter",
  3: "Football Analyst",
  4: "Football Player",
  5: "Football Specialist",
};

export const coachingLicense = {
  0: "None",
  1: "UEFA PRO",
  2: "UEFA A",
  3: "UEFA B",
  4: "UEFA C",
  5: "Goalkeeper A",
  6: "Goalkeeper B",
  7: "Elite Youth A",
  8: "Elite Youth B",
};

export const filterKeyPlayersHome = {
  1: { label: "Age", value: "age" },
  2: { label: "Foot", value: "foot" },
  3: { label: "Date of Birth", value: "dob" },
  4: { label: "Team League", value: "team_league" },
  5: { label: "Contract End Date", value: "contract_end_date" },
  6: { label: "Education Level", value: "education_level" },
  7: {
    label: "Annual Asking Salary",
    value: "new_contract_team_asking_amount",
  },
  8: { label: "Agent", value: "associate_agent_name" },
};

export const filterKeyPlayers = {
  0: { label: "Market Value", value: "current_contract" },
  1: { label: "Availability Status", value: "availability_status" },
  2: { label: "Agent", value: "associate_agent_name" },
  3: { label: "Age", value: "age" },
  4: { label: "Date of Birth", value: "dob" },
  5: { label: "Foot", value: "foot" },
  6: { label: "Height", value: "height" },
  7: { label: "Language(s)", value: "languages" },
  8: { label: "Nationality(s)", value: "nationality" },
  9: { label: "Nationality ID", value: "nationality_id" },
  10: { label: "Current Team Name", value: "team_name" },
  11: { label: "Current Team Country", value: "team_country" },
  12: { label: "Current Team League", value: "team_league" },
  13: { label: "Previous Team Name", value: "team_league" },
  14: { label: "Previous Team Country", value: "team_league" },
  15: { label: "Previous Team League", value: "team_league" },
  16: { label: "Playing Position(s)", value: "positions" },
  17: {
    label: "Annual Asking Salary for New Contract",
    value: "new_contract_team_asking_amount",
  },
  18: {
    label: "Team's Asking Amount for transfer",
    value: "new_contract_team_asking_amount",
  },
  19: {
    label: "Contract Duration's Requirement",
    value: "new_contract_duration",
  },
  20: { label: "Bonuses", value: "Bonuses" },
  21: { label: "Joined Date", value: "joined_date" },
  22: { label: "Contract Start Date", value: "contract_start_date" },
  23: { label: "Contract End Date", value: "contract_end_date" },
  24: { label: "Release Clause", value: "release_clouse" },
  25: { label: "Buyout Clause", value: "buyout_clouse" },

  // 26: { label: "Place of Birth", value: "place_of_birth" },
  // 27: { label: "National Team", value: "national_team" },
  // 28: { label: "Marital Status", value: "martial_status" },
  // 29: { label: "Number of Children", value: "children" },
  // 30: { label: "Education Level", value: "education_level" },
};

export const GlobalfilterKeyPlayers = {
  0: { label: "Gender", value: "gender" },
  1: { label: "Choose Date of Birth", value: "dob" },
  2: { label: "Nationality ID", value: "nationality_id" },
  3: { label: "Team League", value: "team_league" },
  4: { label: "National Team Player", value: "national_team_player" },
  5: { label: "Playing Position", value: "positions" },
  6: { label: "Foot", value: "foot" },
  7: { label: "Height", value: "height" },
  8: { label: "Language", value: "languages" },
  9: { label: "Carrier Trophies", value: "carrier_trophies" },
  10: { label: "Availability Status", value: "availability_status" },
  11: {
    label: "Annual Asking Salary For New Contract",
    value: "current_contract",
  },
  12: { label: "Team's Asking Amount For Transfer", value: "asking_amount" },
};

export const GlobalfilterKeyDirector = {
  0: { label: "Gender", value: "gender" },
  1: { label: "Choose Date of Birth", value: "dob" },
  2: { label: "Previous Status", value: "previous_status" },
  3: { label: "Coaching Licenses", value: "coaching_licenses" },
  4: { label: "Previous Team League", value: "interested_team_league" },
  5: { label: "Carrier Trophies as Director", value: "trophy" },
  6: { label: "Language", value: "languages" },
  7: { label: "Availability Status", value: "availability_status" },
  8: {
    label: "Annual Asking Salary For New Contract",
    value: "current_contract",
  },
};

export const GlobalfilterKeyAgent = {
  0: { label: "Enter Name", value: "name" },
  1: { label: "Country", value: "country" },
  2: { label: "City", value: "city" },
};

export const GlobalfilterKeyCoach = {
  0: { label: "Gender", value: "gender" },
  1: { label: "Choose Date of Birth", value: "dob" },
  2: { label: "Previous Status", value: "previous_status" },
  3: { label: "Coaching Licenses", value: "coaching_licenses" },
  4: { label: "Previous Team League", value: "interested_team_league" },
  5: { label: "Carrier Trophies as Coach", value: "trophy" },
  6: { label: "Language", value: "languages" },
  7: { label: "Availability Status", value: "availability_status" },
  8: {
    label: "Annual Asking Salary For New Contract",
    value: "current_contract",
  },
};

export const filterKeyAppearance = {
  1: { label: "Total Appearances", value: "total_appearence" },
  2: { label: "Starting Eleven", value: "starting_eleven" },
  3: { label: "Enter as substitution", value: "enter_as_substitution" },
  4: { label: "Out as substitution", value: "out_as_substitution" },
  5: { label: "Clean Sheets", value: "clean_sheets" },
  6: { label: "Assists", value: "assists" },
  7: { label: "Red Card", value: "red_card" },
  8: { label: "Yellow Card", value: "yellow_card" },

  // 1: { label: "Goals", value: "goals" },
};

export const filterKeyAchievement = {
  1: { label: "Carrier Trophies", value: "carrier_trophies" },
  2: { label: "Goals", value: "goals" },
  3: { label: "Goals Conceded", value: "goals_cancelled" },
};

// player search filter

export const playersMainInformation = {
  0: { label: "Name of Player", value: "name" },
  1: { label: "Gender", value: "gender" },
  2: { label: "Date of Birth", value: "dob" },
  3: { label: "Nationality", value: "nationality" },
  4: { label: "Nationality ID", value: "nationality_id" },
  // 5: { label: "Place of Birth", value: "place_of_birth" },
  // 6: { label: "Team League", value: "team_league" },
  // 7: { label: "National Team Player", value: "national_team_player" },
  // 8: { label: "National Team", value: "national_team" },
  9: { label: "Foot", value: "foot" },
  10: { label: "Height", value: "height" },
  11: { label: "Languages", value: "languages" },
  12: { label: "Carrier Trophies", value: "carrier_trophies" },
  13: { label: "Availability Status", value: "availability_status" },
};

export const professionalRelations = {
  0: { label: "My Player", value: "my_player" },
  1: { label: "Associate Agents Player", value: "associate_agent_player" },
  2: { label: "Who Brought Player to Team", value: "who_brought_player" },
  3: { label: "Enter Agents Name", value: "associate_agent_name" },
};

export const whoBroughtPlayer = [
  { label: "Me", value: "0" },
  { label: "The Asociate-Agent", value: "1" },
];

export const currentContractDetails = {
  0: { label: "Current Annual Salary", value: "current_contract" },
  1: { label: "Bonus", value: "bonus" },
  2: { label: "Date of Joining", value: "joined_date" },
  3: { label: "Contract Start Date", value: "contract_start_date" },
  4: { label: "Contract End Date", value: "contract_end_date" },
  5: { label: "Release Clause", value: "release_clouse" },
  6: { label: "Buyout Clause", value: "buyout_clouse" },
};

export const newContractDetails = {
  0: {
    label: "Annual Asking Salary for New Contract",
    value: "new_contract_annual_salary",
  },
  1: {
    label: "Team’s Asking Amount for Transfer",
    value: "new_contract_team_asking_amount",
  },
  2: { label: "Contract Duration Requirement", value: "new_contract_duration" },
};

export const playerProposalDetails = {
  0: {
    label: "Teams that the player has been proposed",
    value: "is_player_proposed",
  },
  1: { label: "Team's Name", value: "proposed_team_id" },
  2: { label: "Team's Country", value: "proposed_team_country" },
  3: { label: "Team's League", value: "team_league" },
  // 4: {
  //   label: "Who Proposed Player to Team",
  //   value: "is_agent_proposed_player_to_team",
  // },
  5: { label: "Agent Name", value: "proposed_associate_agent_id" },
};

export const teamsInterestedInPlayer = {
  0: {
    label: "Any Team Interested in the Player",
    value: "is_team_interested_to_player",
  },
  1: { label: "Team's Name", value: "interested_team_id" },
  2: { label: "Team's Country", value: "interested_team_country" },
  3: { label: "Team's League", value: "interested_team_league" },
};

export const playersStatistics = {
  0: { label: "Total Appearance", value: "total_appearence" },
  // 1: { label: "Out of Appearance", value: "total_appearence_out_of" },
  2: { label: "Starting Eleven", value: "starting_eleven" },
  // 3: { label: "Out of Eleven", value: "out_of_eleven" },
  4: { label: "Enter as Substitution", value: "enter_as_substitution" },
  5: { label: "Out as Substitution", value: "out_as_substitution" },
  6: { label: "Goals", value: "goals" },
  7: { label: "Assists", value: "assists" },
  8: { label: "Red Card", value: "red_card" },
  9: { label: "Yellow Card", value: "yellow_card" },
  10: { label: "Goals Conceded", value: "goals_conceded" },
  11: { label: "Clean Sheets", value: "clean_sheets" },
};

export const healthStatus = {
  0: { label: "Current Injuries", value: "is_current_injuries" },
  1: { label: "Past Injuries", value: "past_injuries" },
  2: { label: "Health Problem", value: "health_problems" },
  3: { label: "Date of Coming Back", value: "date_of_coming_back" },
  4: { label: "Current Injuries Issues", value: "current_injuries_issues" },
};

export const otherInformation = {
  0: { label: "Marital Status", value: "martial_status" },
  1: { label: "Number of Children", value: "children" },
  2: {
    label: "Players Hobbies and Interests",
    value: "player_hobbies",
  },
  3: {
    label: "Education Level",
    value: "education_level",
  },
};

export const playersTeam = {
  0: { label: "Team Name", value: "team_id" },
  1: { label: "Team Country", value: "team_country" },
  2: {
    label: "Team League",
    value: "team_league",
  },
  3: { label: "National Team Player", value: "national_team_player" },
  4: { label: "National Team", value: "national_team" },
};

// director filter search

export const DirectorsTeam = {
  0: { label: "Team Name", value: "team_id" },
  1: { label: "Team Country", value: "team_country" },
  2: {
    label: "Team League",
    value: "team_league",
  }
};

export const CoachsTeam = {
  0: { label: "Team Name", value: "team_id" },
  1: { label: "Team Country", value: "team_country" },
  2: {
    label: "Team League",
    value: "team_league",
  }
};



export const directorsMainInformation = {
  0: { label: "Enter name here", value: "name" },
  1: { label: "Gender", value: "gender" },
  2: { label: "Date of Birth", value: "dob" },
  3: { label: "Nationality", value: "nationality" },
  // 4: { label: "Nationality ID", value: "nationality_id" },
  // 5: { label: "Place of Birth", value: "place_of_birth" },
  6: { label: "Previous Status", value: "previous_status" },
  7: { label: "Coaching License", value: "coaching_licenses" },
  // 8: { label: "Team League", value: "team_league" },
  // 9: { label: "Team Name", value: "team_name" },
  // 10: { label: "Team Country", value: "country" },
  // 11: { label: "National Team", value: "national_team" },
  12: { label: "Languages", value: "languages" },
  // 13: { label: "Career Trophies", value: "carrier_trophies" },
  // 14: { label: "Availability Status", value: "availability_status" },
};

export const DirectorProfessionalRelations = {
  0: { label: "My Director", value: "my_director" },
  1: { label: "Associate Agents Director", value: "associate_agent_director" },
  // 2: { label: "Who Brought Director to Team", value: "who_brought_director" },
  // 3: { label: "Enter Agents Name", value: "agent_name" },
  4: { label: "Name of Associate Agent", value: "associate_agent_id" },
};

export const directorCurrentContractDetails = {
  0: { label: "Current Annual Salary", value: "current_annual_salary" },
  1: { label: "Bonus", value: "Bonus" },
  2: { label: "Contract Start Date", value: "contract_start_date" },
  3: { label: "Contract End Date", value: "contract_end_date" },
  4: { label: "Release Clause", value: "release_clouse" },
  5: { label: "Joined Date", value: "joined_date" },
  6: { label: "Buyout Clause", value: "buyout_clouse" },
};

export const directorNewContractDetails = {
  0: {
    label: "Annual Asking Salary for New Contract",
    value: "new_contract_annual_salary",
  },
  1: { label: "Team’s Asking Amount for Transfer", value: "current_contract" },
  2: { label: "Contract Duration Requirements", value: "new_contract_duration" },
};

export const directorIsProposed = {
  0: { label: "Director has been proposed ?", value: "is_director_proposed" },
  1: { label: "Team's Name", value: "proposed_team_id" },
  2: { label: "Team's Country", value: "proposed_team_country" },
  3: { label: "Team's League", value: "proposed_team_league" },
  4: { label: "Name of Associate Agent", value: "proposed_associate_agent_id" },
};

export const teamsInterestedInDirector = {
  0: {
    label: "Any Team Interested in the Player",
    value: "is_team_interested_to_director",
  },
  1: { label: "Team Name", value: "interested_team_id" },
  2: { label: "Team Country", value: "interested_team_country" },
  3: { label: "Team League", value: "interested_team_league" },
};

// coach filter search

export const coachMainInformation = {
  0: { label: "Enter name here", value: "coach_name" },
  1: { label: "Gender", value: "gender" },
  2: { label: "Date of Birth", value: "dob" },
  3: { label: "Nationality", value: "nationality" },
  // 4: { label: "Nationality ID", value: "nationality_id" },
  // 5: { label: "Place of Birth", value: "place_of_birth" },
  6: { label: "Previous Status", value: "previous_status" },
  7: { label: "Coaching License", value: "coaching_licenses" },
  // 8: { label: "Team League", value: "team_league" },
  // 9: { label: "Team Name", value: "team_name" },
  // 10: { label: "Team Country", value: "country" },
  // 11: { label: "National Team", value: "national_team" },
  12: { label: "Languages", value: "languages" },
  // 13: { label: "Carrier Trophies", value: "carrier_trophies" },
  // 14: { label: "Availability Status", value: "availability_status" },
};

export const coachProfessionalRelations = {
  0: { label: "My Coach", value: "my_coach" },
  1: { label: "Associate Agents Coach", value: "associate_agent_coach" },
  // 2: { label: "Who Brought Coach to Team", value: "who_brought_coach" },
  3: { label: "Name of Associate Agent", value: "agent_name" },
};

export const coachCurrentContractDetails = {
  0: { label: "Current Annual Salary", value: "current_annual_salary" },
  1: { label: "Bonus", value: "Bonus" },
  2: { label: "Contract Start Date", value: "contract_start_date" },
  3: { label: "Contract End Date", value: "contract_end_date" },
  4: { label: "Release Clause", value: "release_clouse" },
  5: { label: "Joined Date", value: "joined_date" },
  6: { label: "Buyout Clause", value: "buyout_clouse" },
};

export const coachNewContractDetails = {
  0: { label: "Annual Asking Salary for New Contract", value: "new_contract_annual_salary" },
  1: { label: "Team’s Asking Amount for Transfer", value: "current_contract" },
  2: { label: "Contract Duration Requirements", value: "new_contract_duration" },
};

export const coachIsProposed = {
  0: { label: "Coach has been proposed ?", value: "is_coach_proposed" },
  1: { label: "Team's Name", value: "proposed_team_id" },
  2: { label: "Team's Country", value: "proposed_team_country" },
  3: { label: "Team's League", value: "proposed_team_league" },
  4: { label: "Name of Associate Agent", value: "proposed_associate_agent_id" },
};

export const teamsInterestedInCoach = {
  0: {
    label: "Any Team Interested in the Coach",
    value: "is_team_interested",
  },
  1: { label: "Team Name", value: "interested_team_id" },
  2: { label: "Team Country", value: "interested_team_country" },
  3: { label: "Team League", value: "interested_team_league" },
};

// team filter search

export const teamMainInformation = {
  0: { label: "Enter name here", value: "team_name" },
  1: { label: "Team's Country", value: "country_name" },
  2: { label: "Team's city", value: "city_name" },
  3: { label: "Team League", value: "team_league" },
  4: { label: "Annual Budget", value: "annual_budget" },
  5: { label: "Owner Name", value: "owner_name" },
  6: { label: "President Name", value: "president_name" },
};

export const myPlayerInTeam = {
  // 0: { label: "Do I have player in this team ?", value: "is_my_player" },
  1: { label: "Enter player's name", value: "my_player_name" },
  2: {
    label: "Who Brought player to current team",
    value: "who_brought_player",
  },
  3: { label: "Enter agent's name", value: "player_agent_name" },
  4: { label: "Enter fees Charged", value: "player_agent_fee" },
  // 5: { label: "", value: "empty" },
  6: { label: "Enter fees Received", value: "player_agent_fee_received" },
  7: { label: "Received on", value: "player_agent_fee_received_on" },
  // 8: { label: "", value: "empty1" },
  9: { label: "Enter fees Pending", value: "player_agent_fee_pending" },
  10: { label: "Will Received on", value: "player_agent_fee_will_received_on" },
  11: { label: "", value: "empty2" },
  // 12: { label: "Person's name", value: "player_team_person_name" },
  // 13: { label: "Contact info", value: "player_team_conatact_info" },
};

export const playerWantedByTeam = {
  0: {
    label: "Do I have player in this team ?",
    value: "is_looking_for_player",
  },
  1: { label: "", value: "empty" },
  2: { label: "", value: "empty1" },

  3: { label: "Age", value: "player_age" },
  4: {
    label: "Gender",
    value: "player_gender",
  },
  5: { label: "Nationality", value: "player_nationality" },
  6: { label: "Nationality ID", value: "player_nationality_id" },
  7: { label: "National Team Player", value: "player_national_team_player" },
  8: { label: "Foot", value: "player_foot" },
  9: { label: "Height", value: "player_height" },
  10: { label: "Language", value: "player_languages" },
  11: { label: "", value: "empty2" },
  12: { label: "Position", value: "player_playing_positions" },
  13: { label: "Status", value: "player_availability_status" },
};

export const playerProposedtoteam = {
  // 0: {
  //   label: "Do I have Proposed Player in this team?",
  //   value: "is_player_proposed",
  // },
  1: { label: "Player's Name", value: "player_full_name" },
  2: {
    label: "Who Proposed player to Current Team",
    value: "who_proposed_player",
  },
  3: { label: "Associate agents name", value: "player_associate_agent_name" },
};

export const playerProposedtoMe = {
  // 0: {
  //   label: "Do I have Proposed Player in this team?",
  //   value: "is_player_proposed",
  // },
  1: { label: "Player's Name", value: "team_proposed_full_name" },
  2: {
    label: "Who Proposed player to this Team",
    value: "who_bought_team_proposed_player",
  },
  3: {
    label: "Associate's agent name",
    value: "team_proposed_player_associate_agent_id",
  },
};

export const directorInThisTeam = {
  0: {
    label: "Do I have Director in this team ?",
    value: "have_director_in_team",
  },
  1: { label: "Enter director's name", value: "director_full_name" },
  2: {
    label: "Who Brought director to current team",
    value: "who_brought_director",
  },
  3: { label: "Enter agent's name", value: "director_associate_agent_name" },
  4: { label: "Enter fees Charged", value: "director_agent_fee" },
  5: { label: "", value: "empty" },
  6: { label: "Enter fees Received", value: "director_agent_fee_received" },
  7: { label: "Received on", value: "director_agent_fee_received_on" },
  8: { label: "", value: "empty1" },
  9: { label: "Enter fees Pending", value: "director_agent_fee_pending" },
  10: { label: "Will Received on", value: "director_agent_fee_will_receive" },
  11: { label: "", value: "empty2" },
  12: { label: "Person's name", value: "director_team_conatct_person_name" },
  13: { label: "Contact info", value: "director_team_contact_info" },
};

export const teamsProposedDirector = {
  0: {
    label: "Do I have proposed Director in this team?",
    value: "is_director_proposed",
  },
  1: { label: "Enter full Name", value: "proposed_director_full_name" },
  2: {
    label: "Who Proposed director to Current Team",
    value: "who_proposed_director",
  },
  3: { label: "Enter full Name", value: "proposed_director_agent_name" },
};

export const coachinThisTeam = {
  0: {
    label: "Do I have coach in this team?",
    value: "have_coach_in_team",
  },
  1: { label: "Enter full Name", value: "coach_full_name" },
  2: {
    label: "Who Proposed coach to Current Team",
    value: "who_brought_coach",
  },
  3: { label: "Enter full Name", value: "coach_agent_name" },
};

export const coachProposedtoThisTeam = {
  0: {
    label: "Do I have proposed coach in this team?",
    value: "is_coach_proposed",
  },
  1: { label: "Enter full Name", value: "proposed_coach_full_name" },
  2: {
    label: "Who Proposed coach to Current Team",
    value: "who_proposed_coach",
  },
  3: { label: "Enter full Name", value: "proposed_coach_agent_name" },
};

// agent filter
export const agentMainInformation = {
  0: { label: "Enter name here", value: "name" },
  1: { label: "Country", value: "country" },
  2: { label: "City", value: "city" },
};

export const playerRelation = {
  0: {
    label: "Do I have Player in team from this Agent?",
    value: "player_from_agent",
  },
  1: { label: "Enter full name", value: "agent_players_name" },

  2: { label: "Who Brought player to current team", value: "player_to_agent" },
  3: { label: "Enter full name", value: "my_players_name" },

  4: { label: "Enter fees Charged", value: "proposed_player_to_agent" },
  5: { label: "Enter full name", value: "proposed_players_name" },

  6: { label: "Enter fees Received", value: "proposed_player_from_agent" },
  7: { label: "Enter full name", value: "my_proposed_players_name" },
};

export const directorRelation = {
  0: {
    label: "Do I have Director in team from this Agent?",
    value: "director_from_agent",
  },
  1: { label: "Enter full name", value: "agent_directors_name" },

  2: {
    label: "Who Brought director to current team",
    value: "director_to_agent",
  },
  3: { label: "Enter full name", value: "my_directors_name" },

  4: { label: "Enter fees Charged", value: "proposed_director_to_agent" },
  5: { label: "Enter full name", value: "proposed_directors_name" },

  6: { label: "Enter fees Received", value: "proposed_director_from_agent" },
  7: { label: "Enter full name", value: "my_proposed_directors_name" },
};

export const coachRelation = {
  0: {
    label: "Do I have Coach in team from this Agent?",
    value: "coach_from_agent",
  },
  1: { label: "Enter full name", value: "agent_coaches_name" },

  2: { label: "Who Brought coach to current team", value: "coach_to_agent" },
  3: { label: "Enter full name", value: "my_coaches_name" },

  4: { label: "Enter fees Charged", value: "proposed_coach_to_agent" },
  5: { label: "Enter full name", value: "proposed_coaches_name" },

  6: { label: "Enter fees Received", value: "proposed_coach_from_agent" },
  7: { label: "Enter full name", value: "my_proposed_coaches_name" },
};

export const countryCode = [
  {
    name: "Afghanistan",
    flag: "🇦🇫",
    code: "AF",
    dial_code: "+93",
  },
  {
    name: "Åland Islands",
    flag: "🇦🇽",
    code: "AX",
    dial_code: "+358",
  },
  {
    name: "Albania",
    flag: "🇦🇱",
    code: "AL",
    dial_code: "+355",
  },
  {
    name: "Algeria",
    flag: "🇩🇿",
    code: "DZ",
    dial_code: "+213",
  },
  {
    name: "American Samoa",
    flag: "🇦🇸",
    code: "AS",
    dial_code: "+1684",
  },
  {
    name: "Andorra",
    flag: "🇦🇩",
    code: "AD",
    dial_code: "+376",
  },
  {
    name: "Angola",
    flag: "🇦🇴",
    code: "AO",
    dial_code: "+244",
  },
  {
    name: "Anguilla",
    flag: "🇦🇮",
    code: "AI",
    dial_code: "+1264",
  },
  {
    name: "Antarctica",
    flag: "🇦🇶",
    code: "AQ",
    dial_code: "+672",
  },
  {
    name: "Antigua and Barbuda",
    flag: "🇦🇬",
    code: "AG",
    dial_code: "+1268",
  },
  {
    name: "Argentina",
    flag: "🇦🇷",
    code: "AR",
    dial_code: "+54",
  },
  {
    name: "Armenia",
    flag: "🇦🇲",
    code: "AM",
    dial_code: "+374",
  },
  {
    name: "Aruba",
    flag: "🇦🇼",
    code: "AW",
    dial_code: "+297",
  },
  {
    name: "Australia",
    flag: "🇦🇺",
    code: "AU",
    dial_code: "+61",
  },
  {
    name: "Austria",
    flag: "🇦🇹",
    code: "AT",
    dial_code: "+43",
  },
  {
    name: "Azerbaijan",
    flag: "🇦🇿",
    code: "AZ",
    dial_code: "+994",
  },
  {
    name: "Bahamas",
    flag: "🇧🇸",
    code: "BS",
    dial_code: "+1242",
  },
  {
    name: "Bahrain",
    flag: "🇧🇭",
    code: "BH",
    dial_code: "+973",
  },
  {
    name: "Bangladesh",
    flag: "🇧🇩",
    code: "BD",
    dial_code: "+880",
  },
  {
    name: "Barbados",
    flag: "🇧🇧",
    code: "BB",
    dial_code: "+1246",
  },
  {
    name: "Belarus",
    flag: "🇧🇾",
    code: "BY",
    dial_code: "+375",
  },
  {
    name: "Belgium",
    flag: "🇧🇪",
    code: "BE",
    dial_code: "+32",
  },
  {
    name: "Belize",
    flag: "🇧🇿",
    code: "BZ",
    dial_code: "+501",
  },
  {
    name: "Benin",
    flag: "🇧🇯",
    code: "BJ",
    dial_code: "+229",
  },
  {
    name: "Bermuda",
    flag: "🇧🇲",
    code: "BM",
    dial_code: "+1441",
  },
  {
    name: "Bhutan",
    flag: "🇧🇹",
    code: "BT",
    dial_code: "+975",
  },
  {
    name: "Bolivia, Plurinational State of bolivia",
    flag: "🇧🇴",
    code: "BO",
    dial_code: "+591",
  },
  {
    name: "Bosnia and Herzegovina",
    flag: "🇧🇦",
    code: "BA",
    dial_code: "+387",
  },
  {
    name: "Botswana",
    flag: "🇧🇼",
    code: "BW",
    dial_code: "+267",
  },
  {
    name: "Bouvet Island",
    flag: "🇧🇻",
    code: "BV",
    dial_code: "+47",
  },
  {
    name: "Brazil",
    flag: "🇧🇷",
    code: "BR",
    dial_code: "+55",
  },
  {
    name: "British Indian Ocean Territory",
    flag: "🇮🇴",
    code: "IO",
    dial_code: "+246",
  },
  {
    name: "Brunei Darussalam",
    flag: "🇧🇳",
    code: "BN",
    dial_code: "+673",
  },
  {
    name: "Bulgaria",
    flag: "🇧🇬",
    code: "BG",
    dial_code: "+359",
  },
  {
    name: "Burkina Faso",
    flag: "🇧🇫",
    code: "BF",
    dial_code: "+226",
  },
  {
    name: "Burundi",
    flag: "🇧🇮",
    code: "BI",
    dial_code: "+257",
  },
  {
    name: "Cambodia",
    flag: "🇰🇭",
    code: "KH",
    dial_code: "+855",
  },
  {
    name: "Cameroon",
    flag: "🇨🇲",
    code: "CM",
    dial_code: "+237",
  },
  {
    name: "Canada",
    flag: "🇨🇦",
    code: "CA",
    dial_code: "+1",
  },
  {
    name: "Cape Verde",
    flag: "🇨🇻",
    code: "CV",
    dial_code: "+238",
  },
  {
    name: "Cayman Islands",
    flag: "🇰🇾",
    code: "KY",
    dial_code: "+345",
  },
  {
    name: "Central African Republic",
    flag: "🇨🇫",
    code: "CF",
    dial_code: "+236",
  },
  {
    name: "Chad",
    flag: "🇹🇩",
    code: "TD",
    dial_code: "+235",
  },
  {
    name: "Chile",
    flag: "🇨🇱",
    code: "CL",
    dial_code: "+56",
  },
  {
    name: "China",
    flag: "🇨🇳",
    code: "CN",
    dial_code: "+86",
  },
  {
    name: "Christmas Island",
    flag: "🇨🇽",
    code: "CX",
    dial_code: "+61",
  },
  {
    name: "Cocos (Keeling) Islands",
    flag: "🇨🇨",
    code: "CC",
    dial_code: "+61",
  },
  {
    name: "Colombia",
    flag: "🇨🇴",
    code: "CO",
    dial_code: "+57",
  },
  {
    name: "Comoros",
    flag: "🇰🇲",
    code: "KM",
    dial_code: "+269",
  },
  {
    name: "Congo",
    flag: "🇨🇬",
    code: "CG",
    dial_code: "+242",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    flag: "🇨🇩",
    code: "CD",
    dial_code: "+243",
  },
  {
    name: "Cook Islands",
    flag: "🇨🇰",
    code: "CK",
    dial_code: "+682",
  },
  {
    name: "Costa Rica",
    flag: "🇨🇷",
    code: "CR",
    dial_code: "+506",
  },
  {
    name: "Cote d'Ivoire",
    flag: "🇨🇮",
    code: "CI",
    dial_code: "+225",
  },
  {
    name: "Croatia",
    flag: "🇭🇷",
    code: "HR",
    dial_code: "+385",
  },
  {
    name: "Cuba",
    flag: "🇨🇺",
    code: "CU",
    dial_code: "+53",
  },
  {
    name: "Cyprus",
    flag: "🇨🇾",
    code: "CY",
    dial_code: "+357",
  },
  {
    name: "Czech Republic",
    flag: "🇨🇿",
    code: "CZ",
    dial_code: "+420",
  },
  {
    name: "Denmark",
    flag: "🇩🇰",
    code: "DK",
    dial_code: "+45",
  },
  {
    name: "Djibouti",
    flag: "🇩🇯",
    code: "DJ",
    dial_code: "+253",
  },
  {
    name: "Dominica",
    flag: "🇩🇲",
    code: "DM",
    dial_code: "+1767",
  },
  {
    name: "Dominican Republic",
    flag: "🇩🇴",
    code: "DO",
    dial_code: "+1849",
  },
  {
    name: "Ecuador",
    flag: "🇪🇨",
    code: "EC",
    dial_code: "+593",
  },
  {
    name: "Egypt",
    flag: "🇪🇬",
    code: "EG",
    dial_code: "+20",
  },
  {
    name: "El Salvador",
    flag: "🇸🇻",
    code: "SV",
    dial_code: "+503",
  },
  {
    name: "Equatorial Guinea",
    flag: "🇬🇶",
    code: "GQ",
    dial_code: "+240",
  },
  {
    name: "Eritrea",
    flag: "🇪🇷",
    code: "ER",
    dial_code: "+291",
  },
  {
    name: "Estonia",
    flag: "🇪🇪",
    code: "EE",
    dial_code: "+372",
  },
  {
    name: "Ethiopia",
    flag: "🇪🇹",
    code: "ET",
    dial_code: "+251",
  },
  {
    name: "Falkland Islands (Malvinas)",
    flag: "🇫🇰",
    code: "FK",
    dial_code: "+500",
  },
  {
    name: "Faroe Islands",
    flag: "🇫🇴",
    code: "FO",
    dial_code: "+298",
  },
  {
    name: "Fiji",
    flag: "🇫🇯",
    code: "FJ",
    dial_code: "+679",
  },
  {
    name: "Finland",
    flag: "🇫🇮",
    code: "FI",
    dial_code: "+358",
  },
  {
    name: "France",
    flag: "🇫🇷",
    code: "FR",
    dial_code: "+33",
  },
  {
    name: "French Guiana",
    flag: "🇬🇫",
    code: "GF",
    dial_code: "+594",
  },
  {
    name: "French Polynesia",
    flag: "🇵🇫",
    code: "PF",
    dial_code: "+689",
  },
  {
    name: "French Southern Territories",
    flag: "🇹🇫",
    code: "TF",
    dial_code: "+262",
  },
  {
    name: "Gabon",
    flag: "🇬🇦",
    code: "GA",
    dial_code: "+241",
  },
  {
    name: "Gambia",
    flag: "🇬🇲",
    code: "GM",
    dial_code: "+220",
  },
  {
    name: "Georgia",
    flag: "🇬🇪",
    code: "GE",
    dial_code: "+995",
  },
  {
    name: "Germany",
    flag: "🇩🇪",
    code: "DE",
    dial_code: "+49",
  },
  {
    name: "Ghana",
    flag: "🇬🇭",
    code: "GH",
    dial_code: "+233",
  },
  {
    name: "Gibraltar",
    flag: "🇬🇮",
    code: "GI",
    dial_code: "+350",
  },
  {
    name: "Greece",
    flag: "🇬🇷",
    code: "GR",
    dial_code: "+30",
  },
  {
    name: "Greenland",
    flag: "🇬🇱",
    code: "GL",
    dial_code: "+299",
  },
  {
    name: "Grenada",
    flag: "🇬🇩",
    code: "GD",
    dial_code: "+1473",
  },
  {
    name: "Guadeloupe",
    flag: "🇬🇵",
    code: "GP",
    dial_code: "+590",
  },
  {
    name: "Guam",
    flag: "🇬🇺",
    code: "GU",
    dial_code: "+1671",
  },
  {
    name: "Guatemala",
    flag: "🇬🇹",
    code: "GT",
    dial_code: "+502",
  },
  {
    name: "Guernsey",
    flag: "🇬🇬",
    code: "GG",
    dial_code: "+44",
  },
  {
    name: "Guinea",
    flag: "🇬🇳",
    code: "GN",
    dial_code: "+224",
  },
  {
    name: "Guinea-Bissau",
    flag: "🇬🇼",
    code: "GW",
    dial_code: "+245",
  },
  {
    name: "Guyana",
    flag: "🇬🇾",
    code: "GY",
    dial_code: "+592",
  },
  {
    name: "Haiti",
    flag: "🇭🇹",
    code: "HT",
    dial_code: "+509",
  },
  {
    name: "Heard Island and Mcdonald Islands",
    flag: "🇭🇲",
    code: "HM",
    dial_code: "+672",
  },
  {
    name: "Holy See (Vatican City State)",
    flag: "🇻🇦",
    code: "VA",
    dial_code: "+379",
  },
  {
    name: "Honduras",
    flag: "🇭🇳",
    code: "HN",
    dial_code: "+504",
  },
  {
    name: "Hong Kong",
    flag: "🇭🇰",
    code: "HK",
    dial_code: "+852",
  },
  {
    name: "Hungary",
    flag: "🇭🇺",
    code: "HU",
    dial_code: "+36",
  },
  {
    name: "Iceland",
    flag: "🇮🇸",
    code: "IS",
    dial_code: "+354",
  },
  {
    name: "India",
    flag: "🇮🇳",
    code: "IN",
    dial_code: "+91",
  },
  {
    name: "Indonesia",
    flag: "🇮🇩",
    code: "ID",
    dial_code: "+62",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    flag: "🇮🇷",
    code: "IR",
    dial_code: "+98",
  },
  {
    name: "Iraq",
    flag: "🇮🇶",
    code: "IQ",
    dial_code: "+964",
  },
  {
    name: "Ireland",
    flag: "🇮🇪",
    code: "IE",
    dial_code: "+353",
  },
  {
    name: "Isle of Man",
    flag: "🇮🇲",
    code: "IM",
    dial_code: "+44",
  },
  {
    name: "Israel",
    flag: "🇮🇱",
    code: "IL",
    dial_code: "+972",
  },
  {
    name: "Italy",
    flag: "🇮🇹",
    code: "IT",
    dial_code: "+39",
  },
  {
    name: "Jamaica",
    flag: "🇯🇲",
    code: "JM",
    dial_code: "+1876",
  },
  {
    name: "Japan",
    flag: "🇯🇵",
    code: "JP",
    dial_code: "+81",
  },
  {
    name: "Jersey",
    flag: "🇯🇪",
    code: "JE",
    dial_code: "+44",
  },
  {
    name: "Jordan",
    flag: "🇯🇴",
    code: "JO",
    dial_code: "+962",
  },
  {
    name: "Kazakhstan",
    flag: "🇰🇿",
    code: "KZ",
    dial_code: "+7",
  },
  {
    name: "Kenya",
    flag: "🇰🇪",
    code: "KE",
    dial_code: "+254",
  },
  {
    name: "Kiribati",
    flag: "🇰🇮",
    code: "KI",
    dial_code: "+686",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    flag: "🇰🇵",
    code: "KP",
    dial_code: "+850",
  },
  {
    name: "Korea, Republic of South Korea",
    flag: "🇰🇷",
    code: "KR",
    dial_code: "+82",
  },
  {
    name: "Kosovo",
    flag: "🇽🇰",
    code: "XK",
    dial_code: "+383",
  },
  {
    name: "Kuwait",
    flag: "🇰🇼",
    code: "KW",
    dial_code: "+965",
  },
  {
    name: "Kyrgyzstan",
    flag: "🇰🇬",
    code: "KG",
    dial_code: "+996",
  },
  {
    name: "Laos",
    flag: "🇱🇦",
    code: "LA",
    dial_code: "+856",
  },
  {
    name: "Latvia",
    flag: "🇱🇻",
    code: "LV",
    dial_code: "+371",
  },
  {
    name: "Lebanon",
    flag: "🇱🇧",
    code: "LB",
    dial_code: "+961",
  },
  {
    name: "Lesotho",
    flag: "🇱🇸",
    code: "LS",
    dial_code: "+266",
  },
  {
    name: "Liberia",
    flag: "🇱🇷",
    code: "LR",
    dial_code: "+231",
  },
  {
    name: "Libyan Arab Jamahiriya",
    flag: "🇱🇾",
    code: "LY",
    dial_code: "+218",
  },
  {
    name: "Liechtenstein",
    flag: "🇱🇮",
    code: "LI",
    dial_code: "+423",
  },
  {
    name: "Lithuania",
    flag: "🇱🇹",
    code: "LT",
    dial_code: "+370",
  },
  {
    name: "Luxembourg",
    flag: "🇱🇺",
    code: "LU",
    dial_code: "+352",
  },
  {
    name: "Macao",
    flag: "🇲🇴",
    code: "MO",
    dial_code: "+853",
  },
  {
    name: "Macedonia",
    flag: "🇲🇰",
    code: "MK",
    dial_code: "+389",
  },
  {
    name: "Madagascar",
    flag: "🇲🇬",
    code: "MG",
    dial_code: "+261",
  },
  {
    name: "Malawi",
    flag: "🇲🇼",
    code: "MW",
    dial_code: "+265",
  },
  {
    name: "Malaysia",
    flag: "🇲🇾",
    code: "MY",
    dial_code: "+60",
  },
  {
    name: "Maldives",
    flag: "🇲🇻",
    code: "MV",
    dial_code: "+960",
  },
  {
    name: "Mali",
    flag: "🇲🇱",
    code: "ML",
    dial_code: "+223",
  },
  {
    name: "Malta",
    flag: "🇲🇹",
    code: "MT",
    dial_code: "+356",
  },
  {
    name: "Marshall Islands",
    flag: "🇲🇭",
    code: "MH",
    dial_code: "+692",
  },
  {
    name: "Martinique",
    flag: "🇲🇶",
    code: "MQ",
    dial_code: "+596",
  },
  {
    name: "Mauritania",
    flag: "🇲🇷",
    code: "MR",
    dial_code: "+222",
  },
  {
    name: "Mauritius",
    flag: "🇲🇺",
    code: "MU",
    dial_code: "+230",
  },
  {
    name: "Mayotte",
    flag: "🇾🇹",
    code: "YT",
    dial_code: "+262",
  },
  {
    name: "Mexico",
    flag: "🇲🇽",
    code: "MX",
    dial_code: "+52",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    flag: "🇫🇲",
    code: "FM",
    dial_code: "+691",
  },
  {
    name: "Moldova",
    flag: "🇲🇩",
    code: "MD",
    dial_code: "+373",
  },
  {
    name: "Monaco",
    flag: "🇲🇨",
    code: "MC",
    dial_code: "+377",
  },
  {
    name: "Mongolia",
    flag: "🇲🇳",
    code: "MN",
    dial_code: "+976",
  },
  {
    name: "Montenegro",
    flag: "🇲🇪",
    code: "ME",
    dial_code: "+382",
  },
  {
    name: "Montserrat",
    flag: "🇲🇸",
    code: "MS",
    dial_code: "+1664",
  },
  {
    name: "Morocco",
    flag: "🇲🇦",
    code: "MA",
    dial_code: "+212",
  },
  {
    name: "Mozambique",
    flag: "🇲🇿",
    code: "MZ",
    dial_code: "+258",
  },
  {
    name: "Myanmar",
    flag: "🇲🇲",
    code: "MM",
    dial_code: "+95",
  },
  {
    name: "Namibia",
    flag: "🇳🇦",
    code: "NA",
    dial_code: "+264",
  },
  {
    name: "Nauru",
    flag: "🇳🇷",
    code: "NR",
    dial_code: "+674",
  },
  {
    name: "Nepal",
    flag: "🇳🇵",
    code: "NP",
    dial_code: "+977",
  },
  {
    name: "Netherlands",
    flag: "🇳🇱",
    code: "NL",
    dial_code: "+31",
  },
  {
    name: "Netherlands Antilles",
    flag: "",
    code: "AN",
    dial_code: "+599",
  },
  {
    name: "New Caledonia",
    flag: "🇳🇨",
    code: "NC",
    dial_code: "+687",
  },
  {
    name: "New Zealand",
    flag: "🇳🇿",
    code: "NZ",
    dial_code: "+64",
  },
  {
    name: "Nicaragua",
    flag: "🇳🇮",
    code: "NI",
    dial_code: "+505",
  },
  {
    name: "Niger",
    flag: "🇳🇪",
    code: "NE",
    dial_code: "+227",
  },
  {
    name: "Nigeria",
    flag: "🇳🇬",
    code: "NG",
    dial_code: "+234",
  },
  {
    name: "Niue",
    flag: "🇳🇺",
    code: "NU",
    dial_code: "+683",
  },
  {
    name: "Norfolk Island",
    flag: "🇳🇫",
    code: "NF",
    dial_code: "+672",
  },
  {
    name: "Northern Mariana Islands",
    flag: "🇲🇵",
    code: "MP",
    dial_code: "+1670",
  },
  {
    name: "Norway",
    flag: "🇳🇴",
    code: "NO",
    dial_code: "+47",
  },
  {
    name: "Oman",
    flag: "🇴🇲",
    code: "OM",
    dial_code: "+968",
  },
  {
    name: "Pakistan",
    flag: "🇵🇰",
    code: "PK",
    dial_code: "+92",
  },
  {
    name: "Palau",
    flag: "🇵🇼",
    code: "PW",
    dial_code: "+680",
  },
  {
    name: "Palestinian Territory, Occupied",
    flag: "🇵🇸",
    code: "PS",
    dial_code: "+970",
  },
  {
    name: "Panama",
    flag: "🇵🇦",
    code: "PA",
    dial_code: "+507",
  },
  {
    name: "Papua New Guinea",
    flag: "🇵🇬",
    code: "PG",
    dial_code: "+675",
  },
  {
    name: "Paraguay",
    flag: "🇵🇾",
    code: "PY",
    dial_code: "+595",
  },
  {
    name: "Peru",
    flag: "🇵🇪",
    code: "PE",
    dial_code: "+51",
  },
  {
    name: "Philippines",
    flag: "🇵🇭",
    code: "PH",
    dial_code: "+63",
  },
  {
    name: "Pitcairn",
    flag: "🇵🇳",
    code: "PN",
    dial_code: "+64",
  },
  {
    name: "Poland",
    flag: "🇵🇱",
    code: "PL",
    dial_code: "+48",
  },
  {
    name: "Portugal",
    flag: "🇵🇹",
    code: "PT",
    dial_code: "+351",
  },
  {
    name: "Puerto Rico",
    flag: "🇵🇷",
    code: "PR",
    dial_code: "+1939",
  },
  {
    name: "Qatar",
    flag: "🇶🇦",
    code: "QA",
    dial_code: "+974",
  },
  {
    name: "Romania",
    flag: "🇷🇴",
    code: "RO",
    dial_code: "+40",
  },
  {
    name: "Russia",
    flag: "🇷🇺",
    code: "RU",
    dial_code: "+7",
  },
  {
    name: "Rwanda",
    flag: "🇷🇼",
    code: "RW",
    dial_code: "+250",
  },
  {
    name: "Reunion",
    flag: "🇷🇪",
    code: "RE",
    dial_code: "+262",
  },
  {
    name: "Saint Barthelemy",
    flag: "🇧🇱",
    code: "BL",
    dial_code: "+590",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    flag: "🇸🇭",
    code: "SH",
    dial_code: "+290",
  },
  {
    name: "Saint Kitts and Nevis",
    flag: "🇰🇳",
    code: "KN",
    dial_code: "+1869",
  },
  {
    name: "Saint Lucia",
    flag: "🇱🇨",
    code: "LC",
    dial_code: "+1758",
  },
  {
    name: "Saint Martin",
    flag: "🇲🇫",
    code: "MF",
    dial_code: "+590",
  },
  {
    name: "Saint Pierre and Miquelon",
    flag: "🇵🇲",
    code: "PM",
    dial_code: "+508",
  },
  {
    name: "Saint Vincent and the Grenadines",
    flag: "🇻🇨",
    code: "VC",
    dial_code: "+1784",
  },
  {
    name: "Samoa",
    flag: "🇼🇸",
    code: "WS",
    dial_code: "+685",
  },
  {
    name: "San Marino",
    flag: "🇸🇲",
    code: "SM",
    dial_code: "+378",
  },
  {
    name: "Sao Tome and Principe",
    flag: "🇸🇹",
    code: "ST",
    dial_code: "+239",
  },
  {
    name: "Saudi Arabia",
    flag: "🇸🇦",
    code: "SA",
    dial_code: "+966",
  },
  {
    name: "Senegal",
    flag: "🇸🇳",
    code: "SN",
    dial_code: "+221",
  },
  {
    name: "Serbia",
    flag: "🇷🇸",
    code: "RS",
    dial_code: "+381",
  },
  {
    name: "Seychelles",
    flag: "🇸🇨",
    code: "SC",
    dial_code: "+248",
  },
  {
    name: "Sierra Leone",
    flag: "🇸🇱",
    code: "SL",
    dial_code: "+232",
  },
  {
    name: "Singapore",
    flag: "🇸🇬",
    code: "SG",
    dial_code: "+65",
  },
  {
    name: "Slovakia",
    flag: "🇸🇰",
    code: "SK",
    dial_code: "+421",
  },
  {
    name: "Slovenia",
    flag: "🇸🇮",
    code: "SI",
    dial_code: "+386",
  },
  {
    name: "Solomon Islands",
    flag: "🇸🇧",
    code: "SB",
    dial_code: "+677",
  },
  {
    name: "Somalia",
    flag: "🇸🇴",
    code: "SO",
    dial_code: "+252",
  },
  {
    name: "South Africa",
    flag: "🇿🇦",
    code: "ZA",
    dial_code: "+27",
  },
  {
    name: "South Sudan",
    flag: "🇸🇸",
    code: "SS",
    dial_code: "+211",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    flag: "🇬🇸",
    code: "GS",
    dial_code: "+500",
  },
  {
    name: "Spain",
    flag: "🇪🇸",
    code: "ES",
    dial_code: "+34",
  },
  {
    name: "Sri Lanka",
    flag: "🇱🇰",
    code: "LK",
    dial_code: "+94",
  },
  {
    name: "Sudan",
    flag: "🇸🇩",
    code: "SD",
    dial_code: "+249",
  },
  {
    name: "Suriname",
    flag: "🇸🇷",
    code: "SR",
    dial_code: "+597",
  },
  {
    name: "Svalbard and Jan Mayen",
    flag: "🇸🇯",
    code: "SJ",
    dial_code: "+47",
  },
  {
    name: "Eswatini",
    flag: "🇸🇿",
    code: "SZ",
    dial_code: "+268",
  },
  {
    name: "Sweden",
    flag: "🇸🇪",
    code: "SE",
    dial_code: "+46",
  },
  {
    name: "Switzerland",
    flag: "🇨🇭",
    code: "CH",
    dial_code: "+41",
  },
  {
    name: "Syrian Arab Republic",
    flag: "🇸🇾",
    code: "SY",
    dial_code: "+963",
  },
  {
    name: "Taiwan",
    flag: "🇹🇼",
    code: "TW",
    dial_code: "+886",
  },
  {
    name: "Tajikistan",
    flag: "🇹🇯",
    code: "TJ",
    dial_code: "+992",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    flag: "🇹🇿",
    code: "TZ",
    dial_code: "+255",
  },
  {
    name: "Thailand",
    flag: "🇹🇭",
    code: "TH",
    dial_code: "+66",
  },
  {
    name: "Timor-Leste",
    flag: "🇹🇱",
    code: "TL",
    dial_code: "+670",
  },
  {
    name: "Togo",
    flag: "🇹🇬",
    code: "TG",
    dial_code: "+228",
  },
  {
    name: "Tokelau",
    flag: "🇹🇰",
    code: "TK",
    dial_code: "+690",
  },
  {
    name: "Tonga",
    flag: "🇹🇴",
    code: "TO",
    dial_code: "+676",
  },
  {
    name: "Trinidad and Tobago",
    flag: "🇹🇹",
    code: "TT",
    dial_code: "+1868",
  },
  {
    name: "Tunisia",
    flag: "🇹🇳",
    code: "TN",
    dial_code: "+216",
  },
  {
    name: "Turkey",
    flag: "🇹🇷",
    code: "TR",
    dial_code: "+90",
  },
  {
    name: "Turkmenistan",
    flag: "🇹🇲",
    code: "TM",
    dial_code: "+993",
  },
  {
    name: "Turks and Caicos Islands",
    flag: "🇹🇨",
    code: "TC",
    dial_code: "+1649",
  },
  {
    name: "Tuvalu",
    flag: "🇹🇻",
    code: "TV",
    dial_code: "+688",
  },
  {
    name: "Uganda",
    flag: "🇺🇬",
    code: "UG",
    dial_code: "+256",
  },
  {
    name: "Ukraine",
    flag: "🇺🇦",
    code: "UA",
    dial_code: "+380",
  },
  {
    name: "United Arab Emirates",
    flag: "🇦🇪",
    code: "AE",
    dial_code: "+971",
  },
  {
    name: "United Kingdom",
    flag: "🇬🇧",
    code: "GB",
    dial_code: "+44",
  },
  {
    name: "United States",
    flag: "🇺🇸",
    code: "US",
    dial_code: "+1",
  },
  {
    name: "Uruguay",
    flag: "🇺🇾",
    code: "UY",
    dial_code: "+598",
  },
  {
    name: "Uzbekistan",
    flag: "🇺🇿",
    code: "UZ",
    dial_code: "+998",
  },
  {
    name: "Vanuatu",
    flag: "🇻🇺",
    code: "VU",
    dial_code: "+678",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    flag: "🇻🇪",
    code: "VE",
    dial_code: "+58",
  },
  {
    name: "Vietnam",
    flag: "🇻🇳",
    code: "VN",
    dial_code: "+84",
  },
  {
    name: "Virgin Islands, British",
    flag: "🇻🇬",
    code: "VG",
    dial_code: "+1284",
  },
  {
    name: "Virgin Islands, U.S.",
    flag: "🇻🇮",
    code: "VI",
    dial_code: "+1340",
  },
  {
    name: "Wallis and Futuna",
    flag: "🇼🇫",
    code: "WF",
    dial_code: "+681",
  },
  {
    name: "Yemen",
    flag: "🇾🇪",
    code: "YE",
    dial_code: "+967",
  },
  {
    name: "Zambia",
    flag: "🇿🇲",
    code: "ZM",
    dial_code: "+260",
  },
  {
    name: "Zimbabwe",
    flag: "🇿🇼",
    code: "ZW",
    dial_code: "+263",
  },
];
