import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import "react-calendar/dist/Calendar.css";
import { getTeamDataById, getTeamListAction, getTeamListByIdAction } from "../Redux/Action/teamAction";
import { useDispatch, useSelector } from "react-redux";
import { Encryptedid } from "../utils/Becrypt";
import { capitalize } from "../utils/helper";
import Loader from "../components/Loader";
export default function MyTeam() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const teanListingData = useSelector((e) => e.teamAuthData.getTeamListing);
  const teanListingData = useSelector((e) => e.teamAuthData.getTeamListingById);
  const loader = useSelector((e) => e.teamAuthData.loading);

  useEffect(() => {
    // dispatch(getTeamListAction());
    dispatch(getTeamListByIdAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTeamDataById({ id: "" }));
  }, [dispatch]);

  return (
    <LoginLayout>
      <section>
      {loader && <Loader />}
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-3 d-flex justify-content-between align-items-center">
              <h2>My Teams</h2>
            </div>
            <div className="play-outer">
              <Row>
                {teanListingData?.length > 0 ? (
                  teanListingData?.map((item, index) => (
                    <Col
                      lg={4}
                      key={index}
                      className=""
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/my-team/${Encryptedid(item?._id)}`)
                      }
                    >
                      <div className="player-box">
                        <img
                          src={item?.profile_image ? `${process.env.REACT_APP_IMAGE_PATH}${item?.profile_image}` : require("../Assets/images/biglogo.png")}
                        />
                        <div className="mt-2">
                          <h3>{capitalize(item?.team_name)}</h3>
                          <h5>{item?.team_country}</h5>

                          <p>
                            <span>Team ID:</span>{" "}
                            {item?.unique_id.toUpperCase()}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <h4 className="nodata">No Team Data</h4>
                )}
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
