// import LoginLayout from "../components/LoginLayout";
// import { Col, Container, Form, Row } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import {
//   Formik,
//   Field,
//   Form as FormikForm,
//   ErrorMessage,
//   FieldArray,
// } from "formik";
// import * as Yup from "yup";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect, useState } from "react";
// import { staticTeamDataAction } from "../Redux/Action/staticAction";
// import Select from "react-select";
// import { Country, State } from "country-state-city";
// import {
//   createTeamAction,
//   editTeamAction,
//   getTeamDataById,
// } from "../Redux/Action/teamAction";
// import toast from "react-hot-toast";
// import { Encryptedid } from "../utils/Becrypt";

// export default function AddTeam() {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const [cities, setCities] = useState([]);
//   const [cities1, setCities1] = useState([]);
//   const [cityMap, setCityMap] = useState({});
//   const [loadingCities, setLoadingCities] = useState(false);
//   const [selectedCountry, setSelectedCountry] = useState(null);
//   const [countries, setCountries] = useState([]);
//   const [inputType1, setInputType1] = useState("text");

//   const staticListForTeam = useSelector(
//     (e) => e.staticAuthData.teamStaticDetail
//   );
//   const getTeamDetails = useSelector((e) => e.teamAuthData.getTeamDetailById);

//   const optionsTeamLeague = staticListForTeam?.team_league?.map((item) => ({
//     label: item?.val,
//     value: item?.id,
//   }));

//   const optionsAnnualBudget = staticListForTeam?.annual_budget?.map((item) => ({
//     label: item?.val,
//     value: item?.val,
//   }));

//   const optionsTrophy = staticListForTeam?.trophy?.map((item) => ({
//     label: item?.val,
//     value: item?.id,
//   }));

//   const validateForm = (values) => {
//     const errors = {};

//     if (!values.profile_image) {
//       errors.profile_image = "required";
//     }

//     if (!values.banner_image) {
//       errors.banner_image = "required";
//     }

//     if (!values.team_name) {
//       errors.team_name = "required";
//     } else if (values.team_name.length < 2) {
//       errors.team_name = "Must be at least 2 characters long";
//     }

//     if (!values.team_country) {
//       errors.team_country = "required";
//     }

//     if (!values.team_city) {
//       errors.team_city = "required";
//     }

//     if (!values.team_president) {
//       errors.team_president = "required";
//     }

//     if (!values.team_owner) {
//       errors.team_owner = "required";
//     }

//     if (values.carrier_trophies && values.carrier_trophies.length > 0) {
//       values.carrier_trophies.forEach((item, index) => {
//         if (!item.trophy) {
//           if (!errors.carrier_trophies) errors.carrier_trophies = [];
//           errors.carrier_trophies[index] = { trophy: "Trophy is required" };
//         }
//         if (!item.date_of_winning) {
//           if (!errors.carrier_trophies) errors.carrier_trophies = [];
//           errors.carrier_trophies[index] = {
//             ...errors.carrier_trophies[index],
//             date_of_winning: "Date is required",
//           };
//         }
//       });
//     }

//     return errors;
//   };

//   const processFormData = (values) => {
//     console.log(cities1, "fsdfgdfgdfgdsfs", values);
//     return {
//       ...values,
//       team_city: values.team_city || "",
//       team_league: values.team_league?.value || "",
//       annual_budget: values.annual_budget?.value || "",
//     };
//   };

//   const handleSubmit = (values) => {
//     const processedValues = processFormData(values);

//     let teamId = localStorage.getItem("teamId");

//     if (teamId) {
//       const formData = new FormData();

//       const transformedData = [];

//       values.carrier_trophies.forEach((item) => {
//         const trophies = Array.isArray(item.trophy)
//           ? item.trophy
//           : [item.trophy];

//         trophies.forEach((trophy) => {
//           transformedData.push({
//             trophy: trophy.value,
//             date_of_winning: item.date_of_winning,
//           });
//         });
//       });
//       formData.append("carrier_trophies", JSON.stringify(transformedData));
//       Object.entries(processedValues).forEach(([key, value]) => {
//         if (key !== "carrier_trophies") {
//           formData.append(key, value);
//         }
//       });
//       formData.append("teamId", teamId);
//       formData.append("is_profile_completed", 1);

//       dispatch(editTeamAction(formData)).then((res) => {
//         if (res?.payload?.status === 200) {
//           navigate(`/add-team-two/${Encryptedid(res?.payload?.data?._id)}`);
//         } else {
//           toast.error(res?.payload?.message);
//         }
//       });
//     } else {
//       const transformedData = [];

//       values.carrier_trophies.forEach((item) => {
//         const trophies = Array.isArray(item.trophy)
//           ? item.trophy
//           : [item.trophy];

//         trophies.forEach((trophy) => {
//           transformedData.push({
//             trophy: trophy.value,
//             date_of_winning: item.date_of_winning,
//           });
//         });
//       });

//       const formData = new FormData();
//       formData.append("carrier_trophies", JSON.stringify(transformedData));

//       Object.entries(processedValues).forEach(([key, value]) => {
//         if (key !== "carrier_trophies") {
//           formData.append(key, value);
//         }
//       });

//       formData.append("is_profile_completed", 1);

//       dispatch(createTeamAction(formData)).then((res) => {
//         if (res?.payload?.status === 200) {
//           navigate(`/add-team-two/${Encryptedid(res?.payload?.data?._id)}`);
//           localStorage.setItem("teamId", res?.payload?.data?._id);
//         } else {
//           toast.error(res?.payload?.message);
//         }
//       });
//     }
//   };

//   const handleFileChange = (e, setFieldValue, fieldName) => {
//     const file = e.target.files[0];
//     setFieldValue(fieldName, file ? file : null);
//   };

//   const groupTrophiesByDate = (carrierTrophies, optionsTrophy) => {
//     const groupedTrophies = [];

//     carrierTrophies.forEach((elem) => {
//       const existingGroup = groupedTrophies.find(
//         (group) => group.date_of_winning === elem.date_of_winning.split("T")[0]
//       );

//       if (existingGroup) {
//         existingGroup.trophy.push(
//           optionsTrophy?.find((option) => option.value === elem.trophy)
//         );
//       } else {
//         groupedTrophies.push({
//           trophy: [
//             optionsTrophy?.find((option) => option.value === elem.trophy),
//           ],
//           date_of_winning: elem.date_of_winning.split("T")[0],
//         });
//       }
//     });

//     return groupedTrophies.length > 0
//       ? groupedTrophies
//       : [{ trophy: null, date_of_winning: "" }];
//   };

//   const initialValues = {
//     profile_image: getTeamDetails?.profile_image || "",
//     banner_image: getTeamDetails?.banner_image || "",
//     team_name: getTeamDetails?.team_name || "",
//     team_country: getTeamDetails?.team_country || "",
//     team_city: getTeamDetails?.team_city || "",
//     team_president: getTeamDetails?.team_president || "",
//     team_owner: getTeamDetails?.team_owner || "",
//     team_league:
//       optionsTeamLeague?.find(
//         (elem) => elem.value === getTeamDetails?.team_league
//       ) || "",
//     annual_budget:
//       optionsAnnualBudget?.find(
//         (elem) => elem.value === getTeamDetails?.annual_budget
//       ) || "",
//     // carrier_trophies:
//     //   getTeamDetails?.carrier_trophies?.length > 0
//     //     ? getTeamDetails?.carrier_trophies?.map((elem) => ({
//     //         trophy: optionsTrophy?.find(
//     //           (option) => option.value === elem.trophy
//     //         ),
//     //         date_of_winning: elem?.date_of_winning?.split("T")[0],
//     //       }))
//     //     : [{ trophy: null, date_of_winning: "" }],
//     carrier_trophies: groupTrophiesByDate(
//       getTeamDetails?.carrier_trophies || [],
//       optionsTrophy
//     ),

//     info_other_notes: getTeamDetails?.info_other_notes || "",
//   };

//   useEffect(() => {
//     dispatch(staticTeamDataAction());
//   }, []);

//   useEffect(() => {
//     let teamId = localStorage.getItem("teamId");
//     if (teamId) {
//       dispatch(getTeamDataById({ id: teamId }));
//     }
//   }, [dispatch]);

//   const optionsPlaceOfBirth = staticListForTeam?.country?.map((item) => ({
//     label: item,
//     value: item,
//   }));

//   // const fetchCities = async (countryName) => {
//   //   setLoadingCities(true);
//   //   try {
//   //     const stateList = State.getStatesOfCountry(countryName);
//   //     const cityMap = {};

//   //     const cities = stateList.map((state) => {
//   //       cityMap[state.isoCode] = state.name;
//   //       return {
//   //         value: state.name,
//   //         label: state.name,
//   //       };
//   //     });

//   //     setCities(cities);
//   //     setCityMap(cityMap);
//   //   } catch (error) {
//   //     console.error("Error fetching cities:", error);
//   //   } finally {
//   //     setLoadingCities(false);
//   //   }
//   // };

//   // const handleCountryChange = async (
//   //   selectedOption,
//   //   { setFieldValue, setFieldTouched }
//   // ) => {
//   //   if (!selectedOption) return;

//   //   const countryData = Country.getAllCountries().find(
//   //     (country) => country.isoCode === selectedOption.value
//   //   );

//   //   if (countryData) {
//   //     setSelectedCountry({
//   //       value: countryData.isoCode,
//   //       label: countryData.name,
//   //     });

//   //     setFieldValue("team_country", countryData.name);
//   //     setFieldValue("team_city", "");
//   //     setCities([]);
//   //     await fetchCities(countryData.isoCode);
//   //     setCities1(null);
//   //     setFieldTouched("team_country", false);
//   //   }
//   // };

//   const fetchCities = async (countryIsoCode) => {
//     setLoadingCities(true);
//     try {
//       const stateList = State.getStatesOfCountry(countryIsoCode);
//       const cityMap = {};

//       const cities = stateList.map((state) => {
//         cityMap[state.isoCode] = state.name;
//         return {
//           value: state.name,
//           label: state.name,
//         };
//       });

//       setCities(cities); // Update cities in the state
//       setCityMap(cityMap); // Update the city map
//     } catch (error) {
//       console.error("Error fetching cities:", error);
//     } finally {
//       setLoadingCities(false);
//     }
//   };

//   const handleCountryChange = async (
//     selectedOption,
//     { setFieldValue, setFieldTouched }
//   ) => {
//     if (!selectedOption) return;

//     const countryData = Country.getAllCountries().find(
//       (country) => country.isoCode === selectedOption.value
//     );

//     if (countryData) {
//       setSelectedCountry({
//         value: countryData.isoCode,
//         label: countryData.name,
//       });

//       setFieldValue("team_country", countryData.name); // Set the selected country
//       setFieldValue("team_city", ""); // Reset city when country changes
//       setCities([]); // Clear cities before fetching new ones
//       await fetchCities(countryData.isoCode); // Fetch cities based on the country
//       setCities1(null); // Reset selected city
//       setFieldTouched("team_country", false); // Mark country field as untouched
//     }
//   };

//   useEffect(() => {
//     const countryList = Country.getAllCountries();
//     setCountries(
//       countryList.map((country) => ({
//         value: country.isoCode,
//         label: country.name,
//       }))
//     );
//   }, []);

//   // useEffect(() => {
//   //   if (getTeamDetails?.team_country) {
//   //     const countryOption = countries.find(
//   //       (country) => country.label === getTeamDetails?.team_country
//   //     );
//   //     setSelectedCountry(countryOption || null);

//   //     if (countryOption) {
//   //       fetchCities(countryOption.value);
//   //     }
//   //   }
//   // }, [getTeamDetails, countries]);

//   // useEffect(() => {
//   //   if (getTeamDetails?.team_city) {
//   //     const selectedCityData = cities?.find(
//   //       (city) => city.label === getTeamDetails.team_city
//   //     );

//   //     if (selectedCityData) {
//   //       setCities1(selectedCityData);
//   //     }
//   //   }
//   // }, [cities, getTeamDetails]);

//   // useEffect(() => {
//   //   if (selectedCountry) {
//   //     fetchCities(selectedCountry.value);
//   //   } else {
//   //     setCities([]);
//   //     setCities1(null);
//   //   }
//   // }, [selectedCountry]);

//   useEffect(() => {
//     if (getTeamDetails?.team_country) {
//       const countryOption = countries.find(
//         (country) => country.label === getTeamDetails?.team_country
//       );
//       setSelectedCountry(countryOption || null);

//       if (countryOption) {
//         fetchCities(countryOption.value);
//       }
//     }
//   }, [getTeamDetails, countries]);

//   useEffect(() => {
//     if (getTeamDetails?.team_city) {
//       const selectedCityData = cities?.find(
//         (city) => city.label === getTeamDetails.team_city
//       );

//       if (selectedCityData) {
//         setCities1(selectedCityData);
//       }
//     }
//   }, [cities, getTeamDetails]);

//   return (
//     <LoginLayout>
//       <section>
//         <Container>
//           <div className="cmn-card">
//             <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
//               <h2>Add Team</h2>
//             </div>
//             <Formik
//               initialValues={initialValues}
//               enableReinitialize={true}
//               validate={validateForm}
//               onSubmit={handleSubmit}
//             >
//               {({ setFieldValue, values, setFieldTouched }) => {
//                 return (
//                   <FormikForm>
//                     <div className="common-outer">
//                       <Row>
//                         <Col lg={4}>
//                           <div className="image-upload-main">
//                             <h3>Logo</h3>
//                             <div className="image-upload-inner">
//                               <div className="image-upload-placeholder">
//                                 <img
//                                   src={
//                                     require("../Assets/images/upload.svg")
//                                       .default
//                                   }
//                                   alt="Upload Placeholder"
//                                 />

//                                 <h4>Upload Image</h4>
//                               </div>
//                               <Field name="profile_image">
//                                 {({ field }) => (
//                                   <input
//                                     type="file"
//                                     onChange={(e) =>
//                                       handleFileChange(
//                                         e,
//                                         setFieldValue,
//                                         "profile_image"
//                                       )
//                                     }
//                                   />
//                                 )}
//                               </Field>

//                               <div className="image-upload-placeholder">
//                                 {values?.profile_image &&
//                                 typeof values?.profile_image === "string" ? (
//                                   <img
//                                     className="inner-view"
//                                     src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
//                                     alt="Upload Placeholder"
//                                   />
//                                 ) : values?.profile_image &&
//                                   values.profile_image instanceof File ? (
//                                   <img
//                                     src={
//                                       values?.profile_image
//                                         ? URL.createObjectURL(
//                                             values?.profile_image
//                                           )
//                                         : ""
//                                     }
//                                     className="prev-img"
//                                     alt="Profile Preview"
//                                   />
//                                 ) : (
//                                   ""
//                                 )}
//                               </div>

//                               {values?.profile_image &&
//                                 (typeof values.profile_image === "string" ||
//                                   values.profile_image instanceof File) && (
//                                   <button
//                                     type="button"
//                                     onClick={() =>
//                                       setFieldValue("profile_image", null)
//                                     }
//                                   >
//                                     <img
//                                       src={
//                                         require("../Assets/images/delete.svg")
//                                           .default
//                                       }
//                                       alt="Delete"
//                                     />
//                                   </button>
//                                 )}
//                             </div>
//                             <ErrorMessage
//                               name="profile_image"
//                               component="div"
//                               className="text-danger"
//                             />
//                           </div>
//                         </Col>
//                         <Col lg={4}>
//                           <div className="image-upload-main">
//                             <h3>Banner Image</h3>
//                             <div className="image-upload-inner">
//                               <div className="image-upload-placeholder">
//                                 <img
//                                   src={
//                                     require("../Assets/images/upload.svg")
//                                       .default
//                                   }
//                                   alt="Upload Placeholder"
//                                 />

//                                 <h4>Upload Image</h4>
//                               </div>
//                               <Field name="banner_image">
//                                 {({ field }) => (
//                                   <input
//                                     type="file"
//                                     onChange={(e) =>
//                                       handleFileChange(
//                                         e,
//                                         setFieldValue,
//                                         "banner_image"
//                                       )
//                                     }
//                                   />
//                                 )}
//                               </Field>
//                               <div className="image-upload-placeholder">
//                                 {values?.banner_image &&
//                                 typeof values.banner_image === "string" ? (
//                                   <img
//                                     className="inner-view"
//                                     src={`${process.env.REACT_APP_IMAGE_PATH}${values?.banner_image}`}
//                                     alt="Upload Placeholder"
//                                   />
//                                 ) : values?.banner_image &&
//                                   values.banner_image instanceof File ? (
//                                   <img
//                                     src={URL.createObjectURL(
//                                       values.banner_image
//                                     )}
//                                     className="prev-img"
//                                     alt="Banner Preview"
//                                   />
//                                 ) : (
//                                   ""
//                                 )}
//                               </div>

//                               {values?.banner_image &&
//                                 (typeof values.banner_image === "string" ||
//                                   values.banner_image instanceof File) && (
//                                   <button
//                                     type="button"
//                                     onClick={() =>
//                                       setFieldValue("banner_image", null)
//                                     }
//                                   >
//                                     <img
//                                       src={
//                                         require("../Assets/images/delete.svg")
//                                           .default
//                                       }
//                                       alt="Delete"
//                                     />
//                                   </button>
//                                 )}
//                             </div>
//                             <ErrorMessage
//                               name="banner_image"
//                               component="div"
//                               className="text-danger"
//                             />
//                           </div>
//                         </Col>
//                         <Col lg={4}></Col>
//                       </Row>
//                     </div>

//                     <div className="common-outer mt-4">
//                       <h3 className="h-20">Personal Details</h3>
//                       <div className="cmn-form-fields mt-2">
//                         <Row>
//                           <Col lg={4}>
//                             <Form.Group className="mb-3 form-inner">
//                               <Field
//                                 name="team_name"
//                                 type="text"
//                                 className="form-control"
//                                 placeholder="Enter name here"
//                               />
//                               <ErrorMessage
//                                 name="team_name"
//                                 component="div"
//                                 className="text-danger"
//                               />
//                             </Form.Group>
//                           </Col>
//                           <Col lg={4}>
//                             <div className="profile-card mb-3">
//                               <Select
//                                 name="team_country"
//                                 placeholder="Teams Country"
//                                 isSearchable={true}
//                                 value={selectedCountry}
//                                 onChange={(e) =>
//                                   handleCountryChange(e, {
//                                     setFieldValue,
//                                     setFieldTouched,
//                                   })
//                                 }
//                                 options={countries}
//                               />
//                               <ErrorMessage
//                                 name="team_country"
//                                 component="div"
//                                 className="text-danger"
//                               />
//                             </div>
//                           </Col>

//                           <Col lg={4}>
//                             <div className="profile-card mb-3">
//                               <Select
//                                 // name="team_city"
//                                 // placeholder="Team's City"
//                                 // options={cities}
//                                 // onChange={(option) => {
//                                 //   setFieldValue("team_city", option);
//                                 //   setCities1(option);
//                                 // }}
//                                 // value={cities1 || ""}
//                                 // isLoading={loadingCities}
//                                 // isDisabled={!selectedCountry}
//                                 options={cities}
//                                 onChange={(selectedOption) => {
//                                   setFieldValue("team_city", selectedOption?.value || "");
//                                   setCities1(selectedOption); // Set the selected city in the state
//                                 }}
//                                 value={cities1 || cities?.find((city) => city.value === values.team_city)}
//                                 isDisabled={!selectedCountry}
//                                 isLoading={loadingCities}
//                                 placeholder="Select a city"
//                               />
//                               <ErrorMessage
//                                 name="team_city"
//                                 component="div"
//                                 className="text-danger"
//                               />
//                             </div>
//                           </Col>

//                           <Col lg={4}>
//                             <Form.Group className="mb-3 form-inner">
//                               <Field
//                                 name="team_owner"
//                                 type="text"
//                                 className="form-control"
//                                 placeholder="Enter team owner name"
//                               />
//                               <ErrorMessage
//                                 name="team_owner"
//                                 component="div"
//                                 className="text-danger"
//                               />
//                             </Form.Group>
//                           </Col>

//                           <Col lg={4}>
//                             <div className="profile-card mb-3">
//                               <Select
//                                 name="team_league"
//                                 placeholder="Teams League"
//                                 options={optionsTeamLeague}
//                                 onChange={(option) =>
//                                   setFieldValue("team_league", option)
//                                 }
//                                 value={values.team_league}
//                               />
//                               <ErrorMessage
//                                 name="team_league"
//                                 component="div"
//                                 className="text-danger"
//                               />
//                             </div>
//                           </Col>

//                           <Col lg={4}>
//                             {" "}
//                             <div className="profile-card mb-3">
//                               <Select
//                                 name="annual_budget"
//                                 placeholder="Annual Budget"
//                                 options={optionsAnnualBudget}
//                                 onChange={(option) =>
//                                   setFieldValue("annual_budget", option)
//                                 }
//                                 value={values.annual_budget}
//                               />
//                               <ErrorMessage
//                                 name="annual_budget"
//                                 component="div"
//                                 className="text-danger"
//                               />
//                             </div>
//                           </Col>
//                           <Col lg={4}>
//                             {" "}
//                             <Form.Group className="mb-3 form-inner">
//                               <Field
//                                 name="team_president"
//                                 type="text"
//                                 className="form-control"
//                                 placeholder="Enter President name here  "
//                               />
//                               <ErrorMessage
//                                 name="team_president"
//                                 component="div"
//                                 className="text-danger"
//                               />
//                             </Form.Group>
//                           </Col>
//                         </Row>
//                       </div>
//                     </div>

//                     <div className="common-outer mt-4">
//                       <FieldArray name="carrier_trophies">
//                         {({ push, remove }) => (
//                           <>
//                             <div className="d-flex justify-content-between">
//                               <h3 className="h-20">Carrier Trophies</h3>
//                               <button
//                                 type="button"
//                                 onClick={() =>
//                                   push({ trophy: null, date_of_winning: "" })
//                                 }
//                                 style={{
//                                   border: "none",
//                                   background: "none",
//                                 }}
//                               >
//                                 <svg
//                                   width="28"
//                                   height="28"
//                                   viewBox="0 0 28 28"
//                                   fill="none"
//                                   xmlns="http://www.w3.org/2000/svg"
//                                 >
//                                   <path
//                                     d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
//                                     fill="#4C8BB4"
//                                   />
//                                 </svg>
//                               </button>
//                             </div>
//                             {values?.carrier_trophies?.map((trophy, index) => (
//                               <div key={index} className="cmn-form-fields mt-2">
//                                 <Row>
//                                   <Col lg={4}>
//                                     <div className="profile-card mb-3">
//                                       <Select
//                                         name={`carrier_trophies[${index}].trophy`}
//                                         placeholder="Tropies/Champanioship"
//                                         options={optionsTrophy}
//                                         isMulti
//                                         onChange={(option) =>
//                                           setFieldValue(
//                                             `carrier_trophies[${index}].trophy`,
//                                             option
//                                           )
//                                         }
//                                         value={
//                                           values.carrier_trophies[index]
//                                             .trophy || ""
//                                         }
//                                       />
//                                       <ErrorMessage
//                                         name={`carrier_trophies[${index}].trophy`}
//                                         component="div"
//                                         className="text-danger"
//                                       />
//                                     </div>
//                                   </Col>
//                                   <Col lg={4}>
//                                     <Form.Group className="mb-3 form-inner">
//                                       <Field
//                                         name={`carrier_trophies[${index}].date_of_winning`}
//                                         className="form-control"
//                                         placeholder="Date of Winning"
//                                         type={inputType1}
//                                         onFocus={() => setInputType1("date")}
//                                         onBlur={() => setInputType1("text")}
//                                       />
//                                       <ErrorMessage
//                                         name={`carrier_trophies[${index}].date_of_winning`}
//                                         component="div"
//                                         className="text-danger"
//                                       />
//                                     </Form.Group>
//                                   </Col>
//                                   <Col lg={4}>
//                                     {index !== 0 && (
//                                       <button
//                                         type="button"
//                                         onClick={() => remove(index)}
//                                         style={{
//                                           border: "none",
//                                           background: "none",
//                                         }}
//                                       >
//                                         <svg
//                                           width="28"
//                                           height="28"
//                                           viewBox="0 0 28 28"
//                                           fill="none"
//                                           xmlns="http://www.w3.org/2000/svg"
//                                         >
//                                           <path
//                                             fill-rule="evenodd"
//                                             clip-rule="evenodd"
//                                             d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
//                                             fill="#4C8BB4"
//                                           />
//                                         </svg>
//                                       </button>
//                                     )}
//                                   </Col>
//                                 </Row>
//                               </div>
//                             ))}
//                           </>
//                         )}
//                       </FieldArray>
//                     </div>

//                     <div className="common-outer mt-4">
//                       <div className="d-flex justify-content-between">
//                         <h3 className="h-20">Other Notes</h3>
//                       </div>
//                       <div className="cmn-form-fields ">
//                         <Row>
//                           <Col lg={12}>
//                             {" "}
//                             <Form.Group className="mb-3 form-inner">
//                               <Field
//                                 as="textarea"
//                                 name="info_other_notes"
//                                 rows={3}
//                                 className="form-control"
//                                 placeholder="Write here"
//                               />
//                               <ErrorMessage
//                                 name="info_other_notes"
//                                 component="div"
//                                 className="text-danger"
//                               />
//                             </Form.Group>
//                           </Col>
//                         </Row>
//                       </div>
//                     </div>
//                     <Col lg={12} className="mt-4 d-flex justify-content-end">
//                       <div className="cmn-light-btn">
//                         <button type="submit">Next</button>
//                       </div>
//                     </Col>
//                   </FormikForm>
//                 );
//               }}
//             </Formik>
//           </div>
//         </Container>
//       </section>
//     </LoginLayout>
//   );
// }

import React, { useEffect, useState } from "react";
import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  FieldArray,
} from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  staticDirectorDataAction,
  staticTeamDataAction,
} from "../Redux/Action/staticAction";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { addNewTeam } from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import PreviewModal from "../CommonModals/PreviewModal";
import ProgressBar from "../CommonModals/ProgressBar";
import Loader from "../components/Loader";
import { clearPlayerDetail } from "../Redux/Reducers/playerSlice";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  createTeamAction,
  editTeamAction,
  getTeamDataById,
  getTeamListAction,
} from "../Redux/Action/teamAction";
import {
  createCoachAction,
  editCoachAction,
  getCoachesDataById,
} from "../Redux/Action/coachAction";
import { Country, State } from "country-state-city";

Modal.setAppElement("#root");

const TeamMainInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ids = queryParams.get("teamId");
  const teamId = ids && Decryptedid(atob(ids));
  const staticListForTeam = useSelector(
    (e) => e.staticAuthData.teamStaticDetail
  );

  const [cities, setCities] = useState([]);
  const [cities1, setCities1] = useState([]);
  const [cityMap, setCityMap] = useState({});
  const [loadingCities, setLoadingCities] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTeamName, setNewTeamName] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [intialState, setIntialState] = useState({
    address: "",
    email: "",
    phone_number: "",
    web: "",
    fax: "",
    profile_image: "",
    team_name: "",
    team_city: "",
    team_country: "",
    team_president: "",
    team_owner: "",
    team_league: "",
    annual_budget: "",
    carrier_trophies: [],
    info_other_notes: "",
    instagram: "",
    facebook: "",
    snapchat: "",
    twitter: "",
    status: "",
    contact_person_phone_number: "",
    conatct_person_name: "",
  });

  const playerDataLoadingState = useSelector((e) => e.playerAuthData.loading);
  const teanListingData = useSelector((e) => e.teamAuthData.getTeamListing);
  const getTeamDetails = useSelector((e) => e.teamAuthData.getTeamDetailById);

  const [teamOptions, setTeamOptions] = useState(
    teanListingData?.map((elem) => ({
      label: elem?.team_name,
      value: elem?._id,
      data: elem,
    })) || []
  );

  const optionsPlaceOfBirth = staticListForTeam?.place_of_birth?.map(
    (item) => ({
      label: item,
      value: item,
    })
  );

  const optionsNationaityId = staticListForTeam?.previous_status?.map(
    (item, i) => ({
      label: item?.val,
      value: i,
    })
  );

  const optionsTeamLeague = staticListForTeam?.team_league?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsTrophy = staticListForTeam?.trophy?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsAnnualBudget = staticListForTeam?.annual_budget?.map((item) => ({
    label: item?.val,
    value: item?.val,
  }));

  const customOptions = [
    { label: "+ Add new Team", value: "add-team", isAddTeam: true },
    ...teamOptions,
  ];

  useEffect(() => {
    if (teamId) {
      dispatch(getTeamDataById({ id: teamId, type: 1 })).then((res) => {
        if (res?.payload?.status === 200) {
          let data = res?.payload?.data;
          let countryData = Country.getAllCountries().find(
            (country) => country.name === data.team_country
          );
          if (countryData) {
            setSelectedCountry({
              value: countryData.isoCode,
              label: countryData.name,
            });
          }
        }
      });
    }
  }, [dispatch, teamId]);

  const fetchCities = async (countryIsoCode) => {
    setLoadingCities(true);
    try {
      const stateList = State.getStatesOfCountry(countryIsoCode);
      const cityMap = {};

      const cities = stateList.map((state) => {
        cityMap[state.isoCode] = state.name;
        return {
          value: state.name,
          label: state.name,
        };
      });

      setCities(cities); // Update cities in the state
      setCityMap(cityMap); // Update the city map
    } catch (error) {
      console.error("Error fetching cities:", error);
    } finally {
      setLoadingCities(false);
    }
  };

  const handleCountryChange = async (
    selectedOption,
    { setFieldValue, setFieldTouched }
  ) => {
    if (!selectedOption) return;

    const countryData = Country.getAllCountries().find(
      (country) => country.isoCode === selectedOption.value
    );

    if (countryData) {
      setSelectedCountry({
        value: countryData.isoCode,
        label: countryData.name,
      });

      setFieldValue("team_country", countryData.name); // Set the selected country
      setFieldValue("team_city", ""); // Reset city when country changes
      setCities([]); // Clear cities before fetching new ones
      await fetchCities(countryData.isoCode); // Fetch cities based on the country
      setCities1(null); // Reset selected city
      setFieldTouched("team_country", false); // Mark country field as untouched
    }
  };

  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(
      countryList.map((country) => ({
        value: country.isoCode,
        label: country.name,
      }))
    );
  }, []);

  useEffect(() => {
    if (getTeamDetails?.team_country) {
      const countryOption = countries.find(
        (country) => country.label === getTeamDetails?.team_country
      );
      setSelectedCountry(countryOption || null);

      if (countryOption) {
        fetchCities(countryOption.value);
      }
    }
  }, [getTeamDetails, countries]);

  useEffect(() => {
    if (getTeamDetails?.team_city) {
      const selectedCityData = cities?.find(
        (city) => city.label === getTeamDetails.team_city
      );

      if (selectedCityData) {
        setCities1(selectedCityData);
      }
    }
  }, [cities, getTeamDetails]);

  useEffect(() => {
    const teamOptions = teanListingData?.map((elem) => ({
      label: elem?.team_name,
      value: elem?._id,
      data: elem,
    }));
    setTeamOptions(teamOptions);
  }, [teanListingData]);

  useEffect(() => {
    dispatch(staticTeamDataAction());
  }, []);

  const handleFileChange1 = (e, setFieldValue, fieldName) => {
    const file = e.target.files[0];
    setFieldValue(fieldName, file ? file : null);
  };

  useEffect(() => {
    dispatch(clearPlayerDetail());
    if (teamId) {
      dispatch(getTeamDataById({ id: teamId, type: 1 }));
    }
    dispatch(getTeamListAction());
  }, [dispatch, teamId]);

  const handleShowPreview = (imageSrc) => {
    setPreviewImage(imageSrc);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setPreviewImage("");
  };

  const handleDrop = (e, setFieldValue, type, values) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files);
    if (type === "profile_image") {
      const imageFile = files.find((file) => file.type.startsWith("image/"));

      if (!imageFile) {
        console.error("Only image files are allowed!");
        return;
      }
      setFieldValue("profile_image", imageFile);
    }
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleAddTeam = (values, setFieldValue) => {
    if (!newTeamName.trim()) {
      toast.error("Team name cannot be empty.");
      return;
    }

    let teamCountry = values?.team_country?.value;
    let teamLeague = values?.team_league?.value;

    if (!teamCountry) {
      toast.error("Please select a team country.");
      return;
    }

    if (!teamLeague) {
      toast.error("Please select a team league.");
      return;
    }

    dispatch(
      addNewTeam({
        name: newTeamName,
        type: 1,
        team_country: teamCountry,
        team_league: teamLeague,
      })
    ).then((res) => {
      if (res?.payload?.status === 200) {
        const newTeamId = res?.payload?.data?._id;
        const newTeam = { label: newTeamName, value: newTeamId };

        setTeamOptions([...teamOptions, newTeam]);
        setFieldValue("team_id", newTeam);

        setTimeout(() => {
          setIsModalOpen(false);
          setNewTeamName("");
        }, 0);
      } else {
        toast.error("Failed to add the team. Please try again.");
      }
    });
  };

  const handleInputChange = (input) => {
    const filteredInput = input.replace(/[^a-zA-Z\s]/g, "");
    const isTeamExists = teamOptions?.some((team) =>
      team?.label?.toLowerCase()?.includes(filteredInput.toLowerCase())
    );
    setIsAddingTeam(!isTeamExists);
    return filteredInput;
  };

  const handleSelectChange = (option, setFieldValue) => {
    if (option?.isAddTeam) {
      setIsModalOpen(true);
    } else {
      setFieldValue(
        "team_country",
        optionsPlaceOfBirth?.find(
          (elem) => elem.value === option?.data?.team_country
        )
      );
      setFieldValue(
        "team_league",
        optionsTeamLeague?.find(
          (elem) => elem.value == option?.data?.team_league
        )
      );
      setFieldValue("team_id", option);
    }
  };

  const handleSaveAndClose = (values, validateForm, setErrors) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length > 0) {
        toast.error("Please fill in all required fields.");
        setErrors(errors);
        return;
      }
      handleSubmit(values, "save");
    });
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.address) {
      errors.address = "required";
    }
    if (!values.email) {
      errors.email = "required";
    }
    if (!values.phone_number) {
      errors.phone_number = "required";
    }
    // if (!values.profile_image) {
    //   errors.profile_image = "required";
    // }

    if (!values.status) {
      errors.status = "required";
    }
    if (!values.contact_person_phone_number) {
      errors.contact_person_phone_number = "required";
    }
    if (!values.conatct_person_name) {
      errors.conatct_person_name = "required";
    }

    if (!values.annual_budget) {
      errors.annual_budget = "required";
    }

    if (!values.team_league) {
      errors.team_league = "required";
    }

    if (!values.team_name) {
      errors.team_name = "required";
    } else if (values.team_name.length < 2) {
      errors.team_name = "Must be at least 2 characters long";
    }

    if (!values.team_country) {
      errors.team_country = "required";
    }

    if (!values.team_city) {
      errors.team_city = "required";
    }

    if (!values.team_president) {
      errors.team_president = "required";
    }

    if (!values.team_owner) {
      errors.team_owner = "required";
    }

    if (!values.fax) {
      errors.fax = "required";
    }

    if (!values.web) {
      errors.web = "required";
    }

    if (!values.carrier_trophies || values.carrier_trophies.length === 0) {
      errors.carrier_trophies = "required";
    }

    return errors;
  };

  const groupTrophiesByDate = (carrierTrophies, optionsTrophy) => {
    const groupedTrophies = [];

    carrierTrophies.forEach((elem) => {
      const existingGroup = groupedTrophies.find(
        (group) => group.date_of_winning === elem.date_of_winning.split("T")[0]
      );

      if (existingGroup) {
        existingGroup.trophy.push(
          optionsTrophy?.find((option) => option.value === elem.trophy)
        );
      } else {
        groupedTrophies.push({
          trophy: [
            optionsTrophy?.find((option) => option.value === elem.trophy),
          ],
          date_of_winning: elem.date_of_winning.split("T")[0],
        });
      }
    });

    return groupedTrophies.length > 0
      ? groupedTrophies
      : [{ trophy: null, date_of_winning: "" }];
  };

  const initialValues = {
    address: getTeamDetails?.address || "",
    email: getTeamDetails?.email || "",
    phone_number: getTeamDetails?.phone_number || "",
    web: getTeamDetails?.web || "",
    fax: getTeamDetails?.fax || "",

    instagram: getTeamDetails?.instagram || "",
    facebook: getTeamDetails?.facebook || "",
    snapchat: getTeamDetails?.snapchat || "",
    twitter: getTeamDetails?.twitter || "",

    status: getTeamDetails?.status || "",
    contact_person_phone_number: getTeamDetails?.contact_person_phone_number || "",
    conatct_person_name:  getTeamDetails?.conatct_person_name || "",

    profile_image: getTeamDetails?.profile_image || "",
    team_name: getTeamDetails?.team_name || "",
    team_city: getTeamDetails?.team_city || "",
    team_country: getTeamDetails?.team_country || "",
    team_president: getTeamDetails?.team_president || "",
    team_owner: getTeamDetails?.team_owner || "",
    team_league:
      optionsTeamLeague?.find(
        (elem) => elem.value === getTeamDetails?.team_league
      ) || "",
    annual_budget:
      optionsAnnualBudget?.find(
        (elem) => elem.value === getTeamDetails?.annual_budget
      ) || "",
    carrier_trophies:
      getTeamDetails?.carrier_trophies
        ?.map((trophyObj) => {
          return (
            optionsTrophy?.find((option) => option.value === trophyObj) || null
          );
        })
        .filter((option) => option !== null) || [],

    info_other_notes: getTeamDetails?.info_other_notes || "",
  };

  console.log(getTeamDetails, "dddddddddddd");

  const processFormData = (values) => {
    return {
      ...values,
      team_league: values.team_league?.value || 0,
      team_city: values.team_city || "",
      annual_budget: values.annual_budget?.value || "",
    };
  };

  const handleSubmit = (values, type) => {
    console.log("values", values);
    const processedValues = processFormData(values);
    console.log(processedValues, "values");
    if (teamId) {
      const formData = new FormData();

      Object.entries(processedValues).forEach(([key, value]) => {
        if (key === "carrier_trophies" && Array.isArray(value)) {
          value.forEach((trophy, index) => {
            if (trophy && typeof trophy === "object") {
              formData.append(`${key}[${index}]`, trophy.value);
            }
          });
        } else if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      });

      formData.append("teamId", teamId);
      formData.append("is_profile_completed", 1);

      dispatch(editTeamAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          if (type === "save") {
            navigate(`/my-team`);
          } else {
            navigate(`/add-team-two/${Encryptedid(res?.payload?.data?._id)}`);
          }
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } else {
      const formData = new FormData();
      console.log(
        processedValues,
        Array.isArray(processedValues.carrier_trophies),
        "ffffffffffffffff"
      );

      Object.entries(processedValues).forEach(([key, value]) => {
        if (key === "carrier_trophies" && Array.isArray(value)) {
          value.forEach((trophy, index) => {
            if (trophy && typeof trophy === "object") {
              formData.append(`${key}[${index}]`, trophy.value);
            }
          });
        } else if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      });

      formData.append("is_profile_completed", 1);

      dispatch(createTeamAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          if (type === "save") {
            navigate(`/my-team`);
          } else {
            navigate(`/add-team-two/${Encryptedid(res?.payload?.data?._id)}`);
          }
        } else {
          toast.error(res?.payload?.message);
        }
      });
    }
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <ProgressBar currentStep={1} totalSteps={5} />
          <div className="steps-heading">
            <p>
              {" "}
              <span>Step 1:</span> Team's Main Information{" "}
            </p>
          </div>
          <div className="over-ride-cmn">
            <Formik
              initialValues={teamId ? initialValues : intialState}
              validate={validateForm}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({
                values,
                setFieldValue,
                validateForm,
                setErrors,
                setFieldTouched,
              }) => (
                <FormikForm>
                  {playerDataLoadingState && <Loader />}
                  <div className="personal-heading mt-2">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Team's General Information</h3>
                    </div>

                    <div className="cmn-form-fields team-grid-system mt-2">
                      <Form.Group className="mb-3 form-inner">
                        <div className="label-text-up">
                          <label className="mb-2">Team's Name</label>
                          <Field
                            name="team_name"
                            type="text"
                            className="form-control"
                            placeholder="Enter name here"
                            onKeyPress={(event) => {
                              const regex = /^[a-zA-Z\s]*$/;
                              if (!regex.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onPaste={(event) => {
                              const pastedData =
                                event.clipboardData.getData("Text");
                              const regex = /^[a-zA-Z\s]*$/;
                              if (!regex.test(pastedData)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>

                        <ErrorMessage
                          name="team_name"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <div className="profile-card mb-2 label-text-up">
                        <label className="mb-2">Team's Country</label>
                        <Select
                          name="team_country"
                          placeholder="Select"
                          classNamePrefix="custom-select"
                          isSearchable={true}
                          value={selectedCountry}
                          onChange={(e) =>
                            handleCountryChange(e, {
                              setFieldValue,
                              setFieldTouched,
                            })
                          }
                          options={countries}
                        />
                        <ErrorMessage
                          name="team_country"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      {/* Team City */}

                      <div className="profile-card mb-2 label-text-up">
                        <label className="mb-2">Team's City</label>
                        <Select
                          // name="team_city"
                          // placeholder="Team's City"
                          // options={cities}
                          // onChange={(option) => {
                          //   setFieldValue("team_city", option);
                          //   setCities1(option);
                          // }}
                          // value={cities1 || ""}
                          // isLoading={loadingCities}
                          // isDisabled={!selectedCountry}
                          options={cities}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "team_city",
                              selectedOption?.value || ""
                            );
                            setCities1(selectedOption); // Set the selected city in the state
                          }}
                          value={
                            cities1 ||
                            cities?.find(
                              (city) => city.value === values.team_city
                            )
                          }
                          isDisabled={!selectedCountry}
                          isLoading={loadingCities}
                          placeholder="Select"
                        />
                        <ErrorMessage
                          name="team_city"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="cmn-form-fields team-grid-system  ">
                      <div className="profile-card mb-2 label-text-up">
                        <label className="mb-2">Annual Budget</label>
                        <Select
                          name="annual_budget"
                          placeholder="Select"
                          classNamePrefix="custom-select"
                          options={optionsAnnualBudget}
                          onChange={(option) =>
                            setFieldValue("annual_budget", option)
                          }
                          value={values.annual_budget}
                        />
                        <ErrorMessage
                          name="annual_budget"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Team's Owner</label>
                        <Field
                          name="team_owner"
                          type="text"
                          className="form-control "
                          placeholder="Enter "
                        />
                        <ErrorMessage
                          name="team_owner"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Team's President</label>
                        <Field
                          name="team_president"
                          type="text"
                          className="form-control"
                          placeholder="Enter"
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="team_president"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="cmn-form-fields team-grid-system  ">
                      <div className="profile-card mb-2 label-text-up">
                        <label className="mb-2">Team's League</label>
                        <Select
                          name="team_league"
                          placeholder="Select"
                          classNamePrefix="custom-select"
                          options={optionsTeamLeague}
                          onChange={(option) =>
                            setFieldValue("team_league", option)
                          }
                          value={values.team_league}
                        />
                        <ErrorMessage
                          name="team_league"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className=" mt-4 mb-3">
                      <div className="trophies ">
                        <div className="d-flex justify-content-between">
                          <h3 className="h-20">Team's Trophies</h3>
                        </div>
                        <div className="profile-card mb-3 profile-width">
                          <Select
                            name="carrier_trophies"
                            placeholder="Select"
                            classNamePrefix="custom-select"
                            options={optionsTrophy}
                            onChange={(selectedOptions) => {
                              const isNotYetSelected = selectedOptions.some(
                                (option) => option.value == 6
                              );
                              if (isNotYetSelected) {
                                setFieldValue(`carrier_trophies`, [
                                  {
                                    label: "Not Yet",
                                    value: 6,
                                  },
                                ]);
                              } else {
                                setFieldValue(
                                  `carrier_trophies`,
                                  selectedOptions
                                );
                              }
                            }}
                            isMulti
                            // onChange={(selectedOptions) => setFieldValue('carrier_trophies', selectedOptions)}
                            value={values.carrier_trophies}
                          />
                        </div>
                        <ErrorMessage
                          name="carrier_trophies"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="d-flex justify-content-between">
                        <h3 className="h-20">Contact Information</h3>
                      </div>
                      <div className=" mt-2">
                        <Row>
                          <Col lg={4}>
                            <Form.Group className="form-inner label-text-up">
                              <label className="mb-2">Phone Number</label>
                              <Field
                                name="phone_number"
                                as={Form.Control}
                                type="number"
                                placeholder="Enter phone number"
                              />
                              <ErrorMessage
                                name="phone_number"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4} className=" ">
                            <Form.Group className="form-inner label-text-up">
                              <label className="mb-2">Fax</label>
                              <Field
                                name="fax"
                                as={Form.Control}
                                type="text"
                                placeholder="Enter fax"
                              />
                              <ErrorMessage
                                name="fax"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="form-inner label-text-up">
                              <label className="mb-2">Email</label>

                              <Field
                                name="email"
                                as={Form.Control}
                                type="email"
                                placeholder="Enter email here"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="form-inner mt-3 label-text-up">
                              <label className="mb-2">Address</label>
                              <Field
                                name="address"
                                as={Form.Control}
                                type="text"
                                placeholder="Enter address"
                              />
                              <ErrorMessage
                                name="address"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4} className="mt-3">
                            <Form.Group className="form-inner label-text-up">
                              <label className="mb-2">Web</label>
                              <Field
                                name="web"
                                as={Form.Control}
                                type="text"
                                placeholder="Enter web"
                              />
                              <ErrorMessage
                                name="web"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    {/* Contact Person */}
                    <div className=" mt-3 bottom-lines pb-4">
                      <h4 className="h-20">Team's Contact person</h4>
                      <Row>
                        <Col lg={4}>
                          <Form.Group className="form-inner label-text-up ">
                            <label className="mb-2">
                              Contact person's name
                            </label>

                            <Field
                              name="conatct_person_name"
                              as={Form.Control}
                              type="text"
                              placeholder="Enter name"
                            />
                            <ErrorMessage
                              name="conatct_person_name"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group className="form-inner label-text-up">
                            <label className="mb-2">Status</label>

                            <Field
                              name="status"
                              as={Form.Control}
                              type="text"
                              placeholder="Enter status"
                            />
                            <ErrorMessage
                              name="status"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group className="form-inner label-text-up">
                            <label className="mb-2">Contact</label>
                            <Field
                              name="contact_person_phone_number"
                              as={Form.Control}
                              type="number"
                              placeholder="Enter phone number"
                            />
                            <ErrorMessage
                              name="contact_person_phone_number"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    {/* Social Media links */}
                    <div className=" mt-3 bottom-lines pb-4">
                      <div className="d-flex justify-content-between">
                        <h3 className="h-20">Social Media Links</h3>
                      </div>
                      <Row>
                        <Col lg={3}>
                          <Form.Group className="form-inner label-text-up">
                            <label className="mb-2">Instagram</label>
                            <Field
                              name="instagram"
                              as={Form.Control}
                              type="url"
                              placeholder="Enter"
                            />
                            <ErrorMessage
                              name="instagram"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={3}>
                          <Form.Group className="form-inner label-text-up">
                            <label className="mb-2">Facebook</label>
                            <Field
                              name="facebook"
                              as={Form.Control}
                              type="url"
                              placeholder="Enter"
                            />
                            <ErrorMessage
                              name="facebook"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={3}>
                          <Form.Group className="form-inner label-text-up">
                            <label className="mb-2">Snapchat</label>

                            <Field
                              name="snapchat"
                              as={Form.Control}
                              type="url"
                              placeholder="Enter"
                            />
                            <ErrorMessage
                              name="snapchat"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={3} className="">
                          <Form.Group className="form-inner label-text-up">
                            <label className="mb-2">X</label>
                            <Field
                              name="twitter"
                              as={Form.Control}
                              type="url"
                              placeholder="Enter"
                            />
                            <ErrorMessage
                              name="twitter"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    <Row className="mt-4">
                      <Col lg={6}>
                        <div className="vidoe-link-box">
                          <div className="box-flex-up link-videos  mb-3">
                            <div className="players-cv">
                              <div className="player-up">
                                <h3>Team's Logo</h3>
                                <div
                                  className={`uplode-the-img ${
                                    dragging ? "dragging" : ""
                                  }`}
                                  onDrop={(e) =>
                                    handleDrop(
                                      e,
                                      setFieldValue,
                                      "profile_image"
                                    )
                                  }
                                  onDragOver={handleDragOver}
                                  onDragLeave={handleDragLeave}
                                >
                                  <div className="upload-and-drop">
                                    <div className="click-to-up">
                                      <label htmlFor="file-upload">
                                        <button type="button">
                                          Click to Upload
                                          <Field name="profile_image">
                                            {({ field }) => (
                                              <input
                                                type="file"
                                                id="file-upload"
                                                onChange={(e) =>
                                                  handleFileChange1(
                                                    e,
                                                    setFieldValue,
                                                    "profile_image"
                                                  )
                                                }
                                                accept="image/*"
                                              />
                                            )}
                                          </Field>
                                        </button>
                                      </label>
                                    </div>
                                    <div className="drag-drop">
                                      <p>
                                        <span>
                                          <img
                                            src={
                                              require("../Assets/images/drag-and-drop.svg")
                                                .default
                                            }
                                            alt="Upload Placeholder"
                                          />
                                        </span>
                                        Or drag and drop files.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="preview">
                                    <p>Preview</p>
                                    <div className="preview-content">
                                      {values?.profile_image &&
                                        (typeof values.profile_image ===
                                        "string" ? (
                                          <img
                                            className="inner-view"
                                            src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                            alt="Profile Preview"
                                            onClick={() =>
                                              handleShowPreview(
                                                `${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`
                                              )
                                            }
                                          />
                                        ) : values.profile_image instanceof
                                          File ? (
                                          <img
                                            src={URL.createObjectURL(
                                              values?.profile_image
                                            )}
                                            className="prev-img"
                                            alt="Profile Preview"
                                            style={{ width: "50px" }}
                                            onClick={() =>
                                              handleShowPreview(
                                                URL.createObjectURL(
                                                  values?.profile_image
                                                )
                                              )
                                            }
                                          />
                                        ) : null)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="carrier-notes">
                          <div className="cmn-form-fields ">
                            <div className="all-notes ">
                              <h3 className="h-20">Other Notes</h3>
                              <div className="cmn-form-fields">
                                <Form.Group className="mb-3 form-inner">
                                  <Field
                                    as="textarea"
                                    name="info_other_notes"
                                    rows={2}
                                    className="form-control"
                                    placeholder="Write notes here"
                                  />
                                  <ErrorMessage
                                    name="info_other_notes"
                                    component="div"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="d-flex justify-content-end mb-5">
                    <div className="mt-4 ">
                      <div className="cmn-light-btn save-and-close-btn">
                        <button
                          type="button"
                          onClick={() =>
                            handleSaveAndClose(values, validateForm, setErrors)
                          }
                          className="next-btn-ps"
                        >
                          {" "}
                          Save & Close{" "}
                        </button>
                      </div>
                    </div>

                    <div className="mt-4 ">
                      <div className="cmn-light-btn">
                        <button type="submit" className="next-btn-ps">
                          {" "}
                          Save & Next{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
      <PreviewModal
        show={showPreview}
        onHide={handleClosePreview}
        previewImage={previewImage}
      />
    </LoginLayout>
  );
};

export default TeamMainInfo;
