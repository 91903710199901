import React from "react";
import { Tab, Tabs, Accordion, Row, Col } from "react-bootstrap";
import {
  coachinThisTeam,
  coachProposedtoThisTeam,
  directorInThisTeam,
  myPlayerInTeam,
  playerProposedtoMe,
  playerProposedtoteam,
  playerWantedByTeam,
  teamMainInformation,
  teamsInterestedInDirector,
  teamsProposedDirector,
} from "../utils/rawjson";

const TeamFilterTab = ({
  renderSelectTeam,
  renderSelectTeam1,
  renderSelectTeam2,
  renderSelectTeam3,
  renderSelectTeam8,
  renderSelectTeam4,
  renderSelectTeam5,
  renderSelectTeam6,
  renderSelectTeam7,
  handleSearchForTeam,
}) => {
  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Team Main Information</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(teamMainInformation).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectTeam(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>My Player in this team</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(myPlayerInTeam).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectTeam1(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        {/* <Accordion.Item eventKey="2">
          <Accordion.Header>Players wanted by this team</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(playerWantedByTeam).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectTeam2(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item> */}

        <Accordion.Item eventKey="3">
          <Accordion.Header>
            Players I have proposed to this team
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(playerProposedtoteam).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectTeam3(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8">
          <Accordion.Header>
          Players proposed to me by Team
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(playerProposedtoMe).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectTeam8(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>My Director in this team</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(directorInThisTeam).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectTeam4(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>
            Directors I have proposed to this Team
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(teamsProposedDirector).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectTeam5(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>My Coach in this Team</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(coachinThisTeam).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectTeam6(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header>
            Coaches I have proposed to this Team
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(coachProposedtoThisTeam).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelectTeam7(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Row>
        <Col lg={12} className="mt-4 d-flex justify-content-end">
          <div className="cmn-light-btn">
            <button type="button" onClick={handleSearchForTeam}>
              Search
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TeamFilterTab;
