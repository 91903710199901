import React, { useState, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import api from '../Service/api';

const AgentModalComp = ({ showAgent, setShowAgent, onAgentSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (query.length > 2) { 
      try {
        const response = await api.get(`/user/agentSearch?word=${query}`);
        setAgents(response?.data?.data);
      } catch (error) {
        console.error('Error fetching agents:', error);
        setAgents([]);
      }
    } else {
      setAgents([]);
    }
  };

  console.log(agents, "fgddddddddddddddd")

  useEffect(() => {
    if (!showAgent) {
      setSearchQuery('');
      setAgents([]);
      setSelectedAgent(null);
    }
  }, [showAgent]);

  const handleAgentSelect = (agent) => {
    setSelectedAgent(agent);
    if (onAgentSelect) {
      onAgentSelect(agent); 
    }
    setShowAgent(false); 
  };

  return (
    <Modal
      className="cmn-modal"
      size="lg"
      show={showAgent}
      onHide={() => setShowAgent(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title>Choose Agent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="choose-agent">
          <div className="search-input">
            <img src={require("../Assets/images/search.svg").default} />
            <input
              type="search"
              placeholder="Search here"
              className="w-100"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <div className="agents-main mt-4">
            <Row>
              {agents.length > 0 ? (
                agents.map((agent) => (
                  <Col lg={6} className="mb-2" key={agent._id}>
                    <div className="play-inner-box add-new-bg-shadow">
                      <div className="d-flex">
                        <img src={agent?.profile_image ? `${process.env.REACT_APP_IMAGE_PATH}${agent?.profile_image}` : require("../Assets/images/My Associate Agent male.png")} />
                        <div className="ms-3">
                          <h4>{agent.full_name}</h4>
                          <p>
                            Agent ID: <span>{agent?.unique_id}</span>
                          </p>
                        </div>
                      </div>
                      <button
                        type="button"
                        style={{ background: "none", border: "none" }}
                        onClick={() => handleAgentSelect(agent)}
                      >
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.5 0C10.0277 0 7.61099 0.733112 5.55538 2.10663C3.49976 3.48015 1.89761 5.43238 0.951511 7.71645C0.0054161 10.0005 -0.242126 12.5139 0.24019 14.9386C0.722505 17.3634 1.91301 19.5907 3.66117 21.3388C5.40933 23.087 7.63661 24.2775 10.0614 24.7598C12.4861 25.2421 14.9995 24.9946 17.2835 24.0485C19.5676 23.1024 21.5199 21.5002 22.8934 19.4446C24.2669 17.389 25 14.9723 25 12.5C24.9961 9.18599 23.6779 6.00884 21.3345 3.66548C18.9912 1.32213 15.814 0.00390963 12.5 0ZM18.1818 13.6364H13.6364V18.1818C13.6364 18.4832 13.5166 18.7722 13.3035 18.9853C13.0904 19.1985 12.8014 19.3182 12.5 19.3182C12.1986 19.3182 11.9096 19.1985 11.6965 18.9853C11.4834 18.7722 11.3636 18.4832 11.3636 18.1818V13.6364H6.81819C6.5168 13.6364 6.22777 13.5166 6.01466 13.3035C5.80155 13.0904 5.68182 12.8014 5.68182 12.5C5.68182 12.1986 5.80155 11.9096 6.01466 11.6965C6.22777 11.4834 6.5168 11.3636 6.81819 11.3636H11.3636V6.81818C11.3636 6.5168 11.4834 6.22776 11.6965 6.01465C11.9096 5.80154 12.1986 5.68182 12.5 5.68182C12.8014 5.68182 13.0904 5.80154 13.3035 6.01465C13.5166 6.22776 13.6364 6.5168 13.6364 6.81818V11.3636H18.1818C18.4832 11.3636 18.7722 11.4834 18.9854 11.6965C19.1985 11.9096 19.3182 12.1986 19.3182 12.5C19.3182 12.8014 19.1985 13.0904 18.9854 13.3035C18.7722 13.5166 18.4832 13.6364 18.1818 13.6364Z"
                            fill="#103D56"
                          />
                        </svg>
                      </button>
                    </div>
                  </Col>
                ))
              ) : (
                <Col>
                  <p className="text-center">
                    {searchQuery.length > 2 
                      ? "No agents found" 
                      : "Type at least 3 characters to search"}
                  </p>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AgentModalComp;