import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";

// create Coach
export const createCoachAction = createAsyncThunk(
  "createCoachAction/user/createCoach",
  async (data) => {
    const response = await api.post("/user/createCoach", data);
    return response.data;
  }
);

// edit coach
export const editCoachAction = createAsyncThunk(
  "editCoachAction/user/editCoach",
  async (data) => {
    const response = await api.put("/user/editCoach", data);
    return response.data;
  }
);

// get coaches detail by id
export const getCoachesDataById = createAsyncThunk(
  "getCoachesDataById/user/getCoachById",
  async ({ id, type, shopId }, thunkAPI) => {
    const response = await api.get(`user/getCoachById`, {
      params: {
        coachId: id,
        type,
      },
    });
    return response.data;
  }
);

// get  coach list
export const getCoachListAction = createAsyncThunk(
  "getCoachListAction/user/getMyCoaches",
  async (data) => {
    const response = await api.get("user/getMyCoaches", data);
    return response.data;
  }
);
