// token
// export const getToken = () => {
//   const token = localStorage.getItem("token");
//   return token ? token : null;
// };

export const getToken = () => {
  const token = localStorage.getItem("token");
  const token1 = localStorage.getItem("token1");

  if (token1) {
    return token1;
  } else if (token) {
    return token;
  } else {
    return null;
  }
};

export const today = new Date().toISOString().split("T")[0];

export const getYesterday = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  return today.toISOString().split("T")[0];
};

export const dateOfBirth = (dob) => {
  if (!dob) return "N/A";

  const date = new Date(dob);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const calculateAge = (dob) => {
  if (!dob || isNaN(new Date(dob).getTime())) {
    return "";
  }
  const birthDate = new Date(dob);
  const age = new Date().getFullYear() - birthDate.getFullYear();
  return age;
};

export const capitalize = (name) => {
  if (!name) return "";

  return name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const mapOptions = (data, labelKey = "val", valueKey = "id") => {
  return data?.map((item) => ({
    label: item[labelKey],
    value: item[valueKey],
  }));
};

export const mapSimpleOptions = (data) => {
  return data?.map((item) => ({
    label: item,
    value: item,
  }));
};

export const formatTime = (time24) => {
  if (!time24) return "";

  const [hour, minute] = time24.split(":");
  let hour12 = parseInt(hour, 10);
  const ampm = hour12 >= 12 ? "PM" : "AM";

  hour12 = hour12 % 12 || 12;
  return `${hour12}:${minute} ${ampm}`;
};


export const constructQueryString = (obj) => {
  if (obj) {
    const queryString = Object.keys(obj)
      .filter(
        (key) => obj[key] !== "" && obj[key] !== null && obj[key] !== undefined
      )
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      )
      .join("&");
    return queryString;
  } else {
    return "";
  }
};
