import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import {
  createOtherAgentAction,
  editOtherAgentAction,
  getOtherAgentDataById,
} from "../Redux/Action/agentAction";
import { staticOtherAgentDataAction } from "../Redux/Action/staticAction";
import PreviewModal from "../CommonModals/PreviewModal";
import { Decryptedid } from "../utils/Becrypt";

export default function AddOtherAgent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ids = queryParams.get("otherId");
  const otherId = ids && Decryptedid(atob(ids));

  const staticListForOtherAgent = useSelector(
    (e) => e.staticAuthData.otherAgentStaticDetail
  );

  console.log(staticListForOtherAgent, "staticListForOtherAgent");

  const getOtherAgentDetailById = useSelector(
    (e) => e.agentAuthData.otherAgentDetail
  );

  const validationSchema = Yup.object().shape({
    // profile_image: Yup.mixed().required("required"),
    // banner_image: Yup.mixed().required("required"),

    associate_name: Yup.mixed().required("required"),
    email: Yup.string().email("Invalid email format").required("required"),
    // country: Yup.string().required("required"),
    // role: Yup.string().required("required"),
    phone_number: Yup.string().required("required"),
  });

  const handleSubmit = (values) => {
    console.log("values", values);
    const {
      role: { value: roleValue },
      country: { value: countryValue },
      profile_image,
      // banner_image,
      associate_name,
      doctor_speciality,
      phone_number,
      team,
      other_speciality,
      email,
    } = values;

    const dataToSend = {
      role: roleValue,
      country: countryValue,
      associate_name,
      profile_image,
      // banner_image,
      doctor_speciality,
      phone_number,
      team,
      other_speciality,
      email,
    };

    console.log("dataToSend", dataToSend);

    let otherAgentId = localStorage.getItem("otherAgentId");

    if (otherId) {
      const formData = new FormData();

      Object.entries(dataToSend).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("otherAgentId", otherId);

      dispatch(editOtherAgentAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Success");
          navigate(`/other-associate-agent`);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } else {
      const formData = new FormData();

      Object.entries(dataToSend).forEach(([key, value]) => {
        formData.append(key, value);
      });

      dispatch(createOtherAgentAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success("Success");
          navigate(`/other-associate-agent`);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    }
  };

  const handleFileChange = (e, setFieldValue, fieldName) => {
    const file = e.target.files[0];
    setFieldValue(fieldName, file ? file : null);
  };

  const optionsCountry = staticListForOtherAgent?.country?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsRole = staticListForOtherAgent?.role?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  console.log("optionsRole", optionsRole);

  const initialValues = {
    profile_image: getOtherAgentDetailById?.profile_image || "",
    // banner_image: getOtherAgentDetailById?.banner_image || "",
    associate_name: getOtherAgentDetailById?.associate_name || "",
    country:
      optionsCountry?.find(
        (elem) => elem.value === getOtherAgentDetailById?.country
      ) || [],
    phone_number: getOtherAgentDetailById?.phone_number || "",
    team: getOtherAgentDetailById?.team || "",
    other_speciality: getOtherAgentDetailById?.other_speciality || "",
    doctor_speciality: getOtherAgentDetailById?.doctor_speciality || "",
    role:
      optionsRole?.find(
        (elem) => elem.value === getOtherAgentDetailById?.role
      ) || [],
    email: getOtherAgentDetailById?.email || "",
  };

  useEffect(() => {
    // let otherAgentId = localStorage.getItem("otherAgentId");
    if (otherId) {
      dispatch(getOtherAgentDataById({ id: otherId }));
    }
  }, [dispatch, otherId]);

  useEffect(() => {
    dispatch(staticOtherAgentDataAction());
  }, []);

  const [dragging, setDragging] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handleShowPreview = (imageSrc) => {
    setPreviewImage(imageSrc);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setPreviewImage("");
  };

  const handleDrop = (e, setFieldValue, type, values) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files);
    if (type === "profile_image") {
      const imageFile = files.find((file) => file.type.startsWith("image/"));

      if (!imageFile) {
        console.error("Only image files are allowed!");
        return;
      }
      setFieldValue("profile_image", imageFile);
    }
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleFileChange1 = (e, setFieldValue, fieldName) => {
    const file = e.target.files[0];
    setFieldValue(fieldName, file ? file : null);
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2> Other Associate's Main Information </h2>
            </div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, setFieldTouched, errors }) => {
                return (
                  <FormikForm>
                    {console.log(errors, "Dfgdfgdfgdfgdf")}
                    {/* <div className="common-outer">
                      <Row>
                        <Col lg={4}>
                          <div className="image-upload-main">
                            <h3>Logo</h3>
                            <div className="image-upload-inner">
                              <div className="image-upload-placeholder">
                                <img
                                  src={
                                    require("../Assets/images/upload.svg")
                                      .default
                                  }
                                  alt="Upload Placeholder"
                                />

                                <h4>Upload Image</h4>
                              </div>
                              <Field name="profile_image">
                                {({ field }) => (
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        setFieldValue,
                                        "profile_image"
                                      )
                                    }
                                  />
                                )}
                              </Field>

                              <div className="image-upload-placeholder">
                                {values?.profile_image &&
                                typeof values?.profile_image === "string" ? (
                                  <img
                                    className="inner-view"
                                    src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                    alt="Upload Placeholder"
                                  />
                                ) : values?.profile_image &&
                                  values.profile_image instanceof File ? (
                                  <img
                                    src={
                                      values?.profile_image
                                        ? URL.createObjectURL(
                                            values?.profile_image
                                          )
                                        : ""
                                    }
                                    className="prev-img"
                                    alt="Profile Preview"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>

                              {values?.profile_image &&
                                (typeof values.profile_image === "string" ||
                                  values.profile_image instanceof File) && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setFieldValue("profile_image", null)
                                    }
                                  >
                                    <img
                                      src={
                                        require("../Assets/images/delete.svg")
                                          .default
                                      }
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                            </div>
                            <ErrorMessage
                              name="profile_image"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="image-upload-main">
                            <h3>Banner Image</h3>
                            <div className="image-upload-inner">
                              <div className="image-upload-placeholder">
                                <img
                                  src={
                                    require("../Assets/images/upload.svg")
                                      .default
                                  }
                                  alt="Upload Placeholder"
                                />

                                <h4>Upload Image</h4>
                              </div>
                              <Field name="banner_image">
                                {({ field }) => (
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        setFieldValue,
                                        "banner_image"
                                      )
                                    }
                                  />
                                )}
                              </Field>
                              <div className="image-upload-placeholder">
                                {values?.banner_image &&
                                typeof values.banner_image === "string" ? (
                                  <img
                                    className="inner-view"
                                    src={`${process.env.REACT_APP_IMAGE_PATH}${values?.banner_image}`}
                                    alt="Upload Placeholder"
                                  />
                                ) : values?.banner_image &&
                                  values.banner_image instanceof File ? (
                                  <img
                                    src={URL.createObjectURL(
                                      values.banner_image
                                    )}
                                    className="prev-img"
                                    alt="Banner Preview"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>

                              {values?.banner_image &&
                                (typeof values.banner_image === "string" ||
                                  values.banner_image instanceof File) && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setFieldValue("banner_image", null)
                                    }
                                  >
                                    <img
                                      src={
                                        require("../Assets/images/delete.svg")
                                          .default
                                      }
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                            </div>
                            <ErrorMessage
                              name="banner_image"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}></Col>
                      </Row>
                    </div> */}

                    <div className="common-outer mt-4">
                      <h3 className="h-20">Personal Details</h3>
                      <div className="cmn-form-fields mt-2">
                        <Row>
                          <Col lg={4}>
                            <Form.Group className="mb-3 form-inner label-text-up">
                              <label className="mb-2">Associate's Name</label>
                              <Field
                                name="associate_name"
                                type="text"
                                className="form-control"
                                placeholder="Enter name"
                              />
                              <ErrorMessage
                                name="associate_name"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={4}>
                            <div className="profile-card label-text-up">
                              <label className="mb-2">
                                Associate's Country
                              </label>
                              <Select
                                name="country"
                                placeholder="Select"
                                classNamePrefix="custom-select"
                                options={optionsCountry}
                                onChange={(option) =>
                                  setFieldValue("country", option)
                                }
                                value={values.country}
                              />
                              <ErrorMessage
                                name="country"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div className="profile-card label-text-up">
                              <label className="mb-2">Associate's Role</label>
                              <Select
                                name="role"
                                placeholder="Select"
                                classNamePrefix="custom-select"
                                options={optionsRole}
                                onChange={(option) => {
                                  setFieldValue("role", option);
                                }}
                                value={values.role}
                              />
                              <ErrorMessage
                                name="role"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Col>

                          {values.role.value === 7 ? (
                            <Col lg={4}>
                              <Form.Group className="mb-3 form-inner label-text-up">
                                <label>Doctor's Speciality</label>
                                <Field
                                  name="doctor"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Doctor's Specialty"
                                />
                                <ErrorMessage
                                  name="doctor"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                          ) : (
                            ""
                          )}

                          {values.role.value === 10 ? (
                            <Col lg={4}>
                              <Form.Group className="mb-3 form-inner label-text-up">
                                <label>Other's Speciality</label>
                                <Field
                                  name="other_speciality"
                                  type="text"
                                  className="form-control"
                                  placeholder="Status/ Specialty "
                                />
                                <ErrorMessage
                                  name="other_speciality"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col lg={4}>
                            <Form.Group className="mb-3 form-inner label-text-up">
                              <label>Associate's Team</label>
                              <Field
                                name="team"
                                type="text"
                                className="form-control"
                                placeholder="Enter team"
                              />
                              <ErrorMessage
                                name="team"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="mb-3 form-inner label-text-up">
                              <label>Associate's Email</label>
                              <Field
                                name="email"
                                as={Form.Control}
                                type="email"
                                placeholder="Enter email here"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={4}>
                            <Form.Group className="mb-3 form-inner label-text-up">
                              <label>Phone's Number</label>
                              <Field
                                name="phone_number"
                                type="number"
                                className="form-control"
                                placeholder="Enter phone number"
                              />
                              <ErrorMessage
                                name="phone_number"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <Row className="mt-4">
                      <Col lg={6}>
                        <div className="vidoe-link-box">
                          <div className="box-flex-up link-videos  mb-3">
                            <div className="players-cv">
                              <div className="player-up">
                                <h3>Associate Agent's Image</h3>
                                <div
                                  className={`uplode-the-img ${
                                    dragging ? "dragging" : ""
                                  }`}
                                  onDrop={(e) =>
                                    handleDrop(
                                      e,
                                      setFieldValue,
                                      "profile_image"
                                    )
                                  }
                                  onDragOver={handleDragOver}
                                  onDragLeave={handleDragLeave}
                                >
                                  <div className="upload-and-drop">
                                    <div className="click-to-up">
                                      <label htmlFor="file-upload">
                                        <button type="button">
                                          Click to Upload
                                          <Field name="profile_image">
                                            {({ field }) => (
                                              <input
                                                type="file"
                                                id="file-upload"
                                                onChange={(e) =>
                                                  handleFileChange1(
                                                    e,
                                                    setFieldValue,
                                                    "profile_image"
                                                  )
                                                }
                                                accept="image/*"
                                              />
                                            )}
                                          </Field>
                                        </button>
                                      </label>
                                    </div>
                                    <div className="drag-drop">
                                      <p>
                                        <span>
                                          <img
                                            src={
                                              require("../Assets/images/drag-and-drop.svg")
                                                .default
                                            }
                                            alt="Upload Placeholder"
                                          />
                                        </span>
                                        Or drag and drop files.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="preview">
                                    <p>Preview</p>
                                    <div className="preview-content">
                                      {values?.profile_image &&
                                        (typeof values.profile_image ===
                                        "string" ? (
                                          <img
                                            className="inner-view"
                                            src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                            alt="Profile Preview"
                                            onClick={() =>
                                              handleShowPreview(
                                                `${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`
                                              )
                                            }
                                          />
                                        ) : values.profile_image instanceof
                                          File ? (
                                          <img
                                            src={URL.createObjectURL(
                                              values?.profile_image
                                            )}
                                            className="prev-img"
                                            alt="Profile Preview"
                                            style={{ width: "50px" }}
                                            onClick={() =>
                                              handleShowPreview(
                                                URL.createObjectURL(
                                                  values?.profile_image
                                                )
                                              )
                                            }
                                          />
                                        ) : null)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Col lg={12} className="mt-4 d-flex justify-content-end">
                      <div className="cmn-light-btn">
                        <button type="submit">Save</button>
                      </div>
                    </Col>
                  </FormikForm>
                );
              }}
            </Formik>
          </div>
          <PreviewModal
            show={showPreview}
            onHide={handleClosePreview}
            previewImage={previewImage}
          />
        </Container>
      </section>
    </LoginLayout>
  );
}
