import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import useDebouncedValue from "../Hook/useDebounce";

const GroupSearchComp = ({ onSearch, placeholder }) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 1000);
  const [isInteracted, setIsInteracted] = useState(false);

  const handleSearch = (val) => {
    setSearchValue(val);
  };

  // useEffect(() => {
  //   if (onSearch) {
  //     onSearch(debouncedSearchValue);
  //   }
  // }, [debouncedSearchValue]);

  const handleFocus = () => {
    setIsInteracted(true);
  };

  useEffect(() => {
    if (isInteracted && onSearch) {
      onSearch(debouncedSearchValue);
    }
  }, [debouncedSearchValue, isInteracted]);

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Control
          type="search"
          placeholder={placeholder}
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
          onFocus={handleFocus}
        />
      </Form.Group>
    </>
  );
};

export default GroupSearchComp;
