import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Modal, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import leftArrow from "../Assets/images/leftarrow.png";
import rightArrow from "../Assets/images/rightarrow.png";
import LoginLayout from "../components/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { homeListAction, transferListAction } from "../Redux/Action/homeAction";
import Slider from "react-slick";
import {
  getCompareDataAction,
  getPlayerListAction,
} from "../Redux/Action/playerAction";
import {
  clearPlayerImages,
  setPlayer1Image,
  setPlayer2Image,
} from "../Redux/Reducers/playerSlice";
import toast from "react-hot-toast";
import {
  AvailabilityStatus,
  Children,
  coachingLicense,
  educationLevel,
  filterKeyPlayers,
  filterKeyPlayersHome,
  martialStatus,
  playerFoot,
  playerNationality,
  positions,
  releaseClouse,
  teamLeague,
} from "../utils/rawjson";
import { calculateAge, capitalize, dateOfBirth } from "../utils/helper";
import { Encryptedid } from "../utils/Becrypt";
import TransferModalComp from "../Modal/TransferModalComp";
import CautionModalComp from "../Modal/CautionModalComp";

export default function HomeLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [activePlayer, setActivePlayer] = useState(null);
  const [selectedPlayerIds, setSelectedPlayerIds] = useState([]);

  const [showTransfer, setShowTransfer] = useState(false);
  const [showAgent, setShowAgent] = useState(false);
  const [showSelected, setShowSelected] = useState(false);

  const profileData = useSelector((e) => e.homeAuthData.homeProfileDetail);
  const playerListData = useSelector((e) => e.playerAuthData.playerList);

  const player1Image = useSelector(
    (state) => state.playerAuthData.player1Image
  );
  const player2Image = useSelector(
    (state) => state.playerAuthData.player2Image
  );

  const player1Id = useSelector((state) => state.playerAuthData.player1Id);
  const player2Id = useSelector((state) => state.playerAuthData.player2Id);
  const playerCompareDetail = useSelector(
    (e) => e.playerAuthData.playerCompareeData
  );
  const [stateTransferData, setStateTransferData] = useState([]);

  const handleChoosePlayer = (playerNumber) => {
    setActivePlayer(playerNumber);
    setShowModal(true);
  };

  console.log(profileData, "profileData");

  const options = {
    items: 1, // Number of items to display
    nav: true, // Show next and prev buttons
    navText: [
      `<img src="${leftArrow}" alt="Previous" />`,
      `<img src="${rightArrow}" alt="Next" />`,
    ],
    loop: true, // Enable looping
    margin: 10, // Margin between items
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    dispatch(homeListAction());
    dispatch(getPlayerListAction());
    dispatch(transferListAction()).then((res) => {
      if (res?.payload?.status === 200) {
        setStateTransferData(res?.payload?.data?.upcomingTransferUser);
      }
    });
  }, []);

  // const handlePlayerSelect = (player) => {
  //   const imagePath = `${process.env.REACT_APP_IMAGE_PATH}${player.profile_image}`;

  //   if (selectedPlayerIds.includes(player._id)) {
  //     toast.error("This player is already selected.");
  //     return;
  //   }

  //   if (activePlayer === 1) {
  //     dispatch(setPlayer1Image({ image: imagePath, id: player._id }));
  //   } else if (activePlayer === 2) {
  //     dispatch(setPlayer2Image({ image: imagePath, id: player._id }));
  //   }

  //   if (player1Id && player2Id) {
  //     dispatch(getCompareDataAction({ id1: player1Id, id2: player2Id }));
  //   }

  //   setSelectedPlayerIds((prev) => [...prev, player._id]);
  //   setShowModal(false);
  // };

  const renderComparisonSection = (
    value,
    key,
    label,
    value1,
    value2,
    mapping
  ) => {
    let displayValue1, displayValue2;

    if (value === "dob") {
      displayValue1 = dateOfBirth(value1);
      displayValue2 = dateOfBirth(value2);
    } else if (value === "contract_end_date") {
      displayValue1 = dateOfBirth(value1);
      displayValue2 = dateOfBirth(value2);
    } else if (value === "contract_start_date") {
      displayValue1 = dateOfBirth(value1);
      displayValue2 = dateOfBirth(value2);
    } else if (value === "age") {
      displayValue1 = value1 ? calculateAge(value1) : "N/A";
      displayValue2 = value2 ? calculateAge(value2) : "N/A";
    } else if (mapping) {
      displayValue1 = mapping[value1] || "N/A";
      displayValue2 = mapping[value2] || "N/A";
    } else {
      displayValue1 = value1 || "N/A";
      displayValue2 = value2 || "N/A";
    }

    return (
      <div className="compare-players-bottom mb-3" key={key}>
        <h4 className="label-box">{label}</h4>
        <div className="compare-box home-box d-flex justify-content-between">
          <p>{displayValue1}</p>
          <p>{displayValue2}</p>
        </div>
      </div>
    );
  };
  const handlePlayerSelect = (player) => {
    const imagePath = `${process.env.REACT_APP_IMAGE_PATH}${player.profile_image}`;

    if (player1Id === player._id || player2Id === player._id) {
      toast.error("This player is already selected.");
      return;
    }

    if (activePlayer === 1) {
      dispatch(setPlayer1Image({ image: imagePath, id: player._id }));
    } else if (activePlayer === 2) {
      dispatch(setPlayer2Image({ image: imagePath, id: player._id }));
    }

    if (player1Id && player2Id) {
      dispatch(getCompareDataAction({ id1: player1Id, id2: player2Id }));
    }

    setShowModal(false);
  };

  useEffect(() => {
    if (player1Image && player2Image) {
      dispatch(getCompareDataAction({ id1: player1Id, id2: player2Id }));
    }
  }, [player1Image, player2Image, player1Id, player2Id, dispatch]);

  const handleFile = (playerNumber) => {
    if (playerNumber === 1) {
      dispatch(setPlayer1Image({ image: null, id: null }));
      setSelectedPlayerIds((prev) => prev.filter((id) => id !== player1Id));
    } else if (playerNumber === 2) {
      dispatch(setPlayer2Image({ image: null, id: null }));
      setSelectedPlayerIds((prev) => prev.filter((id) => id !== player2Id));
    }
  };

  console.log(playerCompareDetail, "asdasdasdasdasdasd");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
  };

  return (
    <LoginLayout>
      {/* <div className="animaton-text">
        <div className="home-animation-box">
          <div class="soccer"></div>
          <div className="upcoming-page">
            <h1> Coming Soon </h1>
            <p>
              We're working hard to bring you something awesome. Stay tuned!
            </p>
          </div>
        </div>
      </div> */}

      {/* <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2">
              <h2>Spotlight</h2>
            </div>
            <Row>
              <Col lg={8}>
                <OwlCarousel
                  className="owl-theme banner-owl"
                  loop
                  margin={10}
                  nav
                  items={1}
                  {...options}
                >
                  <div class="item">
                    <div className="banner-slider">
                      <img src={require("../Assets/images/dummy.png")} />
                      <div className="banner-desc">
                        <img src={require("../Assets/images/tiger.png")} />
                        <h2>
                          Kiernan Dewsbury-Hall joins Chelsea - what will
                          Leicester playmaker bring to Maresca’s side?
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div className="banner-slider">
                      <img src={require("../Assets/images/dummy.png")} />
                      <div className="banner-desc">
                        <img src={require("../Assets/images/tiger.png")} />
                        <h2>
                          Kiernan Dewsbury-Hall joins Chelsea - what will
                          Leicester playmaker bring to Maresca’s side?
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div className="banner-slider">
                      <img src={require("../Assets/images/dummy.png")} />
                      <div className="banner-desc">
                        <img src={require("../Assets/images/tiger.png")} />
                        <h2>
                          Kiernan Dewsbury-Hall joins Chelsea - what will
                          Leicester playmaker bring to Maresca’s side?
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div className="banner-slider">
                      <img src={require("../Assets/images/dummy.png")} />
                      <div className="banner-desc">
                        <img src={require("../Assets/images/tiger.png")} />
                        <h2>
                          Kiernan Dewsbury-Hall joins Chelsea - what will
                          Leicester playmaker bring to Maresca’s side?
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div className="banner-slider">
                      <img src={require("../Assets/images/dummy.png")} />
                      <div className="banner-desc">
                        <img src={require("../Assets/images/tiger.png")} />
                        <h2>
                          Kiernan Dewsbury-Hall joins Chelsea - what will
                          Leicester playmaker bring to Maresca’s side?
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div className="banner-slider">
                      <img src={require("../Assets/images/dummy.png")} />
                      <div className="banner-desc">
                        <img src={require("../Assets/images/tiger.png")} />
                        <h2>
                          Kiernan Dewsbury-Hall joins Chelsea - what will
                          Leicester playmaker bring to Maresca’s side?
                        </h2>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </Col>
              <Col lg={4}>
                <div className="home-players">
                  <h2>Top Players</h2>
                  <div className="players-scroll-sec">
                    <div className="players-inner-box">
                      <img src={require("../Assets/images/user.png")} />
                      <h3>
                        Player ID: <span>IVXJW007</span>
                      </h3>
                    </div>
                    <div className="players-inner-box">
                      <img src={require("../Assets/images/user.png")} />
                      <h3>
                        Player ID: <span>IVXJW007</span>
                      </h3>
                    </div>
                    <div className="players-inner-box">
                      <img src={require("../Assets/images/user.png")} />
                      <h3>
                        Player ID: <span>IVXJW007</span>
                      </h3>
                    </div>
                    <div className="players-inner-box">
                      <img src={require("../Assets/images/user.png")} />
                      <h3>
                        Player ID: <span>IVXJW007</span>
                      </h3>
                    </div>
                    <div className="players-inner-box">
                      <img src={require("../Assets/images/user.png")} />
                      <h3>
                        Player ID: <span>IVXJW007</span>
                      </h3>
                    </div>
                    <div className="players-inner-box">
                      <img src={require("../Assets/images/user.png")} />
                      <h3>
                        Player ID: <span>IVXJW007</span>
                      </h3>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4} className="mt-2">
                <div className="add-cards">
                  <img src={require("../Assets/images/football.png")} />
                  <div className="add-banner-desc">
                    <img src={require("../Assets/images/tiger.png")} />
                    <h2>Most Expensive Transfers 2024/25 so far</h2>
                  </div>
                </div>
              </Col>
              <Col lg={4} className="mt-2">
                <div className="add-cards">
                  <img src={require("../Assets/images/football.png")} />
                  <div className="add-banner-desc">
                    <img src={require("../Assets/images/tiger.png")} />
                    <h2>Most Expensive Transfers 2024/25 so far</h2>
                  </div>
                </div>
              </Col>
              <Col lg={4} className="mt-2">
                <div className="add-cards">
                  <img src={require("../Assets/images/football.png")} />
                  <div className="add-banner-desc">
                    <img src={require("../Assets/images/tiger.png")} />
                    <h2>Most Expensive Transfers 2024/25 so far</h2>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section> */}

      <section className="new-banner-image-sp">
        <div className="top-new-section-sp">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="banner-inner-container">
                  <div className="live-football">
                    <p>Feel the heartbeat of football, live</p>
                  </div>
                  <div className="sports-banner-title">
                    <h1>Chasing Goals, Making History Every Game</h1>
                  </div>

                  <div className="banner-slider-section">
                    <div className="slider-container">
                      <Slider {...settings}>
                        <div className="transfer-box-sp">
                          <div className="new-expensive-transfer">
                            <h3>Most Expensive Transfers</h3>
                            <h2>
                              Exciting matchup features league’s top teams
                            </h2>
                            <p>2024/25 so far</p>
                          </div>
                        </div>
                        <div className="transfer-box-sp">
                          <div className="new-expensive-transfer">
                            <h3>Most Expensive Transfers</h3>
                            <h2>
                              Exciting matchup features league’s top teams
                            </h2>
                            <p>2024/25 so far</p>
                          </div>
                        </div>
                        <div className="transfer-box-sp">
                          <div className="new-expensive-transfer">
                            <h3>Most Expensive Transfers</h3>
                            <h2>
                              Exciting matchup features league’s top teams
                            </h2>
                            <p>2024/25 so far</p>
                          </div>
                        </div>
                        <div className="transfer-box-sp">
                          <div className="new-expensive-transfer">
                            <h3>Most Expensive Transfers</h3>
                            <h2>
                              Exciting matchup features league’s top teams
                            </h2>
                            <p>2024/25 so far</p>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section className=" set-background-col">
        <Container>
          <div className="spacing-top-bot">
            <img
              src={
                require("../Assets/images/heading-icon-ball-alt.svg").default
              }
            />
            <h1>Succeed. Honor. Inspire. </h1>
            <div className=" my-players">
              <Row>
                <Col lg={4}>
                  <div className="play-card-sec">
                    <div className="our-top-play">
                      <h2>Our Top Players</h2>
                    </div>
                    <div className="play-scroll-box position-relative white-bg">
                      <div className="top-view-all">
                        <h2>Players List</h2>
                        {profileData?.player?.length > 0 && (
                          <Link to="/my-players" className="linkclass">
                            View all
                          </Link>
                        )}
                      </div>
                      {profileData?.player?.length > 0 ? (
                        profileData?.player?.map((elem, i) => (
                          <div
                            className="play-inner-box player-with-id"
                            key={i}
                            onClick={() =>
                              navigate(
                                `/player-profile/${Encryptedid(elem?._id)}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex">
                              {elem?.gender == 0 ? (
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                      : require("../Assets/images/player_Ai.png")
                                  }
                                  alt="Profile"
                                />
                              ) : (
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                      : require("../Assets/images/player-female.png")
                                  }
                                  alt="Profile"
                                />
                              )}
                              <div className="ms-3 players-name-disp">
                                <h4>{capitalize(elem?.full_name)}</h4>
                                {/* <p>Lorium ipsum Team</p> */}
                                <p>
                                  Position:
                                  {elem?.positions
                                    ?.slice(0, 5)
                                    ?.map((position, i) => (
                                      <React.Fragment key={i}>
                                        <span>
                                          {positions[position]}
                                          {i <
                                            Math.min(elem.positions.length, 7) -
                                              1 && ", "}
                                        </span>
                                      </React.Fragment>
                                    ))}
                                  {elem?.positions?.length > 7 && " ..."}
                                </p>
                              </div>
                            </div>

                            <div className="">
                              <p>
                                Player ID:{" "}
                                <span>{elem?.unique_id.toUpperCase()}</span>
                              </p>
                              {/* <img
                                src={require("../Assets/images/tiger.png")}
                              /> */}
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="nodatafound">No Data</p>
                      )}
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="lorium-ipsum">
                    <div className="our-top-play">
                      <h2>Fresh stories</h2>
                    </div>
                    <div className="lorium-image-sec">
                      <img src={require("../Assets/images/lorium-ipsum.png")} />
                    </div>
                    <div className="bottom-new-line">
                      <h2>Lorium Ipsum is dummy text</h2>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="lorium-ipsum">
                    <div className="our-top-play">
                      <h2>Highlights</h2>
                    </div>
                    <div className="lorium-image-sec">
                      <img
                        src={require("../Assets/images/highlights-image.png")}
                      />
                    </div>
                    <div className="bottom-new-line">
                      <h2>Lorium Ipsum is dummy text</h2>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>

      <section className="mt-4">
        <Container>
          <div className="second-sec-title">
            <img
              src={
                require("../Assets/images/heading-icon-ball-alt.svg").default
              }
            />
            <h1>Discover Our Legends in Action. </h1>
            <div className="cmn-card my-players">
              <Row>
                <Col lg={4}>
                  <div className="play-card-sec">
                    <div className="play-scroll-box position-relative">
                      <div className="top-view-all">
                        <h2>My Players</h2>
                        {profileData?.player?.length > 0 && (
                          <Link to="/my-players" className="linkclass">
                            View all
                          </Link>
                        )}
                      </div>
                      {profileData?.player?.length > 0 ? (
                        profileData?.player?.map((elem, i) => (
                          <div
                            className="play-inner-box player-with-id"
                            key={i}
                            onClick={() =>
                              navigate(
                                `/player-profile/${Encryptedid(elem?._id)}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex">
                              {elem?.gender == 0 ? (
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                      : require("../Assets/images/player_Ai.png")
                                  }
                                  alt="Profile"
                                />
                              ) : (
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                      : require("../Assets/images/player-female.png")
                                  }
                                  alt="Profile"
                                />
                              )}
                              <div className="ms-3 players-name-disp">
                                <h4>{capitalize(elem?.full_name) || "N/A"}</h4>
                                {/* <p>{elem?.national_team || "N/A"}</p> */}
                                <p>
                                  Position:
                                  {elem?.positions
                                    ?.slice(0, 5)
                                    ?.map((position, i) => (
                                      <React.Fragment key={i}>
                                        <span>
                                          {positions[position]}
                                          {i <
                                            Math.min(elem.positions.length, 7) -
                                              1 && ", "}
                                        </span>
                                      </React.Fragment>
                                    ))}
                                  {elem?.positions?.length > 7 && " ..."}
                                </p>
                              </div>
                            </div>

                            <div className="">
                              <p>
                                Player ID:{" "}
                                <span>{elem?.unique_id.toUpperCase()}</span>
                              </p>
                              {/* <img
                                src={require("../Assets/images/tiger.png")}
                              /> */}
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="nodatafound">No Data</p>
                      )}
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="play-card-sec">
                    <div className="play-scroll-box position-relative">
                      <div className="top-view-all">
                        <h2>My Directors</h2>
                        {profileData?.director?.length > 0 && (
                          <Link to="/my-directors" className="linkclass">
                            View all
                          </Link>
                        )}
                      </div>
                      {profileData?.director?.length > 0 ? (
                        profileData?.director?.map((elem, i) => (
                          <div
                            className="play-inner-box player-with-id"
                            key={i}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                `/my-directors/${Encryptedid(elem?._id)}`
                              )
                            }
                          >
                            <div className="d-flex">
                              {elem?.gender == 0 ? (
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                      : require("../Assets/images/My Directors male.png")
                                  }
                                  alt="Profile"
                                />
                              ) : (
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                      : require("../Assets/images/director-female-new.png")
                                  }
                                  alt="Profile"
                                />
                              )}
                              <div className="ms-3 players-name-disp">
                                <h4>{capitalize(elem?.full_name)}</h4>
                                <p>
                                  {elem?.coaching_licenses
                                    ?.map((res) => coachingLicense[res])
                                    .join(", ")}
                                </p>
                                {/* <p>Lorium ipsum Team</p> */}
                              </div>
                            </div>

                            <div className="">
                              <p>
                                Director ID:{" "}
                                <span>{elem?.unique_id.toUpperCase()}</span>
                              </p>
                              {/* <img
                                src={require("../Assets/images/tiger.png")}
                              /> */}
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="nodatafound">No Data</p>
                      )}
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="play-card-sec">
                    <div className="play-scroll-box position-relative">
                      <div className="top-view-all">
                        <h2>My Coaches</h2>
                        {profileData?.coach?.length > 0 && (
                          <Link to="/my-coach" className="linkclass">
                            View all
                          </Link>
                        )}
                      </div>
                      {profileData?.coach?.length > 0 ? (
                        profileData?.coach?.map((elem, i) => (
                          <div
                            className="play-inner-box player-with-id"
                            key={i}
                            onClick={() =>
                              navigate(`/my-coach/${Encryptedid(elem?._id)}`)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex ">
                              {elem?.gender == 0 ? (
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                      : require("../Assets/images/coach-male.png")
                                  }
                                  alt="Profile"
                                />
                              ) : (
                                <img
                                  src={
                                    elem?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem.profile_image}`
                                      : require("../Assets/images/coach-female.png")
                                  }
                                  alt="Profile"
                                />
                              )}
                              <div className="ms-3 players-name-disp">
                                <h4>{capitalize(elem?.full_name) || "N/A"}</h4>
                                <p>
                                  {elem?.coaching_licenses
                                    ?.map((res) => coachingLicense[res])
                                    .join(", ") || "N/A"}
                                </p>
                              </div>
                            </div>

                            <div className="">
                              <p>
                                Coach ID:{" "}
                                <span>{elem?.unique_id.toUpperCase()}</span>
                              </p>
                              {/* <img
                                src={require("../Assets/images/tiger.png")}
                              /> */}
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="nodatafound">No Data</p>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>

      {/* <section className="mt-4">
        <Container>
          <div className="cmn-card">
            <Row>
              <Col lg={8}>
                <div className="play-card-sec">
                  <h3>Transfers</h3>
                  <div className="transfer-box">
                    <div className="transfer-head">
                      <Row>
                        <Col lg={3} md={3} sm={3}>
                          <h3>Player</h3>
                        </Col>
                        <Col
                          lg={3}
                          md={3}
                          sm={3}
                          className="d-flex justify-content-center"
                        >
                          <h3>transferred from</h3>
                        </Col>
                        <Col lg={3} md={3} sm={3}></Col>
                        <Col lg={3} md={3} sm={3}>
                          <h3>transferred to</h3>
                        </Col>
                      </Row>
                    </div>
                    <div className="transfer-mid">
                      <Container>
                        <Row
                          className="align-items-center pt-3 pb-3 ps-1"
                          style={{ borderBottom: "2px solid #D5D5D5" }}
                        >
                          <Col lg={3} md={3} sm={3}>
                            <div className="play-data">
                              <img src={require("../Assets/images/user.png")} />
                              <div>
                                <h4>Andrew Addison</h4>
                                <p>Player ID:IVXJW007</p>
                              </div>
                            </div>
                          </Col>
                          <Col
                            lg={3}
                            md={3}
                            sm={3}
                            className="d-flex justify-content-center"
                          >
                            <div className="transfer">
                              <img
                                src={require("../Assets/images/logoimage.png")}
                              />
                              <p>Manchester</p>
                            </div>
                          </Col>
                          <Col
                            lg={3}
                            md={3}
                            sm={3}
                            className="d-flex justify-content-center"
                          >
                            <img
                              src={
                                require("../Assets/images/arrows.svg").default
                              }
                            />
                          </Col>
                          <Col lg={3} md={3} sm={3}>
                            <div className="transfer">
                              <img
                                src={require("../Assets/images/logoimage.png")}
                              />
                              <p>Chelsea</p>
                            </div>
                          </Col>
                        </Row>
                        <Row
                          className="align-items-center pt-3 pb-3 ps-1"
                          style={{ borderBottom: "2px solid #D5D5D5" }}
                        >
                          <Col lg={3} md={3} sm={3}>
                            <div className="play-data">
                              <img src={require("../Assets/images/user.png")} />
                              <div>
                                <h4>Andrew Addison</h4>
                                <p>Player ID:IVXJW007</p>
                              </div>
                            </div>
                          </Col>
                          <Col
                            lg={3}
                            md={3}
                            sm={3}
                            className="d-flex justify-content-center"
                          >
                            <div className="transfer">
                              <img
                                src={require("../Assets/images/logoimage.png")}
                              />
                              <p>Manchester</p>
                            </div>
                          </Col>
                          <Col
                            lg={3}
                            md={3}
                            sm={3}
                            className="d-flex justify-content-center"
                          >
                            <img
                              src={
                                require("../Assets/images/arrows.svg").default
                              }
                            />
                          </Col>
                          <Col lg={3} md={3} sm={3}>
                            <div className="transfer">
                              <img
                                src={require("../Assets/images/logoimage.png")}
                              />
                              <p>Chelsea</p>
                            </div>
                          </Col>
                        </Row>
                        <Row
                          className="align-items-center pt-3 pb-3 ps-1"
                          style={{ borderBottom: "2px solid #D5D5D5" }}
                        >
                          <Col lg={3} md={3} sm={3}>
                            <div className="play-data">
                              <img src={require("../Assets/images/user.png")} />
                              <div>
                                <h4>Andrew Addison</h4>
                                <p>Player ID:IVXJW007</p>
                              </div>
                            </div>
                          </Col>
                          <Col
                            lg={3}
                            md={3}
                            sm={3}
                            className="d-flex justify-content-center"
                          >
                            <div className="transfer">
                              <img
                                src={require("../Assets/images/logoimage.png")}
                              />
                              <p>Manchester</p>
                            </div>
                          </Col>
                          <Col
                            lg={3}
                            md={3}
                            sm={3}
                            className="d-flex justify-content-center"
                          >
                            <img
                              src={
                                require("../Assets/images/arrows.svg").default
                              }
                            />
                          </Col>
                          <Col lg={3} md={3} sm={3}>
                            <div className="transfer">
                              <img
                                src={require("../Assets/images/logoimage.png")}
                              />
                              <p>Chelsea</p>
                            </div>
                          </Col>
                        </Row>
                        <Row
                          className="align-items-center pt-3 pb-3 ps-1"
                          style={{ borderBottom: "2px solid #D5D5D5" }}
                        >
                          <Col lg={3} md={3} sm={3}>
                            <div className="play-data">
                              <img src={require("../Assets/images/user.png")} />
                              <div>
                                <h4>Andrew Addison</h4>
                                <p>Player ID:IVXJW007</p>
                              </div>
                            </div>
                          </Col>
                          <Col
                            lg={3}
                            md={3}
                            sm={3}
                            className="d-flex justify-content-center"
                          >
                            <div className="transfer">
                              <img
                                src={require("../Assets/images/logoimage.png")}
                              />
                              <p>Manchester</p>
                            </div>
                          </Col>
                          <Col
                            lg={3}
                            md={3}
                            sm={3}
                            className="d-flex justify-content-center"
                          >
                            <img
                              src={
                                require("../Assets/images/arrows.svg").default
                              }
                            />
                          </Col>
                          <Col lg={3} md={3} sm={3}>
                            <div className="transfer">
                              <img
                                src={require("../Assets/images/logoimage.png")}
                              />
                              <p>Chelsea</p>
                            </div>
                          </Col>
                        </Row>
                        <Row
                          className="align-items-center pt-3 pb-3 ps-1"
                          style={{ borderBottom: "2px solid #D5D5D5" }}
                        >
                          <Col lg={3} md={3} sm={3}>
                            <div className="play-data">
                              <img src={require("../Assets/images/user.png")} />
                              <div>
                                <h4>Andrew Addison</h4>
                                <p>Player ID:IVXJW007</p>
                              </div>
                            </div>
                          </Col>
                          <Col
                            lg={3}
                            md={3}
                            sm={3}
                            className="d-flex justify-content-center"
                          >
                            <div className="transfer">
                              <img
                                src={require("../Assets/images/logoimage.png")}
                              />
                              <p>Manchester</p>
                            </div>
                          </Col>
                          <Col
                            lg={3}
                            md={3}
                            sm={3}
                            className="d-flex justify-content-center"
                          >
                            <img
                              src={
                                require("../Assets/images/arrows.svg").default
                              }
                            />
                          </Col>
                          <Col lg={3} md={3} sm={3}>
                            <div className="transfer">
                              <img
                                src={require("../Assets/images/logoimage.png")}
                              />
                              <p>Chelsea</p>
                            </div>
                          </Col>
                        </Row>
                        <Row
                          className="align-items-center pt-3 pb-3 ps-1"
                          style={{ borderBottom: "2px solid #D5D5D5" }}
                        >
                          <Col lg={3} md={3} sm={3}>
                            <div className="play-data">
                              <img src={require("../Assets/images/user.png")} />
                              <div>
                                <h4>Andrew Addison</h4>
                                <p>Player ID:IVXJW007</p>
                              </div>
                            </div>
                          </Col>
                          <Col
                            lg={3}
                            md={3}
                            sm={3}
                            className="d-flex justify-content-center"
                          >
                            <div className="transfer">
                              <img
                                src={require("../Assets/images/logoimage.png")}
                              />
                              <p>Manchester</p>
                            </div>
                          </Col>
                          <Col
                            lg={3}
                            md={3}
                            sm={3}
                            className="d-flex justify-content-center"
                          >
                            <img
                              src={
                                require("../Assets/images/arrows.svg").default
                              }
                            />
                          </Col>
                          <Col lg={3} md={3} sm={3}>
                            <div className="transfer">
                              <img
                                src={require("../Assets/images/logoimage.png")}
                              />
                              <p>Chelsea</p>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                  <Link to="#" className="view-link">
                    View All
                  </Link>
                </div>
              </Col>

              <Col lg={4}>
                <div className="play-card-sec">
                  <h3>Compare Players</h3>
                  <div className="compare-players-main">
                    <div className="players-top-sec">
                      <div className="play-box-wrap">
                        <div className="play-inner ad-delt-btn">
                          {player1Image && (
                            <button
                              type="button"
                              className="delete-button"
                              onClick={() => handleFile(1)}
                            >
                              <img
                                src={
                                  require("../Assets/images/delete.svg").default
                                }
                                alt="Delete"
                              />
                            </button>
                          )}
                          <button
                            id="choosePlayer1"
                            style={{ display: "none" }}
                            onClick={() => handleChoosePlayer(1)}
                          ></button>
                          <label
                            htmlFor="choosePlayer1"
                            style={{
                              cursor: "pointer",
                              display: "inline-block",
                            }}
                          >
                            <img
                              className="up-icon"
                              src={
                                require("../Assets/images/userimg.svg").default
                              }
                            />
                          </label>

                          {player1Image && (
                            <img
                              className="prev-img"
                              src={player1Image}
                              alt="Player 1"
                            />
                          )}
                        </div>
                        <h4>Add Player 1</h4>
                      </div>

                      <div className="play-box-wrap">
                        <div className="play-inner ad-delt-btn">
                          {player2Image && (
                            <button
                              type="button"
                              className="delete-button"
                              onClick={() => handleFile(2)}
                            >
                              <img
                                src={
                                  require("../Assets/images/delete.svg").default
                                }
                                alt="Delete"
                              />
                            </button>
                          )}
                          <button
                            id="choosePlayer2"
                            style={{ display: "none" }}
                            onClick={() => handleChoosePlayer(2)}
                          ></button>
                          <label
                            htmlFor="choosePlayer2"
                            style={{
                              cursor: "pointer",
                              display: "inline-block",
                            }}
                          >
                            <img
                              className="up-icon"
                              src={
                                require("../Assets/images/userimg.svg").default
                              }
                            />
                          </label>

                          {player2Image && (
                            <img
                              className="prev-img"
                              src={player2Image}
                              alt="Player 2"
                            />
                          )}
                        </div>
                        <h4>Add Player 2</h4>
                      </div>
                    </div>

                    <div>
                      {Object.entries(filterKeyPlayersHome)?.map(
                        ([key, { label, value }]) => {
                          let value1, value2;

                          if (value === "age") {
                            const dob1 = playerCompareDetail?.playerData1?.dob;
                            const dob2 = playerCompareDetail?.playerData2?.dob;
                            value1 = dob1;
                            value2 = dob2;
                          } else {
                            value1 = playerCompareDetail?.playerData1?.[value];
                            value2 = playerCompareDetail?.playerData2?.[value];
                          }

                          let mapping;
                          if (value === "team_league") {
                            mapping = teamLeague;
                          } else if (value === "foot") {
                            mapping = playerFoot;
                          } else if (value === "nationality_id") {
                            mapping = playerNationality;
                          } else if (value === "education_level") {
                            mapping = educationLevel;
                          } else if (value === "release_clouse") {
                            mapping = releaseClouse;
                          } else if (value === "martial_status") {
                            mapping = martialStatus;
                          } else if (value === "children") {
                            mapping = Children;
                          } else if (value === "buyout_clouse") {
                            mapping = releaseClouse;
                          } else if (value === "availability_status") {
                            mapping = AvailabilityStatus;
                          } else if (value === "positions") {
                            mapping = positions;
                          }

                          return (
                            Object.keys(value).length &&
                            renderComparisonSection(
                              value,
                              key,
                              label,
                              value1,
                              value2,
                              mapping
                            )
                          );
                        }
                      )}
                    </div>
                  </div>
                  <Link to="/compare-players" className="view-link">
                    View All
                  </Link>
                </div>
              </Col>

              <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Select Player</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="ad-pop-height-player">
                    {playerListData?.map((player) => (
                      <div
                        style={{ cursor: "pointer" }}
                        className="play-inner-box"
                        key={player.id}
                        onClick={() => handlePlayerSelect(player)}
                      >
                        <div className="d-flex">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_PATH}${player?.profile_image}`}
                          />
                          <div className="ms-3">
                            <h4>{player?.full_name}</h4>
                            <p>
                              Player ID:{" "}
                              <span>{player?.unique_id.toUpperCase()}</span>
                            </p>
                          </div>
                        </div>

                        <div className="badge-img">
                          <img src={require("../Assets/images/tiger.png")} />
                        </div>
                      </div>
                    ))}
                  </div>
                </Modal.Body>
              </Modal>
            </Row>
          </div>
        </Container>
      </section> */}

      <section className="mt-5">
        <Container>
          <div className="">
            <Row>
              <div className="home-title-text">
                <img
                  src={
                    require("../Assets/images/heading-icon-ball-alt.svg")
                      .default
                  }
                />
                <h1>Gear Up for the Epic Football Showdowns! </h1>
              </div>
              <Col lg={6}>
                <div className="left-side-image-sp">
                  <img src={require("../Assets/images/left-side-image.png")} />
                </div>
              </Col>

              <Col lg={6}>
                <div className="right-side-players">
                  {Array.isArray(stateTransferData) &&
                  stateTransferData?.length > 0 ? (
                    stateTransferData.map((res, index) => {
                      let data = res?.type == 1 ? res?.player_id : res?.type == 2 ? res?.director_id : res?.coach_id

                      return (
                        <div
                          className={`andrew-addison ${index > 0 && ` mt-3`}`}
                          key={res?._id}
                        >
                          <div className="players-name-id">
                            <img
                              src={
                                data?.profile_image
                                  ? `${process.env.REACT_APP_IMAGE_PATH}${data?.profile_image}`
                                  : data?.gender === 0
                                  ? require("../Assets/images/biguser.png")
                                  : require("../Assets/images/compare-female.png")
                              }
                            />
                            <div className="player-new-name-text">
                              <h4>{data?.full_name || "N/A"}</h4>
                              <p>
                                {`${res?.type == 1 ? `Player` : res?.type == 2 ? `Director` : `Coach`}`} ID:{" "}
                                <span>
                                  {" "}
                                  {data?.unique_id || "N/A"}{" "}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="match-det">
                            <div>
                              <div className="transfer">
                                <img
                                  src={
                                    res?.transfer_from?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${res?.transfer_from?.profile_image}`
                                      : res?.transfer_from?.gender === 0
                                      ? require("../Assets/images/player_Ai.png")
                                      : require("../Assets/images/compare-female.png")
                                  }
                                />
                                <p>{res?.transfer_from?.full_name || "N/A"}</p>
                              </div>
                              <p className="trans-fer">Transferred from</p>
                            </div>

                            <img
                              src={
                                require("../Assets/images/errow-back-per.svg")
                                  .default
                              }
                            />
                            <div>
                              <div className="transfer">
                                <img
                                  src={
                                    res?.transfer_to?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${res?.transfer_to?.profile_image}`
                                      : res?.transfer_to?.gender === 0
                                      ? require("../Assets/images/player_Ai.png")
                                      : require("../Assets/images/compare-female.png")
                                  }
                                />
                                <p>{res?.transfer_to?.full_name || "N/A"}</p>
                              </div>
                              <p className="trans-fer">Transferred to</p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <div className="andrew-addison">
                        {/* <div className="players-name-id"> */}
                        No Data Found
                        {/* </div> */}
                      </div>
                    </>
                  )}

                  {/* {stateTransferData?.length > 0 && ( */}
                  <div className="tranfer-home-btn">
                    <button type="button" onClick={() => setShowTransfer(true)}>
                      Schedule Transfer
                    </button>
                  </div>
                  {/* )} */}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="mt-5">
        <Container>
          <Row>
            <Col lg={12} className="mt-4">
              <div className="home-title-text">
                <img
                  src={
                    require("../Assets/images/heading-icon-ball-alt.svg")
                      .default
                  }
                />
                <h1>Inside the Football World </h1>
              </div>
              <div className="play-images american-football-image">
                <img src={require("../Assets/images/american-football.png")} />
                <div className="inspire-your-play">
                  <h3>
                    Dive into the Thrills, Fun, and Epic Showdowns of the
                    Football World!
                  </h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="">
        <Container>
          <div className="american-players mt-3 pb-5">
            <Row>
              <Col lg={4}>
                <div className="inner-players-box">
                  <div className="build-stamina">
                    <img
                      src={require("../Assets/images/box-first-image.png")}
                    />
                  </div>
                  <div className="date-and-text-box">
                    <div className="jan-date">
                      <p>Jan 2</p>
                      <h3>The Road to Glory: Rising Stars to Watch </h3>
                    </div>
                    <div className="disp-player-text">
                      <p>
                        From local talents to global sensations, discover the{" "}
                      </p>
                      <p>
                        young players making waves in the football world. Their{" "}
                      </p>
                    </div>

                    <div className="read-more-players">
                      <p>Read More </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="inner-players-box">
                  <div className="build-stamina">
                    <img
                      src={require("../Assets/images/second-image-box.png")}
                    />
                  </div>
                  <div className="date-and-text-box">
                    <div className="jan-date">
                      <p>Jan 2</p>
                      <h3>
                        Unforgettable Showdowns: Greatest Matches in Football
                        History
                      </h3>
                    </div>
                    <div className="disp-player-text">
                      <p>
                        Relive the most thrilling, high-stakes games ever
                        played!
                      </p>
                      <p>
                        From last-minute goals to breathtaking comebacks, thes
                      </p>
                    </div>

                    <div className="read-more-players">
                      <p>Read More </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="inner-players-box">
                  <div className="build-stamina">
                    <img
                      src={require("../Assets/images/third-image-box.png")}
                    />
                  </div>
                  <div className="date-and-text-box">
                    <div className="jan-date">
                      <p>Jan 2</p>
                      <h3>
                        Beyond the Pitch: Football’s Impact on Culture and
                        Community
                      </h3>
                    </div>
                    <div className="disp-player-text">
                      <p>Football is more than just a sport — it's a global </p>
                      <p>
                        phenomenon that unites cultures and inspires generatio
                      </p>
                    </div>

                    <div className="read-more-players">
                      <p>Read More </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <TransferModalComp
          showTransfer={showTransfer}
          setShowTransfer={setShowTransfer}
          setShowAgent={setShowAgent}
          setShowSelected={setShowSelected}
        />
        <CautionModalComp
          showSelected={showSelected}
          setShowSelected={setShowSelected}
        />
      </section>
    </LoginLayout>
  );
}
