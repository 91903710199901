import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoginLayout from "../components/LoginLayout";
import "react-calendar/dist/Calendar.css";
import {
  getAgentDataById,
  getAgentListAction,
} from "../Redux/Action/agentAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Encryptedid } from "../utils/Becrypt";
import { capitalize } from "../utils/helper";
import Loader from "../components/Loader";

export default function ManageAssociateAgent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const agentListingData = useSelector((e) => e.agentAuthData.agentListDetail);
  const loader = useSelector((e) => e.agentAuthData.loading);

  useEffect(() => {
    dispatch(getAgentListAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAgentDataById({ id: "" }));
  }, [dispatch]);

  return (
    <LoginLayout>
      <section>
        {loader && <Loader />}
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-3 d-flex justify-content-between align-items-center">
              <h2>My Associate Agents</h2>
            </div>
            <div className="play-outer">
              <Row>
                {agentListingData?.length > 0 ? (
                  agentListingData?.map((item, index) => (
                    <Col
                      lg={4}
                      key={index}
                      onClick={() =>
                        navigate(`/associate-agent/${Encryptedid(item?._id)}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="player-box new-play-box">
                        <div className="agent-box">
                          <img
                            src={
                              item?.profile_image
                                ? `${process.env.REACT_APP_IMAGE_PATH}${item.profile_image}`
                                : item?.gender == 1
                                ? require("../Assets/images/My Associate Agent female.png")
                                : require("../Assets/images/My Associate Agent male.png")
                            }
                            alt="Profile"
                          />

                          <div>
                            <h3 className="mb-2">
                              {capitalize(item?.agent_name)}
                            </h3>

                            <h5>
                              {item?.country}, {item?.city}
                            </h5>
                          </div>
                        </div>
                        <div>
                          <p>{item?.unique_id.toUpperCase()}</p>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <h4 className="nodata">No Associate Agent Data</h4>
                )}
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
