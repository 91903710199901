import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Popover,
  Row,
} from "react-bootstrap";
import LoginLayout from "../components/LoginLayout";
import { setSocket } from "../Redux/Reducers/SocketSlice";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { getMessage, getRoom } from "../Redux/Action/message";
import { getChatId, getSenderData } from "../Redux/Reducers/RoomSlice";
import GroupSearchComp from "../components/GroupSearchComp";
import moment from "moment";
import CreateMeetingComp from "../Modal/CreateMeetingComp";
import TransferModalComp from "../Modal/TransferModalComp";
import CautionModalComp from "../Modal/CautionModalComp";
import AgentModalComp from "../Modal/AgentModalComp";
import BlockModalComp from "../Modal/BlockModalComp";

export default function Message() {
  const dispatch = useDispatch();
  const [socket, setsocket] = useState(null);
  const messagesEndRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [isBlockedState, setIsBlockedState] = useState(0);
  const userId =
    localStorage.getItem("userId") || sessionStorage.getItem("userId");

  useEffect(() => {
    let socketConnection;
    const token = localStorage.getItem("token");
    socketConnection = io("https://sports-agent-dev.bosselt.com", {
      extraHeaders: {
        authorization: token,
      },
    });
    console.log("socketConnection", socketConnection);
    setsocket(socketConnection);
  }, []);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (socket) {
      dispatch(setSocket(socket));
    }
  }, [socket]);

  const [show, setShow] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [showAgent, setShowAgent] = useState(false);
  const [showSelected, setShowSelected] = useState(false);

  const { setSocketState } = useSelector((state) => state.socket);
  const { chatId, senderData } = useSelector((state) => state.room);
  const { messageList } = useSelector((state) => state.msg);
  const { roomList } = useSelector((state) => state.room);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getRoom()).then((res) => {
      if (res?.payload?.status === 200) {
        let dataArray = res?.payload?.data;
        let getRoomId = sessionStorage.getItem("roomId");
        let matchedRoom = dataArray?.find((room) => room._id === getRoomId);
        let selectedRoom = matchedRoom || dataArray?.[0];
        if (selectedRoom) {
          getSingleUserChat(selectedRoom?._id, selectedRoom, true);
        }
        setLoading(false);
      }
    });
    return () => {
      sessionStorage.removeItem("roomId");
    };
  }, [dispatch]);

  const getSingleUserChat = (id, data, type) => {
    if (id) {
      sessionStorage.setItem("roomId", id);
      dispatch(getChatId(id));
      readCountFlow(id);
    }

    if (userId) {
      if (type) {
        let newValues = {
          profile_image: data?.profile_image ?? "",
          name: data?.full_name ?? "",
          _id: data?._id ?? "",
        };
        const userData =
          userId === data?.sender_id?._id
            ? data?.receiver_id
            : userId === data?.receiver_id?._id
            ? data?.sender_id
            : newValues;
        dispatch(getSenderData(userData));
      } else {
        dispatch(getSenderData(data));
      }
    }
  };

  console.log("senderData", senderData);

  const readCountFlow = (id) => {
    let roomIdVal = sessionStorage.getItem("roomId");
    dispatch(getMessage({ roomId: roomIdVal || id })).then((res) => {
      if (res?.payload?.status === 200) {
        setIsBlockedState(res?.payload?.data?.isBlocked);
        dispatch(getRoom());
      }
    });
  };

  useEffect(() => {
    setMessages(messageList?.filtered_data);
  }, [messageList?.filtered_data]);

  const handleSendMessage = () => {
    if (message.trim() === "") return;
    const newMessage = {
      sender_id: userId,
      room_id: chatId,
      message_type: 0,
      message: message,
    };
    setSocketState.emit("send_message", newMessage);
    readCountFlow();
    setMessage("");
    scrollToBottom();
  };

  useEffect(() => {
    if (setSocketState) {
      setSocketState.on(`receive_message`, async (res) => {
        let roomIdVal = sessionStorage?.getItem("roomId");
        if (res?.room_id === roomIdVal) {
          setMessages((prevMessages) => [...prevMessages, res]);
          readCountFlow();
          scrollToBottom();
        } else {
          await dispatch(getRoom());
        }
      });
    }
  }, [setSocketState]);

  const getSearchApiCall = async (searchValue) => {
    await dispatch(getRoom({ search: searchValue })).then((res) => {
      if (res?.payload?.status === 200) {
        console.log("searchData", res?.payload?.message);
      }
    });
  };

  const handleSearch = useCallback(
    (searchValue) => {
      if (searchValue == "") {
        dispatch(getRoom()).then((res) => {
          if (res?.payload?.status === 200) {
            let data = res?.payload?.data;
            getSingleUserChat(data[0]?._id, data[0], true);
          }
        });
      } else if (searchValue != "") {
        getSearchApiCall(searchValue);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (messages?.length > 10) {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [messages?.length]);

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <Row>
              <Col lg={4}>
                <div className="message-left">
                  <div className="search-input">
                    <img src={require("../Assets/images/search.svg").default} />
                    <GroupSearchComp
                      onSearch={handleSearch}
                      placeholder={"Search here"}
                    />
                  </div>
                  <h5 className="heading">{roomList?.length} Message</h5>
                  <div className="messages-total">
                    {loading ? (
                      <div className="loader">Loading...</div>
                    ) : Array.isArray(roomList) && roomList?.length > 0 ? (
                      roomList?.map((res, index) => {
                        const data =
                          userId === res?.receiver_id?._id
                            ? res?.sender_id
                            : userId === res?.sender_id?._id
                            ? res?.receiver_id
                            : null;

                        return (
                          <div
                            className="message-box mb-3"
                            key={index}
                            onClick={() => {
                              getSingleUserChat(res?._id, data, false);
                            }}
                          >
                            <img
                              src={
                                data?.profile_image
                                  ? `${process.env.REACT_APP_IMAGE_PATH}${data?.profile_image}`
                                  : require("../Assets/images/user.png")
                              }
                            />
                            <div className="w-100">
                              <h3 className="text-end">
                                {res?.createdAt &&
                                  moment(res?.createdAt).format("hh:mm a")}
                              </h3>
                              <h6>{data?.full_name || "N/A"}</h6>
                              <div className="d-flex justify-content-between align-items-center w-100">
                                <p>
                                  {res?.last_message?.message_content?.length >
                                  50
                                    ? res?.last_message?.message_content.slice(
                                        0,
                                        50
                                      ) + "..."
                                    : res?.last_message?.message_content}
                                </p>
                                {data?.unread_count > 0 && (
                                  <span>{data?.unread_count}</span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="no-chat">No chats available</div>
                    )}
                  </div>
                </div>
              </Col>

              {chatId ? (
                <Col lg={8}>
                  <div className="message-right" ref={messagesEndRef}>
                    <div className="user-top-info">
                      <div className="user-top-inner">
                        <img
                          src={
                            senderData?.profile_image
                              ? `${process.env.REACT_APP_IMAGE_PATH}${senderData?.profile_image}`
                              : require("../Assets/images/user.png")
                          }
                        />
                        <div>
                          <h3>{senderData?.full_name || "N/A"}</h3>
                          <p>Agent</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <button
                          onClick={() => setShow(true)}
                          type="button"
                          className="me-4"
                          style={{ border: "none", background: "none" }}
                        >
                          <img
                            src={
                              require("../Assets/images/calendor.svg").default
                            }
                          />
                        </button>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <img
                              src={require("../Assets/images/dots.svg").default}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setIsModalOpen(true)}>
                              {isBlockedState === 0 || isBlockedState === 2
                                ? `Block`
                                : `Unblock`}{" "}
                              User
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setShowTransfer(true)}
                            >
                              Transfer player/Director/Coach
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="message-box-sec">
                      {Array.isArray(messages) &&
                        messages.length > 0 &&
                        messages.map((res) => {
                          return (
                            <>
                              <div>
                                {userId === res?.sender_id?._id && (
                                  <>
                                    <div className="left-user justify-content-end">
                                      <div>
                                        <h4>{res?.message_content || "N/A"}</h4>
                                        <p>
                                          {" "}
                                          {moment(res?.createdAt).format(
                                            "hh:mm a"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {userId !== res?.sender_id?._id && (
                                  <>
                                    <div className="left-user">
                                      <img
                                        src={
                                          res?.sender_id?.profile_image
                                            ? `${process.env.REACT_APP_IMAGE_PATH}${res?.sender_id?.profile_image}`
                                            : require("../Assets/images/user.png")
                                                .default
                                        }
                                      />
                                      <div>
                                        <h4>{res?.message_content || "N/A"}</h4>
                                        <p>
                                          {" "}
                                          {moment(res?.createdAt).format(
                                            "hh:mm a"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          );
                        })}
                    </div>

                    {isBlockedState === 0 || isBlockedState === 2 ? (
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSendMessage();
                        }}
                      >
                        <div className="message-send">
                          <Form.Group className="set-mssage-widht">
                            <Form.Control
                              type="text"
                              placeholder="Type message.."
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                          </Form.Group>
                          <button type="submit">
                            <img
                              src={
                                require("../Assets/images/sendicon.svg").default
                              }
                            />
                          </button>
                        </div>
                      </Form>
                    ) : (
                      <div className="text-center"> Agent is Blocked</div>
                    )}
                  </div>
                </Col>
              ) : (
                <Col lg={8}> No Data Found</Col>
              )}
            </Row>
          </div>
        </Container>
      </section>

      {/* Block Modal */}
      <BlockModalComp
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isBlockedState={isBlockedState}
        readCountFlow={readCountFlow}
      />

      {/* Create Meeting */}
      <CreateMeetingComp show={show} setShow={setShow} />

      {/* Transfer  */}
      <TransferModalComp
        showTransfer={showTransfer}
        setShowTransfer={setShowTransfer}
        setShowAgent={setShowAgent}
        setShowSelected={setShowSelected}
      />

      {/* Caution */}
      <CautionModalComp
        showSelected={showSelected}
        setShowSelected={setShowSelected}
      />
    </LoginLayout>
  );
}
