import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { getRoom } from "../Action/message";

const RoomSlice = createSlice({
  name: "room",
  initialState: {
    query: "",
    isLoading: false,
    isError: false,
    roomList: [],
    memberList: [],
    chatId: null,
    senderData:null
  },
  reducers: {
    getChatId: (state, action) => {
      state.chatId = action.payload;
    },
    // getSenderId: (state, action) => {
    //   state.senderId = action.payload;
    // },
    getSenderData: (state, action) => {
      state.senderData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Room Listing
    builder.addCase(getRoom.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRoom.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.success) {
        state.roomList = action.payload.data;
      } 
      // else {
      //   toast.error(action.payload.message);
      // }
    });
    builder.addCase(getRoom.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      toast.error(action.error.message || "Something went wrong");
    });
  },
});

export const { getChatId, getSenderData } = RoomSlice.actions;
export default RoomSlice.reducer;
