import React from "react";
import { Tab, Tabs, Accordion, Row, Col } from "react-bootstrap";
import { playersTeam } from "../utils/rawjson";

const PlayersFilterTab = ({
  playersMainInformation,
  professionalRelations,
  currentContractDetails,
  newContractDetails,
  playerProposalDetails,
  teamsInterestedInPlayer,
  playersStatistics,
  healthStatus,
  otherInformation,
  renderSelect,
  renderSelect1,
  renderSelect2,
  renderSelect3,
  renderSelect4,
  renderSelect5,
  renderSelect6,
  renderSelect7,
  renderSelect8,
  renderSelect9,
  handleSearchForPlayer,
}) => {
  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Players Main Information</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(playersMainInformation).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">{renderSelect(key)}</div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="9">
          <Accordion.Header>Player's Team</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(playersTeam).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelect9(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Professional Relations</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(professionalRelations).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelect1(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Current Contract Details</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(currentContractDetails).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelect2(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>New Contract Details</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(newContractDetails).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelect3(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>Player Proposal Details</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(playerProposalDetails).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelect4(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>Teams Interested in Player</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(teamsInterestedInPlayer).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelect5(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>Players Statistics</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(playersStatistics).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelect6(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header>Health Status</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(healthStatus).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelect7(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8">
          <Accordion.Header>Other Information</Accordion.Header>
          <Accordion.Body>
            <Row>
              {Object.keys(otherInformation).map((key, index) => (
                <Col lg={4} key={index}>
                  <div className="profile-card mt-3">
                    <div className="accordion-content">
                      {renderSelect8(key)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>

      
      </Accordion>

      <Row>
        <Col lg={12} className="mt-4 d-flex justify-content-end">
          <div className="cmn-light-btn">
            <button type="button" onClick={handleSearchForPlayer}>
              Search
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PlayersFilterTab;
