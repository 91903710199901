// import React, { useEffect, useState } from "react";
// import LoginLayout from "../components/LoginLayout";
// import { Col, Container, Form, Row } from "react-bootstrap";
// import { useNavigate, useParams } from "react-router-dom";
// import {
//   Formik,
//   Form as FormikForm,
//   Field,
//   ErrorMessage,
//   FieldArray,
// } from "formik";
// import Select from "react-select";
// import { useDispatch, useSelector } from "react-redux";
// import { staticDirectorDataAction } from "../Redux/Action/staticAction";
// import { Decryptedid, Encryptedid } from "../utils/Becrypt";
// import toast from "react-hot-toast";
// import { getYesterday } from "../utils/helper";
// import {
//   editDirectorAction,
//   getDirectorDataById,
// } from "../Redux/Action/directorAction";
// import {
//   editCoachAction,
//   getCoachesDataById,
// } from "../Redux/Action/coachAction";

// export default function CoachMainInfo() {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const { id } = useParams();
//   const coachID = id && Decryptedid(atob(id));

//   const [age, setAge] = useState("");
//   const [isFree, setIsFree] = useState(false);

//   const [inputType, setInputType] = useState("text");
//   const [inputType1, setInputType1] = useState("text");

//   const staticListForDirector = useSelector(
//     (e) => e.staticAuthData.directorStaticDetail
//   );

//   const coachData = useSelector((e) => e.coachAuthData.coachDetail);

//   const optionsGender = staticListForDirector?.gender?.map((item) => ({
//     label: item?.val,
//     value: item?.id,
//   }));

//   const optionsPlaceOfBirth = staticListForDirector?.place_of_birth?.map(
//     (item) => ({
//       label: item,
//       value: item,
//     })
//   );

//   const optionsNationaity = staticListForDirector?.nationality?.map((item) => ({
//     label: item,
//     value: item,
//   }));

//   const optionsNationaityId = staticListForDirector?.previous_status?.map(
//     (item, i) => ({
//       label: item?.val,
//       value: i,
//     })
//   );

//   const optionsLanguage = staticListForDirector?.languages?.map((item) => ({
//     label: item,
//     value: item,
//   }));

//   const optionsCoachingLicense = staticListForDirector?.coaching_licenses?.map(
//     (item) => ({
//       label: item?.val,
//       value: item?.id,
//     })
//   );

//   const optionsFoot = staticListForDirector?.foot?.map((item) => ({
//     label: item?.val,
//     value: item?.id,
//   }));

//   const optionsPosition = staticListForDirector?.positions?.map((item) => ({
//     label: item?.val,
//     value: item?.id,
//   }));

//   const optionsTeamLeague = staticListForDirector?.interested_team_league?.map(
//     (item) => ({
//       label: item?.val,
//       value: item?.id,
//     })
//   );

//   const optionsTrophy = staticListForDirector?.trophy?.map((item) => ({
//     label: item?.val,
//     value: item?.id,
//   }));

//   const optionsPlayerStatus = staticListForDirector?.availability_status?.map(
//     (item) => ({
//       label: item?.val,
//       value: item?.id,
//     })
//   );

//   useEffect(() => {
//     dispatch(staticDirectorDataAction());
//   }, [dispatch]);

//   const validateForm = (values) => {
//     const errors = {};

//     if (!values.gender || values.gender.length === 0) {
//       errors.gender = "required";
//     }

//     if (!values.full_name || values.full_name.length === 0) {
//       errors.full_name = "required";
//     }

//     if (!values.dob || values.dob.length === 0) {
//       errors.dob = "required";
//     }

//     if (!values.place_of_birth || values.place_of_birth.length === 0) {
//       errors.place_of_birth = "required";
//     }

//     if (!values.nationality || values.nationality.length === 0) {
//       errors.nationality = "required";
//     }

//     if (!values.previous_status || values.previous_status.length === 0) {
//       errors.previous_status = "required";
//     }

//     if (!values.coaching_licenses || values.coaching_licenses.length === 0) {
//       errors.coaching_licenses = "required";
//     }

//     if (!values.languages || values.languages.length === 0) {
//       errors.languages = "required";
//     }

//     if (
//       !values.availability_status ||
//       values.availability_status.length === 0
//     ) {
//       errors.availability_status = "required";
//     }

//     if (values.carrier_trophies && values.carrier_trophies.length > 0) {
//       values.carrier_trophies.forEach((item, index) => {
//         if (!item.trophy) {
//           if (!errors.carrier_trophies) errors.carrier_trophies = [];
//           errors.carrier_trophies[index] = { trophy: "Trophy is required" };
//         }
//         if (!item.date_of_winning) {
//           if (!errors.carrier_trophies) errors.carrier_trophies = [];
//           errors.carrier_trophies[index] = {
//             ...errors.carrier_trophies[index],
//             date_of_winning: "Date is required",
//           };
//         }
//       });
//     }
//     return errors;
//   };

//   const calculateAge = (dob) => {
//     const today = new Date();
//     const birthDateObj = new Date(dob);
//     let age = today.getFullYear() - birthDateObj.getFullYear();
//     const monthDifference = today.getMonth() - birthDateObj.getMonth();

//     if (
//       monthDifference < 0 ||
//       (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
//     ) {
//       age--;
//     }

//     setAge(age);
//   };

//   const handleFileChange = (e, setFieldValue) => {
//     const file = e.target.files[0];
//     const allowedTypes = [
//       "application/pdf",
//       "application/msword",
//       "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//     ];

//     if (file && allowedTypes.includes(file.type)) {
//       setFieldValue("player_cv", file);
//     } else {
//       toast.error("Please select a PDF, DOC, or DOCX file.");
//     }
//   };

//   useEffect(() => {
//     dispatch(getCoachesDataById({ id: coachID }));
//   }, [dispatch]);

//   console.log(coachData?.previous_status, "sdfsdfsd333", optionsNationaityId);

//   const initialValues = {
//     gender:
//       optionsGender?.find((elem) => elem.value === coachData?.gender) || [],
//     full_name: coachData?.full_name || "",
//     dob: coachData?.dob?.split("T")[0] || "",
//     current_team_name: coachData?.current_team_name || "",
//     main_info_other_notes: coachData?.main_info_other_notes || "",
//     place_of_birth:
//       optionsPlaceOfBirth?.find(
//         (elem) => elem.value === coachData?.place_of_birth
//       ) || [],

//     nationality:
//       coachData?.nationality?.map((item) => ({
//         label: item,
//         value: item,
//       })) || [],

//     previous_status:
//       coachData?.previous_status?.length > 0
//         ? coachData?.previous_status?.map((status) =>
//             optionsNationaityId?.find((elem) => elem.value === status)
//           )
//         : [],

//     languages:
//       coachData?.languages?.map((item) => ({
//         label: item,
//         value: item,
//       })) || [],

//     coaching_licenses:
//       Array.isArray(optionsCoachingLicense) &&
//       coachData?.coaching_licenses?.length > 0
//         ? optionsCoachingLicense.find(
//             (elem) => elem.value === coachData.coaching_licenses[0]
//           )
//         : "",

//     positions:
//       coachData?.positions
//         ?.map((footValue) => {
//           const matchedOption = optionsPosition?.find(
//             (option) => option.value === footValue
//           );
//           return matchedOption || null;
//         })
//         .filter((option) => option !== null) || [],

//     is_player_free: coachData?.is_player_free || 0,
//     current_team_country:
//       optionsPlaceOfBirth?.find(
//         (elem) => elem.value === coachData?.current_team_country
//       ) || "",
//     current_team_league:
//       optionsTeamLeague?.find(
//         (elem) => elem.value == coachData?.current_team_league
//       ) || "",

//     availability_status:
//       coachData?.availability_status
//         ?.map((footValue) => {
//           const matchedOption = optionsPlayerStatus?.find(
//             (option) => option.value === footValue
//           );
//           return matchedOption || null;
//         })
//         .filter((option) => option !== null) || [],

//     previous_team:
//       coachData?.previous_team?.length > 0
//         ? coachData?.previous_team?.map((elem) => ({
//             previous_team_name: elem?.previous_team_name,
//             previous_team_country: optionsPlaceOfBirth?.find(
//               (option) => option.value === elem.previous_team_country
//             ),
//             previous_team_league: optionsTeamLeague?.find(
//               (option) => option.value == elem.previous_team_league
//             ),
//           }))
//         : [
//             {
//               previous_team_name: "",
//               previous_team_country: "",
//               previous_team_league: "",
//             },
//           ],

//     carrier_trophies:
//       coachData?.carrier_trophies?.length > 0
//         ? coachData?.carrier_trophies?.map((elem) => ({
//             trophy: optionsTrophy?.find(
//               (option) => option.value === elem.trophy
//             ),
//             date_of_winning: elem?.date_of_winning?.split("T")[0],
//           }))
//         : [{ trophy: null, date_of_winning: "" }],

//     player_cv: coachData?.player_cv || null,
//   };

//   return (
//     <LoginLayout>
//       <section>
//         <Container>
//           <div className="cmn-card">
//             <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
//               <h2>Coaches Main Information</h2>
//             </div>
//             <Formik
//               initialValues={initialValues}
//               validate={validateForm}
//               enableReinitialize={true}
//               onSubmit={(values) => {
//                 const transformedData = [];
//                 const transFormPreviousTeamData = [];

//                 values.carrier_trophies.forEach((item) => {
//                   const trophies = Array.isArray(item.trophy)
//                     ? item.trophy
//                     : [item.trophy];

//                   trophies.forEach((trophy) => {
//                     transformedData.push({
//                       trophy: trophy.value,
//                       date_of_winning: item.date_of_winning,
//                     });
//                   });
//                 });

//                 values.previous_team.forEach((item) => {
//                   transFormPreviousTeamData.push({
//                     previous_team_name: item?.previous_team_name,
//                     previous_team_country: item?.previous_team_country?.value,
//                     previous_team_league: item?.previous_team_league?.value,
//                   });
//                 });

//                 let formData = new FormData();

//                 Object.keys(values).forEach((key) => {
//                   if (Array.isArray(values[key])) {
//                     values[key].forEach((value, index) => {
//                       if (
//                         key !== "carrier_trophies" &&
//                         key !== "previous_team"
//                       ) {
//                         if (typeof value === "object" && value !== null) {
//                           formData.append(`${key}[${index}]`, value.value);
//                         } else {
//                           formData.append(`${key}[${index}]`, value);
//                         }
//                       }
//                     });
//                   } else {
//                     if (key === "coaching_licenses") {
//                       formData.append(key, values[key].value);
//                     } else if (
//                       typeof values[key] === "object" &&
//                       values[key] !== null &&
//                       values[key].value !== undefined
//                     ) {
//                       formData.append(key, values[key].value);
//                     } else {
//                       formData.append(key, values[key]);
//                     }
//                   }
//                 });

//                 formData.append(
//                   "carrier_trophies",
//                   JSON.stringify(transformedData)
//                 );
//                 formData.append(
//                   "previous_team",
//                   JSON.stringify(transFormPreviousTeamData)
//                 );
//                 formData.append("coachId", coachID);
//                 formData.append("is_profile_completed", 2);

//                 dispatch(editCoachAction(formData)).then((res) => {
//                   if (res?.payload?.status === 200) {
//                     navigate(
//                       `/coaches-agent-relationship/${Encryptedid(
//                         res?.payload?.data?._id
//                       )}`
//                     );
//                   } else {
//                     toast.error(res?.payload?.message);
//                   }
//                 });
//               }}
//             >
//               {({ values, setFieldValue }) => (
//                 <FormikForm>
//                   <div className="common-outer mt-2">
//                     <h3 className="h-20">Personal Details</h3>
//                     <div className="cmn-form-fields mt-2">
//                       <Row>
//                         <Col lg={4}>
//                           <div className="profile-card">
//                             <Select
//                               name="gender"
//                               placeholder="Gender"
//                               options={optionsGender}
//                               onChange={(option) =>
//                                 setFieldValue("gender", option)
//                               }
//                               value={values.gender}
//                             />
//                             <ErrorMessage
//                               name="gender"
//                               component="div"
//                               className="text-danger"
//                             />
//                           </div>
//                         </Col>
//                         <Col lg={4}>
//                           <Form.Group className="mb-3 form-inner">
//                             <Field
//                               name="full_name"
//                               type="text"
//                               className="form-control"
//                               placeholder="Enter full name here"
//                             />
//                             <ErrorMessage
//                               name="full_name"
//                               component="div"
//                               className="text-danger"
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col lg={3}>
//                           <Form.Group className="mb-3 form-inner">
//                             <Field
//                               name="dob"
//                               placeholder="Date of Birth"
//                               type={inputType}
//                               className="form-control"
//                               onChange={(e) => {
//                                 setFieldValue("dob", e.target.value);
//                                 calculateAge(e.target.value);
//                               }}
//                               onFocus={() => setInputType("date")}
//                               onBlur={() => setInputType("text")}
//                               max={getYesterday()}
//                             />
//                             <ErrorMessage
//                               name="dob"
//                               component="div"
//                               className="text-danger"
//                             />
//                           </Form.Group>
//                         </Col>

//                         {age ? (
//                           <Col lg={1}>
//                             <Form.Group className="mb-3 form-inner">
//                               <div className="form-control">{age}</div>
//                             </Form.Group>
//                           </Col>
//                         ) : (
//                           ""
//                         )}
//                         <Col lg={4}>
//                           <div className="profile-card mb-3">
//                             <Select
//                               name="place_of_birth"
//                               placeholder="Place of Birth"
//                               options={optionsPlaceOfBirth}
//                               onChange={(option) =>
//                                 setFieldValue("place_of_birth", option)
//                               }
//                               value={values.place_of_birth}
//                             />
//                             <ErrorMessage
//                               name="place_of_birth"
//                               component="div"
//                               className="text-danger"
//                             />
//                           </div>
//                         </Col>
//                         <Col lg={4}>
//                           <div className="profile-card">
//                             <Select
//                               name="nationality"
//                               placeholder="Nationality(s)"
//                               isMulti
//                               options={optionsNationaity}
//                               onChange={(option) =>
//                                 setFieldValue("nationality", option)
//                               }
//                               value={values.nationality}
//                             />
//                             <ErrorMessage
//                               name="nationality"
//                               component="div"
//                               className="text-danger"
//                             />
//                           </div>
//                         </Col>
//                         <Col lg={4}>
//                           <div className="profile-card">
//                             <Select
//                               name="previous_status"
//                               placeholder="Previous Status"
//                               options={optionsNationaityId}
//                               isMulti
//                               onChange={(option) =>
//                                 setFieldValue("previous_status", option)
//                               }
//                               value={values.previous_status}
//                             />
//                             <ErrorMessage
//                               name="previous_status"
//                               component="div"
//                               className="text-danger"
//                             />
//                           </div>
//                         </Col>

//                         <Col lg={4}>
//                           <div className="profile-card mb-3">
//                             <Select
//                               name="coaching_licenses"
//                               placeholder="Coaching Licenses"
//                               options={optionsCoachingLicense}
//                               onChange={(option) =>
//                                 setFieldValue("coaching_licenses", option)
//                               }
//                               value={values.coaching_licenses}
//                             />
//                             <ErrorMessage
//                               name="coaching_licenses"
//                               component="div"
//                               className="text-danger"
//                             />
//                           </div>
//                         </Col>

//                         <Col lg={4}>
//                           <div className="profile-card mb-3">
//                             <Select
//                               name="languages"
//                               options={optionsLanguage}
//                               placeholder="Languages"
//                               isMulti
//                               onChange={(option) =>
//                                 setFieldValue("languages", option)
//                               }
//                               value={values.languages}
//                             />
//                             <ErrorMessage
//                               name="languages"
//                               component="div"
//                               className="text-danger"
//                             />
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                     <div className="common-outer mt-4">
//                       <div className="d-flex justify-content-between">
//                         <h3 className="h-20">Current Team</h3>
//                         <div className="form-check">
//                           <Field
//                             className="form-check-input"
//                             type="checkbox"
//                             name="is_player_free"
//                             onChange={(e) => {
//                               const isChecked = e.target.checked;
//                               setFieldValue(
//                                 "is_player_free",
//                                 isChecked ? 1 : 0
//                               );
//                               setIsFree(isChecked ? 1 : 0);
//                               if (isChecked) {
//                                 setFieldValue("current_team_name", "");
//                                 setFieldValue("current_team_country", []);
//                                 setFieldValue("current_team_league", []);
//                               }
//                             }}
//                           />
//                           <label
//                             className="form-check-label"
//                             htmlFor="is_player_free"
//                           >
//                             Free
//                           </label>
//                         </div>
//                       </div>
//                       <div className="cmn-form-fields mt-2">
//                         <Row>
//                           <Col lg={4}>
//                             <Form.Group className="mb-3 form-inner">
//                               <Field
//                                 name="current_team_name"
//                                 type="text"
//                                 className="form-control"
//                                 placeholder="Enter Team Name"
//                                 disabled={isFree}
//                               />
//                               <ErrorMessage
//                                 name="current_team_name"
//                                 component="div"
//                                 className="text-danger"
//                               />
//                             </Form.Group>
//                           </Col>
//                           <Col lg={4}>
//                             <div className="profile-card mb-3">
//                               <Select
//                                 name="current_team_country"
//                                 placeholder="Teams Country"
//                                 options={optionsPlaceOfBirth}
//                                 onChange={(option) =>
//                                   setFieldValue("current_team_country", option)
//                                 }
//                                 value={values.current_team_country}
//                                 isDisabled={isFree}
//                               />
//                               <ErrorMessage
//                                 name="current_team_country"
//                                 component="div"
//                                 className="text-danger"
//                               />
//                             </div>
//                           </Col>
//                           <Col lg={4}>
//                             <div className="profile-card">
//                               <Select
//                                 name="current_team_league"
//                                 placeholder="Teams League"
//                                 options={optionsTeamLeague}
//                                 onChange={(option) =>
//                                   setFieldValue("current_team_league", option)
//                                 }
//                                 value={values.current_team_league}
//                                 isDisabled={isFree}
//                               />
//                               <ErrorMessage
//                                 name="current_team_league"
//                                 component="div"
//                                 className="text-danger"
//                               />
//                             </div>
//                           </Col>
//                         </Row>
//                       </div>
//                     </div>

//                     <div className="common-outer mt-4">
//                       <FieldArray name="previous_team">
//                         {({ push, remove }) => (
//                           <>
//                             <div className="d-flex justify-content-between">
//                               <h3 className="h-20">Previous Team</h3>
//                               <button
//                                 type="button"
//                                 onClick={() =>
//                                   push({
//                                     previous_team_name: "",
//                                     previous_team_country: "",
//                                     previous_team_league: "",
//                                   })
//                                 }
//                                 style={{
//                                   border: "none",
//                                   background: "none",
//                                 }}
//                               >
//                                 <svg
//                                   width="28"
//                                   coaching_licenses="28"
//                                   viewBox="0 0 28 28"
//                                   fill="none"
//                                   xmlns="http://www.w3.org/2000/svg"
//                                 >
//                                   <path
//                                     d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
//                                     fill="#4C8BB4"
//                                   />
//                                 </svg>
//                               </button>
//                             </div>

//                             {values?.previous_team?.map((trophy, index) => (
//                               <div key={index} className="cmn-form-fields mt-2">
//                                 <div className="d-flex justify-content-end mt-3 mb-3">
//                                   {index !== 0 && (
//                                     <button
//                                       type="button"
//                                       onClick={() => remove(index)}
//                                       style={{
//                                         border: "none",
//                                         background: "none",
//                                       }}
//                                     >
//                                       <svg
//                                         width="28"
//                                         coaching_licenses="28"
//                                         viewBox="0 0 28 28"
//                                         fill="none"
//                                         xmlns="http://www.w3.org/2000/svg"
//                                       >
//                                         <path
//                                           fill-rule="evenodd"
//                                           clip-rule="evenodd"
//                                           d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
//                                           fill="#4C8BB4"
//                                         />
//                                       </svg>
//                                     </button>
//                                   )}
//                                 </div>
//                                 <Row>
//                                   <Col lg={4}>
//                                     <Form.Group className="mb-3 form-inner">
//                                       <Field
//                                         name={`previous_team[${index}].previous_team_name`}
//                                         type="text"
//                                         className="form-control"
//                                         placeholder="Enter Team Name"
//                                       />
//                                       <ErrorMessage
//                                         name={`previous_team[${index}].previous_team_name`}
//                                         component="div"
//                                         className="text-danger"
//                                       />
//                                     </Form.Group>
//                                   </Col>

//                                   <Col lg={4}>
//                                     <div className="profile-card mb-3">
//                                       <Select
//                                         name={`previous_team[${index}].previous_team_country`}
//                                         placeholder="Teams Country"
//                                         options={optionsPlaceOfBirth}
//                                         onChange={(option) =>
//                                           setFieldValue(
//                                             `previous_team[${index}].previous_team_country`,
//                                             option
//                                           )
//                                         }
//                                         value={
//                                           values.previous_team[index]
//                                             .previous_team_country
//                                         }
//                                       />
//                                       <ErrorMessage
//                                         name={`previous_team[${index}].previous_team_country`}
//                                         component="div"
//                                         className="text-danger"
//                                       />
//                                     </div>
//                                   </Col>
//                                   <Col lg={4}>
//                                     <div className="profile-card">
//                                       <Select
//                                         name={`previous_team[${index}].previous_team_league`}
//                                         placeholder="Teams League"
//                                         options={optionsTeamLeague}
//                                         onChange={(option) =>
//                                           setFieldValue(
//                                             `previous_team[${index}].previous_team_league`,
//                                             option
//                                           )
//                                         }
//                                         value={
//                                           values.previous_team[index]
//                                             .previous_team_league
//                                         }
//                                       />
//                                       <ErrorMessage
//                                         name={`previous_team[${index}].previous_team_league`}
//                                         component="div"
//                                         className="text-danger"
//                                       />
//                                     </div>
//                                   </Col>
//                                 </Row>
//                               </div>
//                             ))}
//                           </>
//                         )}
//                       </FieldArray>
//                     </div>
//                   </div>

//                   <div className="common-outer mt-4">
//                     <h3 className="h-20">Availability Status</h3>
//                     <div className="cmn-form-fields mt-2">
//                       <Row>
//                         <Col lg={4}>
//                           <div className="profile-card mb-3">
//                             <Select
//                               name="availability_status"
//                               placeholder="Player Status"
//                               options={optionsPlayerStatus}
//                               isMulti
//                               onChange={(option) =>
//                                 setFieldValue("availability_status", option)
//                               }
//                               value={values.availability_status}
//                             />
//                             <ErrorMessage
//                               name="availability_status"
//                               component="div"
//                               className="text-danger"
//                             />
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                   </div>

//                   <div className="common-outer mt-4">
//                     <FieldArray name="carrier_trophies">
//                       {({ push, remove }) => (
//                         <>
//                           <div className="d-flex justify-content-between">
//                             <h3 className="h-20">Carrier Trophies</h3>
//                             <button
//                               type="button"
//                               onClick={() =>
//                                 push({ trophy: null, date_of_winning: "" })
//                               }
//                               style={{
//                                 border: "none",
//                                 background: "none",
//                               }}
//                             >
//                               <svg
//                                 width="28"
//                                 coaching_licenses="28"
//                                 viewBox="0 0 28 28"
//                                 fill="none"
//                                 xmlns="http://www.w3.org/2000/svg"
//                               >
//                                 <path
//                                   d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
//                                   fill="#4C8BB4"
//                                 />
//                               </svg>
//                             </button>
//                           </div>

//                           {values?.carrier_trophies?.map((trophy, index) => (
//                             <div key={index} className="cmn-form-fields mt-2">
//                               <Row>
//                                 <Col lg={4}>
//                                   <div className="profile-card mb-3">
//                                     <Select
//                                       name={`carrier_trophies[${index}].trophy`}
//                                       placeholder="Tropies/Champanioship"
//                                       options={optionsTrophy}
//                                       isMulti
//                                       onChange={(option) =>
//                                         setFieldValue(
//                                           `carrier_trophies[${index}].trophy`,
//                                           option
//                                         )
//                                       }
//                                       value={
//                                         values.carrier_trophies[index].trophy
//                                       }
//                                     />
//                                     <ErrorMessage
//                                       name={`carrier_trophies[${index}].trophy`}
//                                       component="div"
//                                       className="text-danger"
//                                     />
//                                   </div>
//                                 </Col>
//                                 <Col lg={4}>
//                                   <Form.Group className="mb-3 form-inner">
//                                     <Field
//                                       name={`carrier_trophies[${index}].date_of_winning`}
//                                       className="form-control"
//                                       placeholder="Date of Winning"
//                                       type={inputType1}
//                                       onFocus={() => setInputType1("date")}
//                                       onBlur={() => setInputType1("text")}
//                                     />
//                                     <ErrorMessage
//                                       name={`carrier_trophies[${index}].date_of_winning`}
//                                       component="div"
//                                       className="text-danger"
//                                     />
//                                   </Form.Group>
//                                 </Col>
//                                 <Col lg={4}>
//                                   {index !== 0 && (
//                                     <button
//                                       type="button"
//                                       onClick={() => remove(index)}
//                                       style={{
//                                         border: "none",
//                                         background: "none",
//                                       }}
//                                     >
//                                       <svg
//                                         width="28"
//                                         coaching_licenses="28"
//                                         viewBox="0 0 28 28"
//                                         fill="none"
//                                         xmlns="http://www.w3.org/2000/svg"
//                                       >
//                                         <path
//                                           fill-rule="evenodd"
//                                           clip-rule="evenodd"
//                                           d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
//                                           fill="#4C8BB4"
//                                         />
//                                       </svg>
//                                     </button>
//                                   )}
//                                 </Col>
//                               </Row>
//                             </div>
//                           ))}
//                         </>
//                       )}
//                     </FieldArray>
//                   </div>

//                   <div className="common-outer mt-4">
//                     <h3 className="h-20">Other Notes</h3>
//                     <div className="cmn-form-fields">
//                       <Row>
//                         <Col lg={12}>
//                           <Form.Group className="mb-3 form-inner">
//                             <Field
//                               as="textarea"
//                               name="main_info_other_notes"
//                               rows={3}
//                               className="form-control"
//                               placeholder="Write here"
//                             />
//                             <ErrorMessage
//                               name="main_info_other_notes"
//                               component="div"
//                               className="text-danger"
//                             />
//                           </Form.Group>
//                         </Col>
//                       </Row>
//                     </div>
//                   </div>

//                   {/* <div className="common-outer mt-4">
//                     <h3 className="h-20">Players CV</h3>
//                     <Row>
//                       <Col lg={6}>
//                         <div className="select-file">
//                           <input
//                             name="player_cv"
//                             type="file"
//                             accept=".pdf, .doc, .docx"
//                             onChange={(e) => handleFileChange(e, setFieldValue)}
//                           />
//                           <p>Choose File</p>
//                           <ErrorMessage
//                             name="player_cv"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </div>
//                       </Col>
//                       <Col
//                         lg={6}
//                         className="d-flex justify-content-center align-items-center"
//                       >
//                         <p className="mb-0">
//                           {values.player_cv
//                             ? "File Selected"
//                             : "No File Chosen"}
//                         </p>
//                       </Col>
//                     </Row>
//                   </div> */}

//                   <Col lg={12} className="mt-4 d-flex justify-content-end">
//                     <div className="cmn-light-btn">
//                       <button type="submit">Next</button>
//                     </div>
//                   </Col>
//                 </FormikForm>
//               )}
//             </Formik>
//           </div>
//         </Container>
//       </section>
//     </LoginLayout>
//   );
// }

import React, { useEffect, useState } from "react";
import LoginLayout from "../components/LoginLayout";
import { Container, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { staticDirectorDataAction } from "../Redux/Action/staticAction";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { addNewTeam } from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import PreviewModal from "../CommonModals/PreviewModal";
import ProgressBar from "../CommonModals/ProgressBar";
import Loader from "../components/Loader";
import { clearPlayerDetail } from "../Redux/Reducers/playerSlice";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";
import { getTeamListAction } from "../Redux/Action/teamAction";
import {
  createCoachAction,
  editCoachAction,
  getCoachesDataById,
} from "../Redux/Action/coachAction";

Modal.setAppElement("#root");

const CoachMainInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const [coachId, setCoachId] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ids = queryParams.get("coachId");
    const id = ids && Decryptedid(atob(ids));

    if (id) {
      setCoachId(id);
      dispatch(getCoachesDataById({ id, type: 1 }));
    }
  }, [dispatch, location.search]);

  const [age, setAge] = useState("");
  const [isFree, setIsFree] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTeamName, setNewTeamName] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [dragging, setDragging] = useState(false);

  const staticListForDirector = useSelector(
    (e) => e.staticAuthData.directorStaticDetail
  );
  const coachData = useSelector((e) => e.coachAuthData.coachDetail);
  const playerDataLoadingState = useSelector((e) => e.playerAuthData.loading);
  const teanListingData = useSelector((e) => e.teamAuthData.getTeamListing);

  console.log(coachData, "coachData ");

  const [teamOptions, setTeamOptions] = useState(
    teanListingData?.map((elem) => ({
      label: elem?.team_name,
      value: elem?._id,
      data: elem,
    })) || []
  );

  useEffect(() => {
    const teamOptions = teanListingData?.map((elem) => ({
      label: elem?.team_name,
      value: elem?._id,
      data: elem,
    }));
    setTeamOptions(teamOptions);
  }, [teanListingData]);

  const optionsGender = staticListForDirector?.gender?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPlaceOfBirth = staticListForDirector?.place_of_birth?.map(
    (item) => ({
      label: item,
      value: item,
    })
  );

  const optionsNationaity = staticListForDirector?.nationality?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsLanguage = staticListForDirector?.languages?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsTeamLeague = staticListForDirector?.proposed_team_league?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsTrophy = staticListForDirector?.trophy?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPlayerStatus = staticListForDirector?.availability_status?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsAskingAmount = staticListForDirector?.asking_amount?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  const optionsannualAskingSalary =
    staticListForDirector?.current_contract?.map((item) => ({
      label: item?.val,
      value: item?.val,
    }));

  const optionsNationaityId = staticListForDirector?.previous_status?.map(
    (item, i) => ({
      label: item?.val,
      value: i,
    })
  );

  const optionsCoachingLicense = staticListForDirector?.coaching_licenses?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  useEffect(() => {
    dispatch(staticDirectorDataAction());
  }, [dispatch]);

  const validateForm = (values) => {
    const errors = {};

    if (!values.gender || values.gender.length === 0) {
      errors.gender = "required";
    }

    if (!values.full_name || values.full_name.trim().length === 0) {
      errors.full_name = "required";
    }

    if (!values.dob || values.dob.length === 0) {
      errors.dob = "required";
    }

    if (!values.nationality || values.nationality.length === 0) {
      errors.nationality = "required";
    }

    if (!values.coaching_licenses || values.coaching_licenses.length === 0) {
      errors.coaching_licenses = "required";
    }

    if (!values.previous_status || values.previous_status.length === 0) {
      errors.previous_status = "required";
    }

    if (!values.languages || values.languages.length === 0) {
      errors.languages = "required";
    }

    if (
      !values.availability_status ||
      values.availability_status.length === 0
    ) {
      errors.availability_status = "required";
    }

    if (values.is_coach_freee == 0) {
      if (!values.team_id) {
        errors.team_id = "required";
      }
    }

    if (
      !values.availability_status ||
      values.availability_status.length === 0
    ) {
      errors.availability_status = "required";
    }

    // ====================================================================================
    if (!values.new_contract_annual_salary) {
      errors.new_contract_annual_salary = "required";
    }

    // ===================================================================================

    if (!values.carrier_trophies || values.carrier_trophies.length === 0) {
      errors.carrier_trophies = "required";
    }

    console.log(errors, "sdfsdfsdfsdfsd234234");
    return errors;
  };

  useEffect(() => {
    if (coachData?.dob) {
      const birthDate = moment(coachData.dob).toDate();
      calculateAge(birthDate);
    }
  }, [coachData?.dob]);

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDateObj = new Date(dob);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }
    setAge(age);
  };

  const handleFileChange1 = (e, setFieldValue, fieldName) => {
    const file = e.target.files[0];
    setFieldValue(fieldName, file ? file : null);
  };

  useEffect(() => {
    dispatch(clearPlayerDetail());
    if (coachId) {
      dispatch(getCoachesDataById({ id: coachId, type: 1 }));
    }
    dispatch(getTeamListAction());
  }, [dispatch, coachId]);

  const initialValues = {
    gender:
      optionsGender?.find((elem) => elem.value === coachData?.gender) || [],
    full_name: coachData?.full_name || "",
    dob: coachData?.dob ? moment(coachData.dob).format("DD/MM/YYYY") : "",
    main_info_other_notes: coachData?.main_info_other_notes || "",
    place_of_birth:
      optionsPlaceOfBirth?.find(
        (elem) => elem.value === coachData?.place_of_birth
      ) || [],
    nationality:
      coachData?.nationality?.map((item) => ({
        label: item,
        value: item,
      })) || [],

    languages:
      coachData?.languages?.map((item) => ({ label: item, value: item })) || [],

    is_coach_free: coachData?.is_coach_free || 0,
    team_id:
      teamOptions?.find((elem) => elem.value === coachData?.team_id?._id) || "",
    team_country:
      optionsPlaceOfBirth?.find(
        (elem) => elem.value === coachData?.team_id?.team_country
      ) ?? null,
    team_league:
      optionsTeamLeague?.find(
        (elem) => elem.value == coachData?.team_id?.team_league
      ) ?? null,

    availability_status:
      coachData?.availability_status
        ?.map((footValue) => {
          const matchedOption = optionsPlayerStatus?.find(
            (option) => option.value === footValue
          );
          return matchedOption || null;
        })
        .filter((option) => option !== null) || [],

    carrier_trophies:
      coachData?.carrier_trophies
        ?.map((trophyObj) => {
          return (
            optionsTrophy?.find((option) => option.value === trophyObj) || null
          );
        })
        .filter((option) => option !== null) || [],

    previous_status:
      coachData?.previous_status?.length > 0
        ? coachData?.previous_status?.map((status) =>
            optionsNationaityId?.find((elem) => elem.value === status)
          )
        : [],

    coaching_licenses:
      Array.isArray(optionsCoachingLicense) && coachData?.coaching_licenses
        ? optionsCoachingLicense?.find(
            (elem) => elem.value === coachData.coaching_licenses[0]
          ) || ""
        : "",

    profile_image: coachData?.profile_image || "",
    new_contract_annual_salary:
      optionsannualAskingSalary?.find(
        (elem) => elem.value === coachData?.new_contract_annual_salary
      ) || "",
  };

  const handleShowPreview = (imageSrc) => {
    setPreviewImage(imageSrc);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setPreviewImage("");
  };

  const handleDrop = (e, setFieldValue, type, values) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files);
    if (type === "profile_image") {
      const imageFile = files.find((file) => file.type.startsWith("image/"));

      if (!imageFile) {
        console.error("Only image files are allowed!");
        return;
      }
      setFieldValue("profile_image", imageFile);
    }
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleAddTeam = (values, setFieldValue) => {
    if (!newTeamName.trim()) {
      toast.error("Team name cannot be empty.");
      return;
    }

    let teamCountry = values?.team_country?.value;
    let teamLeague = values?.team_league?.value;

    if (!teamCountry) {
      toast.error("Please select a team country.");
      return;
    }

    if (teamLeague == null) {
      toast.error("Please select a team league.");
      return;
    }

    dispatch(
      addNewTeam({
        name: newTeamName,
        type: 1,
        team_country: teamCountry,
        team_league: teamLeague,
      })
    ).then((res) => {
      if (res?.payload?.status === 200) {
        const newTeamId = res?.payload?.data?._id;
        const newTeam = { label: newTeamName, value: newTeamId };

        setTeamOptions([...teamOptions, newTeam]);
        setFieldValue("team_id", newTeam);

        setTimeout(() => {
          setIsModalOpen(false);
          setNewTeamName("");
        }, 0);
      } else {
        toast.error("Failed to add the team. Please try again.");
      }
    });
  };

  const handleInputChange = (input) => {
    const filteredInput = input.replace(/[^a-zA-Z\s]/g, "");
    const isTeamExists = teamOptions?.some((team) =>
      team?.label?.toLowerCase()?.includes(filteredInput.toLowerCase())
    );
    setIsAddingTeam(!isTeamExists);
    return filteredInput;
  };

  const customOptions = [
    { label: "+ Add new Team", value: "add-team", isAddTeam: true },
    ...teamOptions,
  ];

  const handleSelectChange = (option, setFieldValue) => {
    if (option?.isAddTeam) {
      setIsModalOpen(true);
    } else {
      setFieldValue(
        "team_country",
        optionsPlaceOfBirth?.find(
          (elem) => elem.value === option?.data?.team_country
        )
      );
      setFieldValue(
        "team_league",
        optionsTeamLeague?.find(
          (elem) => elem.value == option?.data?.team_league
        )
      );
      setFieldValue("team_id", option);
    }
  };

  const handleSaveAndClose = (values, validateForm, setErrors) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length > 0) {
        toast.error("Please fill in all required fields.");
        setErrors(errors);
        return;
      }

      if (coachId) {
        if (values.dob) {
          values.dob = moment(values.dob, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        let formData = new FormData();

        Object.keys(values).forEach((key) => {
          if (key === "team_id" && values.is_coach_free === 1) {
            return;
          }

          if (Array.isArray(values[key])) {
            values[key].forEach((value, index) => {
              if (key !== "photos") {
                if (typeof value === "object" && value !== null) {
                  formData.append(`${key}[${index}]`, value?.value);
                } else if (value !== null && value !== undefined) {
                  formData.append(`${key}[${index}]`, value);
                }
              }
            });
          } else {
            if (key === "height" && values[key]?.value !== undefined) {
              formData.append(key, values[key]?.value);
            } else if (
              typeof values[key] === "object" &&
              values[key] !== null &&
              values[key]?.value !== undefined
            ) {
              formData.append(key, values[key]?.value);
            } else if (values[key] !== null && values[key] !== undefined) {
              formData.append(key, values[key]);
            }
          }
        });

        formData.append("coachId", coachId);
        formData.append("is_profile_completed", 1);

        dispatch(editCoachAction(formData)).then((res) => {
          if (res?.payload?.status === 200) {
            navigate("/my-coach");
          } else {
            toast.error(res?.payload?.message);
          }
        });
      } else {
        if (values.dob) {
          values.dob = moment(values.dob, "DD/MM/YYYY").format("YYYY-MM-DD");
        }

        let formData = new FormData();

        Object.keys(values).forEach((key) => {
          if (Array.isArray(values[key])) {
            values[key].forEach((value, index) => {
              if (key !== "photos") {
                if (typeof value === "object" && value !== null) {
                  formData.append(
                    `${key}[${index}]`,
                    key === "team_id" ? value?.value : value?.value
                  );
                } else if (value !== null && value !== undefined) {
                  formData.append(`${key}[${index}]`, value);
                }
              }
            });
          } else {
            if (key === "height" && values[key]?.value !== undefined) {
              formData.append(key, values[key]?.value);
            } else if (key === "gender" || key === "team_id") {
              if (
                values[key]?.value !== null &&
                values[key]?.value !== undefined
              ) {
                formData.append(key, values[key]?.value);
              }
            } else if (
              typeof values[key] === "object" &&
              values[key] !== null &&
              values[key]?.value !== undefined
            ) {
              formData.append(key, values[key]?.value);
            } else if (values[key] !== null && values[key] !== undefined) {
              formData.append(key, values[key]);
            }
          }
        });

        // formData.append("carrier_trophies", JSON.stringify(transformedData));
        formData.append("is_profile_completed", 1);

        dispatch(createCoachAction(formData)).then((res) => {
          if (res?.payload?.status === 200) {
            navigate("/my-coach");
          } else {
            toast.error(res?.payload?.message);
          }
        });
      }
    });
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <ProgressBar currentStep={1} totalSteps={8} />
          <div className="steps-heading">
            <p>
              {" "}
              <span>Step 1:</span> Coach's Main Information{" "}
            </p>
          </div>
          <div className="over-ride-cmn">
            <Formik
              initialValues={initialValues}
              validate={validateForm}
              enableReinitialize={true}
              onSubmit={(values) => {
                if (coachId) {
                  if (values.dob) {
                    values.dob = moment(values.dob, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    );
                  }

                  let formData = new FormData();

                  Object.keys(values).forEach((key) => {
                    if (key === "team_id" && values.is_coach_free === 1) {
                      return;
                    }

                    if (Array.isArray(values[key])) {
                      values[key].forEach((value, index) => {
                        if (key !== "photos") {
                          if (typeof value === "object" && value !== null) {
                            formData.append(`${key}[${index}]`, value?.value);
                          } else if (value !== null && value !== undefined) {
                            formData.append(`${key}[${index}]`, value);
                          }
                        }
                      });
                    } else {
                      if (
                        key === "height" &&
                        values[key]?.value !== undefined
                      ) {
                        formData.append(key, values[key]?.value);
                      } else if (
                        typeof values[key] === "object" &&
                        values[key] !== null &&
                        values[key]?.value !== undefined
                      ) {
                        formData.append(key, values[key]?.value);
                      } else if (
                        values[key] !== null &&
                        values[key] !== undefined
                      ) {
                        formData.append(key, values[key]);
                      }
                    }
                  });

                  formData.append("coachId", coachId);
                  formData.append("is_profile_completed", 1);

                  dispatch(editCoachAction(formData)).then((res) => {
                    if (res?.payload?.status === 200) {
                      navigate(
                        `/coaches-agent-relationship/${Encryptedid(
                          res?.payload?.data?._id
                        )}`
                      );
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                } else {
                  if (values.dob) {
                    values.dob = moment(values.dob, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    );
                  }

                  let formData = new FormData();

                  Object.keys(values).forEach((key) => {
                    if (Array.isArray(values[key])) {
                      values[key].forEach((value, index) => {
                        if (key !== "photos") {
                          if (typeof value === "object" && value !== null) {
                            formData.append(
                              `${key}[${index}]`,
                              key === "team_id" ? value?.value : value?.value
                            );
                          } else if (value !== null && value !== undefined) {
                            formData.append(`${key}[${index}]`, value);
                          }
                        }
                      });
                    } else {
                      if (values[key]?.value !== undefined) {
                        formData.append(key, values[key]?.value);
                      } else if (key === "gender" || key === "team_id") {
                        if (
                          values[key]?.value !== null &&
                          values[key]?.value !== undefined
                        ) {
                          formData.append(key, values[key]?.value);
                        }
                      } else if (
                        typeof values[key] === "object" &&
                        values[key] !== null &&
                        values[key]?.value !== undefined
                      ) {
                        formData.append(key, values[key]?.value);
                      } else if (
                        values[key] !== null &&
                        values[key] !== undefined
                      ) {
                        formData.append(key, values[key]);
                      }
                    }
                  });

                  formData.append("is_profile_completed", 1);

                  dispatch(createCoachAction(formData)).then((res) => {
                    if (res?.payload?.status === 200) {
                      navigate(
                        `/coaches-agent-relationship/${Encryptedid(
                          res?.payload?.data?._id
                        )}`
                      );
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                }
              }}
            >
              {({ values, setFieldValue, validateForm, setErrors }) => (
                <FormikForm>
                  {playerDataLoadingState && <Loader />}
                  <div className="personal-heading mt-2">
                    <h3>Personal Details</h3>

                    <div className="grid-section">
                      <Form.Group className="mb-3 form-inner label-text-up">
                        <label className="mb-2">Full Name</label>
                        <Field
                          name="full_name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Name here"
                          onKeyPress={(event) => {
                            const regex = /^[a-zA-Z\s]*$/;
                            if (!regex.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onPaste={(event) => {
                            const pastedData =
                              event.clipboardData.getData("Text");
                            const regex = /^[a-zA-Z\s]*$/;
                            if (!regex.test(pastedData)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <ErrorMessage
                          name="full_name"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3 form-inner  label-text-up">
                        <label className="mb-2">Date of Birth</label>
                        <div className="age-box">
                          {/* <Field
                            name="dob"
                            placeholder="Date of Birth"
                            type={inputType}
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("dob", e.target.value);
                              calculateAge(e.target.value);
                            }}
                            onFocus={() => setInputType("date")}
                            onBlur={() => setInputType("text")}
                            max={getYesterday()}
                          /> */}

                          <DatePicker
                            id="dob"
                            name="dob"
                            selected={
                              values.dob
                                ? moment(values.dob, "DD/MM/YYYY").toDate()
                                : null
                            }
                            // onChange={(date) => {
                            //   const formattedDate = moment(date).format('DD/MM/YYYY');
                            //   setFieldValue("dob", formattedDate);
                            //   calculateAge(date);
                            // }}
                            onChange={(date) => {
                              if (date) {
                                const formattedDate =
                                  moment(date).format("DD/MM/YYYY");
                                setFieldValue("dob", formattedDate);
                                calculateAge(date);
                              } else {
                                setFieldValue("dob", "");
                              }
                            }}
                            placeholderText="Date of birth"
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            className="custom-datepicker__input form-control"
                            calendarClassName="custom-datepicker"
                          />

                          {age ? (
                            <Form.Group className="form-inner player-age">
                              <div className="form-control">{`${age} yrs`}</div>
                            </Form.Group>
                          ) : (
                            ""
                          )}
                        </div>
                        <ErrorMessage
                          name="dob"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <div className="profile-card label-text-up">
                        <label className="mb-2">Gender</label>
                        <Select
                          name="gender"
                          placeholder="Select"
                          options={optionsGender}
                          onChange={(option) => setFieldValue("gender", option)}
                          value={values.gender}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Nationality(s)</label>
                        <Select
                          name="nationality"
                          placeholder="Select"
                          isMulti
                          options={optionsNationaity}
                          onChange={(option) =>
                            setFieldValue("nationality", option)
                          }
                          value={values.nationality}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="nationality"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Previous Status</label>
                        <Select
                          name="previous_status"
                          placeholder="Select"
                          options={optionsNationaityId}
                          isMulti
                          onChange={(option) =>
                            setFieldValue("previous_status", option)
                          }
                          value={values.previous_status}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="previous_status"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="grid-section bottom-lines">
                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2"> Coaching License(s) </label>
                        <Select
                          name="coaching_licenses"
                          placeholder="Select"
                          options={optionsCoachingLicense}
                          onChange={(option) =>
                            setFieldValue("coaching_licenses", option)
                          }
                          value={values?.coaching_licenses}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="coaching_licenses"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Language(s)</label>
                        <Select
                          name="Languages"
                          options={optionsLanguage}
                          placeholder="Select"
                          isMulti
                          onChange={(option) =>
                            setFieldValue("languages", option)
                          }
                          value={values.languages}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="languages"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">
                          Annual Asking Salary for New Contract
                        </label>

                        <Select
                          options={optionsannualAskingSalary}
                          name="new_contract_annual_salary"
                          placeholder="Select"
                          onChange={(option) =>
                            setFieldValue("new_contract_annual_salary", option)
                          }
                          value={values.new_contract_annual_salary}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="new_contract_annual_salary"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="availability label-text-up">
                        <label className="">Availability Status</label>

                        <div className="cmn-form-fields mt-2">
                          <div className="profile-card mb-3">
                            <Select
                              name="availability_status"
                              placeholder="Select"
                              options={optionsPlayerStatus}
                              isMulti
                              onChange={(option) =>
                                setFieldValue("availability_status", option)
                              }
                              value={values.availability_status}
                              classNamePrefix="custom-select"
                            />
                            <ErrorMessage
                              name="availability_status"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <div className="d-flex justify-content-between">
                        {values?.availability_status?.some(
                          (option) => option.label == "Currently Free"
                        ) ? (
                          <h3 className="h-20">Previous Coach's Team</h3>
                        ) : (
                          <h3 className="h-20">Coach's Team</h3>
                        )}
                      </div>

                      <div className="cmn-form-fields grid-players bottom-lines mt-2">
                        <Form.Group className="mb-3 form-inner">
                          <div className="label-text-up">
                            <label className="mb-2">Team's Name</label>
                            <Select
                              name="team_id"
                              placeholder="Select"
                              classNamePrefix="custom-select"
                              options={customOptions}
                              isDisabled={
                                isFree &&
                                (!values.team_id || values.team_id.length === 0)
                              }
                              components={{ DropdownIndicator: null }}
                              onChange={(e) =>
                                handleSelectChange(e, setFieldValue)
                              }
                              onInputChange={handleInputChange}
                              value={values.team_id || null}
                              isClearable
                              // styles={customStyles}
                              formatOptionLabel={(data) =>
                                data.isAddTeam ? (
                                  <div
                                    style={{
                                      color: "#103D56",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => setIsModalOpen(true)}
                                  >
                                    {" "}
                                    + Add new Team
                                  </div>
                                ) : (
                                  data.label
                                )
                              }
                              noOptionsMessage={() =>
                                isAddingTeam ? (
                                  <div
                                    className="modal-add-teams"
                                    style={{
                                      color: "#103D56",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => setIsModalOpen(true)}
                                  >
                                    {" "}
                                    + Add new Team
                                  </div>
                                ) : (
                                  "No options found"
                                )
                              }
                            />

                            <Modal
                              isOpen={isModalOpen}
                              onRequestClose={() => setIsModalOpen(false)}
                              contentLabel="Add New Team"
                              style={{
                                content: {
                                  top: "50%",
                                  left: "50%",
                                  right: "auto",
                                  bottom: "auto",
                                  marginRight: "-50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "100%",
                                  maxWidth: "600px",
                                  boxShadow:
                                    "0px 2px 20px 0px rgba(0, 35, 149, 0.15)",
                                  background: "#fff",
                                  borderRadius: "10px",
                                  border: "none",
                                  padding: "30px",
                                },
                              }}
                            >
                              <div className="heding-add-new ">
                                <h3>Add New Team</h3>
                              </div>
                              <div className="add-new-input label-text-up">
                                <label className="mb-2">Team’s Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Team Name"
                                  value={newTeamName}
                                  onChange={(e) =>
                                    setNewTeamName(e.target.value)
                                  }
                                  onKeyPress={(event) => {
                                    const regex = /^[a-zA-Z\s]*$/;
                                    if (!regex.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onPaste={(event) => {
                                    const pastedData =
                                      event.clipboardData.getData("Text");
                                    const regex = /^[a-zA-Z\s]*$/;
                                    if (!regex.test(pastedData)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>

                              <div className="add-new-input label-text-up">
                                <label className="mb-2">Team’s Country</label>
                                <Select
                                  name="team_country"
                                  placeholder="Select"
                                  options={optionsPlaceOfBirth}
                                  classNamePrefix="custom-select"
                                  onChange={(option) =>
                                    setFieldValue("team_country", option)
                                  }
                                  value={values.team_country}
                                />
                              </div>

                              <div className="add-new-input label-text-up">
                                <label className="mb-2">Team’s League</label>
                                <Select
                                  name="team_league"
                                  placeholder="Select"
                                  classNamePrefix="custom-select"
                                  options={optionsTeamLeague}
                                  onChange={(option) =>
                                    setFieldValue("team_league", option)
                                  }
                                  value={values.team_league}
                                  // isDisabled={values.team_league == null}
                                />
                              </div>

                              <div className="add-team-btn ">
                                <button
                                  className="add-modal-btn-ps"
                                  onClick={() =>
                                    handleAddTeam(values, setFieldValue)
                                  }
                                >
                                  Add
                                </button>
                                <button onClick={() => setIsModalOpen(false)}>
                                  Cancel
                                </button>
                              </div>
                            </Modal>
                          </div>

                          <ErrorMessage
                            name="team_id"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>

                        <div className="profile-card mb-3 label-text-up">
                          <label className="mb-2">Team's Country</label>

                          <Select
                            name="team_country"
                            placeholder="Select"
                            options={optionsPlaceOfBirth}
                            classNamePrefix="custom-select"
                            onChange={(option) =>
                              setFieldValue("team_country", option)
                            }
                            value={values.team_country}
                            isDisabled={
                              !!values.team_country ||
                              values.team_country == null
                            }
                          />
                          <ErrorMessage
                            name="team_country"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="profile-card mb-3 label-text-up">
                          <label className="mb-2">Team's League</label>

                          <Select
                            name="team_league"
                            placeholder="Select"
                            classNamePrefix="custom-select"
                            options={optionsTeamLeague}
                            onChange={(option) =>
                              setFieldValue("team_league", option)
                            }
                            value={values.team_league}
                            isDisabled={
                              !!values.team_league || values.team_league == null
                            }
                          />
                          <ErrorMessage
                            name="team_league"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="vidoe-link-box">
                        <div className="bottom-lines mt-4 mb-3">
                          <div className="trophies ">
                            <div className="d-flex justify-content-between">
                              <h3 className="h-20">Carrier Trophies</h3>
                            </div>
                            <div className="profile-card mb-3 profile-width">
                              <Select
                                name="carrier_trophies"
                                placeholder="Select"
                                classNamePrefix="custom-select"
                                options={optionsTrophy}
                                onChange={(selectedOptions) => {
                                  const isNotYetSelected = selectedOptions.some(
                                    (option) => option.value == 6
                                  );
                                  if (isNotYetSelected) {
                                    setFieldValue(`carrier_trophies`, [
                                      {
                                        label: "Not Yet",
                                        value: 6,
                                      },
                                    ]);
                                  } else {
                                    setFieldValue(
                                      `carrier_trophies`,
                                      selectedOptions
                                    );
                                  }
                                }}
                                isMulti
                                // onChange={(selectedOptions) => setFieldValue('carrier_trophies', selectedOptions)}
                                value={values.carrier_trophies}
                              />
                            </div>
                            <ErrorMessage
                              name="carrier_trophies"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="box-flex-up link-videos bottom-lines pb-4 mb-3">
                          <div className="players-cv">
                            <div className="player-up">
                              <h3>Coach's Image</h3>
                              <div
                                className={`uplode-the-img ${
                                  dragging ? "dragging" : ""
                                }`}
                                onDrop={(e) =>
                                  handleDrop(e, setFieldValue, "profile_image")
                                }
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                              >
                                <div className="upload-and-drop">
                                  <div className="click-to-up">
                                    <label htmlFor="file-upload">
                                      <button type="button">
                                        Click to Upload
                                        <Field name="profile_image">
                                          {({ field }) => (
                                            <input
                                              type="file"
                                              id="file-upload"
                                              onChange={(e) =>
                                                handleFileChange1(
                                                  e,
                                                  setFieldValue,
                                                  "profile_image"
                                                )
                                              }
                                              accept="image/*"
                                            />
                                          )}
                                        </Field>
                                      </button>
                                    </label>
                                  </div>
                                  <div className="drag-drop">
                                    <p>
                                      <span>
                                        <img
                                          src={
                                            require("../Assets/images/drag-and-drop.svg")
                                              .default
                                          }
                                          alt="Upload Placeholder"
                                        />
                                      </span>
                                      Or drag and drop files.
                                    </p>
                                  </div>
                                </div>
                                <div className="preview">
                                  <p>Preview</p>
                                  <div className="preview-content">
                                    {values?.profile_image &&
                                      (typeof values.profile_image ===
                                      "string" ? (
                                        <img
                                          className="inner-view"
                                          src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                          alt="Profile Preview"
                                          onClick={() =>
                                            handleShowPreview(
                                              `${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`
                                            )
                                          }
                                        />
                                      ) : values.profile_image instanceof
                                        File ? (
                                        <img
                                          src={URL.createObjectURL(
                                            values?.profile_image
                                          )}
                                          className="prev-img"
                                          alt="Profile Preview"
                                          style={{ width: "50px" }}
                                          onClick={() =>
                                            handleShowPreview(
                                              URL.createObjectURL(
                                                values?.profile_image
                                              )
                                            )
                                          }
                                        />
                                      ) : null)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="carrier-notes">
                      <div className="cmn-form-fields uplode-click-box-main">
                        <div className="all-notes ">
                          <h3 className="h-20">Other Notes</h3>
                          <div className="cmn-form-fields">
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                as="textarea"
                                name="main_info_other_notes"
                                rows={3}
                                className="form-control"
                                placeholder="Write notes here"
                              />
                              <ErrorMessage
                                name="main_info_other_notes"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end mb-5">
                    <div className="mt-4 ">
                      <div className="cmn-light-btn save-and-close-btn">
                        <button
                          type="button"
                          onClick={() =>
                            handleSaveAndClose(values, validateForm, setErrors)
                          }
                          className="next-btn-ps"
                        >
                          {" "}
                          Save & Close{" "}
                        </button>
                      </div>
                    </div>

                    <div className="mt-4 ">
                      <div className="cmn-light-btn">
                        <button type="submit" className="next-btn-ps">
                          {" "}
                          Save & Next{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
      <PreviewModal
        show={showPreview}
        onHide={handleClosePreview}
        previewImage={previewImage}
      />
    </LoginLayout>
  );
};

export default CoachMainInfo;
