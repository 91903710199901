import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoginLayout from "../components/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { Decryptedid } from "../utils/Becrypt";
import { calculateAge, capitalize, dateOfBirth } from "../utils/helper";
import {
  AvailabilityStatus,
  coachingLicense,
  gender,
  playerTrophy,
  previousStatus,
  teamLeague,
} from "../utils/rawjson";
import { useNavigate, useParams } from "react-router-dom";
import { getCoachesDataById } from "../Redux/Action/coachAction";
import backgroundImage from "../Assets/images/stadium.png";

export default function GlobalSearchCoachProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const { id } = useParams();
  const coachId = id && Decryptedid(atob(id));

  const coachData = useSelector((e) => e.coachAuthData.coachDetail);

  const backgroundImageStyle = {
    // backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}${coachData?.banner_image})`,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  };

  useEffect(() => {
    if (coachId) {
      dispatch(getCoachesDataById({ id: coachId }));
    }
  }, [dispatch]);

  const copyToClipboard = () => {
    if (coachData?.unique_id) {
      navigator.clipboard
        .writeText(coachData.unique_id)
        .then(() => {
          console.log("Copied");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Coach </h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    {/* <img
                      className="play-img"
                      src={`${process.env.REACT_APP_IMAGE_PATH}${coachData?.profile_image}`}
                    /> */}
                    <img
                      src={
                        coachData?.gender === 0
                          ? require("../Assets/images/coach-on-banner.png")
                          : require("../Assets/images/coach-big.png")
                      }
                    />
                    <div>
                      <h2> {coachData?.full_name}</h2>
                      <div className="d-flex align-items-center mb-4 mt-3">
                        <p className="mb-0" ref={inputRef}>
                          Coach ID: {coachData?.unique_id}
                        </p>
                        <button onClick={copyToClipboard}>
                          <img
                            src={
                              require("../Assets/images/copywhite.svg").default
                            }
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="player-right-info">
                    <div></div>
                    <div className="play-info-box">
                      <div>
                        <h3>{coachData?.gender === 0 ? "Male" : "Female"}</h3>
                        <p>Gender</p>
                      </div>
                      <div>
                        <h3>{calculateAge(coachData?.dob)}</h3>
                        <p>Age</p>
                      </div>
                      {/* <div>
                        <h3>
                          {AvailabilityStatus[coachData?.availability_status]
                            ? AvailabilityStatus[coachData?.availability_status]
                            : "N/A"}
                        </h3>
                        <p>Availability</p>
                      </div> */}
                      <div>
                        <h3>
                          {" "}
                          {coachData?.coaching_licenses
                            ?.map((elem) => coachingLicense[elem])
                            .join(", ")}
                        </h3>
                        <p>Coaching Licenses</p>
                      </div>
                      <div>
                        <h3>
                          {coachData?.languages?.length > 1
                            ? `${coachData.languages[0]} +${
                                coachData.languages.length - 1
                              }`
                            : coachData?.languages?.[0]}
                        </h3>
                        <p>Language</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Row className="mt-4">
              <Col lg={4}>
                <div className="search-inner-box">
                  {/* <div className="play-search-box">
                    <div>
                      <h3>{gender[coachData?.gender]}</h3>
                      <p>Gender</p>
                    </div>
                    <div>
                      <h3>{calculateAge(coachData?.dob)}</h3>
                      <p>Age</p>
                    </div>
                    <div>
                      {AvailabilityStatus[coachData?.availability_status]}
                      <p>Availability</p>
                    </div>
                    <div>
                      {coachData?.languages?.map((elem, i) => (
                        <h3 key={i}>{elem}</h3>
                      ))}
                      <p>Language</p>
                    </div>
                  </div> */}
                </div>
                <div className="search-inner-box mt-2">
                  <ul>
                    <li>
                      <h4>Previous Status:</h4>
                      <p>
                        {coachData?.previous_status
                          ?.map((elem) => previousStatus[elem] || [])
                          .join(", ") || "N/A"}
                      </p>
                    </li>
                    <li>
                      <h4>Coaching License:</h4>
                      <p>
                        {coachData?.coaching_licenses
                          ?.map((elem) => coachingLicense[elem] || [])
                          .join(", ") || "N/A"}
                      </p>
                    </li>
                    <li>
                      <h4>Availability Status:</h4>
                      <p>
                        {" "}
                        {AvailabilityStatus[coachData?.availability_status] ||
                          "N/A"}
                      </p>
                    </li>
                    <li>
                      <h4>Current/Previous Team League:</h4>
                      <p>
                        {" "}
                        {teamLeague[coachData?.current_team_league] || "N/A"}
                      </p>
                    </li>
                    <li>
                      <h4>Annual Asking Salary:</h4>
                      <p>{coachData?.new_contract_annual_salary || "N/A"}</p>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={4}>
                <div className="play-inner-box">
                  <div className="d-flex">
                    <img
                      src={
                        coachData?.creator_id?.profile_image
                          ? `${process.env.REACT_APP_IMAGE_PATH}${coachData?.creator_id?.profile_image}`
                          : require("../Assets/images/My Associate Agent male.png")
                      }
                    />
                    <div className="ms-3">
                      <h4>
                        {capitalize(coachData?.creator_id?.full_name) || "N/A"}
                      </h4>
                      <p>Agent</p>
                    </div>
                  </div>
                  {/* <div className="badge-img">
                    <img src={require("../Assets/images/tiger.png")} />
                  </div> */}
                </div>
                <div className="search-cmn-box">
                  <h2>Carrier Trophies</h2>
                  <div className="search-inner-box">
                    <ul>
                      {coachData?.carrier_trophies?.map((elem, i) => (
                        <li>
                          <div className="d-flex">
                            <img
                              src={require("../Assets/images/smtrophy.png")}
                            />
                            <h4 className="ms-2">{playerTrophy[elem]}</h4>
                          </div>
                          {/* <p>
                            {dateOfBirth(elem?.date_of_winning?.split("T")[0])}
                          </p> */}
                        </li>
                      )) || "N/A"}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                {" "}
                <div className="search-inner-box p-0">
                  <div className="play-images">
                    <img src={require("../Assets/images/smllbanner.png")} />
                    <div className="players-value inner-tab">
                      <h3>CHECK THE MOST VALUABLE PLAYERS IN THE WORLD</h3>
                    </div>
                  </div>{" "}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
