import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { blockUnblockUser } from "../Redux/Action/message";
import toast from "react-hot-toast";

const BlockModalComp = ({
  isModalOpen,
  setIsModalOpen,
  isBlockedState,
  readCountFlow,
}) => {
  const userIdVal =
    localStorage.getItem("userId") || sessionStorage.getItem("userId");
  const { chatId, senderData } = useSelector((state) => state.room);
  const dispatch = useDispatch();
  const [reasonState, setReasonState] = useState("");

  const handleBlock = async (val) => {
    let payload = {
      userId: userIdVal,
      reason: reasonState,
      type: val,
      room_id: chatId,
    };
    let apiRes = await dispatch(blockUnblockUser(payload));
    if (apiRes?.payload?.status === 200) {
      toast.success(apiRes?.payload?.message);
      setIsModalOpen(false);
      readCountFlow(chatId);
      // window.location.reload();
    } else {
      toast.error(apiRes?.payload?.message);
    }
  };

  return (
    <>
      <Modal
        className="cmn-modal"
        size="xs"
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          className="pb-0"
          closeButton
          style={{ borderBottom: "none" }}
        ></Modal.Header>
        <Modal.Body className="pt-0">
          <div className="choose-agent">
            {/* <h3>Block </h3> */}
            <p>
              Are you sure you want to{" "}
              {isBlockedState === 0 || isBlockedState === 2
                ? `Block`
                : `Unblock`}{" "}
              Block ?
            </p>
            <input
              type="text"
              className="form-control mt-3"
              placeholder="Enter Reason Here"
              onChange={(e) => setReasonState(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: "none" }}
          className="d-flex justify-content-center"
        >
          <Button
            variant="primary"
            onClick={() =>
              handleBlock(isBlockedState === 0 || isBlockedState === 2 ? 1 : 2)
            }
          >
            Yes
          </Button>
          <Button variant="primary" onClick={() => setIsModalOpen(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BlockModalComp;
