import React, { useEffect } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import backgroundImage from "../Assets/images/playerbanner.png";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { useDispatch, useSelector } from "react-redux";
import { getCoachesDataById } from "../Redux/Action/coachAction";
import { calculateAge, capitalize, dateOfBirth } from "../utils/helper";
import toast from "react-hot-toast";
import {
  AvailabilityStatus,
  coachingLicense,
  playerTrophy,
  previousStatus,
  releaseClouse,
  teamLeague,
} from "../utils/rawjson";
import Loader from "../components/Loader";

export default function MycoachDetail() {
  const backgroundImageStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  };

  const { id } = useParams();
  const IdVal = id && Decryptedid(atob(id));
  const dispatch = useDispatch();

  const coachData = useSelector((e) => e?.coachAuthData?.coachDetail);
  const loader = useSelector((e) => e?.coachAuthData?.loading);

  useEffect(() => {
    dispatch(getCoachesDataById({ id: IdVal, type: 1 }));
  }, [dispatch]);

  const copyToClipboard = () => {
    if (coachData?.unique_id) {
      navigator.clipboard
        .writeText(coachData.unique_id)
        .then(() => {
          toast.success("Copied");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleCoachEdit = (id) => {
    if (id) {
      localStorage.setItem("coachId", id);
    }
  };

  return (
    <LoginLayout>
      <section>
        {loader && <Loader />}

        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Coach Profile</h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    {coachData?.gender == 0 ? (
                      <img
                        className="play-img"
                        src={
                          coachData?.profile_image
                            ? `${process.env.REACT_APP_IMAGE_PATH}${coachData.profile_image}`
                            : require("../Assets/images/coach-on-banner.png")
                        }
                        alt="Profile"
                      />
                    ) : (
                      <img
                        className="play-img"
                        src={
                          coachData?.profile_image
                            ? `${process.env.REACT_APP_IMAGE_PATH}${coachData.profile_image}`
                            : require("../Assets/images/coach-big.png")
                        }
                        alt="Profile"
                      />
                    )}
                    <div>
                      <h2>
                        {capitalize(coachData?.full_name)},{" "}
                        {calculateAge(coachData?.dob)}
                      </h2>
                      <div className="d-flex align-items-center mb-4 mt-3">
                        <p className="mb-0">Coach ID:{coachData?.unique_id}</p>
                        <button onClick={copyToClipboard}>
                          <img
                            src={
                              require("../Assets/images/copywhite.svg").default
                            }
                          />
                        </button>
                      </div>
                      <div className="socio-icons">
                        {/* Instagram */}
                        {coachData?.instagram && (
                          <Link
                            to={coachData.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/instablue.svg")
                                  .default
                              }
                              alt="Instagram"
                            />
                          </Link>
                        )}

                        {/* Snapchat */}
                        {coachData?.snapchat && (
                          <Link
                            to={coachData.snapchat}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/snapblue.svg").default
                              }
                              alt="Snapchat"
                            />
                          </Link>
                        )}

                        {/* X (formerly Twitter) */}
                        {coachData?.twitter && (
                          <Link
                            to={coachData.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/xblue.svg").default
                              }
                              alt="X"
                            />
                          </Link>
                        )}

                        {/* Facebook */}
                        {coachData?.facebook && (
                          <Link
                            to={coachData.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/fbblue.svg").default
                              }
                              alt="Facebook"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="player-right-info">
                    <div className="play-edit">
                      <Link
                        to={`/coach-info?coachId=${Encryptedid(
                          coachData?._id
                        )}`}
                        onClick={() => handleCoachEdit(coachData?._id)}
                      >
                        <img
                          src={
                            require("../Assets/images/editwhite.svg").default
                          }
                        />
                        Edit Profile
                      </Link>
                    </div>
                    <div className="play-info-box">
                      <div>
                        <h3>{coachData?.gender === 0 ? "Male" : "Female"}</h3>
                        <p>Gender</p>
                      </div>
                      <div>
                        <h3>{calculateAge(coachData?.dob)}</h3>
                        <p>Age</p>
                      </div>
                      {/* <div>
                        <h3>
                          {AvailabilityStatus[coachData?.availability_status]
                            ? AvailabilityStatus[coachData?.availability_status]
                            : "N/A"}
                        </h3>
                        <p>Availability</p>
                      </div> */}
                      <div>
                        <h3>
                          {" "}
                          {coachData?.coaching_licenses
                            ?.map((elem) => coachingLicense[elem])
                            .join(", ")}
                        </h3>
                        <p>Coaching Licenses</p>
                      </div>
                      <div>
                        <h3>
                          {coachData?.languages?.length > 1
                            ? `${coachData.languages[0]} +${
                                coachData.languages.length - 1
                              }`
                            : coachData?.languages?.[0]}
                        </h3>
                        <p>Language</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Tabs
              defaultActiveKey="Profile"
              id="uncontrolled-tab-example"
              className="mb-3 mt-4"
            >
              <Tab eventKey="Profile" title="Profile" className="accord-tabs">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Coach’s Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Date of Birth:</h4>
                              <p> {dateOfBirth(coachData?.dob) || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Nationality (s):</h4>
                              <p> {coachData?.nationality?.join(", ")}</p>
                            </li>

                            <li>
                              <h4>Previous Status:</h4>
                              <p>
                                {coachData?.previous_status
                                  ?.map((elem) => previousStatus[elem])
                                  .join(", ")}
                              </p>
                            </li>

                            <li>
                              <h4>Availability:</h4>
                              <p>
                                {Array.isArray(
                                  coachData?.availability_status
                                ) &&
                                coachData?.availability_status?.length > 0 ? (
                                  coachData?.availability_status?.map(
                                    (res, index) => (
                                      <span key={index}>
                                        {AvailabilityStatus[res]}
                                        {index !==
                                        coachData.availability_status.length - 1
                                          ? ", "
                                          : ""}
                                      </span>
                                    )
                                  )
                                ) : (
                                  <span>{"N/A"}</span>
                                )}
                              </p>
                            </li>

                            <li>
                              <h4>Coaching Licenses:</h4>

                              <p>
                                {coachData?.coaching_licenses
                                  ?.map((elem) => coachingLicense[elem])
                                  .join(", ")}
                              </p>
                            </li>
                            <li>
                              <h4>Languages(s):</h4>
                              <p>{coachData?.languages?.join(", ")}</p>
                            </li>
                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {coachData?.main_info_other_notes
                                  ? coachData?.main_info_other_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* <div className="search-cmn-box mt-3">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Carrier Trophies</h2>
                        <div className="search-inner-box">
                          <ul>
                            {Array.isArray(coachData?.carrier_trophies) &&
                            coachData?.carrier_trophies.length > 0 ? (
                              <>
                                {coachData?.carrier_trophies.map(
                                  (ele, index) => {
                                    return (
                                      <>
                                        <li key={index}>
                                          <div className="d-flex">
                                            <img
                                              src={require("../Assets/images/smtrophy.png")}
                                            />
                                            <h4 className="ms-2">
                                              {playerTrophy[ele]}
                                            </h4>
                                          </div>
                                          {/* <p>
                                            {dateOfBirth(ele?.date_of_winning)}
                                          </p> */}
                                        </li>
                                      </>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <li>No Data Found</li>
                            )}
                          </ul>
                        </div>
                      </div>
                      {Array.isArray(coachData?.photos) &&
                        coachData?.photos.length > 0 && (
                          <div className="play-inner-box search-result mt-3">
                            <div className="d-flex align-items-center">
                              {coachData?.photos.map((ele, index) => {
                                return (
                                  <div key={index}>
                                    <img
                                      className="small-images"
                                      src={`${process.env.REACT_APP_IMAGE_PATH}${ele}`}
                                    />
                                  </div>
                                );
                              })}
                              <div className="ms-3">
                                <h4>Photos</h4>
                              </div>
                            </div>
                            <div
                              className="badge-img"
                              style={{ boxShadow: "none" }}
                            >
                              <img
                                src={
                                  require("../Assets/images/arrow.svg").default
                                }
                              />
                            </div>
                          </div>
                        )}
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Coach's Agent</h2>
                        <div className="play-inner-box search-result mt-3 add-back-ground-color d-block">
                          <div className="d-flex align-items-center">
                            {coachData?.professional_relation === 0 ? (
                              <img
                                src={
                                  coachData?.creator_id?.profile_image
                                    ? `${process.env.REACT_APP_IMAGE_PATH}${coachData?.creator_id?.profile_image}`
                                    : require("../Assets/images/user.png")
                                }
                              />
                            ) : (
                              <img
                                src={
                                  coachData?.associate_agent_id?.profile_image
                                    ? `${process.env.REACT_APP_IMAGE_PATH}${coachData?.associate_agent_id?.profile_image}`
                                    : require("../Assets/images/user.png")
                                }
                              />
                            )}
                            <div className="ms-3">
                              <h4>
                                {coachData?.professional_relation === 0
                                  ? capitalize(coachData?.creator_id?.full_name)
                                  : capitalize(
                                      coachData?.associate_agent_id?.agent_name
                                    )}
                              </h4>
                              <p
                                className=""
                                style={{
                                  color: "#1D161780",
                                  fontSize: "13px",
                                }}
                              >
                                {coachData?.professional_relation === 0
                                  ? `Agent`
                                  : `Associate Agent`}
                              </p>
                            </div>
                          </div>
                          <div className="search-inner-box  player-agent-search-ag">
                            <ul>
                              <li className="flex-column align-items-start mb-0">
                                <h4>Other Notes:</h4>
                                <p className="mt-1">
                                  {coachData?.relationship_other_notes
                                    ? coachData?.relationship_other_notes
                                    : "N/A"}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="search-cmn-box mt-3">
                        {coachData?.availability_status?.includes(0) ? (
                          <h2>Previous Player's Team</h2>
                        ) : (
                          <h2>Current Team</h2>
                        )}
                        <div className="search-inner-box">
                          <div className="play-inner-box align-items-start">
                            <div className="d-flex">
                              <img
                                src={
                                  coachData?.team_id?.profile_image
                                    ? `${process.env.REACT_APP_IMAGE_PATH}${coachData?.team_id?.profile_image}`
                                    : require("../Assets/images/biglogo.png")
                                }
                              />
                              <div className="ms-3">
                                <h4>
                                  {capitalize(coachData?.team_id?.team_name)}{" "}
                                </h4>
                                <p>
                                  <span>{coachData?.team_country}</span>
                                  {/* {elem?.interested_team_id?.team_country && elem?.interested_team_id?.team_city && ' / '} */}
                                  {/* <span>{elem?.interested_team_id?.team_city}</span> */}
                                </p>
                              </div>
                            </div>
                            <p>
                              <span>{teamLeague[coachData?.team_league]}</span>
                            </p>
                          </div>
                          {/* <div className="search-inner-box per-vious-player-team">
                            <ul>
                              <li className="flex-column align-items-start mb-0">
                                <h4>Other Notes:</h4>
                                <p className="mt-1">
                                  {coachData?.previous_player_other_notes
                                    ? coachData?.previous_player_other_notes
                                    : "N/A"}
                                </p>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Contract" title="Contract">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Current Contract Requirements</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Current Annual Salary:</h4>
                              <p>{coachData?.current_contract || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Bonuses:</h4>
                              <p>{coachData?.Bonuses === 1 ? `Yes` : "No"}</p>
                            </li>
                            <li>
                              <h4>Joined Date:</h4>
                              <p>{dateOfBirth(coachData?.joined_date)}</p>
                            </li>
                            <li>
                              <h4>Contract Start Date:</h4>
                              <p>
                                {dateOfBirth(coachData?.contract_start_date)}
                              </p>
                            </li>
                            <li>
                              <h4>Contract End Date:</h4>
                              <p>{dateOfBirth(coachData?.contract_end_date)}</p>
                            </li>
                            <li>
                              <h4>Release Clause:</h4>
                              <p>
                                {releaseClouse[coachData?.release_clouse]
                                  ? releaseClouse[coachData?.release_clouse]
                                  : "N/A"}
                              </p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {coachData?.current_contract_other_notes ||
                                  "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>New Contract Requirements</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Annual Asking Salary:</h4>
                              <p>
                                {coachData?.new_contract_annual_salary || "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Contract Duration:</h4>
                              <p>{coachData?.new_contract_duration || "N/A"}</p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {coachData?.new_contract_other_notes || "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* <div className="search-cmn-box mt-3">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>

              <Tab eventKey="Proposals" title="Proposals">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Proposals</h2>
                        <h3 className="in-heading add-new-pd">
                          Teams that the Coach has been proposed
                        </h3>
                        {coachData?.proposed_team?.map((elem, i) => {
                          return (
                            <>
                              <div className="search-inner-box" key={i}>
                                <div className="play-inner-box align-items-start">
                                  <div className="d-flex">
                                    <img
                                      src={
                                        elem?.proposed_team_id?.profile_image
                                          ? `${process.env.REACT_APP_IMAGE_PATH}${elem?.proposed_team_id?.profile_image}`
                                          : require("../Assets/images/biglogo.png")
                                      }
                                    />
                                    <div className="ms-3">
                                      <h4>
                                        {capitalize(
                                          elem?.proposed_team_id?.team_name
                                        )}{" "}
                                      </h4>
                                      <p>
                                        <span>
                                          {elem?.proposed_team_country}
                                        </span>
                                        {/* {elem?.proposed_team_id?.team_country && elem?.proposed_team_id?.team_city && ' / '} */}
                                        {/* <span>{elem?.proposed_team_id?.team_city}</span> */}
                                      </p>
                                    </div>
                                  </div>
                                  <p>
                                    <span>
                                      {teamLeague[elem?.proposed_team_league]}
                                    </span>
                                  </p>
                                </div>
                                {elem?.proposed_associate_agent_id && (
                                  <div className="play-inner-box align-items-start">
                                    <div className="d-flex">
                                      <img
                                        src={
                                          elem?.proposed_associate_agent_id
                                            ?.profile_image
                                            ? `${process.env.REACT_APP_IMAGE_PATH}${elem?.proposed_associate_agent_id?.profile_image}`
                                            : require("../Assets/images/vector-image.png")
                                        }
                                      />
                                      <div className="ms-3">
                                        <h4>
                                          {capitalize(
                                            elem?.proposed_associate_agent_id
                                              ?.agent_name
                                          )}{" "}
                                        </h4>
                                        <p>
                                          <span>
                                            Agent Proposed Coach to this team
                                          </span>
                                          {/* {elem?.proposed_associate_agent_id?.country && elem?.proposed_associate_agent_id?.city && ' / '}
                          <span>{elem?.proposed_associate_agent_id?.city}</span> */}
                                        </p>
                                      </div>
                                    </div>
                                    {/* <p>
                      <span>
                        {teamLeague[elem?.proposed_team_league]}
                      </span>
                    </p> */}
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <div className="search-cmn-box mt-4">
                        <h2>Other Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                {coachData?.contract_other_notes
                                  ? coachData?.contract_other_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2 style={{ visibility: "hidden" }}>Proposals</h2>
                        <h3 className="in-heading add-new-pd">
                          Teams that are interested for the Coach
                        </h3>
                        {coachData?.interested_team?.map((elem, i) => (
                          <div className="search-inner-box">
                            <div className="play-inner-box align-items-start">
                              <div className="d-flex">
                                <img
                                  src={
                                    elem?.interested_team_id?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem?.interested_team_id?.profile_image}`
                                      : require("../Assets/images/biglogo.png")
                                  }
                                />
                                <div className="ms-3">
                                  <h4>
                                    {capitalize(
                                      elem?.interested_team_id?.team_name
                                    )}{" "}
                                  </h4>
                                  <p>
                                    <span>{elem?.interested_team_country}</span>
                                    {/* {elem?.interested_team_id?.team_country && elem?.interested_team_id?.team_city && ' / '} */}
                                    {/* <span>{elem?.interested_team_id?.team_city}</span> */}
                                  </p>
                                </div>
                              </div>
                              <p>
                                <span>
                                  {teamLeague[elem?.interested_team_league]}
                                </span>
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <div className="search-cmn-box mt-4">
                                            <h2>Agents Notes</h2>
                                            <div className="search-inner-box">
                                              <ul>
                                                <li className="flex-column align-items-start mb-0">
                                                  <p className="mt-1">
                                                    {elem?.proposed_associate_agent_note
                                                      ? elem?.proposed_associate_agent_note
                                                      : "N/A"}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </div> */}
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Contact" title="Contact">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Contact Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Phone Number:</h4>
                              <p>{coachData?.phone_number || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Email Address:</h4>
                              <p>{coachData?.email || "N/A"}</p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Home Address:</h4>
                              <p className="mt-1">
                                {coachData?.address || "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>

                        <div className="managing-comp mt-3">
                          <h2>Social Media Links</h2>
                          <div className="search-inner-box ">
                            <ul>
                              <li>
                                <h4>Facebook:</h4>
                                <p>
                                  {coachData?.facebook
                                    ? coachData?.facebook
                                    : "N/A"}
                                </p>
                              </li>

                              <li>
                                <h4>Instagram:</h4>
                                <p>
                                  {coachData?.instagram
                                    ? coachData?.instagram
                                    : "N/A"}
                                </p>
                              </li>
                              <li>
                                <h4>Snapchat:</h4>
                                <p>
                                  {coachData?.snapchat
                                    ? coachData?.snapchat
                                    : "N/A"}
                                </p>
                              </li>
                              <li>
                                <h4>X:</h4>
                                <p>
                                  {coachData?.twitter
                                    ? coachData?.twitter
                                    : "N/A"}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>

                        {/* <div className="search-inner-box mt-3">
                          {" "}
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                Lorem Ipsum is simply dummy text for printing
                                and typesetting industry. Lorem Ipsum has been
                                industry standard dummy text ever since 1500s.
                              </p>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="managing-comp mt-3">
                        <h2>Managing Company</h2>
                        <div className="search-inner-box ">
                          <ul>
                            <li>
                              <h4>Managing Company:</h4>
                              <p>
                                {coachData?.coach_managing_company_name ||
                                  "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Contact Number:</h4>
                              <p>
                                {coachData?.coach_managing_company_phone_number ||
                                  "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="managing-comp mt-3">
                        <h2>Coach's Lawyer</h2>
                        <div className="search-inner-box ">
                          <ul>
                            <li>
                              <h4>Coach Lawyer:</h4>
                              <p>{coachData?.coach_lawyer_name || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Contact Number:</h4>
                              <p>
                                {coachData?.coach_lawyer_phone_number || "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="search-inner-box mt-3">
                        <ul>
                          <li className="flex-column align-items-start mb-0">
                            <h4>Agents General Notes:</h4>
                            <p className="mt-1">
                              {coachData?.general_notes
                                ? coachData?.general_notes
                                : "N/A"}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
