// import React, { useEffect } from "react";
// import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import LoginLayout from "../components/LoginLayout";
// import { Col, Container, Row, Form } from "react-bootstrap";
// import { Decryptedid, Encryptedid } from "../utils/Becrypt";
// import toast from "react-hot-toast";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import { editDirectorAction, getDirectorDataById } from "../Redux/Action/directorAction";

// const validationSchema = Yup.object({
//   address: Yup.string().required("required"),
//   email: Yup.string().email("Invalid email format").required("required"),
//   phone_number: Yup.number()
//     .typeError("Phone number must be a number")
//     .required("required"),
//   instagram: Yup.string().url("Invalid URL format"),
//   facebook: Yup.string().url("Invalid URL format"),
//   snapchat: Yup.string().url("Invalid URL format"),
//   twitter: Yup.string().url("Invalid URL format"),
//   director_lawyer_name: Yup.string().required("required"),
//   director_lawyer_phone_number: Yup.number()
//     .typeError("Phone number must be a number")
//     .required("required"),
//   director_managing_company_name: Yup.string().required("required"),
//   director_managing_company_phone_number: Yup.number()
//     .typeError("Phone number must be a number")
//     .required("required"),

//   contact_info_other_notes : Yup.string(),
//   contact_info_agent_notes: Yup.string(),
// });

// const DirectorContactInformation = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const { id } = useParams();
//   const directorID = id && Decryptedid(atob(id));

//   const directorData = useSelector((e) => e.directorAuthData.directorDetail);

//   const handleSubmit = (values) => {
//     console.log("Form values:", values);

//     let formData = new FormData();

//     Object.entries(values).forEach(([key, value]) => {
//       formData.append(key, value);
//     });

//     formData.append("directorId", directorID);
//     formData.append("is_profile_completed", 6);

//     dispatch(editDirectorAction(formData)).then((res) => {
//       if (res?.payload?.status === 200) {
//         toast.success("Success");
//         localStorage.removeItem("directorId");
//         navigate(`/my-directors`);
//       } else {
//         toast.error(res?.payload?.message);
//       }
//     });
//   };

//   useEffect(() => {
//     dispatch(getDirectorDataById({ id: directorID }));
//   }, [dispatch]);

//   const initialValues = {
//     address: directorData?.address || "",
//     email: directorData?.email || "",
//     phone_number: directorData?.phone_number || "",
//     instagram: directorData?.instagram || "",
//     facebook: directorData?.facebook || "",
//     snapchat: directorData?.snapchat || "",
//     twitter: directorData?.twitter || "",
//     director_lawyer_name: directorData?.director_lawyer_name || "",
//     director_lawyer_phone_number: directorData?.director_lawyer_phone_number || "",
//     director_managing_company_name:
//       directorData?.director_managing_company_name || "",
//     director_managing_company_phone_number:
//       directorData?.director_managing_company_phone_number || "",
//     contact_info_other_notes : directorData?.contact_info_other_notes  || "",
//     contact_info_agent_notes: directorData?.contact_info_agent_notes || "",
//   };

//   return (
//     <LoginLayout>
//       <section>
//         <Container>
//           <div className="cmn-card">
//             <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
//               <h2>Contact Information</h2>
//             </div>
//             <Formik
//               enableReinitialize={true}
//               initialValues={initialValues}
//               validationSchema={validationSchema}
//               onSubmit={handleSubmit}
//             >
//               {() => (
//                 <FormikForm>
//                   <div className="common-outer mt-4">
//                     <Row>
//                       <Col lg={4}>
//                         <Form.Group className="form-inner">
//                           <Field
//                             name="address"
//                             as={Form.Control}
//                             type="text"
//                             placeholder="Enter address"
//                           />
//                           <ErrorMessage
//                             name="address"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </Form.Group>
//                       </Col>
//                       <Col lg={4}>
//                         <Form.Group className="form-inner">
//                           <Field
//                             name="email"
//                             as={Form.Control}
//                             type="email"
//                             placeholder="Enter email here"
//                           />
//                           <ErrorMessage
//                             name="email"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </Form.Group>
//                       </Col>
//                       <Col lg={4}>
//                         <Form.Group className="form-inner">
//                           <Field
//                             name="phone_number"
//                             as={Form.Control}
//                             type="number"
//                             placeholder="Enter phone number"
//                           />
//                           <ErrorMessage
//                             name="phone_number"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </Form.Group>
//                       </Col>
//                     </Row>
//                   </div>
//                   <div className="common-outer mt-4">
//                     <div className="d-flex justify-content-between">
//                       <h3 className="h-20">Social Media Links</h3>
//                     </div>
//                     <Row>
//                       <Col lg={4}>
//                         <Form.Group className="form-inner">
//                           <Field
//                             name="instagram"
//                             as={Form.Control}
//                             type="url"
//                             placeholder="Instagram"
//                           />
//                           <ErrorMessage
//                             name="instagram"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </Form.Group>
//                       </Col>
//                       <Col lg={4}>
//                         <Form.Group className="form-inner">
//                           <Field
//                             name="facebook"
//                             as={Form.Control}
//                             type="url"
//                             placeholder="Facebook"
//                           />
//                           <ErrorMessage
//                             name="facebook"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </Form.Group>
//                       </Col>
//                       <Col lg={4}>
//                         <Form.Group className="form-inner">
//                           <Field
//                             name="snapchat"
//                             as={Form.Control}
//                             type="url"
//                             placeholder="Snapchat"
//                           />
//                           <ErrorMessage
//                             name="snapchat"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </Form.Group>
//                       </Col>
//                       <Col lg={4} className="mt-3">
//                         <Form.Group className="form-inner">
//                           <Field
//                             name="twitter"
//                             as={Form.Control}
//                             type="url"
//                             placeholder="twitter"
//                           />
//                           <ErrorMessage
//                             name="twitter"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </Form.Group>
//                       </Col>
//                     </Row>
//                   </div>
//                   <div className="common-outer mt-4">
//                     <div className="d-flex justify-content-between">
//                       <h3 className="h-20">Director’s Lawyer</h3>
//                     </div>
//                     <Row>
//                       <Col lg={4}>
//                         <Form.Group className="form-inner">
//                           <Field
//                             name="director_lawyer_name"
//                             as={Form.Control}
//                             type="text"
//                             placeholder="Lawyer’s name enter here"
//                           />
//                           <ErrorMessage
//                             name="director_lawyer_name"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </Form.Group>
//                       </Col>
//                       <Col lg={4}>
//                         <Form.Group className="form-inner">
//                           <Field
//                             name="director_lawyer_phone_number"
//                             as={Form.Control}
//                             type="number"
//                             placeholder="Phone number enter here"
//                           />
//                           <ErrorMessage
//                             name="director_lawyer_phone_number"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </Form.Group>
//                       </Col>
//                     </Row>
//                   </div>
//                   <div className="common-outer mt-4">
//                     <div className="d-flex justify-content-between">
//                       <h3 className="h-20">Director Managing Company</h3>
//                     </div>
//                     <Row>
//                       <Col lg={4}>
//                         <Form.Group className="form-inner">
//                           <Field
//                             name="director_managing_company_name"
//                             as={Form.Control}
//                             type="text"
//                             placeholder="Company name enter here"
//                           />
//                           <ErrorMessage
//                             name="director_managing_company_name"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </Form.Group>
//                       </Col>
//                       <Col lg={4}>
//                         <Form.Group className="form-inner">
//                           <Field
//                             name="director_managing_company_phone_number"
//                             as={Form.Control}
//                             type="number"
//                             placeholder="Phone number enter here"
//                           />
//                           <ErrorMessage
//                             name="director_managing_company_phone_number"
//                             component="div"
//                             className="text-danger"
//                           />
//                         </Form.Group>
//                       </Col>
//                     </Row>
//                   </div>

//                   <div className="common-outer mt-4">
//                     <div className="d-flex justify-content-between">
//                       <h3 className="h-20">Other Notes</h3>
//                     </div>
//                     <Form.Group className="mb-3 form-inner">
//                       <Field
//                         as="textarea"
//                         name="contact_info_other_notes "
//                         rows={3}
//                         placeholder="Write here"
//                         className="form-control"
//                       />
//                       <ErrorMessage
//                         name="contact_info_other_notes "
//                         component="div"
//                         className="text-danger"
//                       />
//                     </Form.Group>
//                   </div>
//                   <div className="common-outer mt-4">
//                     <div className="d-flex justify-content-between">
//                       <h3 className="h-20">Agents Notes</h3>
//                     </div>
//                     <Form.Group className="mb-3 form-inner">
//                       <Field
//                         as="textarea"
//                         name="contact_info_agent_notes"
//                         rows={3}
//                         placeholder="Write here"
//                         className="form-control"
//                       />
//                       <ErrorMessage
//                         name="contact_info_agent_notes"
//                         component="div"
//                         className="text-danger"
//                       />
//                     </Form.Group>
//                   </div>
//                   <Col lg={12} className="mt-4 d-flex justify-content-end">
//                     <div className="cmn-light-btn">
//                       <button type="submit">Save</button>
//                     </div>
//                   </Col>
//                 </FormikForm>
//               )}
//             </Formik>
//           </div>
//         </Container>
//       </section>
//     </LoginLayout>
//   );
// };

// export default DirectorContactInformation;

import React, { useEffect } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import LoginLayout from "../components/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { staticDataAction } from "../Redux/Action/staticAction";
import toast from "react-hot-toast";
import ProgressBar from "../CommonModals/ProgressBar";
import {
  editDirectorAction,
  getDirectorDataById,
} from "../Redux/Action/directorAction";

const validationSchema = Yup.object({
  education_level: Yup.object().required("required"),
  martial_status: Yup.object().required("required"),
  children: Yup.object().required("required"),
  hobbies: Yup.string(),
  other_info_other_notes: Yup.string(),
  general_notes: Yup.string(),
});

const DirectorContactInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const directorId = id && Decryptedid(atob(id));

  // const staticListForDirector = useSelector((e) => e.staticAuthData.directorStaticDetail);
  const staticListForDirector = useSelector(
    (e) => e.staticAuthData.userStaticDetail
  );

  const directorData = useSelector((e) => e.directorAuthData.directorDetail);

  useEffect(() => {
    dispatch(staticDataAction());
  }, [dispatch]);

  console.log(staticListForDirector, "staticListForDirector");

  const optionsEduation = staticListForDirector?.education_level?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsMaritalStatus = staticListForDirector?.martial_status?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsChildren = staticListForDirector?.children?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  console.log(directorData, "directorData");

  const initialValues = {
    education_level:
      optionsEduation?.find(
        (option) => option.value == directorData?.education_level
      ) || null,
    martial_status:
      optionsMaritalStatus?.find(
        (e) => e.value === directorData?.martial_status
      ) || null,
    children:
      optionsChildren?.find((e) => e.value === directorData?.children) || null,
    hobbies: directorData?.hobbies || "",
    other_info_other_notes: directorData?.other_info_other_notes || "",

    // =============================================================================================================

    address: directorData?.address || "",
    email: directorData?.email || "",
    phone_number: directorData?.phone_number || "",
    instagram: directorData?.instagram || "",
    facebook: directorData?.facebook || "",
    snapchat: directorData?.snapchat || "",
    twitter: directorData?.twitter || "",
    director_lawyer_name: directorData?.director_lawyer_name || "",
    director_lawyer_phone_number:
      directorData?.director_lawyer_phone_number || "",
    director_managing_company_name:
      directorData?.director_managing_company_name || "",
    director_managing_company_phone_number:
      directorData?.director_managing_company_phone_number || "",

    general_notes: directorData?.general_notes || "",
    // player_sponsors_name: directorData?.player_sponsors_name || "",
    // player_sponsors_phone_number: directorData?.player_sponsors_phone_number || "",
    contact_info_other_notes: directorData?.contact_info_other_notes || "",
    contact_info_agent_notes: directorData?.contact_info_agent_notes || "",
  };

  const transformValues = (values) => {
    const transformed = { ...values };
    transformed.education_level = values.education_level?.value ?? "0";
    transformed.martial_status = values.martial_status?.value ?? "0";
    transformed.children = values.children?.value ?? 0;
    return transformed;
  };

  const handleSubmit = (values) => {
    const transformedValues = transformValues(values);
    let formData = new FormData();

    for (const key in transformedValues) {
      if (transformedValues.hasOwnProperty(key)) {
        formData.append(key, transformedValues[key]);
      }
    }

    Object.entries(values).forEach(([key, value]) => {
      if (!formData.has(key)) {
        formData.append(key, value);
      }
    });

    formData.append("directorId", directorId);
    formData.append("is_profile_completed", 0);

    dispatch(editDirectorAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("Success");
        localStorage.removeItem("directorId");
        navigate("/my-directors", { replace: true });

        window.history.pushState(null, null, "/my-directors");

        // Prevent further back navigation by listening to the popstate event
        window.addEventListener("popstate", function (event) {
          window.history.pushState(null, null, "/my-directors");
        });
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    dispatch(getDirectorDataById({ id: directorId, type: 1 }));
  }, [dispatch]);

  const handleBack = () => {
    navigate(`/director-contract/${Encryptedid(directorId)}`);
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="">
            <ProgressBar currentStep={7} totalSteps={8} />
            <div className="steps-heading">
              <p>
                <span>Step 4:</span> Contact & Other Information
              </p>
            </div>
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Basic Information</h2>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <FormikForm>
                  {/* <div className=" mt-3 bottom-lines pb-4">
                    <Row>
                      <Col lg={3}>
                        <Form.Group className=" form-inner label-text-up ">
                          <label className="mb-2">Education Level</label>
                          <Select
                            placeholder="Select"
                            classNamePrefix="custom-select"
                            name="education_level"
                            options={optionsEduation}
                            onChange={(option) =>
                              setFieldValue("education_level", option)
                            }
                            value={values.education_level}
                          />
                          <ErrorMessage
                            name="education_level"
                            className="text-danger"
                            component="div"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group className=" form-inner label-text-up">
                          <label className="mb-2">Marital Status</label>
                          <Select
                            placeholder="Select"
                            classNamePrefix="custom-select"
                            name="martial_status"
                            options={optionsMaritalStatus}
                            onChange={(option) =>
                              setFieldValue("martial_status", option)
                            }
                            value={values.martial_status}
                          />

                          <ErrorMessage
                            name="martial_status"
                            className="text-danger"
                            component="div"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group className=" form-inner label-text-up">
                          <label className="mb-2">Children</label>
                          <Select
                            placeholder="Select"
                            classNamePrefix="custom-select"
                            name="children"
                            options={optionsChildren}
                            onChange={(option) =>
                              setFieldValue("children", option)
                            }
                            value={values.children}
                          />

                          <ErrorMessage
                            name="children"
                            className="text-danger"
                            component="div"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={3} className="">
                        <Form.Group className=" form-inner label-text-up">
                          <label className="mb-2">Hobbies & Interest</label>
                          <Form.Control
                            type="text"
                            name="hobbies"
                            placeholder="Select"
                            classNamePrefix="custom-select"
                            as={Field}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div> */}

                  {/* ========================================================================================================= */}
                  <div className=" mt-3 bottom-lines pb-4">
                    <h4 className="h-20">Contact Information</h4>
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner label-text-up ">
                          <label className="mb-2">Address</label>

                          <Field
                            name="address"
                            as={Form.Control}
                            type="text"
                            placeholder="Enter"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner label-text-up">
                          <label className="mb-2">Email</label>

                          <Field
                            name="email"
                            as={Form.Control}
                            type="email"
                            placeholder="Enter "
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner label-text-up">
                          <label className="mb-2">Phone Number</label>
                          <Field
                            name="phone_number"
                            as={Form.Control}
                            type="number"
                            placeholder="Enter"
                          />
                          <ErrorMessage
                            name="phone_number"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className=" mt-3 bottom-lines pb-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Social Media Links</h3>
                    </div>
                    <Row>
                      <Col lg={3}>
                        <Form.Group className="form-inner label-text-up">
                          <label className="mb-2">Instagram</label>
                          <Field
                            name="instagram"
                            as={Form.Control}
                            type="url"
                            placeholder="Enter"
                          />
                          <ErrorMessage
                            name="instagram"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group className="form-inner label-text-up">
                          <label className="mb-2">Facebook</label>
                          <Field
                            name="facebook"
                            as={Form.Control}
                            type="url"
                            placeholder="Enter"
                          />
                          <ErrorMessage
                            name="facebook"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group className="form-inner label-text-up">
                          <label className="mb-2">Snapchat</label>

                          <Field
                            name="snapchat"
                            as={Form.Control}
                            type="url"
                            placeholder="Enter"
                          />
                          <ErrorMessage
                            name="snapchat"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={3} className="">
                        <Form.Group className="form-inner label-text-up">
                          <label className="mb-2">X</label>
                          <Field
                            name="twitter"
                            as={Form.Control}
                            type="url"
                            placeholder="Enter"
                          />
                          <ErrorMessage
                            name="twitter"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  <div className=" mt-3 player-main-div bottom-lines pb-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Director's Lawyers</h3>
                    </div>

                    <div className="sponsor-player">
                      <Form.Group className="form-inner label-text-up">
                        <label className="mb-2">Lawyer's name</label>

                        <Field
                          name="director_lawyer_name"
                          as={Form.Control}
                          type="text"
                          placeholder="Enter"
                        />
                        <ErrorMessage
                          name="director_lawyer_name"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group className="form-inner label-text-up ">
                        <label className="mb-2">Phone Number</label>

                        <Field
                          name="director_lawyer_phone_number"
                          as={Form.Control}
                          type="number"
                          placeholder="Enter"
                        />
                        <ErrorMessage
                          name="director_lawyer_phone_number"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  {/* <div className="d-flex justify-content-between mt-3 ">
                    <h3 className="h-20">Sponsors</h3>
                  </div>

                  <div className="sponsor-player bottom-lines pb-4">
                    <Form.Group className="form-inner label-text-up">
                      <label className="mb-2">Sponsor’s name</label>
                      <Field
                        name="player_sponsors_name"
                        as={Form.Control}
                        type="text"
                        placeholder="Enter"
                      />
                      <ErrorMessage
                        name="player_sponsors_name"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>

                    <Form.Group className="form-inner label-text-up ">
                      <label className="mb-2">Phone Number</label>
                      <Field
                        name="player_sponsors_phone_number"
                        as={Form.Control}
                        type="number"
                        placeholder="Enter"
                      />
                      <ErrorMessage
                        name="player_sponsors_phone_number"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </div> */}

                  <div className=" mt-3 player-main-div mt-3 ">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Managing Company</h3>
                    </div>

                    <div className="managing-agents bottom-lines pb-4">
                      <Form.Group className="form-inner Enter label-text-up">
                        <label className="mb-2">Company's name</label>

                        <Field
                          name="director_managing_company_name"
                          as={Form.Control}
                          type="text"
                          placeholder="Enter"
                        />
                        <ErrorMessage
                          name="director_managing_company_name"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group className="form-inner label-text-up ">
                        <label className="mb-2">Phone Number</label>
                        <Field
                          name="director_managing_company_phone_number"
                          as={Form.Control}
                          type="number"
                          placeholder="Enter"
                        />
                        <ErrorMessage
                          name="director_managing_company_phone_number"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className=" mt-3 player-main-div">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Agent’s General Notes</h3>
                    </div>

                    <Form.Group className="form-inner Enter label-text-up">
                      {/* <label className="mb-2">Add Notes</label> */}

                      <Form.Group className="mb-3 form-inner">
                        <Field
                          as="textarea"
                          name="general_notes"
                          rows={3}
                          placeholder="Write notes here"
                          className="form-control"
                        />
                        {/* <ErrorMessage
                            name="contact_info_other_notes"
                            className="text-danger"
                            component="div"
                          /> */}
                      </Form.Group>
                    </Form.Group>
                  </div>

                  {/* ============================================================================================================== */}
                  {/* <div className=" mt-3">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Other Notes</h3>
                    </div>
                    <div className="cmn-form-fields">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3 form-inner">
                            <Field
                              as="textarea"
                              name="other_info_other_notes"
                              rows={3}
                              placeholder="Write here"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="other_info_other_notes"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div> */}

                  <div className="d-flex justify-content-between mb-5">
                    <div className="d-flex">
                      <div className="mt-4 cmn-light-btn save-and-close-btn">
                        <button
                          className="back-btn-ps"
                          type="button"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mt-4 me-2 cmn-light-btn save-and-close-btn"></div>
                      <div className="mt-4 cmn-light-btn">
                        <button type="submit" className="next-btn-ps">
                          {" "}
                          Save & Finish
                        </button>
                      </div>
                    </div>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default DirectorContactInformation;
