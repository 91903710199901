import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Navbar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import LoginLayout from "../components/LoginLayout";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import backgroundImage from "../Assets/images/playerbanner.png";
import { getDirectorDataById } from "../Redux/Action/directorAction";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { useDispatch, useSelector } from "react-redux";
import { calculateAge, capitalize, dateOfBirth } from "../utils/helper";
import toast from "react-hot-toast";
import {
  AvailabilityStatus,
  coachingLicense,
  playerTrophy,
  previousStatus,
  releaseClouse,
  teamLeague,
} from "../utils/rawjson";
import Loader from "../components/Loader";
export default function InternalSearchDirectorProfileDetail() {
  const backgroundImageStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    width: "100%",
  };
  const { id } = useParams();
  const IdVal = id && Decryptedid(atob(id));
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const directorData = useSelector((e) => e?.directorAuthData?.directorDetail);
  const loader = useSelector((e) => e?.directorAuthData?.loading);

  useEffect(() => {
    dispatch(getDirectorDataById({ id: IdVal, type: 1 }));
  }, [dispatch]);

  const copyToClipboard = () => {
    if (directorData?.unique_id) {
      navigator.clipboard
        .writeText(directorData.unique_id)
        .then(() => {
          toast.success("Copied");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleDirectorEdit = (id) => {
    if (id) {
      localStorage.setItem("directorId", id);
    }
  };

  return (
    <LoginLayout>
      <section>
        {loader && <Loader />}

        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Director Profile</h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    {/* <img
                      className="play-img"
                      src={require("../Assets/images/directorimage.png")}
                    /> */}
                    {/* <img
                      className="play-img"
                      src={`${process.env.REACT_APP_IMAGE_PATH}${directorData?.profile_image}`}
                    /> */}

                    {directorData?.gender == 0 ? (
                      <img
                        className="play-img"
                        src={
                          directorData?.profile_image
                            ? `${process.env.REACT_APP_IMAGE_PATH}${directorData.profile_image}`
                            : require("../Assets/images/My Directors male.png")
                        }
                        alt="Profile"
                      />
                    ) : (
                      <img
                        className="play-img"
                        src={
                          directorData?.profile_image
                            ? `${process.env.REACT_APP_IMAGE_PATH}${directorData.profile_image}`
                            : require("../Assets/images/director-female-new.png")
                        }
                        alt="Profile"
                      />
                    )}

                    <div>
                      <h2>
                        {capitalize(directorData?.full_name)},{" "}
                        {calculateAge(directorData?.dob)}
                      </h2>
                      <div className="d-flex align-items-center mb-4 mt-3">
                        <p className="mb-0" ref={inputRef}>
                          Director ID:{directorData?.unique_id}
                        </p>
                        <button onClick={copyToClipboard}>
                          <img
                            src={
                              require("../Assets/images/copywhite.svg").default
                            }
                          />
                        </button>
                      </div>
                      <div className="socio-icons">
                        {/* Instagram */}
                        {directorData?.instagram && (
                          <Link
                            to={directorData.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/instablue.svg")
                                  .default
                              }
                              alt="Instagram"
                            />
                          </Link>
                        )}

                        {/* Snapchat */}
                        {directorData?.snapchat && (
                          <Link
                            to={directorData.snapchat}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/snapblue.svg").default
                              }
                              alt="Snapchat"
                            />
                          </Link>
                        )}

                        {/* X (formerly Twitter) */}
                        {directorData?.twitter && (
                          <Link
                            to={directorData.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/xblue.svg").default
                              }
                              alt="X"
                            />
                          </Link>
                        )}

                        {/* Facebook */}
                        {directorData?.facebook && (
                          <Link
                            to={directorData.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/fbblue.svg").default
                              }
                              alt="Facebook"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="player-right-info">
                    <div className="play-edit">
                      <Link
                        // to="/director-info"
                        to={`/director-info?directorId=${Encryptedid(
                          directorData?._id
                        )}`}
                        onClick={() => handleDirectorEdit(directorData?._id)}
                      >
                        <img
                          src={
                            require("../Assets/images/editwhite.svg").default
                          }
                        />
                        Edit Profile
                      </Link>
                    </div>
                    <div className="play-info-box">
                      <div>
                        <h3>
                          {directorData?.gender === 0 ? "Male" : "Female"}
                        </h3>
                        <p>Gender</p>
                      </div>
                      <div>
                        <h3>{calculateAge(directorData?.dob)}</h3>
                        <p>Age</p>
                      </div>
                      {/* <div>
                        <h3>
                          {AvailabilityStatus[directorData?.availability_status]
                            ? AvailabilityStatus[
                                directorData?.availability_status
                              ]
                            : "N/A"}
                        </h3>
                        <p>Availability</p>
                      </div> */}
                      <div>
                        <h3>
                          {" "}
                          {directorData?.coaching_licenses
                            ?.map((elem) => coachingLicense[elem])
                            .join(", ")}
                        </h3>
                        <p>Coaching Licenses</p>
                      </div>
                      <div>
                        <h3>
                          {directorData?.languages?.length > 1
                            ? `${directorData.languages[0]} +${
                                directorData.languages.length - 1
                              }`
                            : directorData?.languages?.[0]}
                        </h3>
                        <p>Language</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Tabs
              defaultActiveKey="Profile"
              id="uncontrolled-tab-example"
              className="mb-3 mt-4"
            >
              <Tab eventKey="Profile" title="Profile" className="accord-tabs">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Directors Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Date Of Birth:</h4>
                              <p> {dateOfBirth(directorData?.dob) || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Nationality (s):</h4>
                              <p> {directorData?.nationality?.join(", ")}</p>
                            </li>

                            <li>
                              <h4>Availability:</h4>
                              <p>
                                {Array.isArray(
                                  directorData?.availability_status
                                ) &&
                                directorData?.availability_status?.length >
                                  0 ? (
                                  directorData?.availability_status?.map(
                                    (res, index) => (
                                      <span key={index}>
                                        {AvailabilityStatus[res]}
                                        {index !==
                                        directorData.availability_status
                                          .length -
                                          1
                                          ? ", "
                                          : ""}
                                      </span>
                                    )
                                  )
                                ) : (
                                  <span>{"N/A"}</span>
                                )}
                              </p>
                            </li>

                            <li>
                              <h4>Previous Status:</h4>
                              <p>
                                {directorData?.previous_status
                                  ?.map((elem) => previousStatus[elem])
                                  .join(", ")}
                              </p>
                            </li>
                            <li>
                              <h4>Coaching Licenses:</h4>
                              <p>
                                {directorData?.coaching_licenses
                                  ?.map((elem) => coachingLicense[elem])
                                  .join(", ")}
                              </p>
                            </li>
                            <li>
                              <h4>Languages(s):</h4>
                              {/* {Array.isArray(directorData?.languages) &&
                                directorData?.languages.map((ele, index) => {
                                  return (
                                    <>
                                      <p key={index}>{ele}</p>
                                    </>
                                  );
                                })} */}
                              <p>{directorData?.languages?.join(", ")}</p>
                            </li>
                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {directorData?.main_info_other_notes
                                  ? directorData?.main_info_other_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* <div className="search-cmn-box mt-3">
                                                <h2>Agents Notes</h2>
                                                <div className="search-inner-box">
                                                    <ul>
                                                        <li className="flex-column align-items-start mb-0">
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text for printing
                                                                and typesetting industry. Lorem Ipsum has been
                                                                industry standard dummy text ever since 1500s.
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box mt-3">
                        <h2>Carrier Trophies</h2>
                        <div className="search-inner-box">
                          <ul>
                            {directorData?.carrier_trophies?.map((elem, i) => (
                              <li>
                                <div className="d-flex">
                                  <img
                                    src={require("../Assets/images/smtrophy.png")}
                                  />
                                  <h4 className="ms-2">{playerTrophy[elem]}</h4>
                                </div>
                              </li>
                            ))}
                            {/* <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {directorData?.carrier_other_notes
                                  ? directorData?.carrier_other_notes
                                  : "N/A"}
                              </p>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Director’s Agent</h2>
                        <div className="play-inner-box search-result mt-3 add-back-ground-color d-block">
                          <div className="d-flex align-items-center">
                            {directorData?.professional_relation === 0 ? (
                              <img
                                src={
                                  directorData?.creator_id?.profile_image
                                    ? `${process.env.REACT_APP_IMAGE_PATH}${directorData?.creator_id?.profile_image}`
                                    : require("../Assets/images/user.png")
                                }
                              />
                            ) : (
                              <img
                                src={
                                  directorData?.associate_agent_id
                                    ?.profile_image
                                    ? `${process.env.REACT_APP_IMAGE_PATH}${directorData?.associate_agent_id?.profile_image}`
                                    : require("../Assets/images/user.png")
                                }
                              />
                            )}
                            <div className="ms-3">
                              <h4>
                                {directorData?.professional_relation === 0
                                  ? capitalize(
                                      directorData?.creator_id?.full_name
                                    )
                                  : capitalize(
                                      directorData?.associate_agent_id
                                        ?.agent_name
                                    )}
                              </h4>
                              <p
                                className=""
                                style={{
                                  color: "#1D161780",
                                  fontSize: "13px",
                                }}
                              >
                                {directorData?.professional_relation === 0
                                  ? `Agent`
                                  : `Associate Agent`}
                              </p>
                            </div>
                          </div>
                          <div className="search-inner-box  player-agent-search-ag">
                            <ul>
                              <li className="flex-column align-items-start mb-0">
                                <h4>Other Notes:</h4>
                                <p className="mt-1">
                                  {directorData?.relationship_other_notes
                                    ? directorData?.relationship_other_notes
                                    : "N/A"}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="search-cmn-box mt-3">
                        {directorData?.availability_status?.includes(0) ? (
                          <h2>Previous Director's Team</h2>
                        ) : (
                          <h2>Current Team</h2>
                        )}
                        <div className="search-inner-box">
                          <div className="play-inner-box align-items-start">
                            <div className="d-flex">
                              <img
                                src={
                                  directorData?.team_id?.profile_image
                                    ? `${process.env.REACT_APP_IMAGE_PATH}${directorData?.team_id?.profile_image}`
                                    : require("../Assets/images/biglogo.png")
                                }
                              />
                              <div className="ms-3">
                                <h4>
                                  {capitalize(directorData?.team_id?.team_name)}{" "}
                                </h4>
                                <p>
                                  <span>
                                    {directorData?.team_id?.team_country}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <p>
                              <span>
                                {teamLeague[directorData?.team_id?.team_league]}
                              </span>
                            </p>
                          </div>
                          {/* <div className="search-inner-box per-vious-player-team">
                            <ul>
                              <li className="flex-column align-items-start mb-0">
                                <h4>Other Notes:</h4>
                                <p className="mt-1">
                                  {directorData?.previous_player_other_notes
                                    ? directorData?.previous_player_other_notes
                                    : "N/A"}
                                </p>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Contract" title="Contract">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Current Contract Requirements</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Current Annual Salary:</h4>
                              <p>
                                {directorData?.current_contract
                                  ? directorData?.current_contract
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Bonuses:</h4>
                              <p>
                                {directorData?.Bonuses === 1 ? `Yes` : "No"}
                              </p>
                            </li>

                            <li>
                              <h4>Joined Date:</h4>
                              <p>
                                {directorData?.joined_date
                                  ? dateOfBirth(directorData?.joined_date)
                                  : "N/A"}
                              </p>
                            </li>

                            <li>
                              <h4>Contract Start Date:</h4>
                              <p>
                                {directorData?.contract_start_date
                                  ? dateOfBirth(
                                      directorData?.contract_start_date
                                    )
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Contract End Date:</h4>
                              <p>
                                {directorData?.contract_end_date
                                  ? dateOfBirth(directorData?.contract_end_date)
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Release Clause:</h4>
                              <p>
                                {releaseClouse[directorData?.release_clouse]
                                  ? releaseClouse[directorData?.release_clouse]
                                  : "N/A"}
                              </p>
                            </li>

                            <li>
                              <h4>Buyout Clause:</h4>
                              <p>
                                {releaseClouse[directorData?.buyout_clouse]
                                  ? releaseClouse[directorData?.buyout_clouse]
                                  : "N/A"}
                              </p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {directorData?.current_contract_other_notes
                                  ? directorData?.current_contract_other_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>New Contract Requirements</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Annual Asking Salary:</h4>
                              <p>
                                {directorData?.new_contract_annual_salary ||
                                  "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Team's Asking Amount:</h4>
                              <p>{directorData?.current_contract || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Contract Duration:</h4>
                              <p>
                                {directorData?.new_contract_duration || "N/A"}
                              </p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {directorData?.new_contract_other_notes ||
                                  "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>

              <Tab eventKey="Proposals" title="Proposals">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Proposals</h2>
                        <h3 className="in-heading add-new-pd">
                          Teams that the Director has been proposed
                        </h3>
                        {directorData?.proposed_team?.map((elem, i) => {
                          return (
                            <>
                              <div className="search-inner-box" key={i}>
                                <div className="play-inner-box align-items-start">
                                  <div className="d-flex">
                                    <img
                                      src={
                                        elem?.proposed_team_id?.profile_image
                                          ? `${process.env.REACT_APP_IMAGE_PATH}${elem?.proposed_team_id?.profile_image}`
                                          : require("../Assets/images/biglogo.png")
                                      }
                                    />
                                    <div className="ms-3">
                                      <h4>
                                        {capitalize(
                                          elem?.proposed_team_id?.team_name
                                        )}{" "}
                                      </h4>
                                      <p>
                                        <span>
                                          {elem?.proposed_team_country}
                                        </span>
                                        {/* {elem?.proposed_team_id?.team_country && elem?.proposed_team_id?.team_city && ' / '} */}
                                        {/* <span>{elem?.proposed_team_id?.team_city}</span> */}
                                      </p>
                                    </div>
                                  </div>
                                  <p>
                                    <span>
                                      {teamLeague[elem?.proposed_team_league]}
                                    </span>
                                  </p>
                                </div>
                                {elem?.proposed_associate_agent_id && (
                                  <div className="play-inner-box align-items-start">
                                    <div className="d-flex">
                                      <img
                                        src={
                                          elem?.proposed_associate_agent_id
                                            ?.profile_image
                                            ? `${process.env.REACT_APP_IMAGE_PATH}${elem?.proposed_associate_agent_id?.profile_image}`
                                            : require("../Assets/images/vector-image.png")
                                        }
                                      />
                                      <div className="ms-3">
                                        <h4>
                                          {capitalize(
                                            elem?.proposed_associate_agent_id
                                              ?.agent_name
                                          )}{" "}
                                        </h4>
                                        <p>
                                          <span>
                                            Agent Proposed Director to this team
                                          </span>
                                          {/* {elem?.proposed_associate_agent_id?.country && elem?.proposed_associate_agent_id?.city && ' / '}
                          <span>{elem?.proposed_associate_agent_id?.city}</span> */}
                                        </p>
                                      </div>
                                    </div>
                                    {/* <p>
                      <span>
                        {teamLeague[elem?.proposed_team_league]}
                      </span>
                    </p> */}
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <div className="search-cmn-box mt-4">
                        <h2>Other Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                {directorData?.contract_other_notes
                                  ? directorData?.contract_other_notes
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2 style={{ visibility: "hidden" }}>Proposals</h2>
                        <h3 className="in-heading add-new-pd">
                          Teams that are interested for the Director
                        </h3>
                        {directorData?.interested_team?.map((elem, i) => (
                          <div className="search-inner-box">
                            <div className="play-inner-box align-items-start">
                              <div className="d-flex">
                                <img
                                  src={
                                    elem?.interested_team_id?.profile_image
                                      ? `${process.env.REACT_APP_IMAGE_PATH}${elem?.interested_team_id?.profile_image}`
                                      : require("../Assets/images/biglogo.png")
                                  }
                                />
                                <div className="ms-3">
                                  <h4>
                                    {capitalize(
                                      elem?.interested_team_id?.team_name
                                    )}{" "}
                                  </h4>
                                  <p>
                                    <span>{elem?.interested_team_country}</span>
                                    {/* {elem?.interested_team_id?.team_country && elem?.interested_team_id?.team_city && ' / '} */}
                                    {/* <span>{elem?.interested_team_id?.team_city}</span> */}
                                  </p>
                                </div>
                              </div>
                              <p>
                                <span>
                                  {teamLeague[elem?.interested_team_league]}
                                </span>
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <div className="search-cmn-box mt-4">
                                            <h2>Agents Notes</h2>
                                            <div className="search-inner-box">
                                              <ul>
                                                <li className="flex-column align-items-start mb-0">
                                                  <p className="mt-1">
                                                    {elem?.proposed_associate_agent_note
                                                      ? elem?.proposed_associate_agent_note
                                                      : "N/A"}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </div> */}
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Contact" title="Contact">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Contact Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Phone Number:</h4>
                              <p>{directorData?.phone_number || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Email Address:</h4>
                              <p>{directorData?.email || "N/A"}</p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Home Address:</h4>
                              <p className="mt-1">
                                {directorData?.address || "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>

                        <div className="managing-comp mt-3">
                          <h2>Social Media Links</h2>
                          <div className="search-inner-box ">
                            <ul>
                              <li>
                                <h4>Facebook:</h4>
                                <p>
                                  {directorData?.facebook
                                    ? directorData?.facebook
                                    : "N/A"}
                                </p>
                              </li>

                              <li>
                                <h4>Instagram:</h4>
                                <p>
                                  {directorData?.instagram
                                    ? directorData?.instagram
                                    : "N/A"}
                                </p>
                              </li>
                              <li>
                                <h4>Snapchat:</h4>
                                <p>
                                  {directorData?.snapchat
                                    ? directorData?.snapchat
                                    : "N/A"}
                                </p>
                              </li>
                              <li>
                                <h4>X:</h4>
                                <p>
                                  {directorData?.twitter
                                    ? directorData?.twitter
                                    : "N/A"}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="managing-comp mt-3">
                        <h2>Managing Company</h2>
                        <div className="search-inner-box ">
                          <ul>
                            <li>
                              <h4>Managing Company:</h4>
                              <p>
                                {directorData?.director_managing_company_name ||
                                  "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Contact Number:</h4>
                              <p>
                                {directorData?.director_managing_company_phone_number ||
                                  "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="managing-comp mt-3">
                        <h2>Director’s Lawyer</h2>
                        <div className="search-inner-box ">
                          <ul>
                            <li>
                              <h4>Director Lawyer:</h4>
                              <p>
                                {directorData?.director_lawyer_name || "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Contact Number:</h4>
                              <p>
                                {directorData?.director_lawyer_phone_number ||
                                  "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="search-inner-box mt-3">
                        <ul>
                          <li className="flex-column align-items-start mb-0">
                            <h4>Agents General Notes:</h4>
                            <p className="mt-1">
                              {directorData?.general_notes
                                ? directorData?.general_notes
                                : "N/A"}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
