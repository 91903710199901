import { createSlice } from "@reduxjs/toolkit";
import {
  internalAgentSearchAction,
  internalCoachSearchAction,
  internalDirectorSearchAction,
  internalPlayerSearchAction,
  internalSearchAction,
  internalTeamSearchAction,
} from "../Action/internalSearch";

const initialState = {
  loading: false,
  internalPayerSearchlist: [],
  internalDirectorSearchlist: [],
  internalCoachSearchlist: [],
  internalTeamSearchlist: [],
  internalAgentSearchlist: [],
  internalSearchlist: [],
  error: false,
  query: "",
  results: [],
  selectedFilters: {},
  selectedFilters1: {},
  selectedFilters2: {},
  selectedFilters3: {},
  selectedFilters4: {},
  selectedFilters5: {},
  activeTab1: 'Players',
};

const internalSearchAuthSlice = createSlice({
  name: "internalAuth",
  initialState,
  reducers: {
    clearSearchInternalPlayerData: (state) => {
      state.internalPayerSearchlist = [];
    },

    clearSearchInternalDirectorData: (state) => {
      state.internalDirectorSearchlist = [];
    },
    clearSearchInternalCoachData: (state) => {
      state.internalCoachSearchlist = [];
    },

    clearSearchInternalTeamData: (state) => {
      state.internalTeamSearchlist = [];
    },

    clearSearchInternalAgentData: (state) => {
      state.internalAgentSearchlist = [];
    },

    setSearchQuery(state, action) {
      state.query = action.payload;
    },
    setSearchResults(state, action) {
      state.results = action.payload;
    },


    // internal filter states
    setSelectedFilters: (state, action) => {
      state.selectedFilters = { ...state.selectedFilters, ...action.payload };
    },
    setSelectedFilters1: (state, action) => {
      state.selectedFilters1 = {...state.selectedFilters1, ...action.payload}
    },
    setSelectedFilters2: (state, action) => {
      state.selectedFilters2 =  {...state.selectedFilters2, ...action.payload}
    },
    setSelectedFilters3: (state, action) => {
      state.selectedFilters3 =  {...state.selectedFilters3, ...action.payload}
    },
    setSelectedFilters4: (state, action) => {
      state.selectedFilters4 =  {...state.selectedFilters4, ...action.payload}
    },

    setSelectedFilters5: (state, action) => {
      state.selectedFilters5 =  {...state.selectedFilters5, ...action.payload}
    },

    clearSelectedFilter(state) {
      state.selectedFilters = {}
    },
    clearSelectedFilter1(state) {
      state.selectedFilters1 = {}
    },
    clearSelectedFilter2(state) {
      state.selectedFilters2 = {}
    },
    clearSelectedFilter3(state) {
      state.selectedFilters3 = {}
    },
    clearSelectedFilter4(state) {
      state.selectedFilters4 = {}
    },

    setActiveTab1: (state, action) => {
      state.activeTab1 = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(internalPlayerSearchAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(internalPlayerSearchAction.fulfilled, (state, { payload }) => {
        state.internalPayerSearchlist = payload.data;
        state.loading = false;
      })
      .addCase(internalPlayerSearchAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // director list of internal search

      .addCase(internalDirectorSearchAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(internalDirectorSearchAction.fulfilled, (state, { payload }) => {
        state.internalDirectorSearchlist = payload.data;
        state.loading = false;
      })
      .addCase(internalDirectorSearchAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // coach list of internal search
      .addCase(internalCoachSearchAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(internalCoachSearchAction.fulfilled, (state, { payload }) => {
        state.internalCoachSearchlist = payload.data;
        state.loading = false;
      })
      .addCase(internalCoachSearchAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // team list of internal search
      .addCase(internalTeamSearchAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(internalTeamSearchAction.fulfilled, (state, { payload }) => {
        state.internalTeamSearchlist = payload.data;
        state.loading = false;
      })
      .addCase(internalTeamSearchAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // agent list of internal search
      .addCase(internalAgentSearchAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(internalAgentSearchAction.fulfilled, (state, { payload }) => {
        state.internalAgentSearchlist = payload.data;
        state.loading = false;
      })
      .addCase(internalAgentSearchAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      //  internal search by name and id
      .addCase(internalSearchAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(internalSearchAction.fulfilled, (state, { payload }) => {
        state.internalSearchlist = payload.data;
        state.loading = false;
      })
      .addCase(internalSearchAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  clearSearchInternalPlayerData,
  clearSearchInternalDirectorData,
  clearSearchInternalCoachData,
  clearSearchInternalTeamData,
  clearSearchInternalAgentData,
  setSearchQuery,
  setSearchResults,
  setSelectedFilters,
  setSelectedFilters1,
  setSelectedFilters2,
  setSelectedFilters3,
  setSelectedFilters4,
  setSelectedFilters5,
  clearSelectedFilter,
  clearSelectedFilter1,
  clearSelectedFilter2,
  clearSelectedFilter3,
  clearSelectedFilter4,
  setActiveTab1
} = internalSearchAuthSlice.actions;
export default internalSearchAuthSlice.reducer;
