import LoginLayout from "../components/LoginLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Country, State } from "country-state-city";
import toast from "react-hot-toast";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import {
  createAgentAction,
  editAgentAction,
  getAgentDataById,
} from "../Redux/Action/agentAction";
import PreviewModal from "../CommonModals/PreviewModal";
import ProgressBar from "../CommonModals/ProgressBar";

export default function AddAssociateAgent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ids = queryParams.get("agentId");
  const agentId = ids && Decryptedid(atob(ids));

  const [cities, setCities] = useState([]);
  const [cities1, setCities1] = useState([]);
  const [cityMap, setCityMap] = useState({});
  const [loadingCities, setLoadingCities] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  useEffect(() => {
    if (agentId) {
      dispatch(getAgentDataById({ id: agentId, type: 1 }));
    }
  }, [dispatch, agentId]);

  const getAgentDetailById = useSelector((e) => e.agentAuthData.agentDetail);

  const validationSchema = Yup.object().shape({
    // profile_image: Yup.mixed().required("required"),
    // banner_image: Yup.mixed().required("required"),

    agent_name: Yup.mixed().required("required"),
    email: Yup.mixed().required("required"),
    country: Yup.string().required("required"),
    // city: Yup.string().required("required"),
    phone_number: Yup.string().required("required"),
    address: Yup.mixed().required("required"),
    fax: Yup.mixed().required("required"),
    web: Yup.mixed().required("required"),
    // instagram: Yup.mixed().required("required"),
    // facebook: Yup.mixed().required("required"),
    // snapchat: Yup.mixed().required("required"),
    // twitter: Yup.mixed().required("required"),
  });

  const initialValues = {
    profile_image: getAgentDetailById?.profile_image || "",
    // banner_image: getAgentDetailById?.banner_image || "",
    agent_name: getAgentDetailById?.agent_name || "",
    country: getAgentDetailById?.country || "",
    city: getAgentDetailById?.city || "",
    phone_number: getAgentDetailById?.phone_number || "",
    web: getAgentDetailById?.web || "",
    email: getAgentDetailById?.email || "",
    address: getAgentDetailById?.address || "",
    fax: getAgentDetailById?.fax || "",
    instagram: getAgentDetailById?.instagram || "",
    facebook: getAgentDetailById?.facebook || "",
    snapchat: getAgentDetailById?.snapchat || "",
    twitter: getAgentDetailById?.twitter || "",
    // agent_other_notes: getAgentDetailById?.agent_other_notes || "",
  };

  const processFormData = (values) => {
    return {
      ...values,
    };
  };

  const handleShowPreview = (imageSrc) => {
    setPreviewImage(imageSrc);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setPreviewImage("");
  };

  const handleDrop = (e, setFieldValue, type, values) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files);
    if (type === "profile_image") {
      const imageFile = files.find((file) => file.type.startsWith("image/"));

      if (!imageFile) {
        console.error("Only image files are allowed!");
        return;
      }
      setFieldValue("profile_image", imageFile);
    }
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleFileChange1 = (e, setFieldValue, fieldName) => {
    const file = e.target.files[0];
    setFieldValue(fieldName, file ? file : null);
  };

  const handleSaveAndClose = (values, validateForm, setErrors) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length > 0) {
        toast.error("Please fill in all required fields.");
        setErrors(errors);
        return;
      }

      handleSubmit(values, "save");
    });
  };

  const handleSubmit = (values, type) => {
    const processedValues = processFormData(values);
    // let agentId = localStorage.getItem("agentId");

    if (agentId) {
      const formData = new FormData();

      Object.entries(processedValues).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("agentId", agentId);
      formData.append("is_profile_completed", 1);

      dispatch(editAgentAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          if (type === "save") {
            navigate(`/manage-associate-agent`);
          } else {
            navigate(
              `/add-associate-two/${Encryptedid(res?.payload?.data?._id)}`
            );
          }
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } else {
      const formData = new FormData();

      Object.entries(processedValues).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append("is_profile_completed", 1);

      dispatch(createAgentAction(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          if (type === "save") {
            navigate(`/manage-associate-agent`);
          } else {
            navigate(
              `/add-associate-two/${Encryptedid(res?.payload?.data?._id)}`
            );
          }
          // localStorage.setItem("agentId", res?.payload?.data?._id);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    }
  };

  const handleFileChange = (e, setFieldValue, fieldName) => {
    const file = e.target.files[0];
    setFieldValue(fieldName, file ? file : null);
  };

  useEffect(() => {
    // let agentId = localStorage.getItem("agentId");
    if (agentId) {
      dispatch(getAgentDataById({ id: agentId }));
    }
  }, [dispatch]);

  // const fetchCities = async (countryName) => {
  //   setLoadingCities(true);
  //   try {
  //     const stateList = State.getStatesOfCountry(countryName);
  //     const cityMap = {};

  //     const cities = stateList.map((state) => {
  //       cityMap[state.isoCode] = state.name;
  //       return {
  //         value: state.name,
  //         label: state.name,
  //       };
  //     });

  //     setCities(cities);
  //     setCityMap(cityMap);
  //   } catch (error) {
  //     console.error("Error fetching cities:", error);
  //   } finally {
  //     setLoadingCities(false);
  //   }
  // };

  // const handleCountryChange = async (
  //   selectedOption,
  //   { setFieldValue, setFieldTouched }
  // ) => {
  //   if (!selectedOption) return;

  //   const countryData = Country.getAllCountries().find(
  //     (country) => country.isoCode === selectedOption.value
  //   );

  //   if (countryData) {
  //     setSelectedCountry({
  //       value: countryData.isoCode,
  //       label: countryData.name,
  //     });

  //     setFieldValue("country", countryData.name);
  //     setFieldValue("city", "");
  //     setCities([]);
  //     await fetchCities(countryData.isoCode);
  //     setCities1(null);
  //     setFieldTouched("country", false);
  //   }
  // };

  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(
      countryList.map((country) => ({
        value: country.isoCode,
        label: country.name,
      }))
    );
  }, []);

  useEffect(() => {
    if (getAgentDetailById?.country) {
      const countryOption = countries.find(
        (country) => country.label === getAgentDetailById?.country
      );
      setSelectedCountry(countryOption || null);

      if (countryOption) {
        fetchCities(countryOption.value);
      }
    }
  }, [getAgentDetailById, countries]);

  useEffect(() => {
    if (getAgentDetailById?.city) {
      const selectedCityData = cities?.find(
        (city) => city.label === getAgentDetailById.city
      );

      if (selectedCityData) {
        setCities1(selectedCityData);
      }
    }
  }, [cities, getAgentDetailById]);

  useEffect(() => {
    if (selectedCountry) {
      fetchCities(selectedCountry.value);
    } else {
      setCities([]);
      setCities1(null);
    }
  }, [selectedCountry]);

  const fetchCities = async (countryCode) => {
    setLoadingCities(true);
    try {
      const stateList = State.getStatesOfCountry(countryCode);
      const cities = stateList.map((state) => ({
        value: state.name,
        label: state.name,
      }));
      setCities(cities);
      setCityMap(
        stateList.reduce((map, state) => {
          map[state.name] = state.isoCode;
          return map;
        }, {})
      );
    } catch (error) {
      console.error("Error fetching cities:", error);
    } finally {
      setLoadingCities(false);
    }
  };

  const handleCountryChange = async (
    selectedOption,
    { setFieldValue, setFieldTouched }
  ) => {
    if (!selectedOption) return;

    const countryData = Country.getAllCountries().find(
      (country) => country.isoCode === selectedOption.value
    );

    if (countryData) {
      setSelectedCountry({
        value: countryData.isoCode,
        label: countryData.name,
      });

      setFieldValue("country", countryData.name);
      setFieldValue("city", "");
      setCities([]);
      await fetchCities(countryData.isoCode);
      setFieldTouched("country", false);
    }
  };

  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(
      countryList.map((country) => ({
        value: country.isoCode,
        label: country.name,
      }))
    );
  }, []);

  useEffect(() => {
    if (getAgentDetailById?.country) {
      const countryOption = countries.find(
        (country) => country.label === getAgentDetailById?.country
      );
      setSelectedCountry(countryOption || null);

      if (countryOption) {
        fetchCities(countryOption.value);
      }
    }
  }, [getAgentDetailById, countries]);

  useEffect(() => {
    if (selectedCountry) {
      fetchCities(selectedCountry.value);
    } else {
      setCities([]);
      setCities1(null);
    }
  }, [selectedCountry]);

  return (
    <LoginLayout>
      <section>
        <Container>
          <ProgressBar currentStep={1} totalSteps={5} />
          <div className="steps-heading">
            <p>
              {" "}
              <span>Step 1:</span> Associate Agent's Main Information{" "}
            </p>
          </div>

          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              setFieldValue,
              values,
              setFieldTouched,
              validateForm,
              setErrors,
            }) => {
              return (
                <FormikForm>
                  {/* <div className="common-outer">
                      <Row>
                        <Col lg={4}>
                          <div className="image-upload-main">
                            <h3>Logo</h3>
                            <div className="image-upload-inner">
                              <div className="image-upload-placeholder">
                                <img
                                  src={
                                    require("../Assets/images/upload.svg")
                                      .default
                                  }
                                  alt="Upload Placeholder"
                                />

                                <h4>Upload Image</h4>
                              </div>
                              <Field name="profile_image">
                                {({ field }) => (
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        setFieldValue,
                                        "profile_image"
                                      )
                                    }
                                  />
                                )}
                              </Field>

                              <div className="image-upload-placeholder">
                                {values?.profile_image &&
                                typeof values?.profile_image === "string" ? (
                                  <img
                                    className="inner-view"
                                    src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                    alt="Upload Placeholder"
                                  />
                                ) : values?.profile_image &&
                                  values.profile_image instanceof File ? (
                                  <img
                                    src={
                                      values?.profile_image
                                        ? URL.createObjectURL(
                                            values?.profile_image
                                          )
                                        : ""
                                    }
                                    className="prev-img"
                                    alt="Profile Preview"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>

                              {values?.profile_image &&
                                (typeof values.profile_image === "string" ||
                                  values.profile_image instanceof File) && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setFieldValue("profile_image", null)
                                    }
                                  >
                                    <img
                                      src={
                                        require("../Assets/images/delete.svg")
                                          .default
                                      }
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                            </div>
                            <ErrorMessage
                              name="profile_image"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="image-upload-main">
                            <h3>Banner Image</h3>
                            <div className="image-upload-inner">
                              <div className="image-upload-placeholder">
                                <img
                                  src={
                                    require("../Assets/images/upload.svg")
                                      .default
                                  }
                                  alt="Upload Placeholder"
                                />

                                <h4>Upload Image</h4>
                              </div>
                              <Field name="banner_image">
                                {({ field }) => (
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        e,
                                        setFieldValue,
                                        "banner_image"
                                      )
                                    }
                                  />
                                )}
                              </Field>
                              <div className="image-upload-placeholder">
                                {values?.banner_image &&
                                typeof values.banner_image === "string" ? (
                                  <img
                                    className="inner-view"
                                    src={`${process.env.REACT_APP_IMAGE_PATH}${values?.banner_image}`}
                                    alt="Upload Placeholder"
                                  />
                                ) : values?.banner_image &&
                                  values.banner_image instanceof File ? (
                                  <img
                                    src={URL.createObjectURL(
                                      values.banner_image
                                    )}
                                    className="prev-img"
                                    alt="Banner Preview"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>

                              {values?.banner_image &&
                                (typeof values.banner_image === "string" ||
                                  values.banner_image instanceof File) && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setFieldValue("banner_image", null)
                                    }
                                  >
                                    <img
                                      src={
                                        require("../Assets/images/delete.svg")
                                          .default
                                      }
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                            </div>
                            <ErrorMessage
                              name="banner_image"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Col>
                        <Col lg={4}></Col>
                      </Row>
                    </div> */}

                  <div className=" mt-4">
                    <h3 className="h-20">
                      Associate Agent's General Information
                    </h3>
                    <div className="cmn-form-fields mt-2">
                      <div className="new-grid-system">
                        <div>
                          <Form.Group className=" form-inner label-text-up">
                            <label className="mb-2">Agent’s Name</label>
                            <Field
                              name="agent_name"
                              type="text"
                              className="form-control"
                              placeholder="Enter name"
                              onKeyPress={(event) => {
                                const regex = /^[a-zA-Z\s]*$/;
                                if (!regex.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onPaste={(event) => {
                                const pastedData =
                                  event.clipboardData.getData("Text");
                                const regex = /^[a-zA-Z\s]*$/;
                                if (!regex.test(pastedData)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            <ErrorMessage
                              name="agent_name"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </div>
                        <div>
                          <div className="profile-card label-text-up">
                            <label className="mb-2">Agent’s Country</label>
                            <Select
                              name="country"
                              placeholder="Select"
                              classNamePrefix="custom-select"
                              isSearchable={true}
                              value={selectedCountry}
                              onChange={(e) =>
                                handleCountryChange(e, {
                                  setFieldValue,
                                  setFieldTouched,
                                })
                              }
                              options={countries}
                            />
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div>
                          <div className="profile-card label-text-up">
                            <label className="mb-2">Agent’s Country</label>
                            <Select
                              // name="city"
                              // placeholder="City"
                              // options={cities}
                              // onChange={(option) => {
                              //   setFieldValue("city", option);
                              //   setCities1(option);
                              // }}
                              // value={cities1 || ""}
                              // isLoading={loadingCities}
                              // isDisabled={!selectedCountry}
                              options={cities}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "city",
                                  selectedOption?.value || ""
                                )
                              }
                              value={cities?.find(
                                (city) => city.value === values.city
                              )}
                              isDisabled={!cities.length}
                              placeholder="City"
                              name="city"
                            />
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div>
                          <Form.Group className=" form-inner label-text-up">
                            <label className="mb-2">Agent’s Phone Number</label>
                            <Field
                              name="phone_number"
                              type="number"
                              className="form-control"
                              placeholder="Enter phone number"
                            />
                            <ErrorMessage
                              name="phone_number"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </div>

                        <div>
                          <Form.Group
                            className=" form-inner
                          label-text-up"
                          >
                            <label className="mb-2">Agent’s Fax</label>
                            <Field
                              name="fax"
                              type="text"
                              className="form-control"
                              placeholder="Enter fax"
                            />
                            <ErrorMessage
                              name="fax"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </div>

                        <div>
                          <Form.Group className="form-inner mb-3 label-text-up">
                            <label className="mb-2">Agent’s Email</label>
                            <Field
                              name="email"
                              as={Form.Control}
                              type="email"
                              placeholder="Enter email here"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </div>

                        <div>
                          <Form.Group className="form-inner mb-3 label-text-up">
                            <label className="mb-2">Agent’s Address</label>
                            <Field
                              name="address"
                              as={Form.Control}
                              type="text"
                              placeholder="Enter address"
                            />
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </div>

                        <div>
                          <Form.Group className="mb-3 form-inner label-text-up">
                            <label className="mb-2">Agent’s Web</label>
                            <Field
                              name="web"
                              type="text"
                              className="form-control"
                              placeholder="Enter web"
                            />
                            <ErrorMessage
                              name="web"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" mt-2">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Social Media Links</h3>
                    </div>
                    <div className="new-grid-system">
                      <div>
                        <Form.Group className="form-inner mb-3 label-text-up">
                          <label className="mb-2">Instagram</label>
                          <Field
                            name="instagram"
                            as={Form.Control}
                            type="url"
                            placeholder="Instagram"
                          />
                          {/* <ErrorMessage
                            name="instagram"
                            component="div"
                            className="text-danger"
                          /> */}
                        </Form.Group>
                      </div>
                      <div>
                        <Form.Group className="form-inner mb-3 label-text-up">
                          <label className="mb-2">Facebook</label>
                          <Field
                            name="facebook"
                            as={Form.Control}
                            type="url"
                            placeholder="Facebook"
                          />
                          {/* <ErrorMessage
                            name="facebook"
                            component="div"
                            className="text-danger"
                          /> */}
                        </Form.Group>
                      </div>
                      <div>
                        <Form.Group className="form-inner mb-3 label-text-up">
                          <label className="mb-2">Snapchat</label>
                          <Field
                            name="snapchat"
                            as={Form.Control}
                            type="url"
                            placeholder="Snapchat"
                          />
                          {/* <ErrorMessage
                            name="snapchat"
                            component="div"
                            className="text-danger"
                          /> */}
                        </Form.Group>
                      </div>
                      <div className="">
                        <Form.Group className="form-inner label-text-up">
                          <label className="mb-2">X</label>
                          <Field
                            name="twitter"
                            as={Form.Control}
                            type="url"
                            placeholder="X"
                          />
                          {/* <ErrorMessage
                            name="twitter"
                            component="div"
                            className="text-danger"
                          /> */}
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  <Row className="mt-4">
                    <Col lg={6}>
                      <div className="vidoe-link-box">
                        <div className="box-flex-up link-videos  mb-3">
                          <div className="players-cv">
                            <div className="player-up">
                              <h3>Associate Agent's Image</h3>
                              <div
                                className={`uplode-the-img ${
                                  dragging ? "dragging" : ""
                                }`}
                                onDrop={(e) =>
                                  handleDrop(e, setFieldValue, "profile_image")
                                }
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                              >
                                <div className="upload-and-drop">
                                  <div className="click-to-up">
                                    <label htmlFor="file-upload">
                                      <button type="button">
                                        Click to Upload
                                        <Field name="profile_image">
                                          {({ field }) => (
                                            <input
                                              type="file"
                                              id="file-upload"
                                              onChange={(e) =>
                                                handleFileChange1(
                                                  e,
                                                  setFieldValue,
                                                  "profile_image"
                                                )
                                              }
                                              accept="image/*"
                                            />
                                          )}
                                        </Field>
                                      </button>
                                    </label>
                                  </div>
                                  <div className="drag-drop">
                                    <p>
                                      <span>
                                        <img
                                          src={
                                            require("../Assets/images/drag-and-drop.svg")
                                              .default
                                          }
                                          alt="Upload Placeholder"
                                        />
                                      </span>
                                      Or drag and drop files.
                                    </p>
                                  </div>
                                </div>
                                <div className="preview">
                                  <p>Preview</p>
                                  <div className="preview-content">
                                    {values?.profile_image &&
                                      (typeof values.profile_image ===
                                      "string" ? (
                                        <img
                                          className="inner-view"
                                          src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                          alt="Profile Preview"
                                          onClick={() =>
                                            handleShowPreview(
                                              `${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`
                                            )
                                          }
                                        />
                                      ) : values.profile_image instanceof
                                        File ? (
                                        <img
                                          src={URL.createObjectURL(
                                            values?.profile_image
                                          )}
                                          className="prev-img"
                                          alt="Profile Preview"
                                          style={{ width: "50px" }}
                                          onClick={() =>
                                            handleShowPreview(
                                              URL.createObjectURL(
                                                values?.profile_image
                                              )
                                            )
                                          }
                                        />
                                      ) : null)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    {/* <Col lg={6}>
                      <div className="carrier-notes">
                        <div className="cmn-form-fields ">
                          <div className="all-notes ">
                            <h3 className="h-20">Other Notes</h3>
                            <div className="cmn-form-fields">
                              <Form.Group className="mb-3 form-inner">
                                <Field
                                  as="textarea"
                                  name="agent_other_notes"
                                  rows={2}
                                  className="form-control"
                                  placeholder="Write notes here"
                                />
                                <ErrorMessage
                                  name="agent_other_notes"
                                  component="div"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}
                  </Row>

                  <div className="d-flex justify-content-end mb-5">
                    <div className="mt-4 ">
                      <div className="cmn-light-btn save-and-close-btn">
                        <button
                          type="button"
                          onClick={() =>
                            handleSaveAndClose(values, validateForm, setErrors)
                          }
                          className="next-btn-ps"
                        >
                          {" "}
                          Save & Close{" "}
                        </button>
                      </div>
                    </div>

                    <div className="mt-4 ">
                      <div className="cmn-light-btn">
                        <button type="submit" className="next-btn-ps">
                          {" "}
                          Save & Next{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </FormikForm>
              );
            }}
          </Formik>
        </Container>
        <PreviewModal
          show={showPreview}
          onHide={handleClosePreview}
          previewImage={previewImage}
        />
      </section>
    </LoginLayout>
  );
}
