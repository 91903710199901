import React, { useEffect, useState } from "react";
import LoginLayout from "../components/LoginLayout";
import { Container, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  staticDataAction,
  staticDirectorDataAction,
} from "../Redux/Action/staticAction";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import {
  addNewTeam,
  createPlayerAction,
  editPlayerAction,
} from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import PreviewModal from "../CommonModals/PreviewModal";
import ProgressBar from "../CommonModals/ProgressBar";
import Loader from "../components/Loader";
import { clearPlayerDetail } from "../Redux/Reducers/playerSlice";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  createDirectorAction,
  editDirectorAction,
  getDirectorDataById,
} from "../Redux/Action/directorAction";
import { getTeamListAction } from "../Redux/Action/teamAction";

Modal.setAppElement("#root");

const AddDirector = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const location = useLocation();
  const [direactorId, setDirectorId] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ids = queryParams.get("directorId");
    const id = ids && Decryptedid(atob(ids));

    if (id) {
      setDirectorId(id);
      dispatch(getDirectorDataById({ id, type: 1 }));
    }
  }, [dispatch, location.search]);

  const [age, setAge] = useState("");
  const [isFree, setIsFree] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTeamName, setNewTeamName] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [dragging, setDragging] = useState(false);

  const staticListForDirector = useSelector(
    (e) => e.staticAuthData.directorStaticDetail
  );
  const directorData = useSelector((e) => e.directorAuthData.directorDetail);
  const playerDataLoadingState = useSelector((e) => e.playerAuthData.loading);
  const teanListingData = useSelector((e) => e.teamAuthData.getTeamListing);

  console.log(directorData, "directorData ");

  const [teamOptions, setTeamOptions] = useState(
    teanListingData?.map((elem) => ({
      label: elem?.team_name,
      value: elem?._id,
      data: elem,
    })) || []
  );

  useEffect(() => {
    const teamOptions = teanListingData?.map((elem) => ({
      label: elem?.team_name,
      value: elem?._id,
      data: elem,
    }));
    setTeamOptions(teamOptions);
  }, [teanListingData]);

  const optionsGender = staticListForDirector?.gender?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPlaceOfBirth = staticListForDirector?.place_of_birth?.map(
    (item) => ({
      label: item,
      value: item,
    })
  );

  const optionsNationaity = staticListForDirector?.nationality?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsLanguage = staticListForDirector?.languages?.map((item) => ({
    label: item,
    value: item,
  }));

  const optionsTeamLeague = staticListForDirector?.proposed_team_league?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsTrophy = staticListForDirector?.trophy?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsPlayerStatus = staticListForDirector?.availability_status?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  console.log("staticListForDirector", staticListForDirector?.availability_status)


  const optionsAskingAmount = staticListForDirector?.asking_amount?.map(
    (item) => ({
      label: item?.val,
      value: item?.val,
    })
  );

  const optionsannualAskingSalary =
    staticListForDirector?.current_contract?.map((item) => ({
      label: item?.val,
      value: item?.val,
    }));

  const optionsNationaityId = staticListForDirector?.previous_status?.map(
    (item, i) => ({
      label: item?.val,
      value: i,
    })
  );

  const optionsCoachingLicense = staticListForDirector?.coaching_licenses?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  useEffect(() => {
    dispatch(staticDirectorDataAction());
  }, [dispatch]);

  const validateForm = (values) => {
    const errors = {};

    if (!values.gender || values.gender.length === 0) {
      errors.gender = "required";
    }

    if (!values.full_name || values.full_name.trim().length === 0) {
      errors.full_name = "required";
    }

    if (!values.dob || values.dob.length === 0) {
      errors.dob = "required";
    }

    if (!values.nationality || values.nationality.length === 0) {
      errors.nationality = "required";
    }

    if (!values.coaching_licenses || values.coaching_licenses.length === 0) {
      errors.coaching_licenses = "required";
    }

    if (!values.previous_status || values.previous_status.length === 0) {
      errors.previous_status = "required";
    }

    if (!values.languages || values.languages.length === 0) {
      errors.languages = "required";
    }

    if (
      !values.availability_status ||
      values.availability_status.length === 0
    ) {
      errors.availability_status = "required";
    }

    if (values.is_director_free == 0) {
      if (!values.team_id) {
        errors.team_id = "required";
      }
    }

    if (
      !values.availability_status ||
      values.availability_status.length === 0
    ) {
      errors.availability_status = "required";
    }

    // ====================================================================================
    if (!values.new_contract_annual_salary) {
      errors.new_contract_annual_salary = "required";
    }

    // ===================================================================================

    if (!values.carrier_trophies || values.carrier_trophies.length === 0) {
      errors.carrier_trophies = "required";
    }

    console.log(errors, "sdfsdfsdfsdfsd234234");
    return errors;
  };

  useEffect(() => {
    if (directorData?.dob) {
      const birthDate = moment(directorData.dob).toDate();
      calculateAge(birthDate);
    }
  }, [directorData?.dob]);

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDateObj = new Date(dob);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }
    setAge(age);
  };

  const handleFileChange1 = (e, setFieldValue, fieldName) => {
    const file = e.target.files[0];
    setFieldValue(fieldName, file ? file : null);
  };

  useEffect(() => {
    dispatch(clearPlayerDetail());
    if (direactorId) {
      dispatch(getDirectorDataById({ id: direactorId, type: 1 }));
    }
    dispatch(getTeamListAction());
  }, [dispatch, direactorId]);

  const initialValues = {
    gender:
      optionsGender?.find((elem) => elem.value === directorData?.gender) || [],
    full_name: directorData?.full_name || "",
    dob: directorData?.dob ? moment(directorData.dob).format("DD/MM/YYYY") : "",
    main_info_other_notes: directorData?.main_info_other_notes || "",
    place_of_birth:
      optionsPlaceOfBirth?.find(
        (elem) => elem.value === directorData?.place_of_birth
      ) || [],
    nationality:
      directorData?.nationality?.map((item) => ({
        label: item,
        value: item,
      })) || [],

    languages:
      directorData?.languages?.map((item) => ({ label: item, value: item })) ||
      [],

    is_director_free: directorData?.is_director_free || 0,
    team_id:
      teamOptions?.find((elem) => elem.value === directorData?.team_id?._id) ||
      "",
    team_country:
      optionsPlaceOfBirth?.find(
        (elem) => elem.value === directorData?.team_id?.team_country
      ) ?? null,
    team_league:
      optionsTeamLeague?.find(
        (elem) => elem.value == directorData?.team_id?.team_league
      ) ?? null,

    availability_status:
      directorData?.availability_status
        ?.map((footValue) => {
          const matchedOption = optionsPlayerStatus?.find(
            (option) => option.value === footValue
          );
          return matchedOption || null;
        })
        .filter((option) => option !== null) || [],

    carrier_trophies:
      directorData?.carrier_trophies
        ?.map((trophyObj) => {
          return (
            optionsTrophy?.find((option) => option.value === trophyObj) || null
          );
        })
        .filter((option) => option !== null) || [],

    previous_status:
      directorData?.previous_status?.length > 0
        ? directorData?.previous_status?.map((status) =>
            optionsNationaityId?.find((elem) => elem.value === status)
          )
        : [],

    coaching_licenses:
      Array.isArray(optionsCoachingLicense) && directorData?.coaching_licenses
        ? optionsCoachingLicense?.find(
            (elem) => elem.value === directorData.coaching_licenses[0]
          ) || ""
        : "",

    profile_image: directorData?.profile_image || "",
    new_contract_annual_salary:
      optionsannualAskingSalary?.find(
        (elem) => elem.value === directorData?.new_contract_annual_salary
      ) || "",
  };

  const handleShowPreview = (imageSrc) => {
    setPreviewImage(imageSrc);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setPreviewImage("");
  };

  const handleDrop = (e, setFieldValue, type, values) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files);
    if (type === "profile_image") {
      const imageFile = files.find((file) => file.type.startsWith("image/"));

      if (!imageFile) {
        console.error("Only image files are allowed!");
        return;
      }
      setFieldValue("profile_image", imageFile);
    }
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleAddTeam = (values, setFieldValue) => {
    if (!newTeamName.trim()) {
      toast.error("Team name cannot be empty.");
      return;
    }

    let teamCountry = values?.team_country?.value;
    let teamLeague = values?.team_league?.value;

    if (!teamCountry) {
      toast.error("Please select a team country.");
      return;
    }

    if (teamLeague == null) {
      toast.error("Please select a team league.");
      return;
    }

    dispatch(
      addNewTeam({
        name: newTeamName,
        type: 1,
        team_country: teamCountry,
        team_league: teamLeague,
      })
    ).then((res) => {
      if (res?.payload?.status === 200) {
        const newTeamId = res?.payload?.data?._id;
        const newTeam = { label: newTeamName, value: newTeamId };

        setTeamOptions([...teamOptions, newTeam]);
        setFieldValue("team_id", newTeam);

        setTimeout(() => {
          setIsModalOpen(false);
          setNewTeamName("");
        }, 0);
      } else {
        toast.error("Failed to add the team. Please try again.");
      }
    });
  };

  const handleInputChange = (input) => {
    const filteredInput = input.replace(/[^a-zA-Z\s]/g, "");
    const isTeamExists = teamOptions?.some((team) =>
      team?.label?.toLowerCase()?.includes(filteredInput.toLowerCase())
    );
    setIsAddingTeam(!isTeamExists);
    return filteredInput;
  };

  const customOptions = [
    { label: "+ Add new Team", value: "add-team", isAddTeam: true },
    ...teamOptions,
  ];

  const handleSelectChange = (option, setFieldValue) => {
    if (option?.isAddTeam) {
      setIsModalOpen(true);
    } else {
      setFieldValue(
        "team_country",
        optionsPlaceOfBirth?.find(
          (elem) => elem.value === option?.data?.team_country
        )
      );
      setFieldValue(
        "team_league",
        optionsTeamLeague?.find(
          (elem) => elem.value == option?.data?.team_league
        )
      );
      setFieldValue("team_id", option);
    }
  };

  const handleSaveAndClose = (values, validateForm, setErrors) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length > 0) {
        toast.error("Please fill in all required fields.");
        setErrors(errors);
        return;
      }

      if (direactorId) {
        if (values.dob) {
          values.dob = moment(values.dob, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        let formData = new FormData();

        Object.keys(values).forEach((key) => {
          if (key === "team_id" && values.is_director_free === 1) {
            return;
          }

          if (Array.isArray(values[key])) {
            values[key].forEach((value, index) => {
              if (key !== "photos") {
                if (typeof value === "object" && value !== null) {
                  formData.append(`${key}[${index}]`, value?.value);
                } else if (value !== null && value !== undefined) {
                  formData.append(`${key}[${index}]`, value);
                }
              }
            });
          } else {
            if (key === "height" && values[key]?.value !== undefined) {
              formData.append(key, values[key]?.value);
            } else if (
              typeof values[key] === "object" &&
              values[key] !== null &&
              values[key]?.value !== undefined
            ) {
              formData.append(key, values[key]?.value);
            } else if (values[key] !== null && values[key] !== undefined) {
              formData.append(key, values[key]);
            }
          }
        });

        formData.append("directorId", direactorId);
        formData.append("is_profile_completed", 1);

        dispatch(editDirectorAction(formData)).then((res) => {
          if (res?.payload?.status === 200) {
            navigate("/my-directors");
          } else {
            toast.error(res?.payload?.message);
          }
        });
      } else {
        if (values.dob) {
          values.dob = moment(values.dob, "DD/MM/YYYY").format("YYYY-MM-DD");
        }

        let formData = new FormData();

        Object.keys(values).forEach((key) => {
          if (Array.isArray(values[key])) {
            values[key].forEach((value, index) => {
              if (key !== "photos") {
                if (typeof value === "object" && value !== null) {
                  formData.append(
                    `${key}[${index}]`,
                    key === "team_id" ? value?.value : value?.value
                  );
                } else if (value !== null && value !== undefined) {
                  formData.append(`${key}[${index}]`, value);
                }
              }
            });
          } else {
            if (key === "height" && values[key]?.value !== undefined) {
              formData.append(key, values[key]?.value);
            } else if (key === "gender" || key === "team_id") {
              if (
                values[key]?.value !== null &&
                values[key]?.value !== undefined
              ) {
                formData.append(key, values[key]?.value);
              }
            } else if (
              typeof values[key] === "object" &&
              values[key] !== null &&
              values[key]?.value !== undefined
            ) {
              formData.append(key, values[key]?.value);
            } else if (values[key] !== null && values[key] !== undefined) {
              formData.append(key, values[key]);
            }
          }
        });

        // formData.append("carrier_trophies", JSON.stringify(transformedData));
        formData.append("is_profile_completed", 1);

        dispatch(createDirectorAction(formData)).then((res) => {
          if (res?.payload?.status === 200) {
            navigate("/my-directors");
          } else {
            toast.error(res?.payload?.message);
          }
        });
      }
    });
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <ProgressBar currentStep={1} totalSteps={8} />
          <div className="steps-heading">
            <p>
              {" "}
              <span>Step 1:</span> Director's Main Information{" "}
            </p>
          </div>
          <div className="over-ride-cmn">
            <Formik
              initialValues={initialValues}
              validate={validateForm}
              enableReinitialize={true}
              onSubmit={(values) => {
                if (direactorId) {
                  if (values.dob) {
                    values.dob = moment(values.dob, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    );
                  }

                  let formData = new FormData();

                  Object.keys(values).forEach((key) => {
                    if (key === "team_id" && values.is_director_free === 1) {
                      return;
                    }

                    if (Array.isArray(values[key])) {
                      values[key].forEach((value, index) => {
                        if (key !== "photos") {
                          if (typeof value === "object" && value !== null) {
                            formData.append(`${key}[${index}]`, value?.value);
                          } else if (value !== null && value !== undefined) {
                            formData.append(`${key}[${index}]`, value);
                          }
                        }
                      });
                    } else {
                      if (
                        key === "height" &&
                        values[key]?.value !== undefined
                      ) {
                        formData.append(key, values[key]?.value);
                      } else if (
                        typeof values[key] === "object" &&
                        values[key] !== null &&
                        values[key]?.value !== undefined
                      ) {
                        formData.append(key, values[key]?.value);
                      } else if (
                        values[key] !== null &&
                        values[key] !== undefined
                      ) {
                        formData.append(key, values[key]);
                      }
                    }
                  });

                  formData.append("directorId", direactorId);
                  formData.append("is_profile_completed", 1);

                  dispatch(editDirectorAction(formData)).then((res) => {
                    if (res?.payload?.status === 200) {
                      navigate(
                        `/director-agent-relationship/${Encryptedid(
                          res?.payload?.data?._id
                        )}`
                      );
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                } else {
                  if (values.dob) {
                    values.dob = moment(values.dob, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    );
                  }

                  let formData = new FormData();

                  Object.keys(values).forEach((key) => {
                    if (Array.isArray(values[key])) {
                      values[key].forEach((value, index) => {
                        if (key !== "photos") {
                          if (typeof value === "object" && value !== null) {
                            formData.append(
                              `${key}[${index}]`,
                              key === "team_id" ? value?.value : value?.value
                            );
                          } else if (value !== null && value !== undefined) {
                            formData.append(`${key}[${index}]`, value);
                          }
                        }
                      });
                    } else {
                      if (values[key]?.value !== undefined) {
                        formData.append(key, values[key]?.value);
                      } else if (key === "gender" || key === "team_id") {
                        if (
                          values[key]?.value !== null &&
                          values[key]?.value !== undefined
                        ) {
                          formData.append(key, values[key]?.value);
                        }
                      } else if (
                        typeof values[key] === "object" &&
                        values[key] !== null &&
                        values[key]?.value !== undefined
                      ) {
                        formData.append(key, values[key]?.value);
                      } else if (
                        values[key] !== null &&
                        values[key] !== undefined
                      ) {
                        formData.append(key, values[key]);
                      }
                    }
                  });

                  formData.append("is_profile_completed", 1);

                  dispatch(createDirectorAction(formData)).then((res) => {
                    if (res?.payload?.status === 200) {
                      navigate(
                        `/director-agent-relationship/${Encryptedid(
                          res?.payload?.data?._id
                        )}`
                      );
                    } else {
                      toast.error(res?.payload?.message);
                    }
                  });
                }
              }}
            >
              {({ values, setFieldValue, validateForm, setErrors }) => (
                <FormikForm>
                  {playerDataLoadingState && <Loader />}
                  <div className="personal-heading mt-2">
                    <h3>Personal Details</h3>

                    <div className="grid-section">
                      <Form.Group className="mb-3 form-inner label-text-up">
                        <label className="mb-2">Full Name</label>
                        <Field
                          name="full_name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Name here"
                          onKeyPress={(event) => {
                            const regex = /^[a-zA-Z\s]*$/;
                            if (!regex.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onPaste={(event) => {
                            const pastedData =
                              event.clipboardData.getData("Text");
                            const regex = /^[a-zA-Z\s]*$/;
                            if (!regex.test(pastedData)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <ErrorMessage
                          name="full_name"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3 form-inner  label-text-up">
                        <label className="mb-2">Date of Birth</label>
                        <div className="age-box">
                          {/* <Field
                            name="dob"
                            placeholder="Date of Birth"
                            type={inputType}
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue("dob", e.target.value);
                              calculateAge(e.target.value);
                            }}
                            onFocus={() => setInputType("date")}
                            onBlur={() => setInputType("text")}
                            max={getYesterday()}
                          /> */}

                          <DatePicker
                            id="dob"
                            name="dob"
                            selected={
                              values.dob
                                ? moment(values.dob, "DD/MM/YYYY").toDate()
                                : null
                            }
                            // onChange={(date) => {
                            //   const formattedDate = moment(date).format('DD/MM/YYYY');
                            //   setFieldValue("dob", formattedDate);
                            //   calculateAge(date);
                            // }}
                            onChange={(date) => {
                              if (date) {
                                const formattedDate =
                                  moment(date).format("DD/MM/YYYY");
                                setFieldValue("dob", formattedDate);
                                calculateAge(date);
                              } else {
                                setFieldValue("dob", "");
                              }
                            }}
                            placeholderText="Date of birth"
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            className="custom-datepicker__input form-control"
                            calendarClassName="custom-datepicker"
                          />

                          {age ? (
                            <Form.Group className="form-inner player-age">
                              <div className="form-control">{`${age} yrs`}</div>
                            </Form.Group>
                          ) : (
                            ""
                          )}
                        </div>
                        <ErrorMessage
                          name="dob"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <div className="profile-card label-text-up">
                        <label className="mb-2">Gender</label>
                        <Select
                          name="gender"
                          placeholder="Select"
                          options={optionsGender}
                          onChange={(option) => setFieldValue("gender", option)}
                          value={values.gender}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Nationality(s)</label>
                        <Select
                          name="nationality"
                          placeholder="Select"
                          isMulti
                          options={optionsNationaity}
                          onChange={(option) =>
                            setFieldValue("nationality", option)
                          }
                          value={values.nationality}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="nationality"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Previous Status</label>
                        <Select
                          name="previous_status"
                          placeholder="Select"
                          options={optionsNationaityId}
                          isMulti
                          onChange={(option) =>
                            setFieldValue("previous_status", option)
                          }
                          value={values.previous_status}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="previous_status"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <div className="grid-section bottom-lines">
                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2"> Coaching License(s) </label>
                        <Select
                          name="coaching_licenses"
                          placeholder="Select"
                          options={optionsCoachingLicense}
                          onChange={(option) =>
                            setFieldValue("coaching_licenses", option)
                          }
                          value={values.coaching_licenses}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="coaching_licenses"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">Language(s)</label>
                        <Select
                          name="Languages"
                          options={optionsLanguage}
                          placeholder="Select"
                          isMulti
                          onChange={(option) =>
                            setFieldValue("languages", option)
                          }
                          value={values.languages}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="languages"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="profile-card mb-3 label-text-up">
                        <label className="mb-2">
                          Annual Asking Salary for New Contract
                        </label>

                        <Select
                          options={optionsannualAskingSalary}
                          name="new_contract_annual_salary"
                          placeholder="Select"
                          onChange={(option) =>
                            setFieldValue("new_contract_annual_salary", option)
                          }
                          value={values.new_contract_annual_salary}
                          classNamePrefix="custom-select"
                        />
                        <ErrorMessage
                          name="new_contract_annual_salary"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="availability label-text-up">
                        <label className="">Availability Status</label>

                        <div className="cmn-form-fields mt-2">
                          <div className="profile-card mb-3">
                            <Select
                              name="availability_status"
                              placeholder="Select"
                              options={optionsPlayerStatus}
                              isMulti
                              onChange={(option) =>
                                setFieldValue("availability_status", option)
                              }
                              value={values.availability_status}
                              classNamePrefix="custom-select"
                            />
                            <ErrorMessage
                              name="availability_status"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <div className="d-flex justify-content-between">
                        {values?.availability_status?.some(
                          (option) => option.label == "Currently Free"
                        ) ? (
                          <h3 className="h-20">Previous Director's Team</h3>
                        ) : (
                          <h3 className="h-20">Director's Team</h3>
                        )}
                      </div>

                      <div className="cmn-form-fields grid-players bottom-lines mt-2">
                        <Form.Group className="mb-3 form-inner">
                          <div className="label-text-up">
                            <label className="mb-2">Team's Name</label>
                            <Select
                              name="team_id"
                              placeholder="Select"
                              classNamePrefix="custom-select"
                              options={customOptions}
                              isDisabled={
                                isFree &&
                                (!values.team_id || values.team_id.length === 0)
                              }
                              components={{ DropdownIndicator: null }}
                              onChange={(e) =>
                                handleSelectChange(e, setFieldValue)
                              }
                              onInputChange={handleInputChange}
                              value={values.team_id || null}
                              isClearable
                              // styles={customStyles}
                              formatOptionLabel={(data) =>
                                data.isAddTeam ? (
                                  <div
                                    style={{
                                      color: "#103D56",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => setIsModalOpen(true)}
                                  >
                                    {" "}
                                    + Add new Team
                                  </div>
                                ) : (
                                  data.label
                                )
                              }
                              noOptionsMessage={() =>
                                isAddingTeam ? (
                                  <div
                                    className="modal-add-teams"
                                    style={{
                                      color: "#103D56",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => setIsModalOpen(true)}
                                  >
                                    {" "}
                                    + Add new Team
                                  </div>
                                ) : (
                                  "No options found"
                                )
                              }
                            />

                            <Modal
                              isOpen={isModalOpen}
                              onRequestClose={() => setIsModalOpen(false)}
                              contentLabel="Add New Team"
                              style={{
                                content: {
                                  top: "50%",
                                  left: "50%",
                                  right: "auto",
                                  bottom: "auto",
                                  marginRight: "-50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "100%",
                                  maxWidth: "600px",
                                  boxShadow:
                                    "0px 2px 20px 0px rgba(0, 35, 149, 0.15)",
                                  background: "#fff",
                                  borderRadius: "10px",
                                  border: "none",
                                  padding: "30px",
                                },
                              }}
                            >
                              <div className="heding-add-new ">
                                <h3>Add New Team</h3>
                              </div>
                              <div className="add-new-input label-text-up">
                                <label className="mb-2">Team’s Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Team Name"
                                  value={newTeamName}
                                  onChange={(e) =>
                                    setNewTeamName(e.target.value)
                                  }
                                  onKeyPress={(event) => {
                                    const regex = /^[a-zA-Z\s]*$/;
                                    if (!regex.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onPaste={(event) => {
                                    const pastedData =
                                      event.clipboardData.getData("Text");
                                    const regex = /^[a-zA-Z\s]*$/;
                                    if (!regex.test(pastedData)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>

                              <div className="add-new-input label-text-up">
                                <label className="mb-2">Team’s Country</label>
                                <Select
                                  name="team_country"
                                  placeholder="Select"
                                  options={optionsPlaceOfBirth}
                                  classNamePrefix="custom-select"
                                  onChange={(option) =>
                                    setFieldValue("team_country", option)
                                  }
                                  value={values.team_country}
                                />
                              </div>

                              <div className="add-new-input label-text-up">
                                <label className="mb-2">Team’s League</label>
                                <Select
                                  name="team_league"
                                  placeholder="Select"
                                  classNamePrefix="custom-select"
                                  options={optionsTeamLeague}
                                  onChange={(option) =>
                                    setFieldValue("team_league", option)
                                  }
                                  value={values.team_league}
                                  // isDisabled={values.team_league == null}
                                />
                              </div>

                              <div className="add-team-btn ">
                                <button
                                  className="add-modal-btn-ps"
                                  onClick={() =>
                                    handleAddTeam(values, setFieldValue)
                                  }
                                >
                                  Add
                                </button>
                                <button onClick={() => setIsModalOpen(false)}>
                                  Cancel
                                </button>
                              </div>
                            </Modal>
                          </div>

                          <ErrorMessage
                            name="team_id"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>

                        <div className="profile-card mb-3 label-text-up">
                          <label className="mb-2">Team's Country</label>

                          <Select
                            name="team_country"
                            placeholder="Select"
                            options={optionsPlaceOfBirth}
                            classNamePrefix="custom-select"
                            onChange={(option) =>
                              setFieldValue("team_country", option)
                            }
                            value={values.team_country}
                            isDisabled={
                              !!values.team_country ||
                              values.team_country == null
                            }
                          />
                          <ErrorMessage
                            name="team_country"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="profile-card mb-3 label-text-up">
                          <label className="mb-2">Team's League</label>

                          <Select
                            name="team_league"
                            placeholder="Select"
                            classNamePrefix="custom-select"
                            options={optionsTeamLeague}
                            onChange={(option) =>
                              setFieldValue("team_league", option)
                            }
                            value={values.team_league}
                            isDisabled={
                              !!values.team_league || values.team_league == null
                            }
                          />
                          <ErrorMessage
                            name="team_league"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="vidoe-link-box">
                        <div className="bottom-lines mt-4 mb-3">
                          <div className="trophies ">
                            <div className="d-flex justify-content-between">
                              <h3 className="h-20">Carrier Trophies</h3>
                            </div>
                            <div className="profile-card mb-3 profile-width">
                              <Select
                                name="carrier_trophies"
                                placeholder="Select"
                                classNamePrefix="custom-select"
                                options={optionsTrophy}
                                onChange={(selectedOptions) => {
                                  const isNotYetSelected = selectedOptions.some(
                                    (option) => option.value == 6
                                  );
                                  if (isNotYetSelected) {
                                    setFieldValue(`carrier_trophies`, [
                                      {
                                        label: "Not Yet",
                                        value: 6,
                                      },
                                    ]);
                                  } else {
                                    setFieldValue(
                                      `carrier_trophies`,
                                      selectedOptions
                                    );
                                  }
                                }}
                                isMulti
                                // onChange={(selectedOptions) => setFieldValue('carrier_trophies', selectedOptions)}
                                value={values.carrier_trophies}
                              />
                            </div>
                            <ErrorMessage
                              name="carrier_trophies"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="box-flex-up link-videos bottom-lines pb-4 mb-3">
                          <div className="players-cv">
                            <div className="player-up">
                              <h3>Director's Image</h3>
                              <div
                                className={`uplode-the-img ${
                                  dragging ? "dragging" : ""
                                }`}
                                onDrop={(e) =>
                                  handleDrop(e, setFieldValue, "profile_image")
                                }
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                              >
                                <div className="upload-and-drop">
                                  <div className="click-to-up">
                                    <label htmlFor="file-upload">
                                      <button type="button">
                                        Click to Upload
                                        <Field name="profile_image">
                                          {({ field }) => (
                                            <input
                                              type="file"
                                              id="file-upload"
                                              onChange={(e) =>
                                                handleFileChange1(
                                                  e,
                                                  setFieldValue,
                                                  "profile_image"
                                                )
                                              }
                                              accept="image/*"
                                            />
                                          )}
                                        </Field>
                                      </button>
                                    </label>
                                  </div>
                                  <div className="drag-drop">
                                    <p>
                                      <span>
                                        <img
                                          src={
                                            require("../Assets/images/drag-and-drop.svg")
                                              .default
                                          }
                                          alt="Upload Placeholder"
                                        />
                                      </span>
                                      Or drag and drop files.
                                    </p>
                                  </div>
                                </div>
                                <div className="preview">
                                  <p>Preview</p>
                                  <div className="preview-content">
                                    {values?.profile_image &&
                                      (typeof values.profile_image ===
                                      "string" ? (
                                        <img
                                          className="inner-view"
                                          src={`${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`}
                                          alt="Profile Preview"
                                          onClick={() =>
                                            handleShowPreview(
                                              `${process.env.REACT_APP_IMAGE_PATH}${values?.profile_image}`
                                            )
                                          }
                                        />
                                      ) : values.profile_image instanceof
                                        File ? (
                                        <img
                                          src={URL.createObjectURL(
                                            values?.profile_image
                                          )}
                                          className="prev-img"
                                          alt="Profile Preview"
                                          style={{ width: "50px" }}
                                          onClick={() =>
                                            handleShowPreview(
                                              URL.createObjectURL(
                                                values?.profile_image
                                              )
                                            )
                                          }
                                        />
                                      ) : null)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="carrier-notes">
                      <div className="cmn-form-fields uplode-click-box-main">
                        <div className="all-notes ">
                          <h3 className="h-20">Other Notes</h3>
                          <div className="cmn-form-fields">
                            <Form.Group className="mb-3 form-inner">
                              <Field
                                as="textarea"
                                name="main_info_other_notes"
                                rows={3}
                                className="form-control"
                                placeholder="Write notes here"
                              />
                              <ErrorMessage
                                name="main_info_other_notes"
                                component="div"
                                className="text-danger"
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end mb-5">
                    <div className="mt-4 ">
                      <div className="cmn-light-btn save-and-close-btn">
                        <button
                          type="button"
                          onClick={() =>
                            handleSaveAndClose(values, validateForm, setErrors)
                          }
                          className="next-btn-ps"
                        >
                          {" "}
                          Save & Close{" "}
                        </button>
                      </div>
                    </div>

                    <div className="mt-4 ">
                      <div className="cmn-light-btn">
                        <button type="submit" className="next-btn-ps">
                          {" "}
                          Save & Next{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
      <PreviewModal
        show={showPreview}
        onHide={handleClosePreview}
        previewImage={previewImage}
      />
    </LoginLayout>
  );
};

export default AddDirector;
