import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import LoginLayout from "../components/LoginLayout";
import "react-tabs/style/react-tabs.css";
import backgroundImage from "../Assets/images/playerbanner.png";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { useDispatch, useSelector } from "react-redux";
import { getTeamDataById } from "../Redux/Action/teamAction";
import { capitalize, dateOfBirth } from "../utils/helper";
import { playerTrophy, positions, teamLeague } from "../utils/rawjson";
import Loader from "../components/Loader";
import moment from "moment/moment";

export default function MyteamDetail() {
  const teamData = useSelector((e) => e?.teamAuthData?.getTeamDetailById);
  const loader = useSelector((e) => e?.teamAuthData?.loading);
  const backgroundImageStyle = {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundImage: `${
      teamData?.banner_image
        ? `url(${process.env.REACT_APP_IMAGE_PATH}${teamData?.banner_image})`
        : `url(${backgroundImage})`
    }`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    width: "100%",
  };

  const { id } = useParams();
  const IdVal = id && Decryptedid(atob(id));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamDataById({ id: IdVal }));
  }, [dispatch]);

  const handleTeamEdit = (id) => {
    if (id) {
      localStorage.setItem("teamId", id);
    }
  };

  console.log(teamData, "mnka");

  return (
    <LoginLayout>
      <section>
        {loader && <Loader />}

        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Team’s Profile</h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    <img
                      className="play-img"
                      src={
                        teamData?.profile_image
                          ? `${process.env.REACT_APP_IMAGE_PATH}${teamData?.profile_image}`
                          : require("../Assets/images/team-new.png")
                      }
                    />
                    <div>
                      <h2>{capitalize(teamData?.team_name)}</h2>

                      <div className="socio-icons">
                        {/* Instagram */}
                        {teamData?.instagram && (
                          <Link
                            to={teamData.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/instablue.svg")
                                  .default
                              }
                              alt="Instagram"
                            />
                          </Link>
                        )}

                        {/* Snapchat */}
                        {teamData?.snapchat && (
                          <Link
                            to={teamData.snapchat}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/snapblue.svg").default
                              }
                              alt="Snapchat"
                            />
                          </Link>
                        )}

                        {/* X (formerly Twitter) */}
                        {teamData?.twitter && (
                          <Link
                            to={teamData.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/xblue.svg").default
                              }
                              alt="X"
                            />
                          </Link>
                        )}

                        {/* Facebook */}
                        {teamData?.facebook && (
                          <Link
                            to={teamData.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/fbblue.svg").default
                              }
                              alt="Facebook"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="player-right-info">
                    <div className="play-edit">
                      <Link
                        to={`/add-team?teamId=${Encryptedid(teamData?._id)}`}
                        onClick={() => handleTeamEdit(teamData?._id)}
                      >
                        <img
                          src={
                            require("../Assets/images/editwhite.svg").default
                          }
                        />
                        Edit Profile
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Tabs
              defaultActiveKey="Profile"
              id="uncontrolled-tab-example"
              className="mb-3 mt-4"
            >
              <Tab eventKey="Profile" title="Profile" className="accord-tabs">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Team Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Team Country:</h4>
                              <p>
                                {teamData?.team_country
                                  ? teamData?.team_country
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Team City:</h4>
                              <p>
                                {teamData?.team_city
                                  ? teamData?.team_city
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Team League:</h4>
                              <p>
                                {teamData?.team_league != null
                                  ? teamLeague[teamData?.team_league]
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Annual Budget:</h4>
                              <p>
                                {teamData?.annual_budget
                                  ? teamData?.annual_budget
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Team’s Owner:</h4>
                              <p>
                                {teamData?.team_owner
                                  ? capitalize(teamData?.team_owner)
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Team’s President:</h4>
                              <p>
                                {teamData?.team_president
                                  ? capitalize(teamData?.team_president)
                                  : "N/A"}
                              </p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                <p>
                                  {teamData?.info_other_notes
                                    ? teamData?.info_other_notes
                                    : "N/A"}
                                </p>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Team's Trophies</h2>
                        <div className="search-inner-box">
                          <ul>
                            {Array.isArray(teamData?.carrier_trophies) &&
                            teamData?.carrier_trophies.length > 0 ? (
                              <>
                                {teamData?.carrier_trophies.map(
                                  (ele, index) => {
                                    return (
                                      <>
                                        <li key={index}>
                                          <div className="d-flex">
                                            <img
                                              src={require("../Assets/images/smtrophy.png")}
                                            />
                                            <h4 className="ms-2">
                                              {playerTrophy[ele]}
                                            </h4>
                                          </div>
                                          {/* <p>
                                            {dateOfBirth(ele?.date_of_winning)}
                                          </p> */}
                                        </li>
                                      </>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <li>
                                {" "}
                                <p>No Data Found</p>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                      {/* <div className="search-cmn-box mt-3">
                                                <h2>Agents Notes</h2>
                                                <div className="search-inner-box">
                                                    <ul>
                                                        <li className="flex-column align-items-start mb-0">
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text for printing
                                                                and typesetting industry. Lorem Ipsum has been
                                                                industry standard dummy text ever since 1500s.
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          {/* <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div> */}
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Players" title="Players">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>My Players in this Team</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.players) &&
                          teamData?.players.length > 0 ? (
                            <>
                              {teamData?.players.map((ele, index) => {
                                return (
                                  <>
                                    <div
                                      className="play-inner-box play-agent-box-cs"
                                      key={index}
                                    >
                                      <div className="d-flex">
                                        <img
                                          src={
                                            ele?.player_id?.profile_image
                                              ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.player_id?.profile_image}`
                                              : ele?.player_id?.gender == 0
                                              ? require("../Assets/images/user.png")
                                              : require("../Assets/images/player-female.png")
                                          }
                                        />
                                        <div className="ms-3 remove-right-mg">
                                          <h4>
                                            {ele?.player_id?.full_name ?? "N/A"}
                                          </h4>
                                          <p>
                                            {/* <span>
                                              {capitalize(teamData?.team_name)}
                                            </span> */}
                                            {/* <span>
                                            {ele?.player_id?.team_name ? capitalize(ele?.player_id?.team_name) : "N/A"}
                                          </span> */}
                                          </p>
                                          <p>
                                            <span>
                                              {ele?.player_id?.positions
                                                ?.length > 0
                                                ? positions[
                                                    ele?.player_id?.positions
                                                  ]
                                                : "N/A"}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="ms-3">
                                        <p>
                                          <span>
                                            {ele?.player_id?.unique_id ?? "N/A"}
                                          </span>
                                        </p>
                                      </div>
                                    </div>

                                    {/* associate agent */}

                                    <div className="play-inner-box align-items-start">
                                      <div className="d-flex">
                                        {ele?.who_brought_player === 0 ? (
                                          <img
                                            src={
                                              teamData?.creator_id
                                                ?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${teamData?.creator_id?.profile_image}`
                                                : require("../Assets/images/vector-image.png")
                                            }
                                          />
                                        ) : (
                                          <img
                                            src={
                                              ele?.player_associate_agent_id
                                                ?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.player_associate_agent_id?.profile_image}`
                                                : require("../Assets/images/vector-image.png")
                                            }
                                          />
                                        )}

                                        <div className="ms-3 remove-right-mg">
                                          <h4>
                                            {ele?.who_brought_player === 0
                                              ? capitalize(
                                                  teamData?.creator_id
                                                    ?.full_name
                                                )
                                              : capitalize(
                                                  ele?.player_associate_agent_id
                                                    ?.agent_name
                                                )}
                                          </h4>
                                          <p>
                                            <span>
                                              {" "}
                                              {ele?.who_brought_player === 0
                                                ? `Agent`
                                                : `Associate Agent`}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    {ele?.who_brought_player === 1 && (
                                      <>
                                        <div className="search-inner-box">
                                          <ul>
                                            <li>
                                              <h4>Agents fees Charged:</h4>
                                              <p>
                                                {ele?.player_associate_agent_fee_charged ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Agents fees Received:</h4>
                                              <p>
                                                {ele?.player_associate_agent_fee_received ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Received On:</h4>
                                              <p>
                                                {moment(
                                                  ele?.player_associate_agent_fee_received_on
                                                ).format("DD/MM/YYYY") || "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Agents fees Pending:</h4>
                                              <p>
                                                {ele?.player_associate_agent_fee_pending ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Will Receive On:</h4>
                                              <p>
                                                {moment(
                                                  ele?.player_associate_agent_fee_will_received_on
                                                ).format("DD/MM/YYYY") || "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Team’s Contact Person:</h4>
                                              <p>
                                                {ele?.player_team_contact_person_name ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Contact Number:</h4>
                                              <p>
                                                {ele?.player_team_contact_person_phone_number ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li className="flex-column align-items-start mb-0">
                                              <h4>Other Notes:</h4>
                                              <p className="mt-1">
                                                {ele?.player_other_notes ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Player I have proposed to this Team</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.proposed_players) &&
                          teamData?.proposed_players.length > 0 ? (
                            <>
                              {teamData?.proposed_players.map((ele, index) => {
                                return (
                                  <>
                                    <div
                                      className="play-inner-box play-agent-box-cs"
                                      key={index}
                                    >
                                      <div className="d-flex">
                                        <img
                                          src={
                                            ele?.proposed_player_id
                                              ?.profile_image
                                              ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.proposed_player_id?.profile_image}`
                                              : ele?.proposed_player_id
                                                  ?.gender == 0
                                              ? require("../Assets/images/user.png")
                                              : require("../Assets/images/player-female.png")
                                          }
                                        />
                                        <div className="ms-3 remove-right-mg">
                                          <h4>
                                            {ele?.proposed_player_id
                                              ?.full_name ?? "N/A"}
                                          </h4>
                                          <p>
                                            {/* <span>
                                              {capitalize(teamData?.team_name)}
                                            </span> */}
                                            {/* <span>
                                            {ele?.proposed_player_id?.team_name
                                              ? capitalize(
                                                  ele?.proposed_player_id
                                                    ?.team_name
                                                )
                                              : "N/A"}
                                          </span> */}
                                          </p>
                                          <p>
                                            <span>
                                              {ele?.proposed_player_id
                                                ?.positions?.length > 0
                                                ? positions[
                                                    ele?.proposed_player_id
                                                      ?.positions
                                                  ]
                                                : "N/A"}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="ms-3">
                                        <p>
                                          <span>
                                            {ele?.proposed_player_id
                                              ?.unique_id ?? "N/A"}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    {/* Associate agent */}

                                    <div className="play-inner-box align-items-start">
                                      <div className="d-flex">
                                        {ele?.who_bought_proposed_player ===
                                        0 ? (
                                          <img
                                            src={
                                              teamData?.creator_id
                                                ?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${teamData?.creator_id?.profile_image}`
                                                : require("../Assets/images/vector-image.png")
                                            }
                                          />
                                        ) : (
                                          <img
                                            src={
                                              ele
                                                ?.proposed_player_associate_agent_id
                                                ?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.proposed_player_associate_agent_id?.profile_image}`
                                                : require("../Assets/images/vector-image.png")
                                            }
                                          />
                                        )}

                                        <div className="ms-3 remove-right-mg">
                                          <h4>
                                            {ele?.who_bought_proposed_player ===
                                            0
                                              ? capitalize(
                                                  teamData?.creator_id
                                                    ?.full_name
                                                )
                                              : capitalize(
                                                  ele
                                                    ?.proposed_player_associate_agent_id
                                                    ?.agent_name
                                                )}
                                          </h4>
                                          <p>
                                            <span>
                                              {" "}
                                              {ele?.who_bought_proposed_player ===
                                              0
                                                ? `Agent`
                                                : `Associate Agent`}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Player have been proposed to me from this Team</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.proposed_team_players) &&
                          teamData?.proposed_team_players.length > 0 ? (
                            <>
                              {teamData?.proposed_team_players.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs"
                                        key={index}
                                      >
                                        <div className="d-flex">
                                          <img
                                            src={
                                              ele?.team_proposed_player_id
                                                ?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.team_proposed_player_id?.profile_image}`
                                                : ele?.team_proposed_player_id
                                                    ?.gender == 0
                                                ? require("../Assets/images/user.png")
                                                : require("../Assets/images/player-female.png")
                                            }
                                          />
                                          <div className="ms-3 remove-right-mg">
                                            <h4>
                                              {ele?.team_proposed_player_id
                                                ?.full_name ?? "N/A"}
                                            </h4>
                                            <p>
                                              {/* <span>
                                                {capitalize(
                                                  teamData?.team_name
                                                )}
                                              </span> */}
                                              {/* <span>
                                            {ele?.team_proposed_player_id?.team_name
                                              ? capitalize(
                                                  ele?.team_proposed_player_id
                                                    ?.team_name
                                                )
                                              : "N/A"}
                                          </span> */}
                                            </p>
                                            <p>
                                              <span>
                                                {ele?.team_proposed_player_id
                                                  ?.positions?.length > 0
                                                  ? positions[
                                                      ele
                                                        ?.team_proposed_player_id
                                                        ?.positions
                                                    ]
                                                  : "N/A"}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="ms-3">
                                          <p>
                                            <span>
                                              {ele?.team_proposed_player_id
                                                ?.unique_id ?? "N/A"}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      {/* Associate agent */}

                                      <div className="play-inner-box align-items-start">
                                        <div className="d-flex">
                                          {ele?.who_bought_team_proposed_player ===
                                          0 ? (
                                            <img
                                              src={
                                                teamData?.creator_id
                                                  ?.profile_image
                                                  ? `${process.env.REACT_APP_IMAGE_PATH}${teamData?.creator_id?.profile_image}`
                                                  : require("../Assets/images/vector-image.png")
                                              }
                                            />
                                          ) : (
                                            <img
                                              src={
                                                ele
                                                  ?.team_proposed_player_associate_agent_id
                                                  ?.profile_image
                                                  ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.team_proposed_player_associate_agent_id?.profile_image}`
                                                  : require("../Assets/images/vector-image.png")
                                              }
                                            />
                                          )}

                                          <div className="ms-3 remove-right-mg">
                                            <h4>
                                              {ele?.who_bought_team_proposed_player ===
                                              0
                                                ? capitalize(
                                                    teamData?.creator_id
                                                      ?.full_name
                                                  )
                                                : capitalize(
                                                    ele
                                                      ?.team_proposed_player_associate_agent_id
                                                      ?.agent_name
                                                  )}
                                            </h4>
                                            <p>
                                              <span>
                                                {" "}
                                                {ele?.who_bought_team_proposed_player ===
                                                0
                                                  ? `Agent`
                                                  : `Associate Agent`}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Directors" title="Directors">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>My Technical/ Sport Directors in this team</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.directors) &&
                          teamData?.directors.length > 0 ? (
                            <>
                              {teamData?.directors.map((ele, index) => {
                                return (
                                  <>
                                    <div
                                      className="play-inner-box play-agent-box-cs"
                                      key={index}
                                    >
                                      <div className="d-flex">
                                        <img
                                          src={
                                            ele?.director_id?.profile_image
                                              ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.director_id?.profile_image}`
                                              : ele?.director_id?.gender == 0
                                              ? require("../Assets/images/My Directors male.png")
                                              : require("../Assets/images/director-female-new.png")
                                          }
                                        />
                                        <div className="ms-3">
                                          <h4>
                                            {ele?.director_id?.full_name ||
                                              "N/A"}
                                          </h4>
                                        </div>
                                      </div>
                                      <p>
                                        <span>
                                          {ele?.director_id?.unique_id || "N/A"}
                                        </span>
                                      </p>
                                    </div>

                                    {/* associate agent */}

                                    <div className="play-inner-box align-items-start">
                                      <div className="d-flex">
                                        {ele?.who_brought_director === 0 ? (
                                          <img
                                            src={
                                              teamData?.creator_id
                                                ?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${teamData?.creator_id?.profile_image}`
                                                : require("../Assets/images/vector-image.png")
                                            }
                                          />
                                        ) : (
                                          <img
                                            src={
                                              ele?.director_associate_agent_id
                                                ?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.director_associate_agent_id?.profile_image}`
                                                : require("../Assets/images/vector-image.png")
                                            }
                                          />
                                        )}

                                        <div className="ms-3 remove-right-mg">
                                          <h4>
                                            {ele?.who_brought_director === 0
                                              ? capitalize(
                                                  teamData?.creator_id
                                                    ?.full_name
                                                )
                                              : capitalize(
                                                  ele
                                                    ?.director_associate_agent_id
                                                    ?.agent_name
                                                )}
                                          </h4>
                                          <p>
                                            <span>
                                              {" "}
                                              {ele?.who_brought_director === 0
                                                ? `Agent`
                                                : `Associate Agent`}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    {ele?.who_brought_director === 1 && (
                                      <>
                                        <div className="search-inner-box">
                                          <ul>
                                            <li>
                                              <h4>Agents fees Charged:</h4>
                                              <p>
                                                {ele?.director_associate_agent_fee_charged ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Agents fees Received:</h4>
                                              <p>
                                                {ele?.director_associate_agent_fee_received ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Received On:</h4>
                                              <p>
                                                {moment(
                                                  ele?.director_associate_agent_fee_received_on
                                                ).format("DD/MM/YYYY") || "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Agents fees Pending:</h4>
                                              <p>
                                                {ele?.director_associate_agent_fee_pending ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Will Receive On:</h4>
                                              <p>
                                                {moment(
                                                  ele?.director_associate_agent_fee_will_received_on
                                                ).format("DD/MM/YYYY") || "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Team’s Contact Person:</h4>
                                              <p>
                                                {ele?.director_team_contact_person_name ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Contact Number:</h4>
                                              <p>
                                                {ele?.director_team_contact_person_phone_number ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li className="flex-column align-items-start mb-0">
                                              <h4>Other Notes:</h4>
                                              <p className="mt-1">
                                                {ele?.director_other_notes ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>
                          Technical/ Sport Directors I have proposed to this
                          Team
                        </h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.proposed_directors) &&
                          teamData?.proposed_directors.length > 0 ? (
                            <>
                              {teamData?.proposed_directors.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs"
                                        key={index}
                                      >
                                        <div className="d-flex">
                                          <img
                                            src={
                                              ele?.proposed_director_id
                                                ?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.proposed_director_id?.profile_image}`
                                                : ele?.proposed_director_id
                                                    ?.gender == 0
                                                ? require("../Assets/images/My Directors male.png")
                                                : require("../Assets/images/director-female-new.png")
                                            }
                                          />
                                          <div className="ms-3">
                                            <h4>
                                              {ele?.proposed_director_id
                                                ?.full_name || "N/A"}
                                            </h4>
                                          </div>
                                        </div>
                                        <p>
                                          <span>
                                            {ele?.proposed_director_id
                                              ?.unique_id || "N/A"}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="play-inner-box align-items-start">
                                        <div className="d-flex">
                                          {ele?.who_bought_proposed_director ===
                                          0 ? (
                                            <img
                                              src={
                                                teamData?.creator_id
                                                  ?.profile_image
                                                  ? `${process.env.REACT_APP_IMAGE_PATH}${teamData?.creator_id?.profile_image}`
                                                  : require("../Assets/images/vector-image.png")
                                              }
                                            />
                                          ) : (
                                            <img
                                              src={
                                                ele
                                                  ?.director_proposed_associate_agent_id
                                                  ?.profile_image
                                                  ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.director_proposed_associate_agent_id?.profile_image}`
                                                  : require("../Assets/images/vector-image.png")
                                              }
                                            />
                                          )}

                                          <div className="ms-3 remove-right-mg">
                                            <h4>
                                              {ele?.who_bought_proposed_director ===
                                              0
                                                ? capitalize(
                                                    teamData?.creator_id
                                                      ?.full_name
                                                  )
                                                : capitalize(
                                                    ele
                                                      ?.director_proposed_associate_agent_id
                                                      ?.agent_name
                                                  )}
                                            </h4>
                                            <p>
                                              <span>
                                                {" "}
                                                {ele?.who_bought_proposed_director ===
                                                0
                                                  ? `Agent`
                                                  : `Associate Agent`}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                    {/* <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Directors wanted by Team</h2>
                        <div className="search-inner-box">
                          {Array.isArray(teamData?.directors) &&
                          teamData?.directors.length > 0 ? (
                            <>
                              {teamData?.directors.map((ele, index) => {
                                return (
                                  <div className="play-inner-box" key={index}>
                                    <div className="d-flex">
                                      <img
                                        src={require("../Assets/images/My Directors male.png")}
                                      />
                                      <div className="ms-3">
                                        <h4>{ele?.director_name}</h4>
                                        <p>
                                          Director ID: <span>IVXJW007</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Coaches" title="Coaches">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>My Coaches</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.coaches) &&
                          teamData?.coaches.length > 0 ? (
                            <>
                              {teamData?.coaches.map((ele, index) => {
                                return (
                                  <>
                                    <div
                                      className="play-inner-box play-agent-box-cs"
                                      key={index}
                                    >
                                      <div className="d-flex">
                                        <img
                                          src={
                                            ele?.coach_id?.profile_image
                                              ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.coach_id?.profile_image}`
                                              : ele?.coach_id?.gender == 0
                                              ? require("../Assets/images/coach-male.png")
                                              : require("../Assets/images/coach-female.png")
                                          }
                                        />
                                        <div className="ms-3">
                                          <h4>
                                            {ele?.coach_id?.full_name || "N/A"}
                                          </h4>
                                        </div>
                                      </div>
                                      <p>
                                        <span>
                                          {ele?.coach_id?.unique_id || "N/A"}
                                        </span>
                                      </p>
                                    </div>
                                    {/* associate agent */}

                                    <div className="play-inner-box align-items-start">
                                      <div className="d-flex">
                                        {ele?.who_brought_coach === 0 ? (
                                          <img
                                            src={
                                              teamData?.creator_id
                                                ?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${teamData?.creator_id?.profile_image}`
                                                : require("../Assets/images/vector-image.png")
                                            }
                                          />
                                        ) : (
                                          <img
                                            src={
                                              ele?.director_associate_agent_id
                                                ?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.director_associate_agent_id?.profile_image}`
                                                : require("../Assets/images/vector-image.png")
                                            }
                                          />
                                        )}

                                        <div className="ms-3 remove-right-mg">
                                          <h4>
                                            {ele?.who_brought_coach === 0
                                              ? capitalize(
                                                  teamData?.creator_id
                                                    ?.full_name
                                                )
                                              : capitalize(
                                                  ele
                                                    ?.director_associate_agent_id
                                                    ?.agent_name
                                                )}
                                          </h4>
                                          <p>
                                            <span>
                                              {" "}
                                              {ele?.who_brought_coach === 0
                                                ? `Agent`
                                                : `Associate Agent`}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    {ele?.who_brought_coach === 1 && (
                                      <>
                                        <div className="search-inner-box">
                                          <ul>
                                            <li>
                                              <h4>Agents fees Charged:</h4>
                                              <p>
                                                {ele?.director_associate_agent_fee_charged ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Agents fees Received:</h4>
                                              <p>
                                                {ele?.director_associate_agent_fee_received ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Received On:</h4>
                                              <p>
                                                {moment(
                                                  ele?.director_associate_agent_fee_received_on
                                                ).format("DD/MM/YYYY") || "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Agents fees Pending:</h4>
                                              <p>
                                                {ele?.director_associate_agent_fee_pending ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Will Receive On:</h4>
                                              <p>
                                                {moment(
                                                  ele?.director_associate_agent_fee_will_received_on
                                                ).format("DD/MM/YYYY") || "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Team’s Contact Person:</h4>
                                              <p>
                                                {ele?.director_team_contact_person_name ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li>
                                              <h4>Contact Number:</h4>
                                              <p>
                                                {ele?.director_team_contact_person_phone_number ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                            <li className="flex-column align-items-start mb-0">
                                              <h4>Other Notes:</h4>
                                              <p className="mt-1">
                                                {ele?.director_other_notes ||
                                                  "N/A"}
                                              </p>
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Coaches I Proposed</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.proposed_coaches) &&
                          teamData?.proposed_coaches.length > 0 ? (
                            <>
                              {teamData?.proposed_coaches.map((ele, index) => {
                                return (
                                  <>
                                    <div
                                      className="play-inner-box play-agent-box-cs"
                                      key={index}
                                    >
                                      <div className="d-flex">
                                        <img
                                          src={
                                            ele?.proposed_coach_id
                                              ?.profile_image
                                              ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.proposed_coach_id?.profile_image}`
                                              : ele?.proposed_coach_id
                                                  ?.gender == 0
                                              ? require("../Assets/images/coach-male.png")
                                              : require("../Assets/images/coach-female.png")
                                          }
                                        />
                                        <div className="ms-3">
                                          <h4>
                                            {ele?.proposed_coach_id
                                              ?.full_name || "N/A"}
                                          </h4>
                                        </div>
                                      </div>
                                      <p>
                                        <span>
                                          {ele?.proposed_coach_id?.unique_id ||
                                            "N/A"}
                                        </span>
                                      </p>
                                    </div>
                                    {/* Associate agent coach */}
                                    <div className="play-inner-box align-items-start">
                                      <div className="d-flex">
                                        {ele?.who_bought_proposed_coach ===
                                        0 ? (
                                          <img
                                            src={
                                              teamData?.creator_id
                                                ?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${teamData?.creator_id?.profile_image}`
                                                : require("../Assets/images/vector-image.png")
                                            }
                                          />
                                        ) : (
                                          <img
                                            src={
                                              ele
                                                ?.coach_proposed_associate_agent_id
                                                ?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.coach_proposed_associate_agent_id?.profile_image}`
                                                : require("../Assets/images/vector-image.png")
                                            }
                                          />
                                        )}

                                        <div className="ms-3 remove-right-mg">
                                          <h4>
                                            {ele?.who_bought_proposed_coach ===
                                            0
                                              ? capitalize(
                                                  teamData?.creator_id
                                                    ?.full_name
                                                )
                                              : capitalize(
                                                  ele
                                                    ?.coach_proposed_associate_agent_id
                                                    ?.agent_name
                                                )}
                                          </h4>
                                          <p>
                                            <span>
                                              {" "}
                                              {ele?.who_bought_proposed_coach ===
                                              0
                                                ? `Agent`
                                                : `Associate Agent`}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col>
                    {/* <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Coaches wanted by Team</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(teamData?.proposed_coaches) &&
                          teamData?.proposed_coaches.length > 0 ? (
                            <>
                              {teamData?.coaches.map((ele, index) => {
                                return (
                                  <div className="play-inner-box" key={index}>
                                    <div className="d-flex">
                                      <img
                                        src={require("../Assets/images/coach-male.png")}
                                      />
                                      <div className="ms-3">
                                        <h4>{ele?.coach_name}</h4>
                                        <p>
                                          Coach ID: <span>IVXJW007</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <li>No Data Found</li>
                          )}
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              </Tab>

              <Tab eventKey="Contact" title="Contact">
                {" "}
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Contact Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Phone Number:</h4>
                              <p>{teamData?.phone_number ?? "N/A"}</p>
                            </li>
                            <li>
                              <h4>Email Address:</h4>
                              <p>{teamData?.email ?? "N/A"}</p>
                            </li>
                            <li>
                              <h4>Fax:</h4>
                              <p>{teamData?.fax ?? "N/A"}</p>
                            </li>
                            <li>
                              <h4>Website:</h4>
                              <p>{teamData?.web ?? "N/A"}</p>
                            </li>

                            <li className="flex-column align-items-start mb-0">
                              <h4>Home Address:</h4>
                              <p className="mt-1">
                                {teamData?.address ?? "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="search-inner-box mt-3">
                          <ul>
                            <li>
                              <h4>Instagram:</h4>
                              <p>{teamData?.instagram || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Snapchat:</h4>
                              <p>{teamData?.snapchat || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Facebook:</h4>
                              <p>{teamData?.facebook || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Twitter:</h4>
                              <p>{teamData?.twitter || "N/A"}</p>
                            </li>
                          </ul>
                        </div>
                        <div className="search-inner-box mt-3">
                          <ul>
                            <li>
                              <h4>Contact Person Name:</h4>
                              <p>{teamData?.conatct_person_name || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Status:</h4>
                              <p>{teamData?.status || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Contact Number:</h4>
                              <p>
                                {teamData?.contact_person_phone_number || "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                        {/* <div className="search-inner-box mt-3">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {teamData?.info_other_notes || "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box mt-4">
                        <h2>Agents Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                {teamData?.general_other_notes || "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
