import React, { useEffect } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import LoginLayout from "../components/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { staticDataAction } from "../Redux/Action/staticAction";
import {
  editPlayerAction,
  getPlayerDataById,
} from "../Redux/Action/playerAction";
import toast from "react-hot-toast";
import ProgressBar from "../CommonModals/ProgressBar";

const validationSchema = Yup.object({
  education_level: Yup.object().required("required"),
  martial_status: Yup.object().required("required"),
  children: Yup.object().required("required"),
  hobbies: Yup.string(),
  other_info_other_notes: Yup.string(),
});

const OtherInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const paylerId = id && Decryptedid(atob(id));

  const staticListForPlayer = useSelector(
    (e) => e.staticAuthData.userStaticDetail
  );
  const playerData = useSelector((e) => e.playerAuthData.playerDetail);

  useEffect(() => {
    dispatch(staticDataAction());
  }, [dispatch]);

  const optionsEduation = staticListForPlayer?.education_level?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const optionsMaritalStatus = staticListForPlayer?.martial_status?.map(
    (item) => ({
      label: item?.val,
      value: item?.id,
    })
  );

  const optionsChildren = staticListForPlayer?.children?.map((item) => ({
    label: item?.val,
    value: item?.id,
  }));

  const initialValues = {
    education_level:
      optionsEduation?.find(
        (option) => option.value == playerData?.education_level
      ) || null,
    martial_status:
      optionsMaritalStatus?.find(
        (e) => e.value === playerData?.martial_status
      ) || null,
    children:
      optionsChildren?.find((e) => e.value === playerData?.children) || null,
    hobbies: playerData?.hobbies || "",
    other_info_other_notes: playerData?.other_info_other_notes || "",

    // =============================================================================================================

    address: playerData?.address || "",
    email: playerData?.email || "",
    phone_number: playerData?.phone_number || "",
    instagram: playerData?.instagram || "",
    facebook: playerData?.facebook || "",
    snapchat: playerData?.snapchat || "",
    twitter: playerData?.twitter || "",
    player_lawyer_name: playerData?.player_lawyer_name || "",
    player_lawyer_phone_number: playerData?.player_lawyer_phone_number || "",
    player_managing_company_name:
      playerData?.player_managing_company_name || "",
    player_managing_company_phone_number:
      playerData?.player_managing_company_phone_number || "",
    player_sponsors_name: playerData?.player_sponsors_name || "",
    player_sponsors_phone_number:
      playerData?.player_sponsors_phone_number || "",
    contact_info_other_notes: playerData?.contact_info_other_notes || "",
    contact_info_agent_notes: playerData?.contact_info_agent_notes || "",
  };

  const transformValues = (values) => {
    const transformed = { ...values };
    transformed.education_level = values.education_level?.value ?? "0";
    transformed.martial_status = values.martial_status?.value ?? "0";
    transformed.children = values.children?.value ?? 0;
    return transformed;
  };

  const handleSubmit = (values) => {
    const transformedValues = transformValues(values);
    let formData = new FormData();

    for (const key in transformedValues) {
      if (transformedValues.hasOwnProperty(key)) {
        formData.append(key, transformedValues[key]);
      }
    }

    Object.entries(values).forEach(([key, value]) => {
      if (!formData.has(key)) {
        formData.append(key, value);
      }
    });

    formData.append("playerId", paylerId);
    formData.append("is_profile_completed", 0);

    dispatch(editPlayerAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success("Success");
        localStorage.removeItem("playerId");
        navigate("/my-players", { replace: true });

        window.history.pushState(null, null, "/my-players");

        // Prevent further back navigation by listening to the popstate event
        window.addEventListener("popstate", function (event) {
          window.history.pushState(null, null, "/my-players");
        });
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    dispatch(getPlayerDataById({ id: paylerId }));
  }, [dispatch]);

  const handleBack = () => {
    navigate(`/statistics/${Encryptedid(paylerId)}`);
  };

  return (
    <LoginLayout>
      <section>
        <Container>
          <div className="">
            <ProgressBar currentStep={7} totalSteps={8} />
            <div className="steps-heading">
              <p>
                <span>Step 5:</span> Contact & Other Information
              </p>
            </div>
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Basic Information</h2>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <FormikForm>
                  <div className=" mt-3 bottom-lines pb-4">
                    <Row>
                      <Col lg={3}>
                        <Form.Group className=" form-inner label-text-up ">
                          <label className="mb-2">Education Level</label>
                          <Select
                            placeholder="Select"
                            classNamePrefix="custom-select"
                            name="education_level"
                            options={optionsEduation}
                            onChange={(option) =>
                              setFieldValue("education_level", option)
                            }
                            value={values.education_level}
                          />
                          <ErrorMessage
                            name="education_level"
                            className="text-danger"
                            component="div"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group className=" form-inner label-text-up">
                          <label className="mb-2">Marital Status</label>
                          <Select
                            placeholder="Select"
                            classNamePrefix="custom-select"
                            name="martial_status"
                            options={optionsMaritalStatus}
                            onChange={(option) =>
                              setFieldValue("martial_status", option)
                            }
                            value={values.martial_status}
                          />

                          <ErrorMessage
                            name="martial_status"
                            className="text-danger"
                            component="div"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group className=" form-inner label-text-up">
                          <label className="mb-2">Children</label>
                          <Select
                            placeholder="Select"
                            classNamePrefix="custom-select"
                            name="children"
                            options={optionsChildren}
                            onChange={(option) =>
                              setFieldValue("children", option)
                            }
                            value={values.children}
                          />

                          <ErrorMessage
                            name="children"
                            className="text-danger"
                            component="div"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={3} className="">
                        <Form.Group className=" form-inner label-text-up">
                          <label className="mb-2">Hobbies & Interest</label>
                          <Form.Control
                            type="text"
                            name="hobbies"
                            placeholder="Select"
                            classNamePrefix="custom-select"
                            as={Field}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  {/* ========================================================================================================= */}
                  <div className=" mt-3 bottom-lines pb-4">
                    <h4 className="h-20">Contact Information</h4>
                    <Row>
                      <Col lg={4}>
                        <Form.Group className="form-inner label-text-up ">
                          <label className="mb-2">Address</label>

                          <Field
                            name="address"
                            as={Form.Control}
                            type="text"
                            placeholder="Enter"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner label-text-up">
                          <label className="mb-2">Email</label>

                          <Field
                            name="email"
                            as={Form.Control}
                            type="email"
                            placeholder="Enter "
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="form-inner label-text-up">
                          <label className="mb-2">Phone Number</label>
                          <Field
                            name="phone_number"
                            as={Form.Control}
                            type="number"
                            placeholder="Enter"
                          />
                          <ErrorMessage
                            name="phone_number"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className=" mt-3 bottom-lines pb-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Social Media Links</h3>
                    </div>
                    <Row>
                      <Col lg={3}>
                        <Form.Group className="form-inner label-text-up">
                          <label className="mb-2">Instagram</label>
                          <Field
                            name="instagram"
                            as={Form.Control}
                            type="url"
                            placeholder="Enter"
                          />
                          <ErrorMessage
                            name="instagram"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group className="form-inner label-text-up">
                          <label className="mb-2">Facebook</label>
                          <Field
                            name="facebook"
                            as={Form.Control}
                            type="url"
                            placeholder="Enter"
                          />
                          <ErrorMessage
                            name="facebook"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group className="form-inner label-text-up">
                          <label className="mb-2">Snapchat</label>

                          <Field
                            name="snapchat"
                            as={Form.Control}
                            type="url"
                            placeholder="Enter"
                          />
                          <ErrorMessage
                            name="snapchat"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={3} className="">
                        <Form.Group className="form-inner label-text-up">
                          <label className="mb-2">X</label>
                          <Field
                            name="twitter"
                            as={Form.Control}
                            type="url"
                            placeholder="Enter"
                          />
                          <ErrorMessage
                            name="twitter"
                            component="div"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  <div className=" mt-3 player-main-div bottom-lines pb-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Player's Lawyers</h3>
                    </div>

                    <div className="sponsor-player">
                      <Form.Group className="form-inner label-text-up">
                        <label className="mb-2">Lawyer's name</label>

                        <Field
                          name="player_lawyer_name"
                          as={Form.Control}
                          type="text"
                          placeholder="Enter"
                        />
                        <ErrorMessage
                          name="player_lawyer_name"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group className="form-inner label-text-up ">
                        <label className="mb-2">Phone Number</label>

                        <Field
                          name="player_lawyer_phone_number"
                          as={Form.Control}
                          type="number"
                          placeholder="Enter"
                        />
                        <ErrorMessage
                          name="player_lawyer_phone_number"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mt-3 ">
                    <h3 className="h-20">Sponsors</h3>
                  </div>

                  <div className="sponsor-player bottom-lines pb-4">
                    <Form.Group className="form-inner label-text-up">
                      <label className="mb-2">Sponsor’s name</label>
                      <Field
                        name="player_sponsors_name"
                        as={Form.Control}
                        type="text"
                        placeholder="Enter"
                      />
                      <ErrorMessage
                        name="player_sponsors_name"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>

                    <Form.Group className="form-inner label-text-up ">
                      <label className="mb-2">Phone Number</label>
                      <Field
                        name="player_sponsors_phone_number"
                        as={Form.Control}
                        type="number"
                        placeholder="Enter"
                      />
                      <ErrorMessage
                        name="player_sponsors_phone_number"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </div>

                  <div className=" mt-3 player-main-div mt-3 ">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Managing Company</h3>
                    </div>

                    <div className="managing-agents bottom-lines pb-4">
                      <Form.Group className="form-inner Enter label-text-up">
                        <label className="mb-2">Company's name</label>

                        <Field
                          name="player_managing_company_name"
                          as={Form.Control}
                          type="text"
                          placeholder="Enter"
                        />
                        <ErrorMessage
                          name="player_managing_company_name"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>

                      <Form.Group className="form-inner label-text-up ">
                        <label className="mb-2">Phone Number</label>
                        <Field
                          name="player_managing_company_phone_number"
                          as={Form.Control}
                          type="number"
                          placeholder="Enter"
                        />
                        <ErrorMessage
                          name="player_managing_company_phone_number"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className=" mt-3 player-main-div">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Agent’s General Notes</h3>
                    </div>

                    <Form.Group className="form-inner Enter label-text-up">
                      {/* <label className="mb-2">Add Notes</label> */}

                      <Form.Group className="mb-3 form-inner">
                        <Field
                          as="textarea"
                          name="contact_info_other_notes"
                          rows={3}
                          placeholder="Write notes here"
                          className="form-control"
                        />
                        {/* <ErrorMessage
                            name="contact_info_other_notes"
                            className="text-danger"
                            component="div"
                          /> */}
                      </Form.Group>
                    </Form.Group>
                  </div>

                  {/* ============================================================================================================== */}
                  {/* <div className=" mt-3">
                    <div className="d-flex justify-content-between">
                      <h3 className="h-20">Other Notes</h3>
                    </div>
                    <div className="cmn-form-fields">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3 form-inner">
                            <Field
                              as="textarea"
                              name="other_info_other_notes"
                              rows={3}
                              placeholder="Write here"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="other_info_other_notes"
                              className="text-danger"
                              component="div"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div> */}

                  <div className="d-flex justify-content-between mb-5">
                    <div className="d-flex">
                      <div className="mt-4 cmn-light-btn save-and-close-btn">
                        <button
                          className="back-btn-ps"
                          type="button"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mt-4 me-2 cmn-light-btn save-and-close-btn"></div>
                      <div className="mt-4 cmn-light-btn">
                        <button type="submit" className="next-btn-ps">
                          {" "}
                          Save & Finish
                        </button>
                      </div>
                    </div>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default OtherInformation;
