import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import LoginLayout from "../components/LoginLayout";
import "react-calendar/dist/Calendar.css";
import "react-tabs/style/react-tabs.css";
import backgroundImage from "../Assets/images/playerbanner.png";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { useDispatch, useSelector } from "react-redux";
import { getAgentDataById } from "../Redux/Action/agentAction";
import { capitalize } from "../utils/helper";
import { positions } from "../utils/rawjson";
import Loader from "../components/Loader";
import { createChatRoomAction } from "../Redux/Action/message";
import toast from "react-hot-toast";

export default function AssociateAgentDetail() {
  const associateData = useSelector((e) => e?.agentAuthData?.agentDetail);
  const loader = useSelector((e) => e?.agentAuthData?.loading);
  const { id } = useParams();
  const IdVal = id && Decryptedid(atob(id));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAgentDataById({ id: IdVal }));
  }, [dispatch]);

  const backgroundImageStyle = {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundImage: `${
      associateData?.banner_image
        ? `url(${process.env.REACT_APP_IMAGE_PATH}${associateData?.banner_image})`
        : `url(${backgroundImage})`
    }`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    width: "100%",
  };

  const handleAssociateEdit = (id) => {
    if (id) {
      localStorage.setItem("agentId", id);
    }
  };

  // const createChatRoom = async () => {
  //   let apiRes = await dispatch(createChatRoomAction({ receiver_id: IdVal }));
  //   if (apiRes?.payload?.status === 200) {
  //     toast.success(apiRes?.payload?.message);
  //     navigate(`/message`);
  //   } else {
  //     toast.error(apiRes?.payload?.message);
  //   }
  // };

  return (
    <LoginLayout>
      <section>
        {loader && <Loader />}

        <Container>
          <div className="cmn-card">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h2>Associate Agent’s Profile</h2>
            </div>
            <div className="player-profile-main" style={backgroundImageStyle}>
              <Row className="h-100">
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-center"
                >
                  <div className="player-left-info">
                    <img
                      className="play-img"
                      src={
                        associateData?.profile_image
                          ? `${process.env.REACT_APP_IMAGE_PATH}${associateData.profile_image}`
                          : associateData?.gender == 1
                          ? require("../Assets/images/My Associate Agent female.png")
                          : require("../Assets/images/My Associate Agent male.png")
                      }
                      alt="Profile"
                    />
                    <div>
                      <h2>{capitalize(associateData?.agent_name)}</h2>
                      <h2>
                        Associate Agent ID:
                        {capitalize(associateData?.unique_id)}
                      </h2>

                      <div className="socio-icons mt-3">
                        {/* Instagram */}
                        {associateData?.instagram && (
                          <Link
                            to={associateData.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/instablue.svg")
                                  .default
                              }
                              alt="Instagram"
                            />
                          </Link>
                        )}

                        {/* Snapchat */}
                        {associateData?.snapchat && (
                          <Link
                            to={associateData.snapchat}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/snapblue.svg").default
                              }
                              alt="Snapchat"
                            />
                          </Link>
                        )}

                        {/* X (formerly Twitter) */}
                        {associateData?.twitter && (
                          <Link
                            to={associateData.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/xblue.svg").default
                              }
                              alt="X"
                            />
                          </Link>
                        )}

                        {/* Facebook */}
                        {associateData?.facebook && (
                          <Link
                            to={associateData.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                require("../Assets/images/fbblue.svg").default
                              }
                              alt="Facebook"
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="player-right-info">
                    <div className="play-edit">
                      <Link
                        // to="/add-associate-agent"
                        to={`/add-associate-agent?agentId=${Encryptedid(
                          associateData?._id
                        )}`}
                        onClick={() => handleAssociateEdit(associateData?._id)}
                      >
                        <img
                          src={
                            require("../Assets/images/editwhite.svg").default
                          }
                        />
                        Edit Profile
                      </Link>
                    </div>
                    <div className="play-info-box">
                      <div>
                        <h3>{associateData?.phone_number}</h3>
                        <p>Phone Number</p>
                      </div>
                      <div>
                        <h3>{associateData?.city}</h3>
                        <p>City</p>
                      </div>
                      <div>
                        <h3>{associateData?.email}</h3>
                        <p>Email</p>
                      </div>
                      <div>
                        <h3>{associateData?.country}</h3>
                        <p>Country</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Tabs
              defaultActiveKey="Profile"
              id="uncontrolled-tab-example"
              className="mb-3 mt-4"
            >
              <Tab eventKey="Profile" title="Profile" className="accord-tabs">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Contact Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Country:</h4>
                              <p>
                                {associateData?.country
                                  ? associateData?.country
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>City:</h4>
                              <p>
                                {associateData?.city
                                  ? associateData?.city
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Phone Number:</h4>
                              <p>
                                {associateData?.phone_number
                                  ? associateData?.phone_number
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Email Address:</h4>
                              <p>
                                {associateData?.email
                                  ? associateData?.email
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Fax:</h4>
                              <p>
                                {associateData?.fax
                                  ? associateData?.fax
                                  : "N/A"}
                              </p>
                            </li>

                            <li>
                              <h4>Website:</h4>
                              <p>
                                {associateData?.web
                                  ? associateData?.web
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Home Address:</h4>
                              <p>
                                {associateData?.address
                                  ? associateData?.address
                                  : "N/A"}
                              </p>
                            </li>
                            {/* <li className="flex-column align-items-start mb-0">
                              <h4>Other Notes:</h4>
                              <p className="mt-1">
                                {associateData?.agent_other_notes
                                  ? associateData?.agent_other_notes
                                  : "N/A"}
                              </p>
                            </li> */}
                          </ul>
                        </div>
                      </div>

                      {/* <div className="search-cmn-box mt-4">
                        <h2>Main Information</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Role:</h4>
                              <p>Trainer</p>
                            </li>
                            <li>
                              <h4>Gender:</h4>
                              <p>Male</p>
                            </li>
                            <li>
                              <h4>Team:</h4>
                              <p>Manchester United</p>
                            </li>
                            <li>
                              <h4>Email Address:</h4>
                              <p>
                                {associateData?.email
                                  ? associateData?.email
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <h4>Phone Address:</h4>
                              <p>
                                {associateData?.phone_number
                                  ? associateData?.phone_number
                                  : "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Social Media Links</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li>
                              <h4>Instagram:</h4>
                              <p>{associateData?.instagram || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Snapchat:</h4>
                              <p>{associateData?.snapchat || "N/A"}</p>
                            </li>
                            <li>
                              <h4>Facebook:</h4>
                              <p>{associateData?.facebook || "N/A"}</p>
                            </li>
                            <li>
                              <h4>X:</h4>
                              <p>{associateData?.twitter || "N/A"}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="search-cmn-box mt-3">
                        <h2>General Notes</h2>
                        <div className="search-inner-box">
                          <ul>
                            <li className="flex-column align-items-start mb-0">
                              <p className="mt-1">
                                {associateData?.general_notes ?? "N/A"}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      {" "}
                      <div className="search-inner-box p-0 mt-4">
                        <div className="play-images">
                          <img
                            src={require("../Assets/images/smllbanner.png")}
                          />
                          <div className="players-value inner-tab">
                            <h3>
                              CHECK THE MOST VALUABLE PLAYERS IN THE WORLD
                            </h3>
                          </div>
                        </div>{" "}
                        {/* <div className="comp-btn pt-4">
                          <Link
                            className="mt-0"
                            onClick={() => createChatRoom()}
                          >
                            Message Agent
                          </Link>
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Players" title="Players">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Players I have in teams from this Agent</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(associateData?.agent_players_name) &&
                          associateData?.agent_players_name.length > 0 ? (
                            <>
                              {associateData?.agent_players_name.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs"
                                        key={index}
                                      >
                                        <div className="d-flex">
                                          <img
                                            className="play-img"
                                            src={
                                              ele?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.profile_image}`
                                                : ele?.gender === 0
                                                ? require("../Assets/images/player_Ai.png")
                                                : require("../Assets/images/player-female.png")
                                            }
                                          />
                                          <div className="ms-3 remove-right-mg">
                                            <h4>{ele?.full_name}</h4>
                                            <p>
                                              <span>{ele?.team_name}</span>
                                            </p>
                                            <p>
                                              {Array.isArray(ele?.positions) &&
                                                ele?.positions.length > 0 &&
                                                ele.positions.map(
                                                  (ele, index) => {
                                                    return (
                                                      <span key={index}>
                                                        {positions[ele]}
                                                      </span>
                                                    );
                                                  }
                                                )}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="ms-3 text-set">
                                          <p>
                                            <span>{ele.unique_id}</span>
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>My Players that this Agent has in teams</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(associateData?.my_players_name) &&
                          associateData?.my_players_name.length > 0 ? (
                            <>
                              {associateData?.my_players_name.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs"
                                        key={index}
                                      >
                                        <div className="d-flex">
                                          <img
                                            className="play-img"
                                            src={
                                              ele?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.profile_image}`
                                                : ele?.gender === 0
                                                ? require("../Assets/images/player_Ai.png")
                                                : require("../Assets/images/player-female.png")
                                            }
                                          />
                                          <div className="ms-3 remove-right-mg">
                                            <h4>{ele?.full_name}</h4>
                                            <p>
                                              <span>{ele?.team_name}</span>
                                            </p>
                                            <p>
                                              {Array.isArray(ele?.positions) &&
                                                ele?.positions.length > 0 &&
                                                ele.positions.map(
                                                  (ele, index) => {
                                                    return (
                                                      <span key={index}>
                                                        {ele}
                                                      </span>
                                                    );
                                                  }
                                                )}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="ms-3">
                                          <p>
                                            <span>{ele.unique_id}</span>
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>
                          My Players that have been proposed to this Agent
                        </h2>
                        <div className="search-inner-box">
                          {Array.isArray(
                            associateData?.my_proposed_players_name
                          ) &&
                          associateData?.my_proposed_players_name.length > 0 ? (
                            <>
                              {associateData?.my_proposed_players_name.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs "
                                        key={index}
                                      >
                                        <div className="d-flex">
                                          <img
                                            className="play-img"
                                            src={
                                              ele?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.profile_image}`
                                                : ele?.gender === 0
                                                ? require("../Assets/images/player_Ai.png")
                                                : require("../Assets/images/player-female.png")
                                            }
                                          />
                                          <div className="ms-3 remove-right-mg">
                                            <h4>{ele?.full_name}</h4>
                                            <p>
                                              <span>{ele?.team_name}</span>
                                            </p>
                                            <p>
                                              {Array.isArray(ele?.positions) &&
                                                ele?.positions.length > 0 &&
                                                ele.positions.map(
                                                  (ele, index) => {
                                                    return (
                                                      <span key={index}>
                                                        {positions[ele]}
                                                      </span>
                                                    );
                                                  }
                                                )}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="ms-3">
                                          <p>
                                            <span>{ele.unique_id}</span>
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box mt-4">
                        <h2>
                          Players have been proposed to me from this Agent
                        </h2>
                        <div className="search-inner-box">
                          {Array.isArray(
                            associateData?.proposed_players_name
                          ) &&
                          associateData?.proposed_players_name.length > 0 ? (
                            <>
                              {associateData?.proposed_players_name.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs"
                                        key={index}
                                      >
                                        <div className="d-flex">
                                          <img
                                            className="play-img"
                                            src={
                                              ele?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.profile_image}`
                                                : ele?.gender === 0
                                                ? require("../Assets/images/player_Ai.png")
                                                : require("../Assets/images/player-female.png")
                                            }
                                          />
                                          <div className="ms-3 remove-right-mg">
                                            <h4>{ele?.full_name}</h4>
                                            <p>
                                              <span>{ele?.team_name}</span>
                                            </p>
                                            <p>
                                              {Array.isArray(ele?.positions) &&
                                                ele?.positions.length > 0 &&
                                                ele.positions.map(
                                                  (ele, index) => {
                                                    return (
                                                      <span key={index}>
                                                        {positions[ele]}
                                                      </span>
                                                    );
                                                  }
                                                )}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="ms-3">
                                          <p>
                                            <span>{ele.unique_id}</span>
                                          </p>

                                          {/* <img
                                          className="play-img"
                                          src={`${process.env.REACT_APP_IMAGE_PATH}${ele?.profile_image}`}
                                        /> */}
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Directors" title="Directors">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Directors I have in teams from this Agent</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(associateData?.agent_directors_name) &&
                          associateData?.agent_directors_name.length > 0 ? (
                            <>
                              {associateData?.agent_directors_name.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs"
                                        key={index}
                                      >
                                        <div className="d-flex">
                                          <img
                                            className="play-img"
                                            src={
                                              ele?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.profile_image}`
                                                : ele?.gender === 0
                                                ? require("../Assets/images/director.png")
                                                : require("../Assets/images/director-female-new.png")
                                            }
                                          />
                                          <div className="ms-3 remove-right-mg">
                                            <h4>{ele?.full_name}</h4>
                                            <p>
                                              <span>Right back</span>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="">
                                          <p>
                                            <span>{ele?.unique_id}</span>
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>My Directors that this Agent has in teams</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(associateData?.my_directors_name) &&
                          associateData?.my_directors_name.length > 0 ? (
                            <>
                              {associateData?.my_directors_name.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs"
                                        key={index}
                                      >
                                        <div className="d-flex remove-right-mg">
                                          <img
                                            className="play-img"
                                            src={
                                              ele?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.profile_image}`
                                                : ele?.gender === 0
                                                ? require("../Assets/images/director.png")
                                                : require("../Assets/images/director-female-new.png")
                                            }
                                          />
                                          <div className="ms-3">
                                            <h4>{ele?.full_name}</h4>
                                            <p>
                                              <span>Right back</span>
                                            </p>
                                          </div>
                                        </div>
                                        <p>
                                          <span>{ele?.unique_id}</span>
                                        </p>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>
                          My Directors that have been proposed to this Agent
                        </h2>
                        <div className="search-inner-box">
                          {Array.isArray(
                            associateData?.my_proposed_directors_name
                          ) &&
                          associateData?.my_proposed_directors_name.length >
                            0 ? (
                            <>
                              {associateData?.my_proposed_directors_name.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs"
                                        key={index}
                                      >
                                        <div className="d-flex">
                                          <img
                                            className="play-img remove-right-mg"
                                            src={
                                              ele?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.profile_image}`
                                                : ele?.gender === 0
                                                ? require("../Assets/images/director.png")
                                                : require("../Assets/images/director-female-new.png")
                                            }
                                          />
                                          <div className="ms-3">
                                            <h4>{ele?.full_name}</h4>
                                            <p>
                                              <span>Right back</span>
                                            </p>
                                          </div>
                                        </div>
                                        <p>
                                          <span>{ele?.unique_id}</span>
                                        </p>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box mt-4">
                        <h2>
                          Directors have been proposed to me from this Agent
                        </h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(
                            associateData?.proposed_directors_name
                          ) &&
                          associateData?.proposed_directors_name.length > 0 ? (
                            <>
                              {associateData?.proposed_directors_name.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs"
                                        key={index}
                                      >
                                        <div className="d-flex remove-right-mg">
                                          <img
                                            className="play-img"
                                            src={
                                              ele?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.profile_image}`
                                                : ele?.gender === 0
                                                ? require("../Assets/images/director.png")
                                                : require("../Assets/images/director-female-new.png")
                                            }
                                          />
                                          <div className="ms-3">
                                            <h4>{ele?.full_name}</h4>
                                            <p>
                                              <span>Right back</span>
                                            </p>
                                          </div>
                                        </div>
                                        <p>
                                          <span>{ele?.unique_id}</span>
                                        </p>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Coaches" title="Coaches">
                <div className="inner-search-main-wrap mt-4">
                  <Row>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>Coaches I have in teams from this Agent</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(associateData?.agent_coaches_name) &&
                          associateData?.agent_coaches_name.length > 0 ? (
                            <>
                              {associateData?.agent_coaches_name.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs"
                                        key={index}
                                      >
                                        <div className="d-flex remove-right-mg">
                                          <img
                                            className="play-img"
                                            src={
                                              ele?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.profile_image}`
                                                : ele?.gender === 0
                                                ? require("../Assets/images/coach-male.png")
                                                : require("../Assets/images/coach-female.png")
                                            }
                                          />
                                          <div className="ms-3">
                                            <h4>{ele?.full_name}</h4>
                                            <p>
                                              <span> Right back</span>
                                            </p>
                                          </div>
                                        </div>
                                        <p>
                                          <span> {ele?.unique_id}</span>
                                        </p>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>My Coaches that this Agent has in teams</h2>
                        <div className="search-inner-box">
                          {" "}
                          {Array.isArray(associateData?.my_coaches_name) &&
                          associateData?.my_coaches_name.length > 0 ? (
                            <>
                              {associateData?.my_coaches_name.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs"
                                        key={index}
                                      >
                                        <div className="d-flex remove-right-mg ">
                                          <img
                                            className="play-img "
                                            src={
                                              ele?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.profile_image}`
                                                : ele?.gender === 0
                                                ? require("../Assets/images/coach-male.png")
                                                : require("../Assets/images/coach-female.png")
                                            }
                                          />
                                          <div className="ms-3">
                                            <h4>{ele?.full_name}</h4>
                                            <p>
                                              <span> Right back</span>
                                            </p>
                                          </div>
                                        </div>
                                        <p>
                                          <span>{ele?.unique_id}</span>
                                        </p>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="search-cmn-box">
                        <h2>
                          My Coaches that have been proposed to this Agent
                        </h2>
                        <div className="search-inner-box">
                          {Array.isArray(
                            associateData?.my_proposed_coaches_name
                          ) &&
                          associateData?.my_proposed_coaches_name.length > 0 ? (
                            <>
                              {associateData?.my_proposed_coaches_name.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs"
                                        key={index}
                                      >
                                        <div className="d-flex remove-right-mg">
                                          <img
                                            className="play-img "
                                            src={
                                              ele?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.profile_image}`
                                                : ele?.gender === 0
                                                ? require("../Assets/images/coach-male.png")
                                                : require("../Assets/images/coach-female.png")
                                            }
                                          />
                                          <div className="ms-3">
                                            <h4>{ele?.full_name}</h4>
                                            <p>
                                              <span>Right back</span>
                                            </p>
                                          </div>
                                        </div>
                                        <p>
                                          <span> {ele?.unique_id}</span>
                                        </p>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>
                    </Col>

                    <Col lg={4}>
                      <div className="search-cmn-box mt-4">
                        <h2>
                          Coaches have been proposed to me from this Agent
                        </h2>
                        <div className="search-inner-box">
                          {Array.isArray(
                            associateData?.proposed_coaches_name
                          ) &&
                          associateData?.proposed_coaches_name.length > 0 ? (
                            <>
                              {associateData?.proposed_coaches_name.map(
                                (ele, index) => {
                                  return (
                                    <>
                                      <div
                                        className="play-inner-box play-agent-box-cs"
                                        key={index}
                                      >
                                        <div className="d-flex remove-right-mg">
                                          <img
                                            className="play-img"
                                            src={
                                              ele?.profile_image
                                                ? `${process.env.REACT_APP_IMAGE_PATH}${ele?.profile_image}`
                                                : ele?.gender === 0
                                                ? require("../Assets/images/coach-male.png")
                                                : require("../Assets/images/coach-female.png")
                                            }
                                          />
                                          <div className="ms-3">
                                            <h4>{ele?.full_name}</h4>
                                            <p>
                                              <span>Right back</span>
                                            </p>
                                          </div>
                                        </div>
                                        <p>
                                          <span>{ele?.unique_id}</span>
                                        </p>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div>No Data Found</div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
}
