import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import {
  Formik,
  Field,
  FieldArray,
  Form as FormikForm,
  ErrorMessage,
} from "formik";
import * as Yup from "yup";
import LoginLayout from "../components/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import { editTeamAction, getTeamDataById } from "../Redux/Action/teamAction";
import toast from "react-hot-toast";
import { getAgentListAction } from "../Redux/Action/agentAction";
import { getCoachListAction } from "../Redux/Action/coachAction";
import Select from "react-select";
import ProgressBar from "../CommonModals/ProgressBar";
import Modal from "react-modal";
import { addNewTeam } from "../Redux/Action/playerAction";

const AddTeamSix = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const teamId = id && Decryptedid(atob(id));

  const [inputType1, setInputType1] = useState("text");
  const [inputType, setInputType] = useState("text");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [newTeamName, setNewTeamName] = useState("");

  const getTeamDetails = useSelector((e) => e.teamAuthData.getTeamDetailById);

  const agentListingData = useSelector((e) => e.agentAuthData.agentListDetail);

  const getAllCoachList = useSelector((e) => e.coachAuthData.getCoachLists);

  const coachOptions = getAllCoachList?.map((elem) => ({
    label: elem?.full_name,
    value: elem?._id,
  }));

  const associateAgentOptions = agentListingData?.map((elem) => ({
    label: elem?.agent_name,
    value: elem?._id,
  }));

  const [agentOptions, setAgentOptions] = useState([]);

  useEffect(() => {
    if (agentListingData) {
      setAgentOptions(
        agentListingData?.map((elem) => ({
          label: elem?.agent_name,
          value: elem?._id,
        })) || []
      );
    }
  }, [agentListingData]);

  console.log("agentListingData", agentListingData);
  console.log("agentOptions", agentOptions);

  const customOptions = [
    { label: "+ Add new Agent", value: "add-agent", isAgentTeam: true },
    ...agentOptions,
  ];

  const handleSelectChange = (option, setFieldValue, index, type) => {
    if (option?.isAgentTeam) {
      if (type === "coache") {
        setIsModalOpen(true);
      } else if (type === "proposed") {
        setIsModalOpen2(true);
      }
    } else {
      if (type === "coache") {
        setFieldValue(
          `coaches[${index}].coach_associate_agent_id`,
          option.value
        );
      } else {
        setFieldValue(
          `proposed_coaches[${index}].coach_proposed_associate_agent_id`,
          option.value
        );
      }
    }
  };

  const handleInputChange = (input) => {
    const filteredInput = input.replace(/[^a-zA-Z\s]/g, "");
    const isTeamExists = agentOptions?.some((team) =>
      team.label.toLowerCase()?.includes(filteredInput.toLowerCase())
    );
    setIsAddingTeam(!isTeamExists);
    return filteredInput;
  };

  const handleAddTeam = (values, setFieldValue, index, fieldType) => {
    if (!newTeamName.trim()) {
      toast.error("Agent name cannot be empty.");
      return;
    }

    dispatch(addNewTeam({ name: newTeamName, type: 0 })).then((res) => {
      if (res?.payload?.status === 200) {
        const newTeamId = res?.payload?.data?._id;
        const newTeam = { label: newTeamName, value: newTeamId };
        setAgentOptions([...agentOptions, newTeam]);

        if (fieldType === "coache") {
          setFieldValue(
            `coaches[${index}].coach_associate_agent_id`,
            newTeam.value
          );
          setIsModalOpen(false);
        }
        if (fieldType === "proposed") {
          setFieldValue(
            `proposed_coaches[${index}].coach_proposed_associate_agent_id`,
            newTeam.value
          );
          setIsModalOpen2(false);
        }

        // setFieldValue("associate_agent_id", newTeam.value);

        // setIsFree(true);
        setNewTeamName("");
      } else {
        toast.error("Failed to add the team. Please try again.");
      }
    });
  };

  const validate = (values) => {
    const errors = {};

    if (
      values.has_coach_in_team === null ||
      values.has_coach_in_team === undefined
    ) {
      errors.has_coach_in_team = "required";
    }

    if (values.has_coach_in_team == 1) {
      if (values.coaches && values.coaches.length > 0) {
        values.coaches.forEach((coach, index) => {
          const coachErrors = {};

          if (!coach.coach_id) {
            coachErrors.coach_id = "required";
          }
          if (!coach.who_brought_coach) {
            coachErrors.who_brought_coach = "required";
          }

          if (coach.who_brought_coach === "1") {
            if (!coach.coach_associate_agent_id) {
              coachErrors.coach_associate_agent_id = "required";
            }
            if (!coach.coach_associate_agent_fee_charged) {
              coachErrors.coach_associate_agent_fee_charged = "required";
            }
            if (!coach.coach_associate_agent_fee_received) {
              coachErrors.coach_associate_agent_fee_received = "required";
            }
            if (!coach.coach_associate_agent_fee_received_on) {
              coachErrors.coach_associate_agent_fee_received_on = "required";
            }
            if (!coach.coach_associate_agent_fee_pending) {
              coachErrors.coach_associate_agent_fee_pending = "required";
            }
            if (!coach.coach_associate_agent_fee_will_received_on) {
              coachErrors.coach_associate_agent_fee_will_received_on =
                "required";
            }
            if (!coach.coach_team_contact_person_name) {
              coachErrors.coach_team_contact_person_name = "required";
            }
            if (!coach.coach_team_contact_person_phone_number) {
              coachErrors.coach_team_contact_person_phone_number = "required";
            }
          }

          if (Object.keys(coachErrors).length > 0) {
            errors.coaches = errors.coaches || [];
            errors.coaches[index] = coachErrors;
          }
        });
      } else {
        errors.coaches = "required";
      }
    }

    if (values.has_proposed_coach === null) {
      errors.has_proposed_coach = "required";
    }

    if (values.has_proposed_coach == 1) {
      if (values.proposed_coaches && Array.isArray(values.proposed_coaches)) {
        const proposedCoachesErrors = values.proposed_coaches
          .map((coach, index) => {
            const coachErrors = {};

            if (!coach.proposed_coach_id) {
              coachErrors.proposed_coach_id = "required";
            }

            if (
              coach.who_bought_proposed_coach === "1" &&
              !coach.coach_proposed_associate_agent_id
            ) {
              coachErrors.coach_proposed_associate_agent_id = "required";
            }

            return Object.keys(coachErrors).length > 0 ? coachErrors : null;
          })
          .filter((error) => error !== null);

        if (proposedCoachesErrors.length > 0) {
          errors.proposed_coaches = proposedCoachesErrors;
        }
      }
    }
    console.log(errors, "324234234");
    return errors;
  };

  const handleSaveAndClose = (values, validateForm, setErrors) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length > 0) {
        toast.error("Please fill in all required fields.");
        setErrors(errors);
        return;
      }

      handleSubmit(values, "save");
    });
  };

  const handleSubmit = (values, type) => {
    console.log("values", values);
    // const preparedValues = {
    //   ...values,
    //   coaches: values.coaches.map((player) => {
    //     if (player.who_brought_coach === "0") {
    //       const {
    //         coach_associate_agent_id,
    //         coach_associate_agent_fee_charged,
    //         coach_associate_agent_fee_received,
    //         coach_associate_agent_fee_received_on,
    //         coach_associate_agent_fee_pending,
    //         coach_associate_agent_fee_will_received_on,
    //         coach_team_contact_person_name,
    //         coach_team_contact_person_phone_number,
    //         coach_team_contact_person_status,
    //         coach_other_notes,
    //         ...rest
    //       } = player;

    //       return {
    //         ...rest,
    //         who_brought_coach: player.who_brought_coach,
    //       };
    //     }
    //     return player;
    //   }),
    // };
    // let formData = new FormData();

    // if (values.has_coach_in_team === 1) {
    //   formData.append("has_coach_in_team", values.has_coach_in_team);
    //   formData.append("coaches", JSON.stringify(values.coaches));
    // } else {
    //   formData.append("has_coach_in_team", values.has_coach_in_team);
    // }

    const preparedValues = {
      ...values,
      coaches: values.coaches.map((coach) => {
        if (coach.who_brought_coach === "0") {
          const {
            coach_associate_agent_id,
            coach_associate_agent_fee_charged,
            coach_associate_agent_fee_received,
            coach_associate_agent_fee_received_on,
            coach_associate_agent_fee_pending,
            coach_associate_agent_fee_will_received_on,
            coach_team_contact_person_name,
            coach_team_contact_person_phone_number,
            coach_team_contact_person_status,
            coach_other_notes,
            ...rest
          } = coach;

          return {
            ...rest,
            who_brought_coach: coach.who_brought_coach,
          };
        }
        return coach;
      }),
    };

    // second prepare
    const preparedValues2 = {
      ...values,
      proposed_coaches: values.proposed_coaches.map((coach) => {
        if (coach.who_bought_proposed_coach === "0") {
          const { coach_proposed_associate_agent_id, ...rest } = coach;

          return {
            ...rest,
            who_bought_proposed_coach: coach.who_bought_proposed_coach,
          };
        }
        return coach;
      }),
    };

    let formData = new FormData();

    if (values.has_coach_in_team === 1) {
      formData.append("has_coach_in_team", values.has_coach_in_team);
      formData.append("coaches", JSON.stringify(preparedValues.coaches));
    } else {
      formData.append("has_coach_in_team", values.has_coach_in_team);
    }

    // second start ----------------------------
    if (values.has_proposed_coach == 1) {
      formData.append("has_proposed_coach", values.has_proposed_coach);
      if (preparedValues2.proposed_coaches.length > 0) {
        formData.append(
          "proposed_coaches",
          JSON.stringify(preparedValues2.proposed_coaches)
        );
      }
    } else {
      formData.append("has_proposed_coach", values.has_proposed_coach);
    }
    // second finish ----------------------

    formData.append("teamId", teamId);
    formData.append("is_profile_completed", 4);

    if (values?.coach_other_notes) {
      formData.append("coach_other_notes", values?.coach_other_notes);
    }

    dispatch(editTeamAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        if (type == "save") {
          navigate(`/my-team`);
        } else {
          navigate(`/add-team-eight/${Encryptedid(res?.payload?.data?._id)}`);
        }
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const initialValues = {
    has_coach_in_team: getTeamDetails?.has_coach_in_team || "0",
    coaches:
      getTeamDetails?.coaches?.length > 0
        ? getTeamDetails.coaches.map((elem) => ({
            coach_id: elem.coach_id || "",
            who_brought_coach: elem.who_brought_coach || "0",
            coach_associate_agent_id: elem.coach_associate_agent_id || "",
            coach_associate_agent_fee_charged:
              elem.coach_associate_agent_fee_charged || "",
            coach_associate_agent_fee_received:
              elem.coach_associate_agent_fee_received || "",
            coach_associate_agent_fee_received_on:
              elem.coach_associate_agent_fee_received_on?.split("T")[0] || "",
            coach_associate_agent_fee_pending:
              elem.coach_associate_agent_fee_pending || "",
            coach_associate_agent_fee_will_received_on:
              elem.coach_associate_agent_fee_will_received_on?.split("T")[0] ||
              "",
            coach_team_contact_person_name:
              elem.coach_team_contact_person_name || "",
            coach_team_contact_person_phone_number:
              elem.coach_team_contact_person_phone_number || "",
            coach_team_contact_person_status:
              elem?.coach_team_contact_person_status || "",
            coach_other_notes: elem?.coach_other_notes || "",
          }))
        : [
            {
              coach_id: "",
              who_brought_coach: "0",
              coach_associate_agent_id: "",
              coach_associate_agent_fee_charged: "",

              coach_associate_agent_fee_received: "",
              coach_associate_agent_fee_received_on: "",
              coach_associate_agent_fee_pending: "",

              coach_associate_agent_fee_will_received_on: "",
              coach_team_contact_person_name: "",
              coach_team_contact_person_phone_number: "",
              coach_team_contact_person_status: "",
              coach_other_notes: "",
            },
          ],
    has_proposed_coach: getTeamDetails?.has_proposed_coach || "0",
    proposed_coaches:
      getTeamDetails?.proposed_coaches?.length > 0
        ? getTeamDetails.proposed_coaches?.map((player) => ({
            proposed_coach_id: player.proposed_coach_id || "",
            who_bought_proposed_coach: player.who_bought_proposed_coach || "0",
            coach_proposed_associate_agent_id:
              player.coach_proposed_associate_agent_id || "",
            coach_proposed_team_other_notes:
              player.coach_proposed_team_other_notes || "",
          }))
        : [
            {
              proposed_coach_id: "",
              who_bought_proposed_coach: "0",
              coach_proposed_associate_agent_id: "",
              coach_proposed_team_other_notes: "",
            },
          ],
    // coach_other_notes: getTeamDetails?.coach_other_notes,
  };

  useEffect(() => {
    if (teamId) {
      dispatch(getTeamDataById({ id: teamId }));
    }
    dispatch(getAgentListAction());
    dispatch(getCoachListAction());
  }, [dispatch]);

  return (
    <LoginLayout>
      <section>
        <Container>
          <ProgressBar currentStep={4} totalSteps={5} />
          <div className="steps-heading">
            <p>
              {" "}
              <span>Step 4:</span> Transactions with Coaches/ Managers{" "}
            </p>
          </div>
          <div className="personal-heading">
            <div className="card-heading mb-2 d-flex justify-content-between align-items-center">
              <h3>Coaches/Managers Information</h3>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validate={validate}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, validateForm, setErrors }) => (
                <FormikForm>
                  {console.log(values.has_coach_in_team, "34ertretertert")}
                  <div className="">
                    <Row>
                      <Col lg={6}>
                        <div>
                          <div className="">
                            <div className="d-flex justify-content-between this-team">
                              <p className="h-20">
                                My Coaches/Managers in this Team
                              </p>
                            </div>
                            <div className="cmn-form-fields mt-2">
                              <Row>
                                <Col lg={6}>
                                  <div className="licensed-sec this-team">
                                    <p>Yes</p>
                                    <div className="form-check form-switch">
                                      <Field
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        name="has_coach_in_team"
                                        checked={values.has_coach_in_team == 1}
                                        onChange={() =>
                                          setFieldValue("has_coach_in_team", 1)
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="licensed-sec this-team">
                                    <p>No</p>
                                    <div className="form-check form-switch">
                                      <Field
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        name="has_coach_in_team"
                                        checked={values.has_coach_in_team == 0}
                                        onChange={() =>
                                          setFieldValue("has_coach_in_team", 0)
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <ErrorMessage
                                  name="has_coach_in_team"
                                  component="div"
                                  className="text-danger"
                                />
                              </Row>
                            </div>
                          </div>
                          {values.has_coach_in_team === 1 && (
                            <div className="">
                              <div className="d-flex justify-content-between">
                                {/* <h3 className="h-20">coaches</h3> */}
                                <button
                                  type="button"
                                  style={{ background: "none", border: "none" }}
                                >
                                  <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    {/* SVG content */}
                                  </svg>
                                </button>
                              </div>
                              <div className="cmn-form-fields mt-2">
                                <FieldArray name="coaches">
                                  {({ push, remove }) => (
                                    <>
                                      <div className="d-flex justify-content-between">
                                        {" "}
                                        <h3 className="h-20">Coach 1</h3>
                                        <button
                                          type="button"
                                          style={{
                                            border: "none",
                                            background: "none",
                                          }}
                                          onClick={() =>
                                            push({
                                              coach_id: "",
                                              who_brought_coach: "0",
                                              coach_associate_agent_id: "",
                                              coach_associate_agent_fee_charged:
                                                "",
                                              coach_associate_agent_fee_received:
                                                "",
                                              coach_associate_agent_fee_received_on:
                                                "",
                                              coach_associate_agent_fee_pending:
                                                "",
                                              coach_associate_agent_fee_will_received_on:
                                                "",
                                              coach_team_contact_person_name:
                                                "",
                                              coach_team_contact_person_phone_number:
                                                "",
                                            })
                                          }
                                        >
                                          <svg
                                            width="28"
                                            coaching_licenses="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                              fill="#4C8BB4"
                                            />
                                          </svg>
                                        </button>
                                      </div>

                                      {values.coaches.map((player, index) => (
                                        <div key={index}>
                                          <div className="more-Coache">
                                            {index !== 0 && (
                                              <h3 className="h-20">
                                                Coach {index + 1}
                                              </h3>
                                            )}

                                            <Col lg={6} className="text-end">
                                              {index !== 0 && (
                                                <button
                                                  type="button"
                                                  style={{
                                                    border: "none",
                                                    background: "none",
                                                  }}
                                                  onClick={() => remove(index)}
                                                >
                                                  <svg
                                                    width="28"
                                                    height="28"
                                                    viewBox="0 0 28 28"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      fill-rule="evenodd"
                                                      clip-rule="evenodd"
                                                      d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                      fill="#4C8BB4"
                                                    />
                                                  </svg>
                                                </button>
                                              )}
                                            </Col>
                                          </div>
                                          <Row>
                                            <Col lg={12} className="this-team">
                                              <p className="mb-2">Full Name</p>
                                              <Form.Group className="mb-3">
                                                {/* <Field
                                                  as={Form.Control}
                                                  type="text"
                                                  name={`coaches[${index}].coach_id`}
                                                  placeholder="Enter name"
                                                /> */}
                                                <Select
                                                  className={`form-control`}
                                                  name={`coaches.${index}.coach_id`}
                                                  placeholder="Select"
                                                  classNamePrefix="custom-select"
                                                  options={coachOptions}
                                                  onChange={(option) =>
                                                    setFieldValue(
                                                      `coaches.${index}.coach_id`,
                                                      option ? option.value : ""
                                                    )
                                                  }
                                                  value={coachOptions?.find(
                                                    (elem) =>
                                                      elem.value ==
                                                      values.coaches[index]
                                                        .coach_id?._id
                                                  )}
                                                />
                                              </Form.Group>
                                              <ErrorMessage
                                                name={`coaches[${index}].coach_id`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </Col>
                                          </Row>

                                          <div className="  this-team">
                                            <p className="h-20 ">
                                              Who Brought Coach to Current Team
                                            </p>
                                            <div className="cmn-form-fields mt-2">
                                              <Row>
                                                <Col lg={6}>
                                                  <div className="licensed-sec this-team">
                                                    <p>Me</p>
                                                    <div className="form-check form-switch">
                                                      <Field
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`coaches[${index}].who_brought_coach`}
                                                        value={"0"}
                                                        as="input"
                                                        checked={
                                                          player.who_brought_coach ==
                                                          "0"
                                                        }
                                                        onChange={() =>
                                                          setFieldValue(
                                                            `coaches[${index}].who_brought_coach`,
                                                            "0"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </Col>

                                                <Col lg={6}>
                                                  <div className="licensed-sec this-team">
                                                    <p>The Associate Agent</p>
                                                    <div className="form-check form-switch">
                                                      <Field
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`coaches[${index}].who_brought_coach`}
                                                        value={"1"}
                                                        as="input"
                                                        checked={
                                                          player.who_brought_coach ==
                                                          "1"
                                                        }
                                                        onChange={() =>
                                                          setFieldValue(
                                                            `coaches[${index}].who_brought_coach`,
                                                            "1"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </Col>
                                                <ErrorMessage
                                                  name={`coaches[${index}].who_brought_coach`}
                                                  component="div"
                                                  className="text-danger"
                                                />
                                              </Row>
                                            </div>
                                          </div>

                                          {player.who_brought_coach == "1" && (
                                            <div>
                                              <Row>
                                                <Col lg={6}>
                                                  <div className=" mt-4">
                                                    {/* <h3 className="h-20">
                                                  Name of Associate Agent
                                                </h3> */}
                                                    <div className="cmn-form-fields mt-2">
                                                      {/* <Col lg={4}>
                                                      <Form.Group className="mb-3">
                                                        <Field
                                                          type="text"
                                                          className="form-control"
                                                          placeholder="Enter name"
                                                          name={`coaches[${index}].coach_associate_agent_id`}
                                                          // as={Field}
                                                        />
                                                        <Select
                                                          className="form-control"
                                                          name={`coaches[${index}].coach_associate_agent_id`}
                                                          placeholder="Enter name"
                                                          options={
                                                            associateAgentOptions
                                                          }
                                                          onChange={(option) =>
                                                            setFieldValue(
                                                              `coaches[${index}].coach_associate_agent_id`,
                                                              option
                                                                ? option.value
                                                                : ""
                                                            )
                                                          }
                                                          value={associateAgentOptions?.find(
                                                            (elem) =>
                                                              elem.value ==
                                                              values.coaches[
                                                                index
                                                              ]
                                                                .coach_associate_agent_id
                                                          )}
                                                        />
                                                        <ErrorMessage
                                                          name={`coaches[${index}].coach_associate_agent_id`}
                                                          component="div"
                                                          className="text-danger"
                                                        />
                                                      </Form.Group>
                                                    </Col> */}

                                                      <div className="">
                                                        <div className="d-flex justify-content-between    this-team">
                                                          <p className="h-20">
                                                            Name of Associate
                                                            Agent
                                                          </p>
                                                        </div>
                                                        <div className="cmn-form-fields mt-2">
                                                          <Form.Group className=" form-inner">
                                                            <div>
                                                              <Select
                                                                name={`coaches[${index}].coach_associate_agent_id`}
                                                                // name="associate_agent_id"
                                                                placeholder="Select"
                                                                classNamePrefix="custom-select"
                                                                options={
                                                                  customOptions
                                                                }
                                                                components={{
                                                                  DropdownIndicator:
                                                                    null,
                                                                }}
                                                                onChange={(e) =>
                                                                  handleSelectChange(
                                                                    e,
                                                                    setFieldValue,
                                                                    index,
                                                                    "coache"
                                                                  )
                                                                }
                                                                onInputChange={
                                                                  handleInputChange
                                                                }
                                                                value={
                                                                  values
                                                                    ?.coaches[
                                                                    index
                                                                  ]
                                                                    .coach_associate_agent_id ==
                                                                  ""
                                                                    ? ""
                                                                    : agentOptions?.find(
                                                                        (
                                                                          elem
                                                                        ) =>
                                                                          elem.value ==
                                                                          values
                                                                            .coaches[
                                                                            index
                                                                          ]
                                                                            .coach_associate_agent_id?._id
                                                                      )
                                                                }
                                                                isClearable
                                                                formatOptionLabel={(
                                                                  data
                                                                ) =>
                                                                  data.isAgentTeam ? (
                                                                    <div
                                                                      style={{
                                                                        color:
                                                                          "#103D56",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                      onClick={() =>
                                                                        setIsModalOpen(
                                                                          true
                                                                        )
                                                                      }
                                                                    >
                                                                      {" "}
                                                                      + Add new
                                                                      Agent
                                                                    </div>
                                                                  ) : (
                                                                    data.label
                                                                  )
                                                                }
                                                                noOptionsMessage={() =>
                                                                  isAddingTeam ? (
                                                                    <div
                                                                      className="modal-add-teams"
                                                                      style={{
                                                                        color:
                                                                          "#103D56",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                      onClick={() =>
                                                                        setIsModalOpen(
                                                                          true
                                                                        )
                                                                      }
                                                                    >
                                                                      + Add new
                                                                      Agent
                                                                    </div>
                                                                  ) : (
                                                                    "No options found"
                                                                  )
                                                                }
                                                              />

                                                              {/* Modal for Adding a New Team */}
                                                              <Modal
                                                                isOpen={
                                                                  isModalOpen
                                                                }
                                                                onRequestClose={() =>
                                                                  setIsModalOpen(
                                                                    false
                                                                  )
                                                                }
                                                                contentLabel="Add New Agent"
                                                                style={{
                                                                  content: {
                                                                    top: "50%",
                                                                    left: "50%",
                                                                    right:
                                                                      "auto",
                                                                    bottom:
                                                                      "auto",
                                                                    marginRight:
                                                                      "-50%",
                                                                    transform:
                                                                      "translate(-50%, -50%)",
                                                                    width:
                                                                      "100%",
                                                                    maxWidth:
                                                                      "500px",
                                                                  },
                                                                }}
                                                              >
                                                                <div className="heding-add-new">
                                                                  <h3>
                                                                    Add New
                                                                    Agent
                                                                  </h3>
                                                                </div>
                                                                <div className="add-new-input">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter New Agent Name"
                                                                    value={
                                                                      newTeamName
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      setNewTeamName(
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                    onKeyPress={(
                                                                      event
                                                                    ) => {
                                                                      const regex =
                                                                        /^[a-zA-Z\s]*$/;
                                                                      if (
                                                                        !regex.test(
                                                                          event.key
                                                                        )
                                                                      ) {
                                                                        event.preventDefault();
                                                                      }
                                                                    }}
                                                                    onPaste={(
                                                                      event
                                                                    ) => {
                                                                      const pastedData =
                                                                        event.clipboardData.getData(
                                                                          "Text"
                                                                        );
                                                                      const regex =
                                                                        /^[a-zA-Z\s]*$/;
                                                                      if (
                                                                        !regex.test(
                                                                          pastedData
                                                                        )
                                                                      ) {
                                                                        event.preventDefault();
                                                                      }
                                                                    }}
                                                                  />
                                                                </div>
                                                                <div className="add-team-btn ">
                                                                  <button
                                                                    className="add-modal-btn-ps"
                                                                    onClick={() =>
                                                                      handleAddTeam(
                                                                        values,
                                                                        setFieldValue,
                                                                        index,
                                                                        "coache"
                                                                      )
                                                                    }
                                                                  >
                                                                    Add
                                                                  </button>
                                                                  <button
                                                                    onClick={() =>
                                                                      setIsModalOpen(
                                                                        false
                                                                      )
                                                                    }
                                                                  >
                                                                    Cancel
                                                                  </button>
                                                                </div>
                                                              </Modal>
                                                            </div>

                                                            <ErrorMessage
                                                              name={`coaches[${index}].coach_associate_agent_id`}
                                                              // name="associate_agent_id"
                                                              component="div"
                                                              className="text-danger"
                                                            />
                                                          </Form.Group>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Col>
                                                <Col lg={6}>
                                                  <div className="mt-4  this-team">
                                                    <p className="h-20">
                                                      Agent’s Fees Charged
                                                    </p>
                                                    <div className="cmn-form-fields mt-2">
                                                      <Form.Group className="mb-3">
                                                        <Form.Control
                                                          type="number"
                                                          placeholder="Agent’s Fees Charged"
                                                          name={`coaches[${index}].coach_associate_agent_fee_charged`}
                                                          as={Field}
                                                        />
                                                        <ErrorMessage
                                                          name={`coaches[${index}].coach_associate_agent_fee_charged`}
                                                          component="div"
                                                          className="text-danger"
                                                        />
                                                      </Form.Group>
                                                    </div>
                                                  </div>
                                                </Col>
                                              </Row>

                                              <div className="cmn-form-fields mt-2">
                                                <Row>
                                                  <Col lg={6}>
                                                    <div className="d-flex justify-content-between mb-2    this-team">
                                                      <p className="h-20">
                                                        Agent's Fees Received
                                                      </p>
                                                    </div>
                                                    <Form.Group className="mb-3">
                                                      <Form.Control
                                                        type="number"
                                                        placeholder="Agent’s Fees Received"
                                                        name={`coaches[${index}].coach_associate_agent_fee_received`}
                                                        as={Field}
                                                      />
                                                      <ErrorMessage
                                                        name={`coaches[${index}].coach_associate_agent_fee_received`}
                                                        component="div"
                                                        className="text-danger"
                                                      />
                                                    </Form.Group>
                                                  </Col>
                                                  <Col lg={6}>
                                                    <Form.Group className="mb-3">
                                                      <div className="  this-team">
                                                        <p className="h-20 mb-2">
                                                          Received On
                                                        </p>
                                                      </div>
                                                      <Form.Control
                                                        name={`coaches[${index}].coach_associate_agent_fee_received_on`}
                                                        placeholder="Received On"
                                                        as={Field}
                                                        type={inputType1}
                                                        onFocus={() =>
                                                          setInputType1("date")
                                                        }
                                                        onBlur={() =>
                                                          setInputType1("text")
                                                        }
                                                      />
                                                      <ErrorMessage
                                                        name={`coaches[${index}].coach_associate_agent_fee_received_on`}
                                                        component="div"
                                                        className="text-danger"
                                                      />
                                                    </Form.Group>
                                                  </Col>
                                                </Row>
                                              </div>

                                              <div className=" ">
                                                <div className="cmn-form-fields mt-2">
                                                  <Row>
                                                    <Col lg={6}>
                                                      <div className="d-flex justify-content-between mb-2    this-team">
                                                        <p className="h-20">
                                                          Agent’s Fees Pending
                                                        </p>
                                                      </div>
                                                      <Form.Group className="mb-3">
                                                        <Form.Control
                                                          type="number"
                                                          placeholder="Agent’s Fees Pending"
                                                          name={`coaches[${index}].coach_associate_agent_fee_pending`}
                                                          as={Field}
                                                        />
                                                        <ErrorMessage
                                                          name={`coaches[${index}].coach_associate_agent_fee_pending`}
                                                          component="div"
                                                          className="text-danger"
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                      <Form.Group className="mb-3">
                                                        <div className="d-flex justify-content-between  mb-2  this-team">
                                                          <p className="h-20">
                                                            Will Receive on
                                                          </p>
                                                        </div>
                                                        <Form.Control
                                                          name={`coaches[${index}].coach_associate_agent_fee_will_received_on`}
                                                          placeholder="Will Receive On"
                                                          as={Field}
                                                          type={inputType}
                                                          onFocus={() =>
                                                            setInputType("date")
                                                          }
                                                          onBlur={() =>
                                                            setInputType("text")
                                                          }
                                                        />
                                                        <ErrorMessage
                                                          name={`coaches[${index}].coach_associate_agent_fee_will_received_on`}
                                                          component="div"
                                                          className="text-danger"
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </div>

                                              <div className=" ">
                                                <div className="cmn-form-fields mt-2">
                                                  <Row>
                                                    <Col lg={6}>
                                                      <div className="d-flex justify-content-between mb-2    this-team">
                                                        <p className="h-20">
                                                          Team’s Contact Person
                                                        </p>
                                                      </div>
                                                      <Form.Group className="mb-3">
                                                        <Form.Control
                                                          type="text"
                                                          placeholder="Team Contact Person Name"
                                                          name={`coaches[${index}].coach_team_contact_person_name`}
                                                          as={Field}
                                                        />
                                                        <ErrorMessage
                                                          name={`coaches[${index}].coach_team_contact_person_name`}
                                                          component="div"
                                                          className="text-danger"
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                      <Form.Group className="">
                                                        <div className="d-flex justify-content-between  mb-2  this-team">
                                                          <p className="h-20">
                                                            Contact Number
                                                          </p>
                                                        </div>
                                                        <Form.Control
                                                          type="text"
                                                          placeholder="Contact Phone Number"
                                                          name={`coaches[${index}].coach_team_contact_person_phone_number`}
                                                          as={Field}
                                                        />
                                                        <ErrorMessage
                                                          name={`coaches[${index}].coach_team_contact_person_phone_number`}
                                                          component="div"
                                                          className="text-danger"
                                                        />
                                                      </Form.Group>
                                                    </Col>

                                                    {/* <Col lg={4}>
                                                      <Form.Group className="mb-3">
                                                        <Form.Control
                                                          type="text"
                                                          placeholder="Enter Status"
                                                          name={`coaches[${index}].coach_team_contact_person_status`}
                                                          as={Field}
                                                        />
                                                        <ErrorMessage
                                                          name={`coaches[${index}].coach_team_contact_person_status`}
                                                          component="div"
                                                          className="text-danger"
                                                        />
                                                      </Form.Group>
                                                    </Col> */}
                                                  </Row>
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          <div className="">
                                            <div className="cmn-form-fields ">
                                              <div className="this-team">
                                                <p className="h-20 mb-2">
                                                  Other Notes
                                                </p>
                                              </div>
                                              <Form.Group className="mb-3">
                                                <Field
                                                  as="textarea"
                                                  name={`coaches[${index}].coach_other_notes`}
                                                  rows={3}
                                                  placeholder="Write Notes"
                                                  className="form-control"
                                                />
                                                <ErrorMessage
                                                  name={`coaches[${index}].coach_other_notes`}
                                                  component="div"
                                                  className="text-danger"
                                                />
                                              </Form.Group>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>

                      {/* second Part */}
                      <Col lg={6}>
                        <div>
                          <div className=" ">
                            <div className="d-flex justify-content-between this-team">
                              <p className="h-20">
                                Coaches/Managers I have proposed to this Team
                              </p>
                            </div>
                            <div className="cmn-form-fields mt-2">
                              <Row>
                                <Col lg={6}>
                                  <div className="licensed-sec this-team">
                                    <p>Yes</p>
                                    <div className="form-check form-switch">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        value={"1"}
                                        name="has_proposed_coach"
                                        checked={values.has_proposed_coach == 1}
                                        onChange={() =>
                                          setFieldValue("has_proposed_coach", 1)
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="licensed-sec this-team">
                                    <p>No</p>
                                    <div className="form-check form-switch">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        value={"0"}
                                        name="has_proposed_coach "
                                        checked={values.has_proposed_coach == 0}
                                        onChange={() =>
                                          setFieldValue("has_proposed_coach", 0)
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <ErrorMessage
                                  name="has_proposed_coach"
                                  component="div"
                                  className="text-danger"
                                />
                              </Row>
                            </div>
                          </div>
                          {values.has_proposed_coach == 1 && (
                            <div className="">
                              <div className="d-flex justify-content-between">
                                {/* <h3 className="h-20">Players</h3> */}
                                <button
                                  type="button"
                                  style={{ background: "none", border: "none" }}
                                >
                                  <svg
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    {/* SVG content */}
                                  </svg>
                                </button>
                              </div>
                              <div className="cmn-form-fields mt-2">
                                <FieldArray name="proposed_coaches">
                                  {({ push, remove }) => (
                                    <>
                                      <div className="d-flex justify-content-between">
                                        {" "}
                                        <h3 className="h-20">Coach 1</h3>
                                        <button
                                          type="button"
                                          style={{
                                            border: "none",
                                            background: "none",
                                          }}
                                          onClick={() =>
                                            push({
                                              proposed_coach_id: "",
                                              who_bought_proposed_coach: "0",
                                              coach_proposed_associate_agent_id:
                                                "",
                                              coach_proposed_team_other_notes:
                                                "",
                                            })
                                          }
                                        >
                                          <svg
                                            width="28"
                                            coaching_licenses="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                              fill="#4C8BB4"
                                            />
                                          </svg>
                                        </button>
                                      </div>

                                      {values.proposed_coaches.map(
                                        (player, index) => (
                                          <div key={index}>
                                            <div
                                              className={`more-coache ${
                                                index > 0 ? "mt-3" : ""
                                              }`}
                                            >
                                              {index !== 0 && (
                                                <h3 className="h-20">
                                                  Coach {index + 1}
                                                </h3>
                                              )}
                                              <Col lg={6} className="text-end">
                                                {index !== 0 && (
                                                  <button
                                                    type="button"
                                                    style={{
                                                      border: "none",
                                                      background: "none",
                                                    }}
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    <svg
                                                      width="28"
                                                      height="28"
                                                      viewBox="0 0 28 28"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                        fill="#4C8BB4"
                                                      />
                                                    </svg>
                                                  </button>
                                                )}
                                              </Col>
                                            </div>
                                            <Row>
                                              <Col lg={12}>
                                                <Form.Group className="this-team">
                                                  <p className="mb-2">
                                                    Full Name
                                                  </p>
                                                  {/* <Field
                                                    as={Form.Control}
                                                    type="text"
                                                    name={`proposed_coaches[${index}].proposed_coach_id`}
                                                    placeholder="Enter name"
                                                  /> */}

                                                  <Select
                                                    className={`form-control`}
                                                    name={`proposed_coaches.${index}.proposed_coach_id`}
                                                    placeholder="Select"
                                                    classNamePrefix="custom-select"
                                                    options={coachOptions}
                                                    onChange={(option) =>
                                                      setFieldValue(
                                                        `proposed_coaches.${index}.proposed_coach_id`,
                                                        option
                                                          ? option.value
                                                          : ""
                                                      )
                                                    }
                                                    value={coachOptions?.find(
                                                      (elem) =>
                                                        elem.value ==
                                                        values.proposed_coaches[
                                                          index
                                                        ].proposed_coach_id?._id
                                                    )}
                                                  />
                                                </Form.Group>
                                                <ErrorMessage
                                                  name={`proposed_coaches[${index}].proposed_coach_id`}
                                                  component="div"
                                                  className="text-danger"
                                                />
                                              </Col>
                                            </Row>

                                            <div className=" mt-4 this-team">
                                              <p className="h-20">
                                                Who Proposed Coach to this team
                                              </p>
                                              <div className="cmn-form-fields mt-2">
                                                <Row>
                                                  <Col lg={6}>
                                                    <div className="licensed-sec this-team">
                                                      <p>Me</p>
                                                      <div className="form-check form-switch">
                                                        <Field
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`proposed_coaches[${index}].who_bought_proposed_coach`}
                                                          value={"0"}
                                                          as="input"
                                                          checked={
                                                            player.who_bought_proposed_coach ==
                                                            "0"
                                                          }
                                                          onChange={() =>
                                                            setFieldValue(
                                                              `proposed_coaches[${index}].who_bought_proposed_coach`,
                                                              "0"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </Col>

                                                  <Col lg={6}>
                                                    <div className="licensed-sec this-team">
                                                      <p>Associate agent’s</p>
                                                      <div className="form-check form-switch">
                                                        <Field
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`proposed_coaches[${index}].who_bought_proposed_coach`}
                                                          value={"1"}
                                                          as="input"
                                                          checked={
                                                            player.who_bought_proposed_coach ==
                                                            "1"
                                                          }
                                                          onChange={() =>
                                                            setFieldValue(
                                                              `proposed_coaches[${index}].who_bought_proposed_coach`,
                                                              "1"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </Col>
                                                  <ErrorMessage
                                                    name={`proposed_coaches[${index}].who_bought_proposed_coach`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </Row>
                                              </div>
                                            </div>

                                            {player.who_bought_proposed_coach ==
                                              "1" && (
                                              <div>
                                                <div className=" mt-4">
                                                  <div className="cmn-form-fields mt-2">
                                                    <Row>
                                                      {/* <Col lg={4}>
                                                        <Form.Group className="mb-3">
                                                          <Field
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter name"
                                                  name={`proposed_coaches.${index}.coach_proposed_associate_agent_id`}
                                                />

                                                          <Select
                                                            className="form-control"
                                                            name={`proposed_coaches[${index}].coach_proposed_associate_agent_id`}
                                                            placeholder="Enter name"
                                                            options={
                                                              associateAgentOptions
                                                            }
                                                            onChange={(
                                                              option
                                                            ) =>
                                                              setFieldValue(
                                                                `proposed_coaches[${index}].coach_proposed_associate_agent_id`,
                                                                option
                                                                  ? option.value
                                                                  : ""
                                                              )
                                                            }
                                                            value={associateAgentOptions?.find(
                                                              (elem) =>
                                                                elem.value ==
                                                                values
                                                                  .proposed_coaches[
                                                                  index
                                                                ]
                                                                  .coach_proposed_associate_agent_id
                                                            )}
                                                          />
                                                          <ErrorMessage
                                                            name={`proposed_coaches.${index}.coach_proposed_associate_agent_id`}
                                                            component="div"
                                                            className="text-danger"
                                                          />
                                                        </Form.Group>
                                                      </Col> */}

                                                      <Col lg={6}>
                                                        <div className="">
                                                          <div className="d-flex justify-content-between    this-team">
                                                            <p className="h-20">
                                                              Name of Associate
                                                              Agent
                                                            </p>
                                                          </div>
                                                          <div className="cmn-form-fields mt-2">
                                                            <Form.Group className=" form-inner">
                                                              <div>
                                                                <Select
                                                                  name={`proposed_coaches.${index}.coach_proposed_associate_agent_id`}
                                                                  // name="associate_agent_id"
                                                                  placeholder="Select"
                                                                  classNamePrefix="custom-select"
                                                                  options={
                                                                    customOptions
                                                                  }
                                                                  components={{
                                                                    DropdownIndicator:
                                                                      null,
                                                                  }}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleSelectChange(
                                                                      e,
                                                                      setFieldValue,
                                                                      index,
                                                                      "proposed"
                                                                    )
                                                                  }
                                                                  onInputChange={
                                                                    handleInputChange
                                                                  }
                                                                  value={
                                                                    values
                                                                      ?.proposed_coaches[
                                                                      index
                                                                    ]
                                                                      .coach_proposed_associate_agent_id ==
                                                                    ""
                                                                      ? ""
                                                                      : agentOptions?.find(
                                                                          (
                                                                            elem
                                                                          ) =>
                                                                            elem.value ==
                                                                            values
                                                                              .proposed_coaches[
                                                                              index
                                                                            ]
                                                                              .coach_proposed_associate_agent_id?._id
                                                                        )
                                                                  }
                                                                  isClearable
                                                                  formatOptionLabel={(
                                                                    data
                                                                  ) =>
                                                                    data.isAgentTeam ? (
                                                                      <div
                                                                        style={{
                                                                          color:
                                                                            "#103D56",
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                        onClick={() =>
                                                                          setIsModalOpen2(
                                                                            true
                                                                          )
                                                                        }
                                                                      >
                                                                        {" "}
                                                                        + Add
                                                                        new
                                                                        Agent
                                                                      </div>
                                                                    ) : (
                                                                      data.label
                                                                    )
                                                                  }
                                                                  noOptionsMessage={() =>
                                                                    isAddingTeam ? (
                                                                      <div
                                                                        className="modal-add-teams"
                                                                        style={{
                                                                          color:
                                                                            "#103D56",
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                        onClick={() =>
                                                                          setIsModalOpen2(
                                                                            true
                                                                          )
                                                                        }
                                                                      >
                                                                        + Add
                                                                        new
                                                                        Agent
                                                                      </div>
                                                                    ) : (
                                                                      "No options found"
                                                                    )
                                                                  }
                                                                />

                                                                {/* Modal for Adding a New Team */}
                                                                <Modal
                                                                  isOpen={
                                                                    isModalOpen2
                                                                  }
                                                                  onRequestClose={() =>
                                                                    setIsModalOpen2(
                                                                      false
                                                                    )
                                                                  }
                                                                  contentLabel="Add New Agent"
                                                                  style={{
                                                                    content: {
                                                                      top: "50%",
                                                                      left: "50%",
                                                                      right:
                                                                        "auto",
                                                                      bottom:
                                                                        "auto",
                                                                      marginRight:
                                                                        "-50%",
                                                                      transform:
                                                                        "translate(-50%, -50%)",
                                                                      width:
                                                                        "100%",
                                                                      maxWidth:
                                                                        "500px",
                                                                    },
                                                                  }}
                                                                >
                                                                  <div className="heding-add-new">
                                                                    <h3>
                                                                      Add New
                                                                      Agent
                                                                    </h3>
                                                                  </div>
                                                                  <div className="add-new-input">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      placeholder="Enter New Agent Name"
                                                                      value={
                                                                        newTeamName
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        setNewTeamName(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        )
                                                                      }
                                                                      onKeyPress={(
                                                                        event
                                                                      ) => {
                                                                        const regex =
                                                                          /^[a-zA-Z\s]*$/;
                                                                        if (
                                                                          !regex.test(
                                                                            event.key
                                                                          )
                                                                        ) {
                                                                          event.preventDefault();
                                                                        }
                                                                      }}
                                                                      onPaste={(
                                                                        event
                                                                      ) => {
                                                                        const pastedData =
                                                                          event.clipboardData.getData(
                                                                            "Text"
                                                                          );
                                                                        const regex =
                                                                          /^[a-zA-Z\s]*$/;
                                                                        if (
                                                                          !regex.test(
                                                                            pastedData
                                                                          )
                                                                        ) {
                                                                          event.preventDefault();
                                                                        }
                                                                      }}
                                                                    />
                                                                  </div>
                                                                  <div className="add-team-btn ">
                                                                    <button
                                                                      className="add-modal-btn-ps"
                                                                      onClick={() =>
                                                                        handleAddTeam(
                                                                          values,
                                                                          setFieldValue,
                                                                          index,
                                                                          "proposed"
                                                                        )
                                                                      }
                                                                    >
                                                                      Add
                                                                    </button>
                                                                    <button
                                                                      onClick={() =>
                                                                        setIsModalOpen2(
                                                                          false
                                                                        )
                                                                      }
                                                                    >
                                                                      Cancel
                                                                    </button>
                                                                  </div>
                                                                </Modal>
                                                              </div>

                                                              <ErrorMessage
                                                                name={`proposed_coaches.${index}.coach_proposed_associate_agent_id`}
                                                                // name="associate_agent_id"
                                                                component="div"
                                                                className="text-danger"
                                                              />
                                                            </Form.Group>
                                                          </div>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </div>
                                              </div>
                                            )}

                                            <div className="">
                                              <div className="cmn-form-fields ">
                                                <div className="this-team">
                                                  <p className="h-20 mb-2">
                                                    Other Notes
                                                  </p>
                                                </div>
                                                <Form.Group className="mb-3">
                                                  <Field
                                                    as="textarea"
                                                    name={`proposed_coaches[${index}].coach_proposed_team_other_notes`}
                                                    rows={3}
                                                    placeholder="Write Notes"
                                                    className="form-control"
                                                  />
                                                  <ErrorMessage
                                                    name={`proposed_coaches[${index}].coach_proposed_team_other_notes`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </Form.Group>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* <div className=" mt-4">
                    <h3 className="h-20">Other Notes</h3>
                    <div className="cmn-form-fields mt-2">
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3 input-plc-mk">
                            <Field
                              as="textarea"
                              name={`coach_other_notes`}
                              rows={3}
                              placeholder="Write notes here"
                              className="form-control"
                            />
                            <ErrorMessage
                              name={`coach_other_notes`}
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div> */}
                  <div className="d-flex justify-content-between mb-5">
                    <div className="d-flex">
                      <div className="mt-4 cmn-light-btn ">
                        <button
                          className="button"
                          type="button"
                          onClick={() => navigate(-1)}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mt-4 me-2 cmn-light-btn save-and-close-btn">
                        <button
                          type="button"
                          onClick={() =>
                            handleSaveAndClose(values, validateForm)
                          }
                          className="next-btn-ps"
                        >
                          Save & Close
                        </button>
                      </div>
                      <div className="mt-4 cmn-light-btn">
                        <button type="submit" className="next-btn-ps">
                          {" "}
                          Save & Next
                        </button>
                      </div>
                    </div>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default AddTeamSix;
