import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import {
  Formik,
  Field,
  FieldArray,
  Form as FormikForm,
  ErrorMessage,
} from "formik";
import * as Yup from "yup";
import Modal from "react-modal";
import LoginLayout from "../components/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Decryptedid, Encryptedid } from "../utils/Becrypt";
import {
  editTeamAction,
  getTeamDataById,
  getTeamListAction,
} from "../Redux/Action/teamAction";
import toast from "react-hot-toast";
import Select from "react-select";
import { getAgentListAction } from "../Redux/Action/agentAction";
import { addNewTeam, getPlayerListAction } from "../Redux/Action/playerAction";
import ProgressBar from "../CommonModals/ProgressBar";

const AddTeamTwo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const teamId = id && Decryptedid(atob(id));

  const [inputType1, setInputType1] = useState("text");
  const [inputType, setInputType] = useState("text");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [newTeamName, setNewTeamName] = useState("");

  const getTeamDetails = useSelector((e) => e.teamAuthData.getTeamDetailById);
  const teanListingData = useSelector((e) => e.teamAuthData.getTeamListing);
  const playerListData = useSelector((e) => e.playerAuthData.playerList);

  const agentListingData = useSelector((e) => e.agentAuthData.agentListDetail);

  const associateAgentOptions = agentListingData?.map((elem) => ({
    label: elem?.agent_name,
    value: elem?._id,
  }));

  const playerOptions = playerListData?.map((elem) => ({
    label: elem?.full_name,
    value: elem?._id,
  }));

  const teamOptions = teanListingData?.map((elem) => ({
    label: elem?.team_name,
    value: elem?._id,
  }));
  const [agentOptions, setAgentOptions] = useState([]);

  useEffect(() => {
    if (agentListingData) {
      setAgentOptions(
        agentListingData?.map((elem) => ({
          label: elem?.agent_name,
          value: elem?._id,
        })) || []
      );
    }
  }, [agentListingData]);

  console.log("agentListingData", agentListingData);
  console.log("agentOptions", agentOptions);

  const customOptions = [
    { label: "+ Add new Agent", value: "add-agent", isAgentTeam: true },
    ...agentOptions,
  ];

  const handleSelectChange = (option, setFieldValue, index, type) => {
    if (option?.isAgentTeam) {
      if (type === "player") {
        setIsModalOpen(true);
      } else if (type === "proposed") {
        setIsModalOpen2(true);
      } else if (type === "proposed_team") {
        setIsModalOpen3(true);
      }
    } else {
      if (type === "player") {
        setFieldValue(
          `players[${index}].player_associate_agent_id`,
          option.value
        );
      } else if (type === "proposed") {
        setFieldValue(
          `proposed_players[${index}].proposed_player_associate_agent_id`,
          option.value
        );
      } else if (type === "proposed_team") {
        setFieldValue(
          `proposed_team_players[${index}].team_proposed_player_associate_agent_id`,
          option.value
        );
      }
    }
  };

  const handleInputChange = (input) => {
    const filteredInput = input.replace(/[^a-zA-Z\s]/g, "");
    const isTeamExists = agentOptions?.some((team) =>
      team.label.toLowerCase()?.includes(filteredInput.toLowerCase())
    );
    setIsAddingTeam(!isTeamExists);
    return filteredInput;
  };

  const handleAddTeam = (values, setFieldValue, index, fieldType) => {
    if (!newTeamName.trim()) {
      toast.error("Agent name cannot be empty.");
      return;
    }

    dispatch(addNewTeam({ name: newTeamName, type: 0 })).then((res) => {
      if (res?.payload?.status === 200) {
        const newTeamId = res?.payload?.data?._id;
        const newTeam = { label: newTeamName, value: newTeamId };
        setAgentOptions([...agentOptions, newTeam]);

        if (fieldType === "player") {
          setFieldValue(
            `players[${index}].player_associate_agent_id`,
            newTeam.value
          );
          setIsModalOpen(false);
        }
        if (fieldType === "proposed") {
          setFieldValue(
            `proposed_players[${index}].proposed_player_associate_agent_id`,
            newTeam.value
          );
          setIsModalOpen2(false);
        }
        if (fieldType === "proposed_team") {
          setFieldValue(
            `proposed_team_players[${index}].team_proposed_player_associate_agent_id`,
            newTeam.value
          );
          setIsModalOpen3(false);
        }

        // setFieldValue("associate_agent_id", newTeam.value);

        // setIsFree(true);
        setNewTeamName("");
      } else {
        toast.error("Failed to add the team. Please try again.");
      }
    });
  };

  const validate = (values) => {
    const errors = {};

    if (values.has_player_in_team === null) {
      errors.has_player_in_team = "required";
    }

    if (values.has_player_in_team == "1") {
      if (values.players) {
        const playerErrors = [];

        values.players.forEach((player, index) => {
          const playerError = {};

          if (!player.player_id) {
            playerError.player_id = "required";
          }
          if (!player.who_brought_player) {
            playerError.who_brought_player = "required";
          }

          if (player.who_brought_player === "1") {
            if (!player.player_associate_agent_id) {
              playerError.player_associate_agent_id = "required";
            }
            if (!player.player_associate_agent_fee_charged) {
              playerError.player_associate_agent_fee_charged = "required";
            }
            if (!player.player_associate_agent_fee_received) {
              playerError.player_associate_agent_fee_received = "required";
            }
            if (!player.player_associate_agent_fee_received_on) {
              playerError.player_associate_agent_fee_received_on = "required";
            }
            if (!player.player_associate_agent_fee_pending) {
              playerError.player_associate_agent_fee_pending = "required";
            }
            if (!player.player_associate_agent_fee_will_received_on) {
              playerError.player_associate_agent_fee_will_received_on =
                "required";
            }
            if (!player.player_team_contact_person_name) {
              playerError.player_team_contact_person_name = "required";
            }
            if (!player.player_team_contact_person_phone_number) {
              playerError.player_team_contact_person_phone_number = "required";
            }
          }

          if (Object.keys(playerError).length > 0) {
            playerErrors[index] = playerError;
          }
        });

        if (playerErrors.length > 0) {
          errors.players = playerErrors;
        }
      }
    }

    if (values.has_proposed_player === null) {
      errors.has_proposed_player = "required";
    }

    if (values.has_proposed_player == 1) {
      if (values.proposed_players) {
        values.proposed_players.forEach((player, index) => {
          const playerErrors = {};

          if (!player.proposed_player_id) {
            playerErrors.proposed_player_id = "required";
          }

          if (
            player.who_bought_proposed_player == "1" &&
            !player.proposed_player_associate_agent_id
          ) {
            playerErrors.proposed_player_associate_agent_id = "required";
          }

          if (Object.keys(playerErrors).length > 0) {
            errors.proposed_players = errors.proposed_players || [];
            errors.proposed_players[index] = playerErrors;
          }
        });
      } else {
        errors.proposed_players = "required.";
      }
    }

    if (values.has_team_proposed_player === null) {
      errors.has_team_proposed_player = "required";
    }

    if (values.has_team_proposed_player == 1) {
      if (values.proposed_team_players) {
        values.proposed_team_players.forEach((player, index) => {
          const playerErrors = {};

          if (!player.team_proposed_player_id) {
            playerErrors.team_proposed_player_id = "required";
          }

          if (
            player.who_bought_team_proposed_player == "1" &&
            !player.team_proposed_player_associate_agent_id
          ) {
            playerErrors.team_proposed_player_associate_agent_id = "required";
          }

          if (Object.keys(playerErrors).length > 0) {
            errors.proposed_team_players = errors.proposed_team_players || [];
            errors.proposed_team_players[index] = playerErrors;
          }
        });
      } else {
        errors.proposed_team_players = "required.";
      }
    }

    console.log(errors, "sdfs333333333");
    return errors;
  };

  const handleSaveAndClose = (values, validateForm, setErrors) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length > 0) {
        toast.error("Please fill in all required fields.");
        setErrors(errors);
        return;
      }

      handleSubmit(values, "save");
    });
  };

  const handleBack = () => {
    navigate(`/add-team?teamId=${Encryptedid(teamId)}`);
  };

  const handleSubmit = (values, type) => {
    console.log("values", values);
    // const preparedValues = {
    //   ...values,
    //   players: values.players.map((player) => {
    //     if (player.who_brought_player === "0") {
    //       const {
    //         player_associate_agent_id,
    //         player_associate_agent_fee_charged,
    //         player_associate_agent_fee_received,
    //         player_associate_agent_fee_received_on,
    //         player_associate_agent_fee_pending,
    //         player_associate_agent_fee_will_received_on,
    //         player_team_contact_person_name,
    //         player_team_contact_person_phone_number,
    //         player_other_notes,
    //         ...rest
    //       } = player;

    //       return {
    //         ...rest,
    //         who_brought_player: player.who_brought_player,
    //       };
    //     }
    //     return player;
    //   }),
    // };
    // let formData = new FormData();

    // console.log(preparedValues, "34234234");
    // if (values.has_player_in_team == 1) {
    //   formData.append("has_player_in_team", values.has_player_in_team);
    //   formData.append("players", JSON.stringify(values.players));
    // } else {
    //   formData.append("players", preparedValues);
    //   // formData.append("has_player_in_team", values.has_player_in_team);
    // }

    // formData.append("teamId", teamId);
    // formData.append("is_profile_completed", 2);

    const preparedValues = {
      ...values,
      players: values.players.map((player) => {
        if (player.who_brought_player === "0") {
          const {
            player_associate_agent_id,
            player_associate_agent_fee_charged,
            player_associate_agent_fee_received,
            player_associate_agent_fee_received_on,
            player_associate_agent_fee_pending,
            player_associate_agent_fee_will_received_on,
            player_team_contact_person_name,
            player_team_contact_person_phone_number,
            player_other_notes,
            ...rest
          } = player;

          return {
            ...rest,
            who_brought_player: player.who_brought_player,
          };
        }
        return player;
      }),
    };

    // second part prepared
    const preparedValues2 = {
      ...values,
      proposed_players: values.proposed_players.map((player) => {
        if (player.who_bought_proposed_player === "0") {
          const { proposed_player_associate_agent_id, ...rest } = player;
          return rest;
        }
        return player;
      }),
    };

    // third part prepared
    const preparedValues3 = {
      ...values,
      proposed_team_players: values.proposed_team_players.map((player) => {
        if (player.who_bought_team_proposed_player === "0") {
          const { team_proposed_player_associate_agent_id, ...rest } = player;
          return rest;
        }
        return player;
      }),
    };

    let formData = new FormData();

    console.log("values", values);

    // formData.append("has_player_in_team", values.has_player_in_team);

    if (values.has_player_in_team === 1) {
      formData.append("has_player_in_team", values.has_player_in_team);
      formData.append("players", JSON.stringify(preparedValues.players));
      // formData.append("players", JSON.stringify(values.players));
    } else {
      formData.append("has_player_in_team", values.has_player_in_team);
    }

    // second part start--------
    if (values.has_proposed_player == 1) {
      formData.append("has_proposed_player", values.has_proposed_player);

      if (preparedValues2.proposed_players.length > 0) {
        formData.append(
          "proposed_players",
          JSON.stringify(preparedValues2.proposed_players)
        );
      }
    } else {
      formData.append("has_proposed_player", values.has_proposed_player);
    }

    // second part finish-----

    // third part start--------
    if (values.has_team_proposed_player == 1) {
      formData.append(
        "has_team_proposed_player",
        values.has_team_proposed_player
      );

      if (preparedValues3.proposed_team_players.length > 0) {
        formData.append(
          "proposed_team_players",
          JSON.stringify(preparedValues3.proposed_team_players)
        );
      }
    } else {
      formData.append(
        "has_team_proposed_player",
        values.has_team_proposed_player
      );
    }

    // third part finish-----

    formData.append("teamId", teamId);
    formData.append("is_profile_completed", 2);

    if (values?.player_other_notes) {
      formData.append("player_other_notes", values?.player_other_notes);
    }

    dispatch(editTeamAction(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        if (type == "save") {
          navigate(`/my-team`);
        } else {
          navigate(`/add-team-four/${Encryptedid(res?.payload?.data?._id)}`);
        }
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const initialValues = {
    has_team_proposed_player: getTeamDetails?.has_team_proposed_player || "0",
    proposed_team_players:
      getTeamDetails?.proposed_team_players?.length > 0
        ? getTeamDetails.proposed_team_players.map((player) => ({
            team_proposed_player_id: player.team_proposed_player_id || "",
            who_bought_team_proposed_player:
              player.who_bought_team_proposed_player || "0",
            team_proposed_player_associate_agent_id:
              player.team_proposed_player_associate_agent_id || "",
            proposed_team_other_notes: player.proposed_team_other_notes || "",
          }))
        : [
            {
              team_proposed_player_id: null,
              who_bought_team_proposed_player: "0",
              team_proposed_player_associate_agent_id: "",
              proposed_team_other_notes: "",
            },
          ],
    has_proposed_player: getTeamDetails?.has_proposed_player || "0",
    proposed_players:
      getTeamDetails?.proposed_players?.length > 0
        ? getTeamDetails.proposed_players.map((player) => ({
            proposed_player_id: player.proposed_player_id || "",
            who_bought_proposed_player:
              player.who_bought_proposed_player || "0",
            proposed_player_associate_agent_id:
              player.proposed_player_associate_agent_id || "",
            proposed_player_other_notes:
              player.proposed_player_other_notes || "",
          }))
        : [
            {
              proposed_player_id: null,
              who_bought_proposed_player: "0",
              proposed_player_associate_agent_id: "",
              proposed_player_other_notes: "",
            },
          ],
    has_player_in_team: getTeamDetails?.has_player_in_team || 0,
    players:
      getTeamDetails?.players?.length > 0
        ? getTeamDetails.players.map((elem) => ({
            player_id: elem.player_id || "",
            who_brought_player: elem.who_brought_player || "0",
            player_associate_agent_id: elem.player_associate_agent_id || "",
            player_associate_agent_fee_charged:
              elem.player_associate_agent_fee_charged || "",
            player_associate_agent_fee_received:
              elem.player_associate_agent_fee_received || "",
            player_associate_agent_fee_received_on:
              elem.player_associate_agent_fee_received_on?.split("T")[0] || "",
            player_associate_agent_fee_pending:
              elem.player_associate_agent_fee_pending || "",
            player_associate_agent_fee_will_received_on:
              elem.player_associate_agent_fee_will_received_on?.split("T")[0] ||
              "",
            player_team_contact_person_name:
              elem.player_team_contact_person_name || "",
            player_team_contact_person_phone_number:
              elem.player_team_contact_person_phone_number || "",
            player_other_notes: elem.player_other_notes || "",
          }))
        : [
            {
              player_id: "",
              who_brought_player: "0",
              player_associate_agent_id: "",
              player_associate_agent_fee_charged: "",
              player_associate_agent_fee_received: "",
              player_associate_agent_fee_received_on: "",
              player_associate_agent_fee_pending: "",
              player_associate_agent_fee_will_received_on: "",
              player_team_contact_person_name: "",
              player_team_contact_person_phone_number: "",
              player_other_notes: "",
            },
          ],
    // player_other_notes: getTeamDetails?.player_other_notes || "",
  };

  useEffect(() => {
    if (teamId) {
      dispatch(getTeamDataById({ id: teamId }));
    }
    dispatch(getTeamListAction());
    dispatch(getAgentListAction());
    dispatch(getPlayerListAction());
  }, [dispatch]);

  return (
    <LoginLayout>
      <section>
        <Container>
          <ProgressBar currentStep={2} totalSteps={5} />
          <div className="steps-heading">
            <p>
              {" "}
              <span>Step 2:</span> Transactions with Players{" "}
            </p>
          </div>
          <div className="over-ride-cmn personal-heading">
            <h3>Player's Information</h3>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validate={validate}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, errors, validateForm, setErrors }) => (
                <FormikForm>
                  {console.log(values.has_player_in_team, "34ertretertert")}
                  <div className="">
                    <Row>
                      <Col lg={6}>
                        <div>
                          <div className=" mt-4">
                            <div className="d-flex justify-content-between this-team">
                              <p className="h-20">My Player in this Team</p>
                            </div>
                            <div className="cmn-form-fields mt-2">
                              <Row>
                                <Col lg={6}>
                                  <div className="licensed-sec mb-3 this-team">
                                    <p>Yes</p>
                                    <div className="form-check form-switch">
                                      <Field
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        name="has_player_in_team"
                                        checked={
                                          values.has_player_in_team === 1
                                        }
                                        onChange={() =>
                                          setFieldValue("has_player_in_team", 1)
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="licensed-sec this-team">
                                    <p>No</p>
                                    <div className="form-check form-switch">
                                      <Field
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        name="has_player_in_team"
                                        checked={
                                          values.has_player_in_team === 0
                                        }
                                        onChange={() =>
                                          setFieldValue("has_player_in_team", 0)
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <ErrorMessage
                                  name="has_player_in_team"
                                  component="div"
                                  className="text-danger"
                                />
                              </Row>
                            </div>
                          </div>
                          {values.has_player_in_team === 1 && (
                            <div className=" ">
                              <div className="cmn-form-fields ">
                                <FieldArray name="players">
                                  {({ push, remove }) => (
                                    <>
                                      <div className="d-flex justify-content-between">
                                        {" "}
                                        <h3 className="h-20">Players 1</h3>
                                        <button
                                          type="button"
                                          style={{
                                            border: "none",
                                            background: "none",
                                          }}
                                          onClick={() =>
                                            push({
                                              player_id: "",
                                              who_brought_player: "0",
                                              player_associate_agent_id: "",
                                              player_associate_agent_fee_charged:
                                                "",
                                              player_associate_agent_fee_received:
                                                "",
                                              player_associate_agent_fee_received_on:
                                                "",
                                              player_associate_agent_fee_pending:
                                                "",
                                              player_associate_agent_fee_will_received_on:
                                                "",
                                              player_team_contact_person_name:
                                                "",
                                              player_team_contact_person_phone_number:
                                                "",
                                            })
                                          }
                                        >
                                          <svg
                                            width="28"
                                            coaching_licenses="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                              fill="#4C8BB4"
                                            />
                                          </svg>
                                        </button>
                                      </div>

                                      {values.players.map((player, index) => (
                                        <div key={index}>
                                          <div
                                            className={`more-coache ${
                                              index > 0 ? "mt-3" : ""
                                            }`}
                                          >
                                            {index !== 0 && (
                                              <h3 className="h-20">
                                                Players {index + 1}
                                              </h3>
                                            )}

                                            {index !== 0 && (
                                              <button
                                                type="button"
                                                style={{
                                                  border: "none",
                                                  background: "none",
                                                }}
                                                onClick={() => remove(index)}
                                              >
                                                <svg
                                                  width="28"
                                                  height="28"
                                                  viewBox="0 0 28 28"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                    fill="#4C8BB4"
                                                  />
                                                </svg>
                                              </button>
                                            )}
                                          </div>
                                          <Row>
                                            <Col lg={12}>
                                              <Form.Group className="mb-3 label-text-up">
                                                <label className="mb-2">
                                                  Full Name
                                                </label>
                                                {/* <Field
                                                  as={Form.Control}
                                                  type="text"
                                                  name={`players[${index}].player_id`}
                                                  placeholder="Enter Name"
                                                  // value={values.players[index].player_id}
                                                /> */}
                                                <Select
                                                  className={`form-control`}
                                                  name={`players.${index}.player_id`}
                                                  placeholder="Select"
                                                  classNamePrefix="custom-select"
                                                  options={playerOptions}
                                                  onChange={(option) =>
                                                    setFieldValue(
                                                      `players.${index}.player_id`,
                                                      option ? option.value : ""
                                                    )
                                                  }
                                                  value={playerOptions?.find(
                                                    (elem) =>
                                                      elem.value ==
                                                      values.players[index]
                                                        .player_id?._id
                                                  )}
                                                />

                                                <ErrorMessage
                                                  name={`players.${index}.player_id`}
                                                  component="div"
                                                  className="text-danger"
                                                />
                                              </Form.Group>
                                            </Col>
                                          </Row>

                                          <div className="  this-team">
                                            <p className="h-20">
                                              Who Brought Player to Current Team
                                            </p>
                                            <div className="cmn-form-fields mt-2">
                                              <Row>
                                                <Col lg={6}>
                                                  <div className="licensed-sec mb-3  this-team">
                                                    <p>Me</p>
                                                    <div className="form-check form-switch">
                                                      <Field
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`players[${index}].who_brought_player`}
                                                        // value={"0"}
                                                        value={
                                                          values.players[index]
                                                            .who_brought_player
                                                        }
                                                        as="input"
                                                        checked={
                                                          player.who_brought_player ==
                                                          "0"
                                                        }
                                                        onChange={() =>
                                                          setFieldValue(
                                                            `players[${index}].who_brought_player`,
                                                            "0"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </Col>

                                                <Col lg={6}>
                                                  <div className="licensed-sec  this-team">
                                                    <p>The Associate Agent</p>
                                                    <div className="form-check form-switch">
                                                      <Field
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`players[${index}].who_brought_player`}
                                                        // value={"1"}
                                                        value={
                                                          values.players[index]
                                                            .who_brought_player
                                                        }
                                                        as="input"
                                                        checked={
                                                          player.who_brought_player ==
                                                          "1"
                                                        }
                                                        onChange={() =>
                                                          setFieldValue(
                                                            `players[${index}].who_brought_player`,
                                                            "1"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </Col>
                                                <ErrorMessage
                                                  name={`players[${index}].who_brought_player`}
                                                  component="div"
                                                  className="text-danger"
                                                />
                                              </Row>
                                            </div>
                                          </div>

                                          {player.who_brought_player == "1" && (
                                            <div>
                                              <div className="cmn-form-fields ">
                                                <Row>
                                                  {/* <Col lg={6}>
                                                    <div className=" mb-2  this-team">
                                                      <p className="h-20">
                                                        Name of Associate Agent
                                                      </p>
                                                    </div>
                                                    <Form.Group className="mb-3">
                                                      <Field
                                                        type="text"
                                                        placeholder="Enter name"
                                                        name={`players[${index}].player_associate_agent_id`}
                                                        // as={Field}
                                                      />
                                                      <Select
                                                        name={`players[${index}].player_associate_agent_id`}
                                                        placeholder="Select"
                                                        options={
                                                          associateAgentOptions
                                                        }
                                                        // isDisabled={
                                                        //   values.player_associate_agent_id !=
                                                        //   1
                                                        // }
                                                        onChange={(option) =>
                                                          setFieldValue(
                                                            `players[${index}].player_associate_agent_id`,
                                                            option
                                                              ? option.value
                                                              : ""
                                                          )
                                                        }
                                                        value={associateAgentOptions?.find(
                                                          (elem) =>
                                                            elem.value ==
                                                            values.players[
                                                              index
                                                            ]
                                                              .player_associate_agent_id
                                                        )}
                                                      />
                                                      <ErrorMessage
                                                        name={`players[${index}].player_associate_agent_id`}
                                                        component="div"
                                                        className="text-danger"
                                                      />
                                                    </Form.Group>
                                                  </Col> */}

                                                  <Col lg={6}>
                                                    <div className="">
                                                      <div className="d-flex justify-content-between    this-team">
                                                        <p className="h-20">
                                                          Name of Associate
                                                          Agent
                                                        </p>
                                                      </div>
                                                      <div className="cmn-form-fields mt-2">
                                                        <Form.Group className=" form-inner">
                                                          <div>
                                                            <Select
                                                              name={`players[${index}].player_associate_agent_id`}
                                                              // name="associate_agent_id"
                                                              placeholder="Select"
                                                              classNamePrefix="custom-select"
                                                              options={
                                                                customOptions
                                                              }
                                                              components={{
                                                                DropdownIndicator:
                                                                  null,
                                                              }}
                                                              onChange={(e) =>
                                                                handleSelectChange(
                                                                  e,
                                                                  setFieldValue,
                                                                  index,
                                                                  "player"
                                                                )
                                                              }
                                                              onInputChange={
                                                                handleInputChange
                                                              }
                                                              value={
                                                                values?.players[
                                                                  index
                                                                ]
                                                                  .player_associate_agent_id ==
                                                                ""
                                                                  ? ""
                                                                  : agentOptions?.find(
                                                                      (elem) =>
                                                                        elem.value ==
                                                                        values
                                                                          .players[
                                                                          index
                                                                        ]
                                                                          .player_associate_agent_id?._id
                                                                    )
                                                              }
                                                              isClearable
                                                              formatOptionLabel={(
                                                                data
                                                              ) =>
                                                                data.isAgentTeam ? (
                                                                  <div
                                                                    style={{
                                                                      color:
                                                                        "#103D56",
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                    onClick={() =>
                                                                      setIsModalOpen(
                                                                        true
                                                                      )
                                                                    }
                                                                  >
                                                                    {" "}
                                                                    + Add new
                                                                    Agent
                                                                  </div>
                                                                ) : (
                                                                  data.label
                                                                )
                                                              }
                                                              noOptionsMessage={() =>
                                                                isAddingTeam ? (
                                                                  <div
                                                                    className="modal-add-teams"
                                                                    style={{
                                                                      color:
                                                                        "#103D56",
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                    onClick={() =>
                                                                      setIsModalOpen(
                                                                        true
                                                                      )
                                                                    }
                                                                  >
                                                                    + Add new
                                                                    Agent
                                                                  </div>
                                                                ) : (
                                                                  "No options found"
                                                                )
                                                              }
                                                            />

                                                            {/* Modal for Adding a New Team */}
                                                            <Modal
                                                              isOpen={
                                                                isModalOpen
                                                              }
                                                              onRequestClose={() =>
                                                                setIsModalOpen(
                                                                  false
                                                                )
                                                              }
                                                              contentLabel="Add New Agent"
                                                              style={{
                                                                content: {
                                                                  top: "50%",
                                                                  left: "50%",
                                                                  right: "auto",
                                                                  bottom:
                                                                    "auto",
                                                                  marginRight:
                                                                    "-50%",
                                                                  transform:
                                                                    "translate(-50%, -50%)",
                                                                  width: "100%",
                                                                  maxWidth:
                                                                    "500px",
                                                                },
                                                              }}
                                                            >
                                                              <div className="heding-add-new">
                                                                <h3>
                                                                  Add New Agent
                                                                </h3>
                                                              </div>
                                                              <div className="add-new-input">
                                                                <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder="Enter New Agent Name"
                                                                  value={
                                                                    newTeamName
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    setNewTeamName(
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  }
                                                                  onKeyPress={(
                                                                    event
                                                                  ) => {
                                                                    const regex =
                                                                      /^[a-zA-Z\s]*$/;
                                                                    if (
                                                                      !regex.test(
                                                                        event.key
                                                                      )
                                                                    ) {
                                                                      event.preventDefault();
                                                                    }
                                                                  }}
                                                                  onPaste={(
                                                                    event
                                                                  ) => {
                                                                    const pastedData =
                                                                      event.clipboardData.getData(
                                                                        "Text"
                                                                      );
                                                                    const regex =
                                                                      /^[a-zA-Z\s]*$/;
                                                                    if (
                                                                      !regex.test(
                                                                        pastedData
                                                                      )
                                                                    ) {
                                                                      event.preventDefault();
                                                                    }
                                                                  }}
                                                                />
                                                              </div>
                                                              <div className="add-team-btn ">
                                                                <button
                                                                  className="add-modal-btn-ps"
                                                                  onClick={() =>
                                                                    handleAddTeam(
                                                                      values,
                                                                      setFieldValue,
                                                                      index,
                                                                      "player"
                                                                    )
                                                                  }
                                                                >
                                                                  Add
                                                                </button>
                                                                <button
                                                                  onClick={() =>
                                                                    setIsModalOpen(
                                                                      false
                                                                    )
                                                                  }
                                                                >
                                                                  Cancel
                                                                </button>
                                                              </div>
                                                            </Modal>
                                                          </div>

                                                          <ErrorMessage
                                                            name={`players[${index}].player_associate_agent_id`}
                                                            // name="associate_agent_id"
                                                            component="div"
                                                            className="text-danger"
                                                          />
                                                        </Form.Group>
                                                      </div>
                                                    </div>
                                                  </Col>

                                                  <Col lg={6}>
                                                    <div className="   this-team">
                                                      <p className="h-20">
                                                        Agent’s Fees Charged
                                                      </p>
                                                      <div className="cmn-form-fields mt-2">
                                                        <Form.Group className="mb-3">
                                                          <Form.Control
                                                            type="number"
                                                            placeholder="Agent’s Fees Charged"
                                                            name={`players[${index}].player_associate_agent_fee_charged`}
                                                            as={Field}
                                                          />
                                                          <ErrorMessage
                                                            name={`players[${index}].player_associate_agent_fee_charged`}
                                                            component="div"
                                                            className="text-danger"
                                                          />
                                                        </Form.Group>
                                                      </div>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>

                                              <div className="this-team ">
                                                <div className="cmn-form-fields ">
                                                  <Row>
                                                    <Col lg={6}>
                                                      <div className="this-team">
                                                        <p className="h-20 mb-2">
                                                          Agent’s Fees Received
                                                        </p>
                                                      </div>
                                                      <Form.Group className="mb-3">
                                                        <Form.Control
                                                          type="number"
                                                          placeholder="Agent’s Fees Received"
                                                          name={`players[${index}].player_associate_agent_fee_received`}
                                                          as={Field}
                                                        />
                                                        <ErrorMessage
                                                          name={`players[${index}].player_associate_agent_fee_received`}
                                                          component="div"
                                                          className="text-danger"
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                      <div className="this-team ">
                                                        <p className="h-20 mb-2">
                                                          Received On
                                                        </p>
                                                      </div>
                                                      <Form.Group className="mb-3">
                                                        <Form.Control
                                                          name={`players[${index}].player_associate_agent_fee_received_on`}
                                                          placeholder="Received On"
                                                          as={Field}
                                                          type={inputType1}
                                                          onFocus={() =>
                                                            setInputType1(
                                                              "date"
                                                            )
                                                          }
                                                          onBlur={() =>
                                                            setInputType1(
                                                              "text"
                                                            )
                                                          }
                                                        />
                                                        <ErrorMessage
                                                          name={`players[${index}].player_associate_agent_fee_received_on`}
                                                          component="div"
                                                          className="text-danger"
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </div>

                                              <div className="cmn-form-fields ">
                                                <Row>
                                                  <Col lg={6}>
                                                    <div className="this-team">
                                                      <p className="h-20 mb-2 ">
                                                        Agent’s Fees Pending
                                                      </p>
                                                    </div>
                                                    <Form.Group className="mb-3">
                                                      <Form.Control
                                                        type="number"
                                                        placeholder="Agent’s Fees Pending"
                                                        name={`players[${index}].player_associate_agent_fee_pending`}
                                                        as={Field}
                                                      />
                                                      <ErrorMessage
                                                        name={`players[${index}].player_associate_agent_fee_pending`}
                                                        component="div"
                                                        className="text-danger"
                                                      />
                                                    </Form.Group>
                                                  </Col>
                                                  <Col lg={6}>
                                                    <div className="this-team">
                                                      <p className="h-20 mb-2">
                                                        Will Receive on
                                                      </p>
                                                    </div>
                                                    <Form.Group className="mb-3">
                                                      <Form.Control
                                                        name={`players[${index}].player_associate_agent_fee_will_received_on`}
                                                        placeholder="Will Receive On"
                                                        as={Field}
                                                        type={inputType}
                                                        onFocus={() =>
                                                          setInputType("date")
                                                        }
                                                        onBlur={() =>
                                                          setInputType("text")
                                                        }
                                                      />
                                                      <ErrorMessage
                                                        name={`players[${index}].player_associate_agent_fee_will_received_on`}
                                                        component="div"
                                                        className="text-danger"
                                                      />
                                                    </Form.Group>
                                                  </Col>
                                                </Row>
                                              </div>

                                              <div className="">
                                                <div className="cmn-form-fields ">
                                                  <Row>
                                                    <Col lg={6}>
                                                      <div className="this-team">
                                                        <p className="h-20 mb-2">
                                                          Team’s Contact Person
                                                        </p>
                                                      </div>
                                                      <Form.Group className="mb-3">
                                                        <Form.Control
                                                          type="text"
                                                          placeholder="Team Contact Person Name"
                                                          name={`players[${index}].player_team_contact_person_name`}
                                                          as={Field}
                                                        />
                                                        <ErrorMessage
                                                          name={`players[${index}].player_team_contact_person_name`}
                                                          component="div"
                                                          className="text-danger"
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                      <div className="this-team">
                                                        <p className="h-20 mb-2">
                                                          Contact Number
                                                        </p>
                                                      </div>
                                                      <Form.Group className="mb-3">
                                                        <Form.Control
                                                          type="text"
                                                          placeholder="Contact Phone Number"
                                                          name={`players[${index}].player_team_contact_person_phone_number`}
                                                          as={Field}
                                                        />
                                                        <ErrorMessage
                                                          name={`players[${index}].player_team_contact_person_phone_number`}
                                                          component="div"
                                                          className="text-danger"
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          <div className="">
                                            <div className="cmn-form-fields ">
                                              <div className="this-team">
                                                <p className="h-20 mb-2">
                                                  Other Notes
                                                </p>
                                              </div>
                                              <Form.Group className="mb-3">
                                                <Field
                                                  as="textarea"
                                                  name={`players[${index}].player_other_notes`}
                                                  rows={3}
                                                  placeholder="Write Notes"
                                                  className="form-control"
                                                />
                                                <ErrorMessage
                                                  name={`players[${index}].player_other_notes`}
                                                  component="div"
                                                  className="text-danger"
                                                />
                                              </Form.Group>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* team proposed */}
                        <div>
                          <div className=" mt-4">
                            <div className="d-flex justify-content-between this-team">
                              <p className="h-20">
                                Does this Team proposed to me any players?
                              </p>
                            </div>
                            <div className="cmn-form-fields mt-2">
                              <Row>
                                <Col lg={6}>
                                  <div className="licensed-sec this-team">
                                    <p>Yes</p>
                                    <div className="form-check form-switch">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        value={"1"}
                                        name="has_team_proposed_player"
                                        checked={
                                          values.has_team_proposed_player == 1
                                        }
                                        onChange={() =>
                                          setFieldValue(
                                            "has_team_proposed_player",
                                            1
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="licensed-sec this-team">
                                    <p>No</p>
                                    <div className="form-check form-switch">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        value={"0"}
                                        name="has_team_proposed_player "
                                        checked={
                                          values.has_team_proposed_player == 0
                                        }
                                        onChange={() =>
                                          setFieldValue(
                                            "has_team_proposed_player",
                                            0
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <ErrorMessage
                                  name="has_team_proposed_player"
                                  component="div"
                                  className="text-danger"
                                />
                              </Row>
                            </div>
                          </div>
                          {values.has_team_proposed_player == 1 && (
                            <div className="">
                              <div className="cmn-form-fields mt-3">
                                <FieldArray name="proposed_team_players">
                                  {({ push, remove }) => (
                                    <>
                                      <div className="d-flex justify-content-between">
                                        {" "}
                                        <h3 className="h-20">Players 1</h3>
                                        <button
                                          type="button"
                                          style={{
                                            border: "none",
                                            background: "none",
                                          }}
                                          onClick={() =>
                                            push({
                                              team_proposed_player_id: "",
                                              who_bought_team_proposed_player:
                                                "0",
                                              team_proposed_player_associate_agent_id:
                                                "",
                                              proposed_team_other_notes: "",
                                            })
                                          }
                                        >
                                          <svg
                                            width="28"
                                            coaching_licenses="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                              fill="#4C8BB4"
                                            />
                                          </svg>
                                        </button>
                                      </div>

                                      {values.proposed_team_players.map(
                                        (player, index) => (
                                          <div key={index}>
                                            {index !== 0 && (
                                              <div className=" mt-3 ">
                                                <div
                                                  className={`more-coache ${
                                                    index > 0 ? "mt-3" : ""
                                                  }`}
                                                >
                                                  {index !== 0 && (
                                                    <h3 className="h-20">
                                                      Players {index + 1}
                                                    </h3>
                                                  )}
                                                  <button
                                                    type="button"
                                                    style={{
                                                      border: "none",
                                                      background: "none",
                                                    }}
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    <svg
                                                      width="28"
                                                      height="28"
                                                      viewBox="0 0 28 28"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                        fill="#4C8BB4"
                                                      />
                                                    </svg>
                                                  </button>
                                                </div>
                                              </div>
                                            )}
                                            <Row>
                                              <Col
                                                lg={12}
                                                className="label-text-up"
                                              >
                                                <label className="mb-2">
                                                  Full Name
                                                </label>
                                                {/* <Field
                                                  as={Form.Control}
                                                  type="text"
                                                  name={`proposed_team_players[${index}].team_proposed_player_id`}
                                                  placeholder="Enter name"
                                                /> */}
                                                <Form.Group className="mb-3">
                                                  <Select
                                                    className={`form-control`}
                                                    name={`proposed_team_players.${index}.team_proposed_player_id`}
                                                    placeholder="Select"
                                                    classNamePrefix="custom-select"
                                                    options={playerOptions}
                                                    onChange={(option) =>
                                                      setFieldValue(
                                                        `proposed_team_players.${index}.team_proposed_player_id`,
                                                        option
                                                          ? option.value
                                                          : ""
                                                      )
                                                    }
                                                    value={playerOptions?.find(
                                                      (elem) =>
                                                        elem.value ==
                                                        values
                                                          .proposed_team_players[
                                                          index
                                                        ]
                                                          .team_proposed_player_id
                                                          ?._id
                                                    )}
                                                  />
                                                </Form.Group>
                                                <ErrorMessage
                                                  name={`proposed_team_players[${index}].team_proposed_player_id`}
                                                  component="div"
                                                  className="text-danger"
                                                />
                                              </Col>
                                            </Row>

                                            <div className=" mt-2 this-team">
                                              <p className="h-20">
                                                Who Proposed Player to this team
                                              </p>
                                              <div className="cmn-form-fields mt-2">
                                                <Row>
                                                  <Col lg={6}>
                                                    <div className="licensed-sec this-team">
                                                      <p>Me</p>
                                                      <div className="form-check form-switch">
                                                        <Field
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`proposed_team_players[${index}].who_bought_team_proposed_player`}
                                                          value={"0"}
                                                          as="input"
                                                          checked={
                                                            player.who_bought_team_proposed_player ==
                                                            "0"
                                                          }
                                                          onChange={() =>
                                                            setFieldValue(
                                                              `proposed_team_players[${index}].who_bought_team_proposed_player`,
                                                              "0"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </Col>

                                                  <Col lg={6}>
                                                    <div className="licensed-sec this-team">
                                                      <p>Associate agent’s</p>
                                                      <div className="form-check form-switch">
                                                        <Field
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`proposed_team_players[${index}].who_bought_team_proposed_player`}
                                                          value={"1"}
                                                          as="input"
                                                          checked={
                                                            player.who_bought_team_proposed_player ==
                                                            "1"
                                                          }
                                                          onChange={() =>
                                                            setFieldValue(
                                                              `proposed_team_players[${index}].who_bought_team_proposed_player`,
                                                              "1"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </Col>
                                                  <ErrorMessage
                                                    name={`proposed_team_players[${index}].who_bought_team_proposed_player`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </Row>
                                              </div>
                                            </div>

                                            {player.who_bought_team_proposed_player ==
                                              "1" && (
                                              <div>
                                                <div className="this-team mt-4">
                                                  {/* <p className="h-20">
                                                    Name of Associate Agent
                                                  </p> */}
                                                  <div className="cmn-form-fields mt-2">
                                                    <Row>
                                                      {/* <Col lg={6}>
                                                        <Form.Group className="mb-3">
                                                          <Field
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter name"
                                                            name={`proposed_team_players.${index}.team_proposed_player_associate_agent_id`}
                                                          />

                                                          <Select
                                                            className="form-control"
                                                            name={`proposed_team_players[${index}].team_proposed_player_associate_agent_id`}
                                                            placeholder="Enter name"
                                                            options={
                                                              associateAgentOptions
                                                            }
                                                            onChange={(
                                                              option
                                                            ) =>
                                                              setFieldValue(
                                                                `proposed_team_players[${index}].team_proposed_player_associate_agent_id`,
                                                                option
                                                                  ? option.value
                                                                  : ""
                                                              )
                                                            }
                                                            value={associateAgentOptions?.find(
                                                              (elem) =>
                                                                elem.value ==
                                                                values
                                                                  .proposed_team_players[
                                                                  index
                                                                ]
                                                                  .team_proposed_player_associate_agent_id
                                                            )}
                                                          />
                                                          <ErrorMessage
                                                            name={`proposed_team_players[${index}].team_proposed_player_associate_agent_id`}
                                                            component="div"
                                                            className="text-danger"
                                                          />
                                                        </Form.Group>
                                                      </Col> */}

                                                      <Col lg={6}>
                                                        <div className="">
                                                          <div className="d-flex justify-content-between    this-team">
                                                            <p className="h-20">
                                                              Name of Associate
                                                              Agent
                                                            </p>
                                                          </div>
                                                          <div className="cmn-form-fields mt-2">
                                                            <Form.Group className=" form-inner">
                                                              <div>
                                                                <Select
                                                                  name={`proposed_team_players.${index}.team_proposed_player_associate_agent_id`}
                                                                  // name="associate_agent_id"
                                                                  placeholder="Select"
                                                                  classNamePrefix="custom-select"
                                                                  options={
                                                                    customOptions
                                                                  }
                                                                  components={{
                                                                    DropdownIndicator:
                                                                      null,
                                                                  }}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleSelectChange(
                                                                      e,
                                                                      setFieldValue,
                                                                      index,
                                                                      "proposed_team"
                                                                    )
                                                                  }
                                                                  onInputChange={
                                                                    handleInputChange
                                                                  }
                                                                  value={
                                                                    values
                                                                      ?.proposed_team_players[
                                                                      index
                                                                    ]
                                                                      .team_proposed_player_associate_agent_id ==
                                                                    ""
                                                                      ? ""
                                                                      : agentOptions?.find(
                                                                          (
                                                                            elem
                                                                          ) =>
                                                                            elem.value ==
                                                                            values
                                                                              .proposed_team_players[
                                                                              index
                                                                            ]
                                                                              .team_proposed_player_associate_agent_id?._id
                                                                        )
                                                                  }
                                                                  isClearable
                                                                  formatOptionLabel={(
                                                                    data
                                                                  ) =>
                                                                    data.isAgentTeam ? (
                                                                      <div
                                                                        style={{
                                                                          color:
                                                                            "#103D56",
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                        onClick={() =>
                                                                          setIsModalOpen3(
                                                                            true
                                                                          )
                                                                        }
                                                                      >
                                                                        {" "}
                                                                        + Add
                                                                        new
                                                                        Agent
                                                                      </div>
                                                                    ) : (
                                                                      data.label
                                                                    )
                                                                  }
                                                                  noOptionsMessage={() =>
                                                                    isAddingTeam ? (
                                                                      <div
                                                                        className="modal-add-teams"
                                                                        style={{
                                                                          color:
                                                                            "#103D56",
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                        onClick={() =>
                                                                          setIsModalOpen3(
                                                                            true
                                                                          )
                                                                        }
                                                                      >
                                                                        + Add
                                                                        new
                                                                        Agent
                                                                      </div>
                                                                    ) : (
                                                                      "No options found"
                                                                    )
                                                                  }
                                                                />

                                                                {/* Modal for Adding a New Team */}
                                                                <Modal
                                                                  isOpen={
                                                                    isModalOpen3
                                                                  }
                                                                  onRequestClose={() =>
                                                                    setIsModalOpen3(
                                                                      false
                                                                    )
                                                                  }
                                                                  contentLabel="Add New Agent"
                                                                  style={{
                                                                    content: {
                                                                      top: "50%",
                                                                      left: "50%",
                                                                      right:
                                                                        "auto",
                                                                      bottom:
                                                                        "auto",
                                                                      marginRight:
                                                                        "-50%",
                                                                      transform:
                                                                        "translate(-50%, -50%)",
                                                                      width:
                                                                        "100%",
                                                                      maxWidth:
                                                                        "500px",
                                                                    },
                                                                  }}
                                                                >
                                                                  <div className="heding-add-new">
                                                                    <h3>
                                                                      Add New
                                                                      Agent
                                                                    </h3>
                                                                  </div>
                                                                  <div className="add-new-input">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      placeholder="Enter New Agent Name"
                                                                      value={
                                                                        newTeamName
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        setNewTeamName(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        )
                                                                      }
                                                                      onKeyPress={(
                                                                        event
                                                                      ) => {
                                                                        const regex =
                                                                          /^[a-zA-Z\s]*$/;
                                                                        if (
                                                                          !regex.test(
                                                                            event.key
                                                                          )
                                                                        ) {
                                                                          event.preventDefault();
                                                                        }
                                                                      }}
                                                                      onPaste={(
                                                                        event
                                                                      ) => {
                                                                        const pastedData =
                                                                          event.clipboardData.getData(
                                                                            "Text"
                                                                          );
                                                                        const regex =
                                                                          /^[a-zA-Z\s]*$/;
                                                                        if (
                                                                          !regex.test(
                                                                            pastedData
                                                                          )
                                                                        ) {
                                                                          event.preventDefault();
                                                                        }
                                                                      }}
                                                                    />
                                                                  </div>
                                                                  <div className="add-team-btn ">
                                                                    <button
                                                                      className="add-modal-btn-ps"
                                                                      onClick={() =>
                                                                        handleAddTeam(
                                                                          values,
                                                                          setFieldValue,
                                                                          index,
                                                                          "proposed_team"
                                                                        )
                                                                      }
                                                                    >
                                                                      Add
                                                                    </button>
                                                                    <button
                                                                      onClick={() =>
                                                                        setIsModalOpen3(
                                                                          false
                                                                        )
                                                                      }
                                                                    >
                                                                      Cancel
                                                                    </button>
                                                                  </div>
                                                                </Modal>
                                                              </div>

                                                              <ErrorMessage
                                                                name={`proposed_team_players.${index}.team_proposed_player_associate_agent_id`}
                                                                // name="associate_agent_id"
                                                                component="div"
                                                                className="text-danger"
                                                              />
                                                            </Form.Group>
                                                          </div>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                            <div className="">
                                              <div className="cmn-form-fields ">
                                                <div className="this-team">
                                                  <p className="h-20 mb-2">
                                                    Other Notes
                                                  </p>
                                                </div>
                                                <Form.Group className="mb-3">
                                                  <Field
                                                    as="textarea"
                                                    name={`proposed_team_players[${index}].proposed_team_other_notes`}
                                                    rows={3}
                                                    placeholder="Other Notes"
                                                    className="form-control"
                                                  />
                                                  <ErrorMessage
                                                    name={`proposed_team_players[${index}].proposed_team_other_notes`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </Form.Group>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>

                      {/* second part */}
                      <Col lg={6}>
                        <div>
                          <div className=" mt-4">
                            <div className="d-flex justify-content-between this-team">
                              <p className="h-20">
                                Players I have Proposed to this Team
                              </p>
                            </div>
                            <div className="cmn-form-fields mt-2">
                              <Row>
                                <Col lg={6}>
                                  <div className="licensed-sec this-team">
                                    <p>Yes</p>
                                    <div className="form-check form-switch">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        value={"1"}
                                        name="has_proposed_player"
                                        checked={
                                          values.has_proposed_player == 1
                                        }
                                        onChange={() =>
                                          setFieldValue(
                                            "has_proposed_player",
                                            1
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="licensed-sec this-team">
                                    <p>No</p>
                                    <div className="form-check form-switch">
                                      <Field
                                        className="form-check-input"
                                        type="radio"
                                        value={"0"}
                                        name="has_proposed_player "
                                        checked={
                                          values.has_proposed_player == 0
                                        }
                                        onChange={() =>
                                          setFieldValue(
                                            "has_proposed_player",
                                            0
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <ErrorMessage
                                  name="has_proposed_player"
                                  component="div"
                                  className="text-danger"
                                />
                              </Row>
                            </div>
                          </div>
                          {values.has_proposed_player == 1 && (
                            <div className="">
                              <div className="cmn-form-fields mt-3">
                                <FieldArray name="proposed_players">
                                  {({ push, remove }) => (
                                    <>
                                      <div className="d-flex justify-content-between">
                                        {" "}
                                        <h3 className="h-20">Players 1</h3>
                                        <button
                                          type="button"
                                          style={{
                                            border: "none",
                                            background: "none",
                                          }}
                                          onClick={() =>
                                            push({
                                              proposed_player_id: "",
                                              who_bought_proposed_player: "0",
                                              proposed_player_associate_agent_id:
                                                "",
                                              proposed_team_other_notes: "",
                                            })
                                          }
                                        >
                                          <svg
                                            width="28"
                                            coaching_licenses="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M14 0C11.2311 0 8.52431 0.821085 6.22202 2.35942C3.91973 3.89776 2.12532 6.08426 1.06569 8.64243C0.00606604 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C27.9956 10.2883 26.5192 6.7299 23.8947 4.10534C21.2701 1.48078 17.7117 0.00437878 14 0ZM20.3636 15.2727H15.2727V20.3636C15.2727 20.7012 15.1386 21.0249 14.9 21.2636C14.6613 21.5023 14.3376 21.6364 14 21.6364C13.6625 21.6364 13.3387 21.5023 13.1 21.2636C12.8614 21.0249 12.7273 20.7012 12.7273 20.3636V15.2727H7.63637C7.29882 15.2727 6.9751 15.1386 6.73641 14.9C6.49773 14.6613 6.36364 14.3375 6.36364 14C6.36364 13.6624 6.49773 13.3387 6.73641 13.1C6.9751 12.8614 7.29882 12.7273 7.63637 12.7273H12.7273V7.63636C12.7273 7.29881 12.8614 6.97509 13.1 6.73641C13.3387 6.49773 13.6625 6.36364 14 6.36364C14.3376 6.36364 14.6613 6.49773 14.9 6.73641C15.1386 6.97509 15.2727 7.29881 15.2727 7.63636V12.7273H20.3636C20.7012 12.7273 21.0249 12.8614 21.2636 13.1C21.5023 13.3387 21.6364 13.6624 21.6364 14C21.6364 14.3375 21.5023 14.6613 21.2636 14.9C21.0249 15.1386 20.7012 15.2727 20.3636 15.2727Z"
                                              fill="#4C8BB4"
                                            />
                                          </svg>
                                        </button>
                                      </div>

                                      {values.proposed_players.map(
                                        (player, index) => (
                                          <div key={index}>
                                            {index !== 0 && (
                                              <div className=" mt-3 ">
                                                <div
                                                  className={`more-coache ${
                                                    index > 0 ? "mt-3" : ""
                                                  }`}
                                                >
                                                  {index !== 0 && (
                                                    <h3 className="h-20">
                                                      Players {index + 1}
                                                    </h3>
                                                  )}
                                                  <button
                                                    type="button"
                                                    style={{
                                                      border: "none",
                                                      background: "none",
                                                    }}
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    <svg
                                                      width="28"
                                                      height="28"
                                                      viewBox="0 0 28 28"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM6.22222 13C5.66994 13 5.22222 13.4477 5.22222 14C5.22222 14.5523 5.66994 15 6.22222 15H21.7778C22.3301 15 22.7778 14.5523 22.7778 14C22.7778 13.4477 22.3301 13 21.7778 13H6.22222Z"
                                                        fill="#4C8BB4"
                                                      />
                                                    </svg>
                                                  </button>
                                                </div>
                                              </div>
                                            )}
                                            <Row>
                                              <Col
                                                lg={12}
                                                className="label-text-up"
                                              >
                                                <label className="mb-2">
                                                  Full Name
                                                </label>
                                                {/* <Field
                                                  as={Form.Control}
                                                  type="text"
                                                  name={`proposed_players[${index}].proposed_player_id`}
                                                  placeholder="Enter name"
                                                /> */}
                                                <Form.Group className="mb-3">
                                                  <Select
                                                    className={`form-control`}
                                                    name={`proposed_players.${index}.proposed_player_id`}
                                                    placeholder="Select"
                                                    classNamePrefix="custom-select"
                                                    options={playerOptions}
                                                    onChange={(option) =>
                                                      setFieldValue(
                                                        `proposed_players.${index}.proposed_player_id`,
                                                        option
                                                          ? option.value
                                                          : ""
                                                      )
                                                    }
                                                    value={playerOptions?.find(
                                                      (elem) =>
                                                        elem.value ==
                                                        values.proposed_players[
                                                          index
                                                        ].proposed_player_id
                                                          ?._id
                                                    )}
                                                  />
                                                </Form.Group>
                                                <ErrorMessage
                                                  name={`proposed_players[${index}].proposed_player_id`}
                                                  component="div"
                                                  className="text-danger"
                                                />
                                              </Col>
                                            </Row>

                                            <div className=" mt-2 this-team">
                                              <p className="h-20">
                                                Who Proposed Player to this team
                                              </p>
                                              <div className="cmn-form-fields mt-2">
                                                <Row>
                                                  <Col lg={6}>
                                                    <div className="licensed-sec this-team">
                                                      <p>Me</p>
                                                      <div className="form-check form-switch">
                                                        <Field
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`proposed_players[${index}].who_bought_proposed_player`}
                                                          value={"0"}
                                                          as="input"
                                                          checked={
                                                            player.who_bought_proposed_player ==
                                                            "0"
                                                          }
                                                          onChange={() =>
                                                            setFieldValue(
                                                              `proposed_players[${index}].who_bought_proposed_player`,
                                                              "0"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </Col>

                                                  <Col lg={6}>
                                                    <div className="licensed-sec this-team">
                                                      <p>Associate agent’s</p>
                                                      <div className="form-check form-switch">
                                                        <Field
                                                          className="form-check-input"
                                                          type="radio"
                                                          name={`proposed_players[${index}].who_bought_proposed_player`}
                                                          value={"1"}
                                                          as="input"
                                                          checked={
                                                            player.who_bought_proposed_player ==
                                                            "1"
                                                          }
                                                          onChange={() =>
                                                            setFieldValue(
                                                              `proposed_players[${index}].who_bought_proposed_player`,
                                                              "1"
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </Col>
                                                  <ErrorMessage
                                                    name={`proposed_players[${index}].who_bought_proposed_player`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </Row>
                                              </div>
                                            </div>

                                            {player.who_bought_proposed_player ==
                                              "1" && (
                                              <div>
                                                <div className="this-team mt-4">
                                                  {/* <p className="h-20">
                                                    Name of Associate Agent
                                                  </p> */}
                                                  <div className="cmn-form-fields mt-2">
                                                    <Row>
                                                      {/* <Col lg={6}>
                                                        <Form.Group className="mb-3">
                                                          <Field
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter name"
                                                            name={`proposed_players.${index}.proposed_player_associate_agent_id`}
                                                          />

                                                          <Select
                                                            className="form-control"
                                                            name={`proposed_players[${index}].proposed_player_associate_agent_id`}
                                                            placeholder="Enter name"
                                                            options={
                                                              associateAgentOptions
                                                            }
                                                            onChange={(
                                                              option
                                                            ) =>
                                                              setFieldValue(
                                                                `proposed_players[${index}].proposed_player_associate_agent_id`,
                                                                option
                                                                  ? option.value
                                                                  : ""
                                                              )
                                                            }
                                                            value={associateAgentOptions?.find(
                                                              (elem) =>
                                                                elem.value ==
                                                                values
                                                                  .proposed_players[
                                                                  index
                                                                ]
                                                                  .proposed_player_associate_agent_id
                                                            )}
                                                          />
                                                          <ErrorMessage
                                                            name={`proposed_players[${index}].proposed_player_associate_agent_id`}
                                                            component="div"
                                                            className="text-danger"
                                                          />
                                                        </Form.Group>
                                                      </Col> */}

                                                      <Col lg={6}>
                                                        <div className="">
                                                          <div className="d-flex justify-content-between    this-team">
                                                            <p className="h-20">
                                                              Name of Associate
                                                              Agent
                                                            </p>
                                                          </div>
                                                          <div className="cmn-form-fields mt-2">
                                                            <Form.Group className=" form-inner">
                                                              <div>
                                                                <Select
                                                                  name={`proposed_players.${index}.proposed_player_associate_agent_id`}
                                                                  // name="associate_agent_id"
                                                                  placeholder="Select"
                                                                  classNamePrefix="custom-select"
                                                                  options={
                                                                    customOptions
                                                                  }
                                                                  components={{
                                                                    DropdownIndicator:
                                                                      null,
                                                                  }}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleSelectChange(
                                                                      e,
                                                                      setFieldValue,
                                                                      index,
                                                                      "proposed"
                                                                    )
                                                                  }
                                                                  onInputChange={
                                                                    handleInputChange
                                                                  }
                                                                  value={
                                                                    values
                                                                      ?.proposed_players[
                                                                      index
                                                                    ]
                                                                      .proposed_player_associate_agent_id ==
                                                                    ""
                                                                      ? ""
                                                                      : agentOptions?.find(
                                                                          (
                                                                            elem
                                                                          ) =>
                                                                            elem.value ==
                                                                            values
                                                                              .proposed_players[
                                                                              index
                                                                            ]
                                                                              .proposed_player_associate_agent_id?._id
                                                                        )
                                                                  }
                                                                  isClearable
                                                                  formatOptionLabel={(
                                                                    data
                                                                  ) =>
                                                                    data.isAgentTeam ? (
                                                                      <div
                                                                        style={{
                                                                          color:
                                                                            "#103D56",
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                        onClick={() =>
                                                                          setIsModalOpen2(
                                                                            true
                                                                          )
                                                                        }
                                                                      >
                                                                        {" "}
                                                                        + Add
                                                                        new
                                                                        Agent
                                                                      </div>
                                                                    ) : (
                                                                      data.label
                                                                    )
                                                                  }
                                                                  noOptionsMessage={() =>
                                                                    isAddingTeam ? (
                                                                      <div
                                                                        className="modal-add-teams"
                                                                        style={{
                                                                          color:
                                                                            "#103D56",
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                        onClick={() =>
                                                                          setIsModalOpen2(
                                                                            true
                                                                          )
                                                                        }
                                                                      >
                                                                        + Add
                                                                        new
                                                                        Agent
                                                                      </div>
                                                                    ) : (
                                                                      "No options found"
                                                                    )
                                                                  }
                                                                />

                                                                {/* Modal for Adding a New Team */}
                                                                <Modal
                                                                  isOpen={
                                                                    isModalOpen2
                                                                  }
                                                                  onRequestClose={() =>
                                                                    setIsModalOpen2(
                                                                      false
                                                                    )
                                                                  }
                                                                  contentLabel="Add New Agent"
                                                                  style={{
                                                                    content: {
                                                                      top: "50%",
                                                                      left: "50%",
                                                                      right:
                                                                        "auto",
                                                                      bottom:
                                                                        "auto",
                                                                      marginRight:
                                                                        "-50%",
                                                                      transform:
                                                                        "translate(-50%, -50%)",
                                                                      width:
                                                                        "100%",
                                                                      maxWidth:
                                                                        "500px",
                                                                    },
                                                                  }}
                                                                >
                                                                  <div className="heding-add-new">
                                                                    <h3>
                                                                      Add New
                                                                      Agent
                                                                    </h3>
                                                                  </div>
                                                                  <div className="add-new-input">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      placeholder="Enter New Agent Name"
                                                                      value={
                                                                        newTeamName
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        setNewTeamName(
                                                                          e
                                                                            .target
                                                                            .value
                                                                        )
                                                                      }
                                                                      onKeyPress={(
                                                                        event
                                                                      ) => {
                                                                        const regex =
                                                                          /^[a-zA-Z\s]*$/;
                                                                        if (
                                                                          !regex.test(
                                                                            event.key
                                                                          )
                                                                        ) {
                                                                          event.preventDefault();
                                                                        }
                                                                      }}
                                                                      onPaste={(
                                                                        event
                                                                      ) => {
                                                                        const pastedData =
                                                                          event.clipboardData.getData(
                                                                            "Text"
                                                                          );
                                                                        const regex =
                                                                          /^[a-zA-Z\s]*$/;
                                                                        if (
                                                                          !regex.test(
                                                                            pastedData
                                                                          )
                                                                        ) {
                                                                          event.preventDefault();
                                                                        }
                                                                      }}
                                                                    />
                                                                  </div>
                                                                  <div className="add-team-btn ">
                                                                    <button
                                                                      className="add-modal-btn-ps"
                                                                      onClick={() =>
                                                                        handleAddTeam(
                                                                          values,
                                                                          setFieldValue,
                                                                          index,
                                                                          "proposed"
                                                                        )
                                                                      }
                                                                    >
                                                                      Add
                                                                    </button>
                                                                    <button
                                                                      onClick={() =>
                                                                        setIsModalOpen2(
                                                                          false
                                                                        )
                                                                      }
                                                                    >
                                                                      Cancel
                                                                    </button>
                                                                  </div>
                                                                </Modal>
                                                              </div>

                                                              <ErrorMessage
                                                                name={`proposed_players.${index}.proposed_player_associate_agent_id`}
                                                                // name="associate_agent_id"
                                                                component="div"
                                                                className="text-danger"
                                                              />
                                                            </Form.Group>
                                                          </div>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                            <div className="">
                                              <div className="cmn-form-fields ">
                                                <div className="this-team">
                                                  <p className="h-20 mb-2">
                                                    Other Notes
                                                  </p>
                                                </div>
                                                <Form.Group className="mb-3">
                                                  <Field
                                                    as="textarea"
                                                    name={`proposed_players[${index}].proposed_player_other_notes`}
                                                    rows={3}
                                                    placeholder="Write Notes"
                                                    className="form-control"
                                                  />
                                                  <ErrorMessage
                                                    name={`proposed_players[${index}].proposed_player_other_notes`}
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </Form.Group>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>

                    {/* <div className="this-team mt-4">
                      <p className="h-20">Other Notes</p>
                      <div className="cmn-form-fields mt-2">
                        <Row>
                          <Col lg={12}>
                            <Form.Group className="mb-3 input-plc-mk">
                              <Field
                                as="textarea"
                                name={"player_other_notes"}
                                rows={3}
                                placeholder="Write notes here"
                                className="form-control"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </div> */}
                  </div>

                  <div className="d-flex justify-content-between mb-5">
                    <div className="d-flex">
                      <div className="mt-4 cmn-light-btn ">
                        <button
                          className="button"
                          type="button"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="mt-4 me-2 cmn-light-btn save-and-close-btn">
                        <button
                          type="button"
                          onClick={() =>
                            handleSaveAndClose(values, validateForm)
                          }
                          className="next-btn-ps"
                        >
                          Save & Close
                        </button>
                      </div>
                      <div className="mt-4 cmn-light-btn">
                        <button type="submit" className="next-btn-ps">
                          {" "}
                          Save & Next
                        </button>
                      </div>
                    </div>
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Container>
      </section>
    </LoginLayout>
  );
};

export default AddTeamTwo;
